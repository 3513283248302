import React, { forwardRef } from 'react'
import cx from 'classnames'

import s from './styles.module.css'
import { LabelProps } from './types'

const Label = forwardRef<HTMLLabelElement, LabelProps>((props, ref) => {
  const {
    className,
    children,
    ...labelProps
  } = props

  const labelClasses = cx(
    s.label,
    className
  )

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label
      ref={ref}
      className={labelClasses}
      {...labelProps}>
      {children}
    </label>
  )
})

export default Label