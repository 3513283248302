import React from 'react'
import cx from 'classnames';

import SVGIcon from '../Icon'
import { SortIconProps } from '../types'

import s from './styles.module.css'

const Sort = (props: SortIconProps) => {
  const { width = 10, height = 12, sort = 'none', className, ...svgProps } = props
  const classes = cx(s.sort, [`${s[sort]}`], className)

  return (
    <SVGIcon
      width={width}
      height={height}
      className={classes}
      {...svgProps}>
      <path d="M0 1H10" />
      <path d="M0 11H10" />
      <path d="M0 6H10" />
    </SVGIcon>
  )
};

export default Sort
