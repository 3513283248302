import React, { useCallback } from 'react'
import { motion } from 'framer-motion'
import cn from 'classnames'

import { Modal, useModal } from '../Modal'
import TransakPayEmbed from './TransakPayEmbed'
import { TransakConfiguration } from './TransakPayEmbed/types'
import { TRANSAK_ENVIRONMENT_PRODUCTION, TRANSAK_ENVIRONMENT_STAGING } from './TransakPayEmbed/TransakPayEmbed'

import { TransakPayProps } from './types'
import s from './styles.module.css'

const TransakPay = (props: TransakPayProps) => {
  const {
    apiKey,
    environment,
    className,
    email,
    onClick
  } = props

  const { isOpen, open, close } = useModal()

  const transakConfig: TransakConfiguration = {
    apiKey,
    environment: environment === "STAGING" ? TRANSAK_ENVIRONMENT_STAGING : TRANSAK_ENVIRONMENT_PRODUCTION,
    hideExchangeScreen: true,
    productsAvailed: "BUY",
    defaultNetwork: "base",
    defaultCryptoCurrency: "usdc",
    networks: ["base", "mainnet"],
    cryptoCurrencyList: ["ada", "usdc", "copi", "eth"],
    fiatAmount: 250,
    defaultPaymentMethod: "credit_debit_card",
    hideMenu: true,
    colorMode: "DARK",
    themeColor: "ffa400",
    email
  };

  const handleClick = useCallback(
    () => {
      if (!isOpen)
        open()

      if (onClick) onClick()
    },
    [open, isOpen, onClick],
  )

  return (
    <>
      <motion.button
        whileTap={{ scale: 0.95 }}
        whileHover={{
          scale: 1.05
        }}
        title="Click to buy ADA or USDC"
        transition={{ duration: 0.25, yoyo: Infinity }}
        className={cn(s.transak_button, className)}
        onClick={handleClick}>
        <svg width="100%" height="100%" viewBox="0 0 194 71" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M158.411 0H35.5894C15.9339 0 0 15.8902 0 35.4917C0 55.0933 15.9339 70.9834 35.5894 70.9834H158.411C178.066 70.9834 194 55.0933 194 35.4917C194 15.8902 178.066 0 158.411 0Z" fill="white" />
          <path d="M158.412 65.2705C174.903 65.2705 188.272 51.9381 188.272 35.4917C188.272 19.0453 174.903 5.71289 158.412 5.71289C141.92 5.71289 128.551 19.0453 128.551 35.4917C128.551 51.9381 141.92 65.2705 158.412 65.2705Z" fill="black" />
          <path d="M147.771 35.3362C147.669 37.0781 149.016 38.5698 150.759 38.6679H150.949C152.709 38.6679 154.127 37.2506 154.114 35.4985C154.114 33.7431 152.692 32.3292 150.936 32.3427C149.253 32.3427 147.859 33.6585 147.771 35.3362Z" fill="white" />
          <path d="M135.937 34.521C135.37 34.4838 134.882 34.9235 134.855 35.485C134.828 36.0465 135.258 36.5369 135.821 36.564C136.388 36.6012 136.863 36.1615 136.903 35.6C136.941 35.0351 136.5 34.5616 135.937 34.521Z" fill="white" />
          <path d="M147.571 16.9697C148.073 16.7194 148.273 16.0936 148.022 15.593C147.771 15.0924 147.143 14.8929 146.641 15.1432C146.139 15.3935 145.939 16.0091 146.19 16.5097C146.441 17.0238 147.058 17.2335 147.571 16.9697Z" fill="white" />
          <path d="M150.785 22.571C151.565 22.182 151.877 21.2315 151.487 20.4536C151.097 19.6756 150.144 19.3644 149.364 19.7534C148.584 20.1424 148.272 21.0928 148.662 21.8708C149.052 22.6487 150.005 22.9599 150.785 22.571Z" fill="white" />
          <path d="M139.955 26.4168C140.559 26.8057 141.363 26.6434 141.763 26.0413C142.153 25.4392 141.99 24.6376 141.387 24.2385C140.783 23.8495 139.979 24.0119 139.579 24.6139C139.175 25.216 139.352 26.0312 139.955 26.4168Z" fill="white" />
          <path d="M141.88 33.9561C141.001 33.9054 140.259 34.5683 140.208 35.4477C140.157 36.3272 140.822 37.0645 141.704 37.1153C142.582 37.166 143.325 36.5031 143.376 35.6236C143.427 34.7577 142.762 34.0068 141.88 33.9561Z" fill="white" />
          <path d="M140.132 44.5668C139.491 44.8915 139.227 45.6695 139.556 46.3223C139.881 46.9616 140.662 47.2254 141.316 46.8973C141.957 46.5726 142.222 45.7946 141.893 45.1418C141.567 44.5025 140.773 44.2387 140.132 44.5668Z" fill="white" />
          <path d="M146.289 30.6887C147.157 31.2535 148.311 31.0134 148.863 30.1509C149.43 29.285 149.189 28.135 148.324 27.5836C147.456 27.0188 146.303 27.2589 145.75 28.1214C145.183 28.9738 145.424 30.1272 146.289 30.6887Z" fill="white" />
          <path d="M165.822 22.4458C166.551 22.9227 167.541 22.7198 168.019 21.9959C168.498 21.2721 168.294 20.2811 167.568 19.8041C166.839 19.3272 165.849 19.5302 165.37 20.254C164.882 20.9947 165.082 21.9689 165.822 22.4458Z" fill="white" />
          <path d="M169.063 16.8716C169.542 17.1861 170.169 17.0475 170.485 16.5705C170.8 16.0936 170.661 15.4679 170.183 15.1533C169.704 14.8387 169.077 14.9774 168.762 15.4408C168.46 15.9177 168.599 16.5604 169.063 16.8716Z" fill="white" />
          <path d="M166.061 32.3157C164.314 32.2142 162.808 33.557 162.72 35.2956C162.619 37.0376 163.965 38.5393 165.708 38.6273H165.885C167.631 38.6273 169.049 37.2101 169.049 35.458C169.063 33.7904 167.743 32.4036 166.061 32.3157Z" fill="white" />
          <path d="M151.827 30.4621C152.367 31.5276 153.459 32.2041 154.666 32.2041C156.413 32.2041 157.831 30.7869 157.831 29.0348C157.831 28.5477 157.719 28.0572 157.491 27.6074C156.701 26.0413 154.792 25.4156 153.231 26.2037C151.661 27.0053 151.037 28.9096 151.827 30.4621Z" fill="white" />
          <path d="M176.688 26.4167C177.329 26.092 177.58 25.3005 177.254 24.6612C176.928 24.022 176.135 23.7717 175.494 24.0964C174.853 24.4211 174.602 25.199 174.917 25.8383C175.256 26.4776 176.036 26.7448 176.688 26.4167Z" fill="white" />
          <path d="M168.65 27.4179C167.734 27.8813 167.358 28.9975 167.822 29.9108C168.287 30.824 169.406 31.1995 170.322 30.7361C171.238 30.2727 171.614 29.1565 171.15 28.2432C170.685 27.33 169.566 26.9647 168.65 27.4179Z" fill="white" />
          <path d="M158.286 16.3946C159.001 16.4318 159.619 15.8805 159.666 15.1668C159.714 14.4531 159.151 13.8375 158.435 13.7901C157.719 13.7529 157.102 14.2907 157.055 15.0044C157.017 15.7317 157.57 16.3439 158.286 16.3946Z" fill="white" />
          <path d="M158.27 24.5733C159.302 24.6376 160.18 23.8461 160.231 22.8178C160.295 21.7896 159.502 20.9135 158.471 20.8628C157.439 20.7985 156.561 21.59 156.51 22.6183C156.459 23.6465 157.239 24.5226 158.27 24.5733Z" fill="white" />
          <path d="M148.174 43.5656C149.089 43.1022 149.466 41.986 149.001 41.0728C148.537 40.1595 147.417 39.7841 146.502 40.2474C145.586 40.7108 145.209 41.827 145.674 42.7403C146.139 43.6535 147.258 44.029 148.174 43.5656Z" fill="white" />
          <path d="M159.479 27.2927C158.526 28.7573 158.927 30.7124 160.395 31.6662C161.864 32.62 163.824 32.2175 164.781 30.753C165.737 29.2884 165.334 27.3333 163.865 26.3795C163.349 26.0412 162.746 25.8654 162.132 25.8654C161.063 25.8654 160.07 26.4032 159.479 27.2927Z" fill="white" />
          <path d="M164.993 40.5214C164.203 38.9554 162.294 38.3296 160.733 39.1177C159.163 39.9058 158.536 41.8101 159.326 43.3661C160.116 44.9321 162.026 45.5579 163.586 44.7698C165.143 43.9918 165.784 42.1146 165.007 40.5485C165.007 40.5485 165.002 40.5406 164.993 40.5248V40.5214Z" fill="white" />
          <path d="M170.533 40.2948C169.665 39.7299 168.512 39.9701 167.959 40.8326C167.392 41.6985 167.633 42.8485 168.498 43.3999C169.366 43.9647 170.52 43.7246 171.072 42.8621C171.639 42.0097 171.398 40.8563 170.533 40.2948Z" fill="white" />
          <path d="M176.614 35.5493C176.665 34.6732 176 33.9325 175.118 33.8818C174.24 33.831 173.497 34.494 173.446 35.3734C173.395 36.2528 174.06 36.9902 174.942 37.0409C175.81 37.0917 176.563 36.4152 176.614 35.5493Z" fill="white" />
          <path d="M180.995 34.4196C180.429 34.3824 179.94 34.8221 179.913 35.3835C179.886 35.945 180.317 36.4355 180.88 36.4625C181.446 36.4997 181.921 36.06 181.962 35.4985C181.986 34.9337 181.558 34.4601 180.995 34.4196Z" fill="white" />
          <path d="M176.865 44.5667C176.261 44.1777 175.458 44.3401 175.057 44.9422C174.667 45.5442 174.83 46.3459 175.434 46.745C176.038 47.134 176.841 46.9716 177.242 46.3695C177.645 45.7675 177.469 44.9523 176.865 44.5667Z" fill="white" />
          <path d="M147.747 54.1154C147.269 53.8008 146.642 53.9395 146.326 54.4029C146.011 54.8798 146.15 55.5055 146.615 55.8201C147.093 56.1347 147.72 55.996 148.036 55.5326C148.361 55.0692 148.226 54.4299 147.747 54.1154Z" fill="white" />
          <path d="M169.251 54.0139C168.749 54.2642 168.549 54.8899 168.8 55.3905C169.051 55.8911 169.678 56.0907 170.18 55.8404C170.682 55.5901 170.882 54.9745 170.631 54.4739C170.38 53.9597 169.763 53.75 169.251 54.0139Z" fill="white" />
          <path d="M157.342 43.6908C158.295 42.2262 157.895 40.2712 156.426 39.3072C154.957 38.3567 152.997 38.7558 152.03 40.2204C151.064 41.685 151.478 43.6401 152.946 44.604C153.462 44.9423 154.065 45.1182 154.679 45.1182C155.761 45.1317 156.752 44.5905 157.342 43.6908Z" fill="white" />
          <path d="M151 48.5378C150.271 48.0609 149.28 48.2638 148.802 48.9876C148.324 49.7149 148.527 50.7025 149.253 51.1794C149.979 51.6564 150.973 51.4534 151.451 50.7296C151.939 50.0024 151.739 49.0147 151 48.5378Z" fill="white" />
          <path d="M158.397 54.5889C157.681 54.5517 157.064 55.1031 157.016 55.8168C156.969 56.5304 157.532 57.146 158.248 57.1934C158.963 57.2306 159.581 56.6928 159.628 55.9791C159.665 55.2519 159.113 54.6397 158.397 54.5889Z" fill="white" />
          <path d="M158.409 46.4101C157.378 46.3459 156.5 47.1374 156.449 48.1656C156.385 49.1939 157.178 50.0699 158.209 50.1207C159.24 50.1714 160.119 49.3934 160.17 48.3652C160.234 47.3505 159.44 46.4609 158.409 46.4101Z" fill="white" />
          <path d="M166.034 48.4126C165.254 48.8151 164.942 49.7655 165.342 50.5435C165.743 51.3215 166.699 51.6326 167.479 51.2335C168.259 50.8445 168.571 49.8941 168.181 49.1161C167.778 48.3382 166.825 48.0134 166.034 48.416V48.4126Z" fill="white" />
          <path d="M113.128 70.9834C132.851 70.9834 148.718 55.1605 148.718 35.4917C148.718 15.823 132.851 0 113.128 0C93.4056 0 77.5391 15.823 77.5391 35.4917C77.5391 55.1605 93.4056 70.9834 113.128 70.9834Z" fill="white" />
          <path d="M113.128 65.2705C129.677 65.2705 142.989 51.9945 142.989 35.4917C142.989 18.9889 129.677 5.71289 113.128 5.71289C96.5801 5.71289 83.2676 18.9889 83.2676 35.4917C83.2676 51.9945 96.5801 65.2705 113.128 65.2705Z" fill="black" />
          <path d="M121.339 40.2068C121.339 35.8638 118.728 34.3755 113.501 33.7565C109.77 33.2593 109.02 32.2683 109.02 30.5297C109.02 28.7911 110.265 27.6749 112.751 27.6749C114.99 27.6749 116.235 28.4191 116.855 30.2794C116.981 30.6515 117.354 30.8984 117.727 30.8984H119.718C120.217 30.8984 120.59 30.5263 120.59 30.0291V29.9039C120.091 27.1743 117.853 25.0637 114.99 24.8168V21.8403C114.99 21.343 114.617 20.971 113.996 20.8458H112.131C111.632 20.8458 111.259 21.2179 111.134 21.8403V24.695C107.403 25.1922 105.039 27.6715 105.039 30.7732C105.039 34.8659 107.528 36.4794 112.751 37.1017C116.235 37.7207 117.354 38.4648 117.354 40.4503C117.354 42.4358 115.611 43.7989 113.25 43.7989C110.014 43.7989 108.895 42.4324 108.522 40.5721C108.396 40.0749 108.023 39.828 107.65 39.828H105.534C105.035 39.828 104.662 40.2 104.662 40.6972V40.8224C105.161 43.9241 107.152 46.1565 111.256 46.7788V49.7554C111.256 50.2526 111.629 50.6246 112.249 50.7464H114.115C114.613 50.7464 114.987 50.3743 115.112 49.7554V46.7788C118.843 46.1598 121.332 43.552 121.332 40.2034L121.339 40.2068Z" fill="white" />
          <path d="M106.781 53.2324C97.0775 49.7587 92.1018 38.9654 95.7072 29.4101C97.5726 24.1978 101.68 20.2302 106.781 18.3665C107.28 18.1195 107.527 17.7475 107.527 17.1251V15.3866C107.527 14.8893 107.28 14.5173 106.781 14.3955C106.656 14.3955 106.408 14.3955 106.283 14.5207C94.4624 18.2413 87.9944 30.7732 91.7253 42.5609C93.9638 49.5084 99.316 54.8425 106.283 57.0782C106.781 57.3252 107.28 57.0782 107.402 56.581C107.527 56.4559 107.527 56.3341 107.527 56.0838V54.3452C107.527 53.9732 107.154 53.4794 106.781 53.2324ZM119.968 14.5207C119.47 14.2737 118.971 14.5207 118.849 15.0179C118.723 15.143 118.723 15.2648 118.723 15.5151V17.2537C118.723 17.7509 119.097 18.2447 119.47 18.495C129.173 21.9687 134.149 32.762 130.544 42.3174C128.678 47.5297 124.571 51.4973 119.47 53.361C118.971 53.6079 118.723 53.9799 118.723 54.6023V56.3409C118.723 56.8381 118.971 57.2102 119.47 57.3319C119.595 57.3319 119.843 57.3319 119.968 57.2068C131.788 53.4861 138.256 40.9543 134.526 29.1665C132.287 22.0939 126.813 16.7598 119.968 14.5274V14.5207Z" fill="white" />
          <path d="M30.8583 26.1258C29.6271 26.1258 28.6333 25.111 28.6706 23.8765C28.7079 22.6825 29.7526 21.7625 30.9499 21.7625H36.0985H59.9016C60.2883 21.7625 60.5868 21.4141 60.5223 21.0319C60.173 18.9686 58.3754 17.3958 56.2047 17.3958H28.6706C23.9799 17.3958 20.1777 21.1874 20.1777 25.8653V45.1181C20.1777 49.7959 23.9799 53.5876 28.6706 53.5876H66.4307C67.8926 53.5876 69.0763 52.4072 69.0763 50.9493V28.7607C69.0763 27.3029 67.8926 26.1224 66.4307 26.1224L30.8583 26.1258ZM63.5545 37.3554C64.9384 37.3554 66.061 38.475 66.061 39.855C66.061 41.235 64.9384 42.3546 63.5545 42.3546C62.1707 42.3546 61.048 41.235 61.048 39.855C61.048 38.475 62.1707 37.3554 63.5545 37.3554Z" fill="black" />
          <path d="M29.8061 34.5953H35.4771L37.0135 36.1276V38.4953L35.9519 39.5573V39.669L37.1526 40.8663V43.5148L35.6162 45.047H29.8027V34.5953H29.8061ZM34.3612 38.705L34.7818 38.2889V36.9664L34.3612 36.5504H32.0413V38.7084H34.3612V38.705ZM34.5003 43.0954L34.9175 42.6793V41.0761L34.5003 40.6566H32.0413V43.0954H34.5003Z" fill="white" />
          <path d="M38.8965 43.5858V34.5953H41.1316V42.747L41.5522 43.163H43.5906L44.0112 42.747V34.5953H46.2464V43.5858L44.7099 45.118H40.4363L38.8965 43.5858Z" fill="white" />
          <path d="M47.6831 34.5953H49.9182V38.2889L51.3292 39.696H51.4411L52.8521 38.2889V34.5953H55.0872V38.9147L52.4315 41.5631V45.047H50.3354V41.5631L47.6797 38.9147V34.5953H47.6831Z" fill="white" />
        </svg>
      </motion.button>
      <Modal
        size="sm"
        title="Fund Your Wallet"
        modalTitleClassName='!mb-4 px-4'
        modalClassName='px-0 pb-0 pt-4'
        onClose={close}
        onClickOutside={close}
        open={isOpen}>
        <TransakPayEmbed
          config={transakConfig}
        />
      </Modal>
    </>
  )
}

export default TransakPay
