import React, { forwardRef } from 'react'
import { Controller } from 'react-hook-form'
import VerificationInput from 'react-verification-input'
import cx from 'classnames'

import s from './styles.module.css'
import { VerificationProps } from './types'
import Label from '../Label'

const Verification = forwardRef<HTMLInputElement, VerificationProps>((props, ref) => {
  const {
    label = 'Enter code',
    name,
    wrapperClassName,
    className,
    length = 6,
    placeholder = '',
    validChars = '0-9',
    inputProps,
    control,
    rules,
    ...verificationProps
  } = props

  const wrapperClasses = cx(s.wrapper, wrapperClassName)
  const inputClasses = cx(s.character, className)

  return (
    <div className={wrapperClasses}>
      <Controller
        name={name}
        rules={rules}
        control={control}
        render={({ field: { onChange, value } }) => (
          <VerificationInput
            ref={ref}
            value={value}
            onChange={onChange}
            length={length}
            placeholder={placeholder}
            inputProps={{
              autoComplete: "one-time-code",
              ...inputProps
            }}
            validChars={validChars}
            classNames={{
              character: inputClasses,
              container: s.character_container
            }}
            {...verificationProps} />

        )} />
      {
        label && <Label className={s.label}>{label}</Label>
      }
    </div>
  )
})

export default Verification