import { useRouter } from 'next/router'

function useRedirect(destination: string) {
    const router = useRouter()
    if (typeof window !== 'undefined') {
        router.push(destination)
    }
}

export default useRedirect
