import React, { Children, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useSwipeable } from 'react-swipeable';

import { DoubleArrow } from '../Icon';

import { CarouselProps } from "./types";
import s from './styles.module.css'

// TODO:: Responsive
// TODO:: Reduce animation degradation on button spam
// TODO:: Allow less slider to work with less slides by duplicating existing slides
/**
 * Display images with a 3d loop effect
 * @param props 
 * @returns 
 */
const Carousel = (props: CarouselProps) => {
  const {
    children,
    startIndex = 2,
    duration = 1.25,
    centerScale = 1.25,
    noOfLeftSlides = 2,
    noOfRightSlides = 2,
    leftScale = [0.8, 0.4, 0.2],
    rightScale = [0.8, 0.4, 0.2],
    leftXRange = [100, 160, 200],
    rightXRange = [100, 160, 200]
  } = props;

  const arrayChildren = Children.toArray(children);
  const slideCount = arrayChildren.length;

  if (slideCount < noOfLeftSlides + noOfRightSlides + 1)
    throw new Error(
      "Not enough slides have been provided to accomendate current parameters"
    );

  // If so some reason start index is outside of range, set to 0
  const [currentIndex, setCurrentIndex] = useState<number>(
    startIndex > slideCount ? 0 : startIndex
  );

  const [isAnimating, setIsAnimating] = useState<boolean>(false)

  const paginate = (direction: number) => {
    // If animation has not finished don't do anything
    if (isAnimating) return

    setIsAnimating(true)

    const index = currentIndex + direction

    if (index >= slideCount) {
      setCurrentIndex(0);
    }
    else if (index < 0) {
      setCurrentIndex(slideCount - 1);
    }
    else {
      setCurrentIndex(index);
    }
  }

  const variants = {
    center: {
      x: "0%",
      opacity: 1,
      filter: "brightness(100%)",
      scale: centerScale,
      zIndex: 20,
      transition: {
        type: "spring",
        duration
      }
    },
    left: (offset: number) => {
      const scale = typeof leftScale === "function" ? leftScale(offset) : leftScale;
      const left = typeof leftXRange === "function" ? leftXRange(offset) : leftXRange;

      return {
        x: `-${left[offset]}%`,
        opacity: 1,
        filter: `brightness(${30 - ((offset + 1) * 7)}%)`,
        scale: scale[offset],
        zIndex: 10 - offset,
        transition: {
          type: "spring",
          duration
        }
      };
    },
    right: (offset: number) => {
      const scale = typeof rightScale === "function" ? rightScale(offset) : rightScale;
      const right = typeof rightXRange === "function" ? rightXRange(offset) : rightXRange;

      return {
        x: `${right[offset]}%`,
        opacity: 1,
        filter: `brightness(${100 - (offset + 1) * 40}%)`,
        scale: scale[offset],
        zIndex: 10 - offset,
        transition: {
          type: "spring",
          duration
        }
      };
    },
    hidden: () => ({
      scale: 0.25,
      opacity: 0,
      filter: `brightness(25%)`,
      zIndex: 0,
      transition: {
        duration
      }
    })
  };

  // positionToCenter - false === before, true === after
  const getSlides = (positionToCenter: boolean, noOfSlides: number) => {
    // Create array based on no of slides to show either side of center
    const slides = Array.from({ length: noOfSlides }, (v, i) => {
      const directionValue = positionToCenter ? 1 : -1;
      const position = i + 1;
      // Get index based on current center slide
      let childIndex = currentIndex + position * directionValue;

      // Loop slides
      if (childIndex < 0) {
        childIndex = slideCount + childIndex;
      } else if (childIndex > slideCount - 1) {
        childIndex = -(slideCount - childIndex);
      }

      const child = arrayChildren[childIndex];
      const animate = positionToCenter ? "right" : "left";

      return (
        <motion.div
          key={childIndex}
          variants={variants}
          animate={animate}
          exit="hidden"
          custom={i}
          className={s.carousel_item}
        >
          {child}
        </motion.div>
      );
    });

    if (!positionToCenter) return slides.reverse();

    return slides;
  };

  // Handle swipe events
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => paginate(1),
    onSwipedRight: () => paginate(-1)
  })

  return (
    <div
      {...swipeHandlers}>
      <motion.div className={s.carousel}>
        <motion.div
          className={s.carousel_content}>
          <AnimatePresence initial={false}>
            {getSlides(false, noOfLeftSlides)}

            <motion.div
              variants={variants}
              key={currentIndex}
              animate="center"
              className={s.carousel_item}
              onAnimationComplete={() => setIsAnimating(false)}
            >
              {arrayChildren[currentIndex]}
            </motion.div>

            {getSlides(true, noOfRightSlides)}
          </AnimatePresence>
        </motion.div>

        <motion.button
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            type: "spring",
            duration: 0.5
          }}
          whileHover={{ scale: 1.4 }}
          whileTap={{ scale: 1 }}
          className={s.carousel_btn_prev}
          onClick={() => paginate(-1)}
        >
          <DoubleArrow
            rotation="90"
            aria-label="Previous" />
        </motion.button>

        <motion.button
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            type: "spring",
            duration: 0.5
          }}
          whileHover={{ scale: 1.4 }}
          whileTap={{ scale: 1 }}
          className={s.carousel_btn_next}
          onClick={() => paginate(1)}
        >
          <DoubleArrow
            rotation="-90"
            aria-label="Next" />
        </motion.button>
      </motion.div>
    </div>
  );
};

export default Carousel;
