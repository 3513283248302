
import React from 'react'
import { useRouter } from 'next/router'

import Link from '../../../Link'
import Typography from '../../../Typography'

import { FilterItemProps } from './types'
import s from './styles.module.css'

const FilterItem = (props: FilterItemProps) => {
  const { label, value, pathname, queryKey } = props
  const { query } = useRouter()

  return (
    <Link
      variant="secondary"
      className={s.menu_item_link}
      href={
        {
          pathname,
          query: {
            ...query,
            [`${queryKey}`]: value,
          }
        }
      }>
      <Typography
        as="span" className={s.menu_item_label}
        uppercase>
        {label}
      </Typography>
    </Link>
  )
}

export default FilterItem