import React, { createContext, useContext, useMemo, useState } from 'react'
import { PageScrollContextType, PageScrollProviderProps } from './types'

// Prefix to be used on pages
const PAGE_SCROLL_ID_PREFIX = "page_scroll_"

const PageScrollContext = createContext<PageScrollContextType | undefined>(undefined)

/**
 * Page scroll provider manages all pages. 
 * It tracks the current page, allows you to change the page, or create custom indicators
 * @param props 
 * @returns 
 */
const PageScrollProvider = (props: PageScrollProviderProps) => {
  const { children, pages = [], initialPage = 0 } = props
  const [currentPage, setCurrentPage] = useState<string | number>(initialPage)

  const createPageId = (id: string | number): string => `${PAGE_SCROLL_ID_PREFIX}${id.toString()}`

  // Manually scroll a page into view
  const scrollToPage = (id: string | number) => {
    document.getElementById(`${PAGE_SCROLL_ID_PREFIX}${id.toString()}`)?.scrollIntoView({ behavior: "smooth" })
  }

  const value = useMemo(() => ({
    createPageId,
    scrollToPage,
    setCurrentPage,
    currentPage,
    pages
  }), [currentPage, pages])

  return <PageScrollContext.Provider value={value}>{children}</PageScrollContext.Provider>
}

const usePageScroll = () => {
  const context = useContext(PageScrollContext)
  if (!context) {
    throw new Error('usePageScrollContext must be used within an < PageScrollProvider />')
  }

  return context
}

export { usePageScroll, PageScrollContext }
export default PageScrollProvider