import Gallery from './Gallery'

export { default as GalleryPreview } from './GalleryPreview'
export { default as GalleryContentImage } from './GallerySlide'
export { default as GalleryScrollIndicator } from './GalleryScrollIndicator'
export { default as GallerySlide } from './GallerySlide'
export { default as GalleryCard } from './GalleryCard'
export { default as GalleryCategory } from './GalleryCategory'

export default Gallery
