import { MfaSignInPayloadType, SuccessSignInResponseType } from 'types'
import t from 'helpers/translation/getTranslation'
import logger from '../../logger'

import { AuthHttpClient } from '../httpClients'

type MfaProps = MfaSignInPayloadType & { id: number }

const mfa = async (data?: Record<never, string>) => {
    const { id, userCode, session } = data as MfaProps

    const {
        error,
        data: auth,
        response,
    } = await AuthHttpClient.MfaSignin(id, {
        userCode,
        session,
    })

    if (error && error.length > 0) {
        logger.error(`APP Sign in (MFA): %s`, JSON.stringify(error))

        throw new Error(t('pages.login.mfaError') as string)
    }

    if (auth) {
        return auth as SuccessSignInResponseType
    }

    return null
}

export default mfa
