import { useEffect, useRef } from 'react'

/**
 * Use previous value - See https://usehooks.com/usePrevious/
 * @param value
 * @returns
 */
function usePrevious<T>(value: T): T {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ref: any = useRef<T>()

    // Store current value in ref
    useEffect(() => {
        ref.current = value
    }, [value]) // Only re-run if value changes

    // Return previous value (happens before update in useEffect above)
    return ref.current
}

export default usePrevious
