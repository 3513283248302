import {
    MfaSignInResponseType,
    SignInPayloadType,
    SuccessSignInResponseType,
    UnconfirmedUserSignInResponseType,
    UserStatus,
} from 'types'
import t from 'helpers/translation/getTranslation'
import { AuthHttpClient } from '../httpClients'
import logger from '../../logger'

const credentials = async (userCredentials?: Record<never, string>) => {
    const { email, password, source } = userCredentials as SignInPayloadType

    const payload = {
        email,
        password,
    } as SignInPayloadType

    if (source) payload.source = source

    const { response, error, data: auth } = await AuthHttpClient.Signin(payload)

    if (!response.ok) {
        const errorMessage = error ? error[0].message : null

        logger.error(`APP Sign in: %s`, JSON.stringify(error))

        // Override API response with our own
        if (response.status === 401 && errorMessage === 'User is disabled.')
            throw new Error(t('pages.login.credentialsAccountSuspended') as string)

        // TODO:: Perhaps should handle other errors differently
        throw new Error(t('pages.login.credentialsError') as string)
    }

    // if user email is not confirmed
    if ((auth as UnconfirmedUserSignInResponseType).status === UserStatus.Unconfirmed) {
        // Added ID to patch many typescript errors when null
        return {
            id: -1,
            unconfirmed: true,
        }
    }

    // if auth must be confirmed via mfa
    if ((auth as MfaSignInResponseType).forceMfa) {
        const { id, session: sessionToken, forceMfa } = auth as MfaSignInResponseType

        return {
            id,
            forceMfa,
            sessionToken,
        }
    }

    if (auth) {
        return auth as SuccessSignInResponseType
    }

    return null
}

export default credentials
