import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const User = (props: SVGIconProps) => {
  const { width = 33, height = 33, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} aria-label="User Icon" {...svgProps}>
      <path d="M16.5 18.5625C21.624 18.5625 25.7812 14.4053 25.7812 9.28125C25.7812 4.15723 21.624 0 16.5 0C11.376 0 7.21875 4.15723 7.21875 9.28125C7.21875 14.4053 11.376 18.5625 16.5 18.5625ZM24.75 20.625H21.1986C19.7678 21.2824 18.1758 21.6562 16.5 21.6562C14.8242 21.6562 13.2387 21.2824 11.8014 20.625H8.25C3.69316 20.625 0 24.3182 0 28.875V29.9062C0 31.6143 1.38574 33 3.09375 33H29.9062C31.6143 33 33 31.6143 33 29.9062V28.875C33 24.3182 29.3068 20.625 24.75 20.625Z" fill="currentcolor" />
    </SVGIcon>
  )
}

export default User
