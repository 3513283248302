import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const Trophy = (props: SVGIconProps) => {
  const { width = 64, height = 64, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} {...svgProps}>
      <path d="M 62.33 5.785 C 61.169 4.47 59.518 3.717 57.799 3.717 L 51.923 3.717 C 51.628 -0.462 48.791 0.118 47.685 0.118 C 46.519 0.118 32.12 0.118 32.12 0.118 C 32.12 0.118 17.721 0.118 16.555 0.118 C 15.45 0.118 12.612 -0.462 12.317 3.717 L 6.441 3.717 C 4.722 3.717 3.071 4.471 1.91 5.785 C 0.503 7.379 -0.104 9.68 0.194 12.274 C 0.249 13.426 0.831 19.346 6.955 23.556 C 10.068 25.695 13.059 26.786 15.78 27.341 C 16.377 27.47 16.98 27.586 17.598 27.676 C 17.607 27.686 17.615 27.696 17.624 27.705 C 30.356 41.104 28.802 33.353 28.802 45.634 C 28.802 50.396 22.205 48.855 22.205 52.509 C 22.205 56.164 17.064 53.156 17.54 57.492 C 17.831 60.15 25.705 62.143 32.12 62.143 C 38.536 62.143 46.409 60.15 46.701 57.492 C 47.177 53.156 42.035 56.164 42.035 52.509 C 42.035 48.855 35.439 50.396 35.439 45.634 C 35.439 33.352 33.884 41.104 46.617 27.705 C 46.634 27.686 46.65 27.667 46.668 27.648 C 49.839 27.237 53.475 26.176 57.285 23.556 C 63.409 19.346 63.991 13.426 64.046 12.274 C 64.344 9.68 63.737 7.379 62.33 5.785 Z M 59.022 11.763 L 58.999 11.887 L 59.002 12.056 C 58.99 12.384 58.751 16.42 54.424 19.395 C 53.241 20.209 52.096 20.826 50.982 21.305 C 50.731 21.407 50.479 21.509 50.222 21.598 C 51.742 17.316 51.989 12.528 51.993 8.766 L 57.799 8.766 C 57.994 8.766 58.281 8.829 58.545 9.127 C 58.994 9.636 59.168 10.596 59.022 11.763 Z M 9.816 19.395 C 5.493 16.423 5.25 12.391 5.238 12.057 L 5.241 11.942 L 5.218 11.762 C 5.072 10.596 5.246 9.635 5.695 9.127 C 5.959 8.828 6.246 8.766 6.441 8.766 L 12.246 8.766 C 12.25 11.977 12.438 15.935 13.436 19.689 C 13.603 20.336 13.787 20.98 14.004 21.612 C 12.659 21.103 11.265 20.391 9.816 19.395 Z" />
    </SVGIcon>
  )
}

export default Trophy