import React from 'react'
import { Tab } from '@headlessui/react'
import { TabsProps } from './types'

function Tabs(props: TabsProps) {
  const { children, tabs } = props

  return (
    <Tab.Group>
      <Tab.List>
        {tabs}
      </Tab.List>
      <Tab.Panels>
        {children}
      </Tab.Panels>
    </Tab.Group>
  )
}

export default Tabs