import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const HolocacheLogo = (props: SVGIconProps) => {
  const { width = 455, height = 61, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} {...svgProps}>
      <g clipPath="url(#clip0_6295_6596)">
        <path d="M153.565 9.79407L149.62 13.7628V47.2373L153.565 51.206H185.644L189.588 47.239V13.761L185.644 9.79407H153.565ZM182.321 43.8982H156.887V17.1018H182.321V43.8982Z" fill="url(#paint0_linear_6295_6596)" />
        <path d="M241.22 51.206L245.167 47.239V37.8087H237.898V43.8982H212.465V17.1018H237.898V23.8014H245.167V13.761L241.22 9.79407H209.143L205.197 13.7628V47.2373L209.143 51.206H241.22Z" fill="url(#paint1_linear_6295_6596)" />
        <path d="M347.323 51.206L351.267 47.239V37.8087H344.001V43.8982H318.566V17.1018H344.001V23.8014H351.267V13.761L347.323 9.79407H315.244L311.299 13.7628V47.2373L315.244 51.206H347.323Z" fill="url(#paint2_linear_6295_6596)" />
        <path d="M7.26682 9.79407H0V51.206H7.26682V9.79407Z" fill="url(#paint3_linear_6295_6596)" />
        <path d="M32.7016 9.79407V27.4544H14.5354V34.7639H32.7016V51.206H39.9684V9.79407H32.7016Z" fill="url(#paint4_linear_6295_6596)" />
        <path d="M371.679 9.79407H364.412V51.206H371.679V9.79407Z" fill="url(#paint5_linear_6295_6596)" />
        <path d="M397.114 9.79407V27.4544H378.948V34.7639H397.114V51.206H404.381V9.79407H397.114Z" fill="url(#paint6_linear_6295_6596)" />
        <path d="M294.209 9.79407H262.13L258.186 13.7628V51.206H265.453V42.152V39.0269V31.7174V20.0699V17.1018H290.887V31.7174H272.725V39.0269H290.887V51.206H298.154V13.761L294.209 9.79407Z" fill="url(#paint7_linear_6295_6596)" />
        <path d="M88.0237 51.4119L91.9702 47.445V38.0146H84.7016V44.1041H59.2686V17.3078H84.7016V38.0146H91.9702V13.9669L88.0237 10H55.9465L52 13.9687V47.4432L55.9465 51.4119H88.0237Z" fill="url(#paint8_linear_6295_6596)" />
        <path d="M109.953 9.79407H102.686V47.2373L106.631 51.206H139.02V43.8982H109.953V9.79407Z" fill="url(#paint9_linear_6295_6596)" />
        <path d="M455 17.1018V9.79407H420.794L416.85 13.7628V47.2373L420.794 51.206H455V43.8982H424.116V17.1018H455Z" fill="url(#paint10_linear_6295_6596)" />
        <path d="M146.642 24.3151H139.279V36.6849H146.642V24.3151Z" fill="url(#paint11_linear_6295_6596)" />
        <path d="M199.927 24.3151H192.564V36.6849H199.927V24.3151Z" fill="url(#paint12_linear_6295_6596)" />
        <path d="M450.763 26.8461H431.389V34.1538H450.763V26.8461Z" fill="url(#paint13_linear_6295_6596)" />
        <path d="M175.753 54.2008H163.453V60.9949H175.753V54.2008Z" fill="url(#paint14_linear_6295_6596)" />
        <path d="M175.753 0.00512695H163.453V6.7992H175.753V0.00512695Z" fill="url(#paint15_linear_6295_6596)" />
        <path d="M175.753 24.3151H163.453V36.6849H175.753V24.3151Z" fill="url(#paint16_linear_6295_6596)" />
      </g>
      <defs>
        <linearGradient id="paint0_linear_6295_6596" x1="-1.28585" y1="30.5009" x2="452.983" y2="30.5009" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="0.248" stopColor="#8AC33F" />
          <stop offset="0.506" stopColor="#00ABEC" />
          <stop offset="0.748" stopColor="#8F278C" />
          <stop offset="1" stopColor="#F58020" />
        </linearGradient>
        <linearGradient id="paint1_linear_6295_6596" x1="-1.28594" y1="0.00518794" x2="452.983" y2="0.00518794" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="0.248" stopColor="#8AC33F" />
          <stop offset="0.506" stopColor="#00ABEC" />
          <stop offset="0.748" stopColor="#8F278C" />
          <stop offset="1" stopColor="#F58020" />
        </linearGradient>
        <linearGradient id="paint2_linear_6295_6596" x1="-1.28591" y1="0.00518794" x2="452.983" y2="0.00518794" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="0.248" stopColor="#8AC33F" />
          <stop offset="0.506" stopColor="#00ABEC" />
          <stop offset="0.748" stopColor="#8F278C" />
          <stop offset="1" stopColor="#F58020" />
        </linearGradient>
        <linearGradient id="paint3_linear_6295_6596" x1="-1.28593" y1="30.5009" x2="452.983" y2="30.5009" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="0.248" stopColor="#8AC33F" />
          <stop offset="0.506" stopColor="#00ABEC" />
          <stop offset="0.748" stopColor="#8F278C" />
          <stop offset="1" stopColor="#F58020" />
        </linearGradient>
        <linearGradient id="paint4_linear_6295_6596" x1="-1.28594" y1="30.5009" x2="452.983" y2="30.5009" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="0.248" stopColor="#8AC33F" />
          <stop offset="0.506" stopColor="#00ABEC" />
          <stop offset="0.748" stopColor="#8F278C" />
          <stop offset="1" stopColor="#F58020" />
        </linearGradient>
        <linearGradient id="paint5_linear_6295_6596" x1="-1.28593" y1="30.5009" x2="452.983" y2="30.5009" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="0.248" stopColor="#8AC33F" />
          <stop offset="0.506" stopColor="#00ABEC" />
          <stop offset="0.748" stopColor="#8F278C" />
          <stop offset="1" stopColor="#F58020" />
        </linearGradient>
        <linearGradient id="paint6_linear_6295_6596" x1="-1.28602" y1="30.5009" x2="452.983" y2="30.5009" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="0.248" stopColor="#8AC33F" />
          <stop offset="0.506" stopColor="#00ABEC" />
          <stop offset="0.748" stopColor="#8F278C" />
          <stop offset="1" stopColor="#F58020" />
        </linearGradient>
        <linearGradient id="paint7_linear_6295_6596" x1="-1.28604" y1="30.5009" x2="452.983" y2="30.5009" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="0.248" stopColor="#8AC33F" />
          <stop offset="0.506" stopColor="#00ABEC" />
          <stop offset="0.748" stopColor="#8F278C" />
          <stop offset="1" stopColor="#F58020" />
        </linearGradient>
        <linearGradient id="paint8_linear_6295_6596" x1="3.8709e-07" y1="31" x2="451.5" y2="30.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="0.248" stopColor="#8AC33F" />
          <stop offset="0.506" stopColor="#00ABEC" />
          <stop offset="0.748" stopColor="#8F278C" />
          <stop offset="1" stopColor="#F58020" />
        </linearGradient>
        <linearGradient id="paint9_linear_6295_6596" x1="-1.2859" y1="0.00518794" x2="452.983" y2="0.00518794" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="0.248" stopColor="#8AC33F" />
          <stop offset="0.506" stopColor="#00ABEC" />
          <stop offset="0.748" stopColor="#8F278C" />
          <stop offset="1" stopColor="#F58020" />
        </linearGradient>
        <linearGradient id="paint10_linear_6295_6596" x1="-1.28603" y1="30.5009" x2="452.983" y2="30.5009" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="0.248" stopColor="#8AC33F" />
          <stop offset="0.506" stopColor="#00ABEC" />
          <stop offset="0.748" stopColor="#8F278C" />
          <stop offset="1" stopColor="#F58020" />
        </linearGradient>
        <linearGradient id="paint11_linear_6295_6596" x1="-1.28599" y1="30.5009" x2="452.983" y2="30.5009" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="0.248" stopColor="#8AC33F" />
          <stop offset="0.506" stopColor="#00ABEC" />
          <stop offset="0.748" stopColor="#8F278C" />
          <stop offset="1" stopColor="#F58020" />
        </linearGradient>
        <linearGradient id="paint12_linear_6295_6596" x1="-1.28599" y1="30.5009" x2="452.983" y2="30.5009" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="0.248" stopColor="#8AC33F" />
          <stop offset="0.506" stopColor="#00ABEC" />
          <stop offset="0.748" stopColor="#8F278C" />
          <stop offset="1" stopColor="#F58020" />
        </linearGradient>
        <linearGradient id="paint13_linear_6295_6596" x1="-1.28609" y1="30.5008" x2="452.983" y2="30.5008" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="0.248" stopColor="#8AC33F" />
          <stop offset="0.506" stopColor="#00ABEC" />
          <stop offset="0.748" stopColor="#8F278C" />
          <stop offset="1" stopColor="#F58020" />
        </linearGradient>
        <linearGradient id="paint14_linear_6295_6596" x1="9.61249e-05" y1="57.5969" x2="452.983" y2="57.5969" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="0.248" stopColor="#8AC33F" />
          <stop offset="0.506" stopColor="#00ABEC" />
          <stop offset="0.748" stopColor="#8F278C" />
          <stop offset="1" stopColor="#F58020" />
        </linearGradient>
        <linearGradient id="paint15_linear_6295_6596" x1="9.61249e-05" y1="3.40306" x2="452.983" y2="3.40306" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="0.248" stopColor="#8AC33F" />
          <stop offset="0.506" stopColor="#00ABEC" />
          <stop offset="0.748" stopColor="#8F278C" />
          <stop offset="1" stopColor="#F58020" />
        </linearGradient>
        <linearGradient id="paint16_linear_6295_6596" x1="9.61249e-05" y1="30.5009" x2="452.983" y2="30.5009" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="0.248" stopColor="#8AC33F" />
          <stop offset="0.506" stopColor="#00ABEC" />
          <stop offset="0.748" stopColor="#8F278C" />
          <stop offset="1" stopColor="#F58020" />
        </linearGradient>
        <clipPath id="clip0_6295_6596">
          <rect width="455" height="61" fill="white" />
        </clipPath>
      </defs>
    </SVGIcon>
  )
}

export default HolocacheLogo
