import React from 'react'
import cx from 'classnames'

import s from './styles.module.css'
import { ListProps } from './types'

/**
 * Wrapper for list items
 * @param props 
 * @returns 
 */
const List = (props: ListProps) => {
  const { children, topBorder = true, bottomBorder = true, className } = props

  const classes = cx(
    s.list,
    topBorder && s.topBorder,
    bottomBorder && s.bottomBorder,
    className
  )

  return (
    <section className={classes}>
      {children}
    </section>
  )
}

export default List

