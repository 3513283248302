import React from 'react'

import { Link, StyledButton } from '@/components/ui'
import { ROUTE_GET_THE_GAME } from '@/consts'

import { PlayTheGameButtonProps } from './types'

const PlayTheGameButton = (props: PlayTheGameButtonProps) => {
  const { buttonText = "PLAY", ...buttonProps } = props

  return (
    <StyledButton
      as={Link}
      variant="filled"
      color="primary"
      size="sm"
      {...buttonProps}
      href={ROUTE_GET_THE_GAME}>
      {buttonText}
    </StyledButton>
  )
}

export default PlayTheGameButton