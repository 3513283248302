import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const Avatar = (props: SVGIconProps) => {
  const { width = 34, height = 36, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} {...svgProps}>
      <path d="M20.8334 20.7968C24.7944 19.332 27.6321 15.5284 27.6321 11.0631C27.6321 5.33704 22.9734 0.678284 17.2473 0.678284C11.5213 0.678284 6.86251 5.33704 6.86251 11.0631C6.86251 15.5271 9.70019 19.332 13.6612 20.7968C6.23681 21.8268 0.664185 25.9772 0.664185 30.9428C0.665519 31.8367 1.22985 32.6585 2.09836 33.0387C6.20746 34.8305 11.588 35.8164 17.2473 35.8164C22.9067 35.8164 28.2858 34.8291 32.3949 33.0387C33.2634 32.6598 33.8264 31.838 33.8304 30.9295C33.8304 25.9746 28.2565 21.8268 20.8334 20.7968ZM7.76571 11.0631C7.76571 5.83466 12.0202 1.58148 17.2486 1.58148C22.4771 1.58148 26.7302 5.83466 26.7302 11.0631C26.7302 16.2915 22.4771 20.5447 17.2486 20.5447C12.0202 20.5447 7.76571 16.2915 7.76571 11.0631ZM32.0347 32.2102C28.0377 33.9539 22.7866 34.9132 17.2486 34.9132C11.7107 34.9132 6.45827 33.9539 2.46125 32.2102C1.91959 31.9741 1.56872 31.4765 1.56872 30.9295C1.56872 25.7011 8.60221 21.4479 17.2486 21.4479C25.8951 21.4479 32.9286 25.7011 32.9286 30.9402C32.9272 31.4751 32.5764 31.9741 32.0347 32.2102Z" fill="black" stroke="black" strokeWidth="0.266824" strokeMiterlimit="10" />
    </SVGIcon>
  )
}

export default Avatar