import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const Cross = (props: SVGIconProps) => {
  const { width = 15, height = 15, className = "stroke-white", ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} className={className} {...svgProps}>
      <line x1="2.55563" y1="2" x2="12.3137" y2="11.7581" strokeWidth="2.2" strokeLinecap="round" />
      <line x1="2" y1="11.7581" x2="11.7581" y2="2.00001" strokeWidth="2.2" strokeLinecap="round" />
    </SVGIcon>
  )
}

export default Cross
