import React from 'react'
import { Controller } from 'react-hook-form'

import RangeBase from './RangeBase'
import { RangeProps } from './types'

const Range = (props: RangeProps) => {
  const { name, control, onDrag, onChange: propsOnChange, ...rangeProps } = props

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const { onChange, name: fieldName, value } = field

        return (
          <RangeBase
            value={value}
            onDrag={(evt) => {
              if (onDrag) onDrag(evt)

              onChange(evt)
            }}
            onChange={(evt) => {
              if (propsOnChange) propsOnChange(evt)

              onChange(evt)
            }}
            name={fieldName}
            {...rangeProps} />
        )
      }} />
  )
}

export default Range