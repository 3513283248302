import React from 'react'

import Link from '@/components/ui/Link';

import s from './styles.module.css'
import { ConnectedNavSubMenuProps } from './types';

const ConnectedNavItemSubMenu = (props: ConnectedNavSubMenuProps) => {
  const { links, onClick } = props

  return (
    <div className={s.submenu}>
      {
        links.map((link, index) => (
          <Link
            // eslint-disable-next-line react/no-array-index-key
            key={`${link.href}_${index}`}
            onClick={onClick}
            className={s.link}
            href={link.href}>
            {link.label}
          </Link>

        ))

      }
    </div>
  )
}

ConnectedNavItemSubMenu.defaultProps = {
  links: []
}

export default ConnectedNavItemSubMenu
