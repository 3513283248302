import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const Cardano = (props: SVGIconProps) => {
  const { width, height = 16, showTitle = true, ...svgProps } = props
  const svgWidth = width || (showTitle ? 80 : 17)

  return (
    <SVGIcon width={svgWidth} height={height} aria-label="ADA Logo" {...svgProps}>
      <path d="M4.64753 7.93977C4.63014 8.24891 4.73368 8.55245 4.9354 8.78369C5.13711 9.01494 5.42051 9.15498 5.72331 9.17304H5.78571C6.00871 9.17279 6.22676 9.10589 6.41295 8.98058C6.59914 8.85528 6.74532 8.67707 6.83344 8.46794C6.92156 8.2588 6.94776 8.02791 6.90883 7.80375C6.86989 7.57959 6.76751 7.37197 6.61432 7.20652C6.46113 7.04108 6.26384 6.92504 6.0468 6.87274C5.82976 6.82043 5.60247 6.83415 5.39297 6.9122C5.18348 6.99024 5.00096 7.1292 4.86794 7.31192C4.73491 7.49464 4.6572 7.71313 4.64441 7.94041L4.64753 7.93977Z" fill="currentcolor" />
      <path d="M0.389392 7.64414C0.316591 7.63998 0.244217 7.65795 0.181414 7.69577C0.118612 7.73359 0.0682009 7.78956 0.0365519 7.85662C0.00490285 7.92368 -0.00656356 7.99881 0.00360171 8.07252C0.013767 8.14623 0.0451077 8.21521 0.0936631 8.27074C0.142219 8.32627 0.205809 8.36586 0.276399 8.38451C0.346989 8.40315 0.421409 8.40002 0.490255 8.3755C0.559101 8.35098 0.619282 8.30618 0.663193 8.24676C0.707105 8.18733 0.732775 8.11596 0.73696 8.04164C0.739826 7.99222 0.733098 7.9427 0.717162 7.89594C0.701226 7.84918 0.676396 7.80609 0.644099 7.76915C0.611802 7.73221 0.572674 7.70216 0.528964 7.6807C0.485253 7.65925 0.437821 7.64683 0.389392 7.64414Z" fill="currentcolor" />
      <path d="M4.57515 1.17401C4.63999 1.14002 4.69353 1.0872 4.72903 1.02222C4.76453 0.957229 4.7804 0.882973 4.77465 0.808795C4.7689 0.734617 4.74179 0.663834 4.69672 0.605354C4.65165 0.546875 4.59064 0.503315 4.52137 0.480156C4.4521 0.456997 4.37767 0.455275 4.30744 0.475207C4.23722 0.495139 4.17434 0.535834 4.12672 0.592169C4.0791 0.648504 4.04887 0.717962 4.03984 0.791797C4.0308 0.865633 4.04337 0.940547 4.07595 1.00711C4.09785 1.05166 4.12817 1.09135 4.16517 1.12388C4.20216 1.15642 4.2451 1.18116 4.2915 1.19667C4.33791 1.21219 4.38686 1.21817 4.43554 1.21428C4.48423 1.21039 4.53167 1.1967 4.57515 1.17401Z" fill="currentcolor" />
      <path d="M5.73394 3.2366C5.83464 3.18474 5.91802 3.10357 5.97353 3.00335C6.02905 2.90313 6.05421 2.78835 6.04584 2.67353C6.03746 2.55871 5.99593 2.449 5.92649 2.35828C5.85705 2.26755 5.76282 2.19988 5.65571 2.16382C5.5486 2.12776 5.43342 2.12492 5.32474 2.15568C5.21605 2.18643 5.11873 2.24939 5.04509 2.3366C4.97145 2.4238 4.92479 2.53134 4.91101 2.64561C4.89723 2.75989 4.91695 2.87577 4.96767 2.9786C5.00137 3.04693 5.04793 3.1078 5.10468 3.15775C5.16144 3.2077 5.22728 3.24574 5.29844 3.2697C5.3696 3.29366 5.44469 3.30306 5.51942 3.29738C5.59414 3.29171 5.66704 3.27105 5.73394 3.2366Z" fill="currentcolor" />
      <path d="M1.83463 4.65536C1.9123 4.7075 2.00337 4.73495 2.09633 4.73424C2.18929 4.73353 2.27995 4.70468 2.35684 4.65135C2.43374 4.59802 2.49341 4.52261 2.52831 4.43465C2.5632 4.34669 2.57176 4.25014 2.55289 4.15721C2.53402 4.06429 2.48858 3.97917 2.4223 3.91262C2.35603 3.84607 2.27191 3.80108 2.18059 3.78335C2.08927 3.76562 1.99485 3.77594 1.90927 3.81301C1.82369 3.85007 1.75081 3.91222 1.69984 3.99159C1.63158 4.09788 1.60744 4.22749 1.63271 4.35194C1.65798 4.4764 1.7306 4.58552 1.83463 4.65536Z" fill="currentcolor" />
      <path d="M2.5291 7.43208C2.41651 7.42559 2.30455 7.45333 2.20739 7.51178C2.11024 7.57024 2.03223 7.65679 1.98325 7.76049C1.93426 7.86419 1.91649 7.98039 1.93218 8.0944C1.94787 8.20841 1.99632 8.31511 2.0714 8.40101C2.14649 8.48692 2.24483 8.54817 2.354 8.57703C2.46318 8.60589 2.57829 8.60106 2.68477 8.56315C2.79126 8.52524 2.88435 8.45596 2.95227 8.36405C3.02019 8.27214 3.0599 8.16174 3.06637 8.0468C3.07489 7.89257 3.02309 7.7412 2.92235 7.62594C2.82161 7.51067 2.68017 7.44095 2.5291 7.43208Z" fill="currentcolor" />
      <path d="M1.89697 11.3465C1.81404 11.3893 1.74541 11.4562 1.69973 11.5388C1.65406 11.6214 1.6334 11.716 1.64036 11.8106C1.64733 11.9051 1.6816 11.9955 1.73886 12.0702C1.79612 12.1449 1.87379 12.2006 1.96204 12.2302C2.0503 12.2599 2.14519 12.2622 2.23471 12.2368C2.32423 12.2114 2.40437 12.1594 2.46499 12.0876C2.52562 12.0157 2.564 11.9271 2.5753 11.8329C2.58659 11.7388 2.57029 11.6433 2.52846 11.5586C2.50066 11.5023 2.46227 11.4522 2.41548 11.4111C2.36869 11.37 2.31442 11.3387 2.25578 11.319C2.19713 11.2993 2.13525 11.2915 2.07369 11.2963C2.01212 11.301 1.95207 11.3181 1.89697 11.3465Z" fill="currentcolor" />
      <path d="M4.11406 6.22869C4.22512 6.303 4.35527 6.34201 4.48805 6.34081C4.62084 6.3396 4.75029 6.29823 4.86004 6.22193C4.96979 6.14562 5.05491 6.03781 5.10464 5.91212C5.15438 5.78642 5.16648 5.6485 5.13944 5.51579C5.11239 5.38307 5.0474 5.26152 4.95269 5.16651C4.85798 5.0715 4.7378 5.00729 4.60734 4.982C4.47688 4.95672 4.34201 4.97149 4.21978 5.02445C4.09754 5.0774 3.99344 5.16618 3.92062 5.27953C3.87218 5.35479 3.83875 5.43907 3.82226 5.52753C3.80576 5.61599 3.80652 5.70691 3.82449 5.79507C3.84246 5.88324 3.87728 5.96692 3.92697 6.04133C3.97666 6.11573 4.04024 6.1794 4.11406 6.22869Z" fill="currentcolor" />
      <path d="M11.1478 3.19094C11.2424 3.25391 11.3531 3.28684 11.466 3.28558C11.5789 3.28432 11.6889 3.24892 11.7821 3.18385C11.8753 3.11878 11.9475 3.02697 11.9896 2.92002C12.0316 2.81307 12.0417 2.69578 12.0185 2.58299C11.9953 2.47019 11.9399 2.36694 11.8592 2.2863C11.7786 2.20566 11.6763 2.15124 11.5653 2.12993C11.4544 2.10861 11.3397 2.12136 11.2359 2.16655C11.132 2.21175 11.0436 2.28736 10.9818 2.38384C10.9407 2.44792 10.9124 2.51966 10.8984 2.59495C10.8845 2.67023 10.8853 2.74758 10.9007 2.82256C10.9161 2.89754 10.9459 2.96868 10.9883 3.03189C11.0307 3.0951 11.0849 3.14915 11.1478 3.19094Z" fill="currentcolor" />
      <path d="M12.3184 1.13465C12.3795 1.17555 12.4511 1.19702 12.5242 1.19635C12.5973 1.19568 12.6685 1.1729 12.7289 1.13088C12.7893 1.08887 12.8362 1.02951 12.8635 0.960316C12.8908 0.891125 12.8974 0.81521 12.8825 0.742178C12.8676 0.669145 12.8318 0.602277 12.7796 0.550033C12.7274 0.497789 12.6612 0.462517 12.5894 0.448681C12.5176 0.434844 12.4434 0.443065 12.3761 0.472303C12.3089 0.50154 12.2517 0.550481 12.2117 0.612932C12.1581 0.69658 12.1393 0.798509 12.1593 0.89633C12.1793 0.994152 12.2365 1.07987 12.3184 1.13465Z" fill="currentcolor" />
      <path d="M11.2377 6.83005C11.0879 6.82126 10.9379 6.84268 10.7963 6.89306C10.6546 6.94345 10.5241 7.02183 10.4121 7.12372C10.186 7.3295 10.0492 7.61856 10.0318 7.92731C10.0144 8.23606 10.1179 8.53922 10.3195 8.77008C10.521 9.00094 10.8042 9.14061 11.1066 9.15835C11.1285 9.15835 11.1509 9.15835 11.1728 9.15835C11.3931 9.15824 11.6087 9.09307 11.7935 8.97069C11.9784 8.84831 12.1246 8.67396 12.2146 8.46865C12.3046 8.26334 12.3344 8.03584 12.3006 7.81359C12.2668 7.59134 12.1707 7.38383 12.0239 7.21608C11.9245 7.10167 11.8038 7.00861 11.6688 6.94234C11.5338 6.87606 11.3873 6.83788 11.2377 6.83005Z" fill="currentcolor" />
      <path d="M6.10902 6.14798C6.20382 6.34132 6.3496 6.5038 6.52996 6.61713C6.71032 6.73047 6.9181 6.79015 7.12988 6.78946C7.32514 6.7895 7.51715 6.73846 7.68759 6.6412C7.85803 6.54395 8.00121 6.40372 8.10347 6.23391C8.20573 6.0641 8.26365 5.87037 8.27171 5.67121C8.27976 5.47205 8.23769 5.2741 8.1495 5.09626C8.05404 4.9038 7.90798 4.74227 7.72768 4.62976C7.54737 4.51726 7.33995 4.45821 7.12864 4.45924C6.93398 4.45958 6.74263 4.51068 6.57275 4.60771C6.40287 4.70473 6.26009 4.84445 6.15795 5.01361C6.05581 5.18278 5.99771 5.37578 5.98914 5.57431C5.98058 5.77283 6.02184 5.9703 6.10902 6.14798Z" fill="currentcolor" />
      <path d="M15.0634 4.654C15.1463 4.61128 15.215 4.54441 15.2607 4.46185C15.3065 4.37929 15.3272 4.28475 15.3203 4.19017C15.3134 4.09559 15.2792 4.00522 15.222 3.93048C15.1648 3.85574 15.0872 3.79998 14.999 3.77026C14.9108 3.74054 14.8159 3.73818 14.7263 3.76348C14.6368 3.78879 14.5566 3.84062 14.4959 3.91243C14.4353 3.98424 14.3968 4.0728 14.3854 4.16692C14.374 4.26105 14.3902 4.35651 14.4319 4.44124C14.4881 4.55489 14.5861 4.64113 14.7045 4.68103C14.8229 4.72092 14.952 4.7112 15.0634 4.654Z" fill="currentcolor" />
      <path d="M12.1674 5.02538C12.0488 5.08636 11.9506 5.18187 11.8851 5.29984C11.8197 5.41781 11.79 5.55294 11.7998 5.68813C11.8096 5.82333 11.8585 5.95252 11.9402 6.05938C12.0219 6.16623 12.1328 6.24595 12.2589 6.28844C12.385 6.33094 12.5206 6.3343 12.6486 6.29812C12.7766 6.26193 12.8912 6.18781 12.9779 6.08514C13.0646 5.98247 13.1196 5.85586 13.1358 5.72131C13.152 5.58676 13.1288 5.45032 13.0691 5.32924C13.0295 5.24879 12.9747 5.1771 12.9079 5.11827C12.8412 5.05945 12.7637 5.01464 12.6799 4.98642C12.5962 4.9582 12.5078 4.94711 12.4199 4.95379C12.3319 4.96048 12.2461 4.98481 12.1674 5.02538Z" fill="currentcolor" />
      <path d="M8.43717 0.958818C8.52997 0.964214 8.62225 0.941391 8.70234 0.893238C8.78243 0.845085 8.84673 0.773764 8.88711 0.688298C8.92749 0.602833 8.94213 0.507061 8.92918 0.413099C8.91623 0.319138 8.87628 0.231207 8.81437 0.16043C8.75246 0.0896536 8.67138 0.039211 8.58139 0.0154835C8.49139 -0.0082441 8.39653 -0.00419064 8.30879 0.0271311C8.22105 0.0584528 8.14438 0.115636 8.08848 0.191446C8.03259 0.267256 7.99997 0.358288 7.99476 0.453026C7.98778 0.579977 8.03045 0.704565 8.11341 0.799408C8.19637 0.894251 8.31282 0.951589 8.43717 0.958818Z" fill="currentcolor" />
      <path d="M8.43215 3.97746C8.56469 3.98504 8.69647 3.95234 8.81082 3.8835C8.92517 3.81465 9.01696 3.71274 9.07459 3.59065C9.13222 3.46856 9.1531 3.33177 9.1346 3.19756C9.1161 3.06336 9.05904 2.93777 8.97063 2.83666C8.88223 2.73555 8.76645 2.66347 8.63793 2.62952C8.5094 2.59557 8.37391 2.60128 8.24856 2.64592C8.12321 2.69056 8.01364 2.77214 7.9337 2.88034C7.85375 2.98854 7.80702 3.1185 7.79941 3.2538C7.78936 3.43541 7.85037 3.61366 7.96902 3.74936C8.08767 3.88506 8.25426 3.9671 8.43215 3.97746Z" fill="currentcolor" />
      <path d="M4.79425 10.9745C4.91284 10.9134 5.01103 10.8179 5.07641 10.6999C5.14179 10.5819 5.17142 10.4467 5.16155 10.3115C5.15168 10.1763 5.10276 10.0471 5.02097 9.9403C4.93917 9.83349 4.82819 9.75383 4.70205 9.71141C4.57591 9.669 4.44028 9.66572 4.31232 9.702C4.18436 9.73827 4.0698 9.81248 3.98316 9.91522C3.89651 10.018 3.84165 10.1446 3.82552 10.2792C3.8094 10.4138 3.83273 10.5502 3.89257 10.6713C3.97278 10.8335 4.11282 10.9566 4.28191 11.0135C4.451 11.0703 4.63528 11.0563 4.79425 10.9745Z" fill="currentcolor" />
      <path d="M8.86575 4.97774C8.72649 5.19469 8.66209 5.45285 8.68273 5.71137C8.70337 5.96989 8.80787 6.21399 8.9797 6.40506C9.15153 6.59612 9.38087 6.72323 9.63142 6.76626C9.88198 6.80929 10.1394 6.76579 10.363 6.64265C10.5867 6.5195 10.7636 6.32374 10.866 6.08635C10.9684 5.84895 10.9903 5.58349 10.9282 5.33197C10.8662 5.08045 10.7237 4.85724 10.5234 4.69766C10.3231 4.53809 10.0764 4.45126 9.82234 4.45093C9.63259 4.45079 9.44579 4.49888 9.27879 4.59085C9.11179 4.68282 8.96984 4.81578 8.86575 4.97774Z" fill="currentcolor" />
      <path d="M10.8513 9.85275C10.7599 9.66755 10.6216 9.51061 10.4509 9.39815C10.2802 9.28568 10.0831 9.22175 9.88014 9.21296C9.67714 9.20417 9.47551 9.25083 9.29607 9.34813C9.11663 9.44544 8.96586 9.58985 8.85934 9.76649C8.75281 9.94312 8.69438 10.1456 8.69008 10.3529C8.68577 10.5603 8.73574 10.7651 8.83483 10.9462C8.93393 11.1273 9.07856 11.2781 9.2538 11.3831C9.42904 11.4881 9.62855 11.5434 9.83173 11.5434C10.0105 11.5421 10.1865 11.4985 10.3459 11.416C10.6156 11.2767 10.8201 11.0339 10.9149 10.7409C11.0096 10.448 10.9868 10.1286 10.8513 9.85275Z" fill="currentcolor" />
      <path d="M12.8469 9.77113C12.7359 9.69673 12.6058 9.65761 12.473 9.65871C12.3402 9.65982 12.2107 9.70109 12.1009 9.77733C11.9911 9.85356 11.9059 9.96132 11.856 10.087C11.8062 10.2126 11.794 10.3506 11.821 10.4833C11.848 10.6161 11.9129 10.7376 12.0075 10.8327C12.1022 10.9278 12.2224 10.9921 12.3528 11.0175C12.4833 11.0428 12.6181 11.0281 12.7404 10.9752C12.8627 10.9223 12.9668 10.8336 13.0397 10.7203C13.1371 10.5682 13.1715 10.3829 13.1354 10.205C13.0992 10.0271 12.9955 9.87111 12.8469 9.77113Z" fill="currentcolor" />
      <path d="M15.0334 8.02007C15.0398 7.90512 15.0126 7.79083 14.9554 7.69165C14.8981 7.59246 14.8133 7.51283 14.7117 7.46282C14.6102 7.41282 14.4963 7.39467 14.3847 7.41069C14.273 7.42671 14.1685 7.47617 14.0843 7.55282C14.0002 7.62947 13.9402 7.72986 13.9119 7.84131C13.8836 7.95277 13.8883 8.07028 13.9255 8.17898C13.9626 8.28769 14.0305 8.38272 14.1205 8.45206C14.2105 8.5214 14.3187 8.56193 14.4313 8.56854C14.5824 8.57724 14.7306 8.52436 14.8435 8.42152C14.9565 8.31867 15.0248 8.17429 15.0334 8.02007Z" fill="currentcolor" />
      <path d="M16.6134 7.60536C16.5405 7.60107 16.4679 7.61896 16.4049 7.65677C16.342 7.69458 16.2914 7.7506 16.2596 7.81776C16.2278 7.88491 16.2162 7.96019 16.2263 8.03405C16.2364 8.10792 16.2678 8.17707 16.3164 8.23276C16.365 8.28844 16.4287 8.32816 16.4994 8.34689C16.5702 8.36561 16.6447 8.36251 16.7137 8.33797C16.7827 8.31343 16.843 8.26856 16.887 8.20902C16.9311 8.14948 16.9568 8.07796 16.961 8.0035C16.9664 7.90369 16.9329 7.80575 16.8678 7.73112C16.8026 7.6565 16.7112 7.61127 16.6134 7.60536Z" fill="currentcolor" />
      <path d="M15.1258 11.3448C15.048 11.2928 14.957 11.2655 14.864 11.2664C14.7711 11.2672 14.6806 11.2962 14.6038 11.3496C14.527 11.403 14.4674 11.4785 14.4326 11.5664C14.3978 11.6544 14.3894 11.7509 14.4083 11.8437C14.4272 11.9366 14.4727 12.0217 14.539 12.0881C14.6052 12.1546 14.6893 12.1996 14.7806 12.2173C14.8719 12.235 14.9663 12.2247 15.0518 12.1876C15.1373 12.1506 15.2102 12.0885 15.2612 12.0092C15.295 11.9565 15.3184 11.8975 15.3299 11.8356C15.3414 11.7737 15.3409 11.71 15.3283 11.6483C15.3158 11.5866 15.2914 11.5281 15.2566 11.476C15.2219 11.4239 15.1774 11.3793 15.1258 11.3448Z" fill="currentcolor" />
      <path d="M4.64263 14.8655C4.58155 14.8246 4.50996 14.8031 4.43691 14.8037C4.36386 14.8043 4.29263 14.8271 4.23222 14.869C4.17181 14.9109 4.12494 14.9702 4.09754 15.0394C4.07013 15.1085 4.06342 15.1844 4.07825 15.2574C4.09308 15.3304 4.12878 15.3973 4.18085 15.4496C4.23292 15.5019 4.29901 15.5373 4.37078 15.5513C4.44254 15.5652 4.51674 15.5572 4.58401 15.5281C4.65128 15.499 4.7086 15.4502 4.74871 15.3879C4.77535 15.3465 4.79375 15.3001 4.80284 15.2515C4.81192 15.2028 4.81153 15.1528 4.80168 15.1043C4.79183 15.0558 4.77271 15.0097 4.74542 14.9687C4.71813 14.9278 4.6832 14.8927 4.64263 14.8655Z" fill="currentcolor" />
      <path d="M12.3851 14.826C12.3199 14.8595 12.2659 14.9121 12.2299 14.977C12.1939 15.042 12.1776 15.1163 12.183 15.1907C12.1884 15.2651 12.2154 15.3362 12.2603 15.3949C12.3053 15.4537 12.3664 15.4976 12.4358 15.5209C12.5052 15.5442 12.5798 15.5461 12.6502 15.5261C12.7206 15.5062 12.7836 15.4653 12.8313 15.4088C12.879 15.3523 12.9092 15.2826 12.9181 15.2086C12.927 15.1345 12.9141 15.0595 12.8812 14.9929C12.8594 14.9486 12.8293 14.9092 12.7926 14.8768C12.7558 14.8445 12.7132 14.8198 12.6671 14.8043C12.621 14.7888 12.5724 14.7827 12.524 14.7865C12.4756 14.7902 12.4284 14.8036 12.3851 14.826Z" fill="currentcolor" />
      <path d="M8.09448 11.0205C8.23375 10.8035 8.29816 10.5453 8.2775 10.2868C8.25684 10.0282 8.1523 9.78412 7.98043 9.59306C7.80855 9.402 7.57917 9.27492 7.32858 9.23194C7.07799 9.18895 6.82052 9.23252 6.59692 9.35574C6.37332 9.47897 6.19636 9.6748 6.09406 9.91226C5.99177 10.1497 5.96997 10.4152 6.03213 10.6667C6.09429 10.9183 6.23685 11.1414 6.43724 11.3009C6.63764 11.4604 6.88441 11.5471 7.13852 11.5473C7.32828 11.548 7.51518 11.5001 7.68216 11.4081C7.84915 11.3161 7.9909 11.1828 8.09448 11.0205Z" fill="currentcolor" />
      <path d="M5.81262 12.8098C5.71825 12.7468 5.60768 12.7138 5.49491 12.7149C5.38214 12.716 5.27222 12.7512 5.17905 12.8161C5.08587 12.8809 5.01363 12.9725 4.97145 13.0793C4.92927 13.1861 4.91904 13.3032 4.94206 13.4159C4.96509 13.5286 5.02032 13.6319 5.10079 13.7125C5.18126 13.7932 5.28335 13.8477 5.39415 13.8691C5.50496 13.8906 5.6195 13.878 5.72331 13.833C5.82712 13.788 5.91552 13.7126 5.97736 13.6163C6.06018 13.487 6.08935 13.3295 6.05846 13.1783C6.02757 13.0271 5.93915 12.8945 5.81262 12.8098Z" fill="currentcolor" />
      <path d="M8.47519 15.0413C8.38242 15.0359 8.29017 15.0587 8.2101 15.1069C8.13003 15.155 8.06574 15.2263 8.02535 15.3117C7.98496 15.3971 7.97029 15.4929 7.98319 15.5868C7.99609 15.6807 8.03599 15.7687 8.09784 15.8395C8.15968 15.9103 8.2407 15.9607 8.33065 15.9845C8.4206 16.0083 8.51544 16.0044 8.60318 15.9731C8.69092 15.9419 8.76762 15.8848 8.82359 15.8091C8.87956 15.7334 8.91227 15.6424 8.91761 15.5477C8.92115 15.4848 8.91251 15.4218 8.8922 15.3623C8.87188 15.3028 8.84027 15.248 8.79918 15.2009C8.7581 15.1539 8.70834 15.1156 8.65275 15.0882C8.59715 15.0608 8.53682 15.0449 8.47519 15.0413Z" fill="currentcolor" />
      <path d="M8.48022 12.023C8.34756 12.0148 8.21548 12.0469 8.10073 12.1154C7.98598 12.1838 7.89371 12.2855 7.83561 12.4075C7.77751 12.5296 7.7562 12.6665 7.77437 12.8009C7.79254 12.9353 7.84938 13.0612 7.93769 13.1626C8.02599 13.264 8.14179 13.3363 8.27042 13.3705C8.39905 13.4047 8.53471 13.3991 8.66023 13.3545C8.78575 13.3099 8.89548 13.2282 8.97552 13.1199C9.05556 13.0116 9.1023 12.8815 9.10984 12.746C9.12007 12.5649 9.05952 12.3871 8.94149 12.2516C8.82345 12.116 8.65756 12.0338 8.48022 12.023Z" fill="currentcolor" />
      <path d="M11.2264 12.7632C11.1257 12.8154 11.0424 12.897 10.987 12.9975C10.9317 13.0981 10.9068 13.2131 10.9155 13.3281C10.9243 13.4431 10.9662 13.5529 11.0361 13.6435C11.106 13.7341 11.2006 13.8016 11.3081 13.8373C11.4155 13.873 11.5309 13.8753 11.6397 13.844C11.7484 13.8128 11.8456 13.7492 11.919 13.6615C11.9923 13.5738 12.0386 13.4658 12.0518 13.3513C12.065 13.2367 12.0447 13.1208 11.9933 13.018C11.9594 12.9498 11.9127 12.8892 11.8558 12.8394C11.7988 12.7897 11.7329 12.7519 11.6616 12.7283C11.5904 12.7046 11.5153 12.6955 11.4406 12.7015C11.3659 12.7075 11.2932 12.7285 11.2264 12.7632Z" fill="currentcolor" />

      {
        showTitle &&
        <>
          <path d="M25.6935 5.2218C26.7992 5.2218 27.5736 5.84226 27.5736 6.99399H29.0219C29.0219 4.97336 27.5623 4 25.6716 4C23.3491 4 22 5.28742 22 8.00812C22 10.7964 23.3491 12.0156 25.6716 12.0156C27.5848 12.0156 29.0219 11.0677 29.0219 9.04646H27.6285C27.6285 10.1753 26.8653 10.7964 25.6941 10.7964C24.1565 10.7964 23.4477 9.92682 23.4477 8.12087V7.89537C23.4489 6.12446 24.1672 5.2218 25.6935 5.2218Z" fill="currentcolor" />
          <path d="M32.8513 4.1377L29.8986 11.8826H31.3587L31.9665 10.2002H35.4366L36.0606 11.8826H37.5869L34.6322 4.1377H32.8513ZM32.4089 8.97904L33.1833 6.85585C33.338 6.43861 33.6145 5.53531 33.67 5.37733H33.7249C33.7805 5.53531 34.0569 6.44944 34.2116 6.85585L34.9854 8.9784L32.4089 8.97904Z" fill="currentcolor" />
          <path d="M45.4829 6.48575C45.4829 5.05245 44.6755 4.1377 43.0057 4.1377H39.0021V11.8826H40.4179V8.88858H42.7729L44.2081 11.8826H45.7563L44.1339 8.66499C45.0293 8.3038 45.4829 7.53619 45.4829 6.48575ZM42.8946 7.67124H40.4179V5.36842H42.8946C43.6577 5.36842 44.0559 5.7863 44.0559 6.48575C44.0559 7.22023 43.6247 7.67124 42.8946 7.67124Z" fill="currentcolor" />
          <path d="M50.4488 4.1377H47.4174V11.8826H50.4475C52.7363 11.8826 54.1291 10.6633 54.1291 8.01013C54.1291 5.35695 52.7376 4.1377 50.4488 4.1377ZM52.6827 8.12288C52.6714 9.81672 51.8864 10.6518 50.4488 10.6518H48.8345V5.36842H50.4488C51.884 5.36842 52.6827 6.20418 52.6827 7.89738V8.12288Z" fill="currentcolor" />
          <path d="M57.8382 4.1377L54.8842 11.8826H56.3437L56.9521 10.2002H60.4247L61.0437 11.8826H62.57L59.6172 4.1377H57.8382ZM57.3958 8.97904L58.1689 6.85585C58.3236 6.43861 58.6001 5.53531 58.6556 5.37733H58.7118C58.7667 5.53531 59.0437 6.44944 59.1985 6.85585L59.9723 8.9784L57.3958 8.97904Z" fill="currentcolor" />
          <path d="M69.0415 8.74398C69.0415 8.89049 69.0633 9.28544 69.0633 9.3536L69.0084 9.3759C68.9753 9.33131 68.7538 8.93572 68.634 8.75544L65.3386 4.1377H63.9877V11.8826H65.348V7.24252C65.348 7.05142 65.3255 6.67813 65.3255 6.62143L65.3811 6.58767C65.4141 6.6329 65.5801 6.96033 65.7555 7.22023L69.0733 11.8826H70.3999V4.1377H69.0402V8.74398H69.0415Z" fill="currentcolor" />
          <path d="M76.039 4.00269C73.6616 4.00269 72.2239 5.28946 72.2239 8.01017C72.2239 10.7309 73.6591 12.0177 76.039 12.0177C78.4189 12.0177 79.8654 10.7309 79.8654 8.01017C79.8654 5.28946 78.4165 4.00269 76.039 4.00269ZM78.4165 8.13439C78.4165 9.90658 77.5872 10.7984 76.039 10.7984C74.5021 10.7984 73.6728 9.90658 73.6728 8.13439V7.89742C73.6728 6.12459 74.5133 5.22194 76.039 5.22194C77.5765 5.22194 78.4165 6.12459 78.4165 7.89742V8.13439Z" fill="currentcolor" />
        </>
      }
    </SVGIcon>
  )
};

export default Cardano
