/* eslint-disable react/no-danger */
import React, { ComponentPropsWithRef, ElementType } from 'react'
import { parse } from 'node-html-parser';
import classNames from 'classnames'

import s from './styles.module.css'
import { HtmlProps } from './types'

const Html = <T extends ElementType = "div">(props: HtmlProps<T> & ComponentPropsWithRef<T>) => {
  const { as = "div", html, className, origin, ...elementProps } = props
  const classes = classNames(s.content, className)
  const Component = as

  const parseAndOpenExternalLinks = (htmlString: string) => {
    if (!origin) return htmlString

    const doc = parse(htmlString)

    // Get all anchor tags
    const anchors = doc.getElementsByTagName('a')

    Array.from(anchors).forEach(anchor => {
      const href = anchor.getAttribute('href')

      // Check if the link is external
      if (href && !href.startsWith(origin)) {
        anchor.setAttribute('target', '_blank')
        anchor.setAttribute('rel', 'noopener noreferrer')
      }
    });

    // Return the modified HTML string
    return doc.toString()
  };

  const sanitizedHTML = parseAndOpenExternalLinks(html)

  return (
    <Component
      className={classes}
      dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
      {...elementProps}
    />
  )
}

export default Html