import { useState } from 'react'

const useConfirm = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [itemId, setItemId] = useState<number | string | null>(null)

    const onOpen = (id: number | string | null) => {
        setItemId(id)
        setIsOpen(true)
    }

    const onClose = () => {
        setItemId(null)
        setIsOpen(false)
    }

    return { isOpen, itemId, onOpen, onClose }
}

export default useConfirm
