import classNames from 'classnames'
import React, { useMemo } from 'react'
import Link from '../../ui/Link'

import SocialIcon from '../../ui/SocialIcon/SocialIcon'

import s from './styles.module.css'
import { SocialBarProps } from './types'

/**
 * Display list of icons + links from props
 * @param props 
 * @returns 
 */
const SocialBar = (props: SocialBarProps) => {
  const { links = [], className, iconClassName } = props
  const classes = classNames(s.social_bar, className)
  const iconClasses = classNames(s.social_icon, iconClassName)

  const icons = useMemo(() =>
    links.map((link) => {
      const { url, id } = link

      return (
        <Link
          key={id}
          href={url}
          target="_blank">
          <SocialIcon
            className={iconClasses}
            platform={id} />
        </Link>
      )
    }), [iconClasses, links])

  return (
    <div className={classes}>
      {icons}
    </div>
  )
}

export default SocialBar