
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import useOnClickOutside from 'hooks/useOnClickOutside'

import { MegaMenuContextValueType, MegaMenuProviderProps } from './types'

const MegaMenuContext = createContext<MegaMenuContextValueType | null>(null)

const MegaMenuProvider = (props: MegaMenuProviderProps) => {
  const { children } = props
  // Store ref to mega menu element
  const megaMenuRef = useRef<HTMLDivElement>(null)
  // Store ref to mega menu element
  const menuRef = useRef<HTMLDivElement>(null)
  // Store element that triggered menu open
  const [trigger, setTrigger] = useState<HTMLElement>()
  const [active, setActive] = useState<string | null>(null)

  /**
   * Change / update current menu displayed
   */
  const toggleActive = useCallback((id: string | null, target?: EventTarget) => {
    const current = active && active === id ? null : id
    setActive(current)
    setTrigger(target as HTMLElement)
  }, [active, setActive])

  /**
   * Close mega menu, focus trigger if set
   */
  const close = useCallback(() => {
    setActive(null)
    // Clear previous trigger
    setTrigger(undefined)

    // Focus on element that triggered menu to open
    if (trigger) {
      trigger.focus()
    }
  }, [setActive, trigger])

  const isActive = useCallback((id: string | null) => active === id, [active])

  const value = useMemo(
    () => ({
      megaMenuRef,
      menuRef,
      toggleActive,
      close,
      isActive,
      active
    }),
    [active, toggleActive, isActive, close]
  )

  // Focus on the mega menu when it opens
  useEffect(() => {
    if (megaMenuRef?.current && active !== null) {
      megaMenuRef.current.focus()
    }
  }, [active])

  useOnClickOutside([menuRef, megaMenuRef], close)

  return (
    <MegaMenuContext.Provider value={value}>
      {children}
    </MegaMenuContext.Provider>
  )
}

const useMegaMenu = () => {
  const context = useContext(MegaMenuContext)
  if (!context) {
    throw new Error('useMegaMenu must be used within an < MegaMenuProvider />')
  }

  return context
}

export { useMegaMenu, MegaMenuContext }
export default MegaMenuProvider