import React from 'react'
import cx from 'classnames';

import Typography from '../Typography';

import { SeparatorProps } from './types'
import s from './styles.module.css'

/**
 * Separator charater
 * @param props 
 * @returns 
 */
const Separator = (props: SeparatorProps) => {
  const { separator = "|", className, ...rest } = props

  const classes = cx(s.separator, className)

  return (
    <Typography
      as="span"
      className={classes}
      {...rest}>
      {separator}
    </Typography>
  )
}

export default Separator
