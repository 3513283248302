export type SupportedWalletType = {
    icon: string
    name: string
    chain: string
    installUrl: string
    desktopEnabled?: boolean
    mobileEnabled: boolean
}

export enum UnavailableWalletVisibility {
    SHOW_UNAVAILABLE,
    HIDE_UNAVAILABLE,
    SHOW_UNAVAILABLE_ON_MOBILE,
}

export enum NetworkType {
    MAINNET = 'mainnet',
    TESTNET = 'testnet',
}

export type Wallet = {
    name: string
    isEnabled: boolean
    icon: string
    apiVersion: string
}

export type HumanReadableUtxoAsset = {
    policyId: string
    assets: {
        assetName: string
        quantity: number
        assetNameHex: string
    }[]
}

export type HumanReadableUtxo = {
    tx: {
        txHash: string
        idx: number
    }
    txDetails: {
        destination: string
        value?: number
        tokens?: {
            fee: number
            assets: HumanReadableUtxoAsset[]
        }
    }
}
