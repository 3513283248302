/**
 * Check if current website is being run on production
 * @returns
 */
const isProduction = (): boolean => {
    const baseUrl = process.env.NEXT_PUBLIC_SITE_URL
    const url = new URL('', baseUrl)

    return url.origin.includes('.io')
}

export default isProduction
