import { GoogleConsent, GoogleEventType } from './types'

export const GTAG_TRACKING_ID = process.env.NEXT_PUBLIC_GTAG_ID
export const GTM_TRACKING_ID = process.env.NEXT_PUBLIC_GTM_ID

/**
 * Track a page view - https://developers.google.com/analytics/devguides/collection/gtagjs/pages
 * @param url
 * @returns
 */
export const pageview = (url: string) => {
    if (!window.gtag) {
        return
    }

    window.gtag(
        'config',
        GTAG_TRACKING_ID as string,
        {
            page_path: url,
        } as Gtag.ConfigParams,
    )
}

/**
 * Track an event - https://developers.google.com/analytics/devguides/collection/gtagjs/events
 * @param param0
 * @returns
 */
export const event = ({ action, category, label, value }: GoogleEventType) => {
    if (!window.gtag) {
        return
    }

    window.gtag('event', action, {
        event_category: category,
        event_label: label,
        value,
    })
}

export const getConsentMode = (consent: GoogleConsent) =>
    ({
        functionality_storage: consent.preferences ? 'granted' : 'denied',
        security_storage: consent.necessary ? 'granted' : 'denied',
        ad_storage: consent.marketing ? 'granted' : 'denied',
        analytics_storage: consent.analytics ? 'granted' : 'denied',
        personalization_storage: consent.preferences ? 'granted' : 'denied',
        ad_user_data: consent.marketing ? 'granted' : 'denied',
        ad_personalization: consent.marketing ? 'granted' : 'denied',
        ads_data_redaction: consent.redact,
    } as Gtag.ConsentParams)

export const setConsentMode = (consent: GoogleConsent) => {
    if (!window.gtag) {
        return
    }

    const consentMode = getConsentMode(consent)

    if (consentMode) window.gtag('consent', 'update', consentMode)
}

/**
 * Toogle whether analytics can track with cookies - https://developers.google.com/tag-platform/devguides/privacy#consent_mode_
 * @param value
 */
export const toggleAnalyticsConsent = (value: 'granted' | 'denied') => {
    if (!window.gtag) {
        return
    }

    window.gtag('consent', 'update', {
        analytics_storage: value,
    })
}

function useGoogleTags() {
    return {
        gtagId: GTAG_TRACKING_ID,
        gtmId: GTM_TRACKING_ID,
        toggleAnalyticsConsent,
        event,
        pageview,
    }
}

export default useGoogleTags
