import {
    API_MFA_SIGNIN,
    API_REFRESH_ACCESS_TOKEN,
    API_REGISTER,
    API_SIGN_IN,
    API_SIGNIN_VALIDATE,
    API_SIGNIN_GRANT,
} from 'consts/apiRoutes'

import {
    RefreshAccessTokenResponseType,
    RegisterUserPayloadType,
    RegisterUserResponseType,
    SignInPayloadType,
    SignInResponseType,
    SuccessSignInResponseType,
    MfaSignInPayloadType,
    SignInGrantResponseType,
    SignValidateResponseType,
} from 'types'

import HttpClientBase from './HttpClientBase'

class AuthHttpClient extends HttpClientBase {
    static setAccessToken(accessToken: string) {
        super.setAccessToken(accessToken)

        return this
    }

    static async Signin(payload: SignInPayloadType) {
        return this.request<SignInResponseType>({
            url: API_SIGN_IN,
            data: payload,
            method: 'POST',
        })
    }

    static async SigninValidate(source?: string, callbackUrl?: string) {
        return this.request<SignValidateResponseType>({
            url: `${API_SIGNIN_VALIDATE}?source=${source || ''}&callbackUrl=${callbackUrl}&json=1`,
            method: 'GET',
        })
    }

    static async SigninGrant(id: number, refreshToken: string) {
        this.requireAuth()

        return this.request<SignInGrantResponseType>({
            url: API_SIGNIN_GRANT,
            data: {
                playerUserId: id,
                refreshToken,
            },
            method: 'POST',
        })
    }

    static async MfaSignin(id: number, payload: MfaSignInPayloadType) {
        return this.request<SuccessSignInResponseType>({
            url: API_MFA_SIGNIN(id),
            data: payload,
            method: 'POST',
        })
    }

    static async Register(payload: RegisterUserPayloadType) {
        return this.request<RegisterUserResponseType>({
            url: API_REGISTER,
            data: payload,
            method: 'POST',
        })
    }

    static async RefreshAccessToken(token: string) {
        return this.request<RefreshAccessTokenResponseType>({
            url: API_REFRESH_ACCESS_TOKEN,
            data: {
                refreshToken: token,
            },
            method: 'POST',
        })
    }
}

export default AuthHttpClient
