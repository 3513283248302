import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const Vespr = (props: SVGIconProps) => {
  const { width = 140, height = 80, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} {...svgProps}>
      <path d="M107.624 0.23814L69.7798 51.3786V51.1752L31.9157 0H0L9.8431 13.306H19.3389L69.76 79.7321V80L120.201 13.5442H129.697L139.54 0.23814H107.624Z" fill="white" />
    </SVGIcon>
  )
}

export default Vespr