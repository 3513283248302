import React from 'react'
import cn from 'classnames'

import { ROUTE_AZURE_MARKETPLACE, ROUTE_AZURE_MARKETPLACE_WITH_LOGIN } from 'consts/routes'
import { LOCAL_STORAGE_MARKETPLACE_AFFILIATE_LINK_GUID_ID } from 'consts/marketplace'
import useLocalStorage from 'hooks/useLocalStorage'

import Link from '../Link'
import StyledButton from '../StyledButton'
import { MarketplaceLinkProps } from './types'

import s from './styles.module.css'

const MarketplaceLink = (props: MarketplaceLinkProps) => {
  const { href, saleSlug = '', disabled, onClick: propsOnClick, chain, className, ...linkProps } = props
  const { reset: resetAffiliateLinkGuid, getLatestValue } = useLocalStorage<string | null>(LOCAL_STORAGE_MARKETPLACE_AFFILIATE_LINK_GUID_ID, null)
  const affiliateLinkGuid = getLatestValue()

  let url = ''

  const goTo = href ?? `${ROUTE_AZURE_MARKETPLACE}${saleSlug}${chain ? `/${chain}` : ''}`

  if (!disabled) {
    const newUrl = new URL(goTo)

    if (affiliateLinkGuid)
      newUrl.searchParams.set('allid', affiliateLinkGuid)

    url = ROUTE_AZURE_MARKETPLACE_WITH_LOGIN(encodeURIComponent(newUrl.toString()))
  }

  const onClick = () => {
    if (disabled) return

    if (affiliateLinkGuid) {
      setTimeout(() => {
        resetAffiliateLinkGuid()
      }, 5000)
    }

    if (propsOnClick) propsOnClick()
  }

  return (
    <StyledButton
      as={Link}
      className={cn(s.link, className)}
      disabled={disabled}
      onClick={onClick}
      href={url}
      {...linkProps} />
  )
}

export default MarketplaceLink