import { intervalToDuration, formatDuration } from 'date-fns'

function secondsToDurationString(seconds: number): string {
    const duration = intervalToDuration({ start: 0, end: seconds * 1000 })

    return formatDuration(duration, {
        delimiter: ', ',
        format: ['days', 'hours', 'minutes', 'seconds'],
        zero: false,
    })
}

export default secondsToDurationString
