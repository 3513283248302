import { UseQueryOptions } from '@tanstack/react-query'
import useAuthApi from 'hooks/useAuthApi'

import { PlayerHttpClient } from '../network'
import { PlayerUserType, UserType, UserOwnedAssetsResponse } from '../types'

export const PLAYER_QUERY_KEY = 'player'
export const PLAYER_FULL_QUERY_KEY = `${PLAYER_QUERY_KEY}_FULL`
export const PLAYER_SHORT_QUERY_KEY = `${PLAYER_QUERY_KEY}_SHORT`
export const USER_ASSETS_QUERY_KEY = `${PLAYER_QUERY_KEY}_ASSETS`
export const PLAYER_BALANCE_QUERY_KEY = `${PLAYER_QUERY_KEY}_BALANCE`

export const usePlayerCopiBalance = (refetchInterval: number | false = false) =>
    useAuthApi({
        queryKey: [PLAYER_BALANCE_QUERY_KEY],
        queryFn: (token) => PlayerHttpClient.setAccessToken(token).GetUserCopiBalance(),
        retry: false,
        refetchOnWindowFocus: false,
        refetchInterval,
    })

export const usePlayerFullQuery = (
    id: number,
    options?: Omit<UseQueryOptions<PlayerUserType>, 'queryKey' | 'queryFn'>,
) =>
    useAuthApi({
        queryKey: [PLAYER_FULL_QUERY_KEY, id],
        queryFn: (token) => PlayerHttpClient.setAccessToken(token).GetUserFull(id),
        retry: false,
        refetchOnWindowFocus: false,
        ...options,
    })

export const usePlayerShortQuery = (
    id: number,
    options?: Omit<UseQueryOptions<UserType>, 'queryKey' | 'queryFn'>,
) =>
    useAuthApi({
        queryKey: [PLAYER_SHORT_QUERY_KEY, id],
        queryFn: (token) => PlayerHttpClient.setAccessToken(token).GetUserShort(),
        retry: false,
        refetchOnWindowFocus: false,
        ...options,
    })

export const useUserOwnedAssetsQuery = (
    options?: Omit<UseQueryOptions<UserOwnedAssetsResponse>, 'queryKey' | 'queryFn'>,
) =>
    useAuthApi({
        queryKey: [USER_ASSETS_QUERY_KEY],
        queryFn: (token) => PlayerHttpClient.setAccessToken(token).GetUserOwnedAssets(),
        retry: false,
        refetchOnWindowFocus: false,
        ...options,
    })
