import React from "react"
import { HighlightedTextProps } from './types'

const HighlightedText = (props: HighlightedTextProps) => {
  const { text, highlight, className } = props

  if (!highlight) return text

  const parts = text.split(new RegExp(`(${highlight})`, "gi"))

  return (
    <>
      {parts.map((part, index) =>
        part.toLowerCase() === highlight.toLowerCase() ? (
          <span key={index} className={className}>
            {part}
          </span>
        ) : (
          part
        )
      )}
    </>
  )
}

export default HighlightedText