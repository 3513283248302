import React from 'react'
import cn from 'classnames'

import useVariant from 'hooks/useVariant'
import { HelpIconProps } from './types'
import s from './styles.module.css'

const HelpIcon = (props: HelpIconProps) => {
  const { variant = "primary", size = "md", hover = true } = props

  const variantClass = useVariant({
    variant,
    color: true,
    filled: true,
    hover
  })

  return (
    <span className={cn(s.help, s[size], variantClass)}>?</span>
  )
}

export default HelpIcon