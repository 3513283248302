import React from 'react'
import cn from 'classnames'

import Mask from '../Mask'
import { useVariant } from '../../../hooks'
import { StyledPillProps } from './types'

import s from "./styles.module.css"

const StyledPill = (props: StyledPillProps) => {
  const { children, variant, hover = false, className } = props

  const variantClass = useVariant({
    variant,
    filled: true,
    hover
  })

  return (
    <Mask
      type="notched"
      className={cn(s.pill, variantClass, className)}>
      {children}
    </Mask>
  )
}

export default StyledPill