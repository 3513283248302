const getFileNodeNameFromId = (id: string): string => {
    const regex = /CFNB|CFN/i
    const replacement = id.match(regex)
        ? `File Node License (${id.includes('CFNB') ? 'Base' : 'Cardano'}) #`
        : ''
    const name = id.replace(regex, replacement)

    return name
}

export default getFileNodeNameFromId
