export type HttpClientResponseErrorParams = {
    message: string
    code?: number
    field?: string
    errors?: HttpClientResponseErrorParams[]
}

class HttpClientResponseError extends Error {
    public code?: number

    public field?: string

    public errors?: HttpClientResponseErrorParams[]

    constructor(params: HttpClientResponseErrorParams) {
        const { message, code, field = 'root', errors } = params

        super(message)
        this.name = this.constructor.name
        this.code = code
        this.field = field
        this.errors = errors

        Object.setPrototypeOf(this, HttpClientResponseError.prototype)
    }

    isUnauthorized() {
        return this.code === 401
    }

    getFirstErrorMessage() {
        return this.errors && this.errors.length > 0 ? this.errors[0].message : this.message
    }
}

export default HttpClientResponseError
