import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const Tick = (props: SVGIconProps) => {
  const { width = 15, height = 11, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} aria-label="Complete" {...svgProps}>
      <path d="M5.32393 10.7553C4.67245 10.7553 4.02097 10.4946 3.52492 9.97272L0.607178 6.90239C0.10864 6.37839 0.103178 5.53205 0.589799 5.00021C1.07642 4.46838 1.94191 4.46472 2.45386 5.00335L4.70175 7.3684C5.04586 7.73044 5.60349 7.73044 5.9476 7.3684L12.4559 0.519833C12.9599 -0.0104359 13.7777 -0.0104359 14.2817 0.519833C14.7857 1.0501 14.7857 1.91055 14.2817 2.44082L7.12294 9.97272C6.62688 10.4946 5.9754 10.7553 5.32393 10.7553Z" />
    </SVGIcon>
  )
};

export default Tick
