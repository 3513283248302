import React from 'react'

import { SorterProps } from './types'
import { Sort } from '../../Icon'

const Sorter = (props: SorterProps) => {
  const { sort, className } = props

  const renderIcon = () => {
    if (!sort) {
      return <Sort sort="none" />
    }

    if (sort === 'asc') {
      return <Sort sort="asc" />
    }

    return <Sort sort="desc" />
  }

  return (
    <div className={className}>
      {renderIcon()}
    </div>
  )
}

export default Sorter
