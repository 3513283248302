/**
 * Chunk array based on items in array.
 * @param {Array}
 * @param {number}
 * @returns
 */

const chunkArray = <T>(array: Array<T>, chunk: number): Array<Array<T>> => {
    const result = []

    for (let i = 0; i < array.length; i += chunk) {
        result.push(array.slice(i, i + chunk))
    }

    return result
}

export default chunkArray
