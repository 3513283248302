import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const Ascendex = (props: SVGIconProps) => {
  const { width = 195, height = 32, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} aria-label="AsendEx" {...svgProps}>
      <path d="M46.6693 0.235761C45.453 -0.243174 44.0765 0.0194671 43.1483 0.915537L31.0648 12.5799V18.9142L44.0125 6.431V27.2414H22.5024L20.0216 23.3637L11.3632 31.7218H45.437C47.2134 31.7218 48.6538 30.3313 48.6538 28.6166V3.12481C48.6538 1.85796 47.8696 0.730143 46.6693 0.235761Z" fill="currentcolor" />
      <path d="M11.2192 27.2414L25.4793 13.476V26.33H30.1206V10.1543C30.1206 8.88747 29.3364 7.75965 28.136 7.28072C26.9196 6.80178 25.5433 7.06444 24.615 7.9605L0 31.7218H10.0029L14.6442 27.2414H11.2192Z" fill="currentcolor" />
      <path d="M160.798 31.66H175.746V28.4311H164.543V22.1585H175.746V18.9915H164.543V12.549H175.746V9.49001H160.798V31.66Z" fill="currentcolor" />
      <path d="M187.782 19.9957L194.504 9.49001H190.678L187.173 14.9437C186.917 15.3608 186.677 15.7625 186.469 16.1024C186.261 16.4577 186.085 16.7822 185.909 17.1066L185.669 16.674C185.189 15.7779 184.853 15.2063 184.677 14.9128L181.364 9.49001H177.443L184.037 20.0574L176.578 31.66H180.532L184.549 25.3257C184.789 24.9395 185.013 24.5687 185.221 24.198C185.429 23.8272 185.621 23.4718 185.797 23.1319C186.149 23.7962 186.533 24.507 186.965 25.2639L187.029 25.372L190.871 31.66H195L187.782 19.9957Z" fill="currentcolor" />
      <path d="M65.8268 9.84534H62.4498L53.6153 31.66H57.4082L59.905 25.403H68.2755L70.7242 31.66H74.6453L65.8747 9.95349L65.8268 9.84534ZM61.2014 22.035L64.1302 14.5574L66.995 22.035H61.2014Z" fill="currentcolor" />
      <path d="M102.445 25.4494C101.981 26.3918 101.325 27.1333 100.509 27.6586C99.6924 28.1685 98.7482 28.4311 97.7399 28.4311C96.2195 28.4311 94.939 27.9058 93.9148 26.8707C92.8904 25.8355 92.3782 24.507 92.3782 22.931C92.3782 21.3862 92.8904 20.0574 93.8988 19.0069C94.9071 17.9563 96.1395 17.431 97.5798 17.431C98.7001 17.431 99.6764 17.6628 100.477 18.1417C101.277 18.6207 101.949 19.3467 102.461 20.3047L102.509 20.3974H106.19L106.11 20.1656C105.454 18.2808 104.398 16.7976 102.957 15.7471C101.517 14.6965 99.7885 14.1712 97.8359 14.1712C96.5875 14.1712 95.3872 14.4029 94.2989 14.8819C93.1945 15.3454 92.2024 16.056 91.322 16.9521C90.5218 17.7864 89.9137 18.7133 89.5134 19.7176C89.0975 20.7218 88.9054 21.8187 88.9054 22.962C88.9054 24.0743 89.1294 25.1867 89.5776 26.2681C90.0256 27.3342 90.6658 28.292 91.4501 29.0955C92.2824 29.9451 93.2426 30.5941 94.2828 31.0112C95.3231 31.4438 96.4915 31.6446 97.7559 31.6446C99.7724 31.6446 101.517 31.1194 102.925 30.0996C104.334 29.0799 105.406 27.5504 106.126 25.5729L106.206 25.3412H102.525L102.445 25.4494Z" fill="currentcolor" />
      <path d="M122.995 18.0336C122.163 16.8285 121.075 15.8706 119.794 15.1909C118.498 14.5111 117.089 14.1712 115.585 14.1712C114.321 14.1712 113.136 14.4029 112.08 14.851C111.008 15.299 110.047 15.9942 109.199 16.8903C108.399 17.7246 107.791 18.6515 107.391 19.6557C106.974 20.66 106.782 21.7569 106.782 22.9156C106.782 24.0279 107.007 25.1404 107.455 26.2219C107.903 27.2878 108.543 28.2456 109.327 29.0491C110.175 29.9141 111.136 30.5631 112.176 30.9957C113.216 31.4284 114.385 31.6446 115.649 31.6446C117.521 31.6446 119.186 31.1502 120.61 30.177C122.019 29.2036 123.139 27.7977 123.923 25.9746L124.035 25.7274H120.242L120.194 25.8201C119.73 26.639 119.09 27.2724 118.306 27.7203C117.521 28.1684 116.625 28.3847 115.665 28.3847C114.321 28.3847 113.152 27.9676 112.176 27.1643C111.248 26.3918 110.656 25.3876 110.383 24.1824H124.483V23.549C124.483 22.4057 124.355 21.3862 124.131 20.49C123.859 19.6095 123.491 18.7752 122.995 18.0336ZM110.463 21.2317C110.832 20.0728 111.456 19.146 112.336 18.4662C113.264 17.74 114.385 17.3847 115.665 17.3847C116.913 17.3847 117.986 17.74 118.882 18.4507C119.714 19.115 120.338 20.0574 120.706 21.2471H110.463V21.2317Z" fill="currentcolor" />
      <path d="M155.42 16.7822C154.668 16.1178 153.868 15.608 152.988 15.2527C151.995 14.851 150.907 14.6501 149.755 14.6501C148.458 14.6501 147.274 14.8664 146.25 15.2836C145.225 15.7007 144.281 16.3496 143.417 17.2147C142.633 18.0027 142.008 18.9296 141.592 19.9647C141.176 20.9998 140.952 22.1123 140.952 23.271C140.952 25.7584 141.816 27.8594 143.513 29.5126C145.209 31.1655 147.37 32 149.931 32C151.035 32 152.075 31.7991 153.052 31.382C153.9 31.0266 154.684 30.5322 155.388 29.8834V31.66H158.813V9.49001H155.42V16.7822ZM153.9 27.2414C152.812 28.323 151.531 28.8636 150.091 28.8636C148.522 28.8636 147.178 28.323 146.09 27.2568C145.001 26.1909 144.457 24.8314 144.457 23.2556C144.457 21.7414 145.001 20.4592 146.09 19.4086C147.178 18.3579 148.506 17.8327 150.027 17.8327C151.515 17.8327 152.828 18.3889 153.9 19.4858C154.988 20.5827 155.532 21.9114 155.532 23.4563C155.548 24.8931 154.988 26.1599 153.9 27.2414Z" fill="currentcolor" />
      <path d="M81.3832 17.3692C81.4953 17.3538 81.5914 17.3538 81.7194 17.3538C82.0876 17.3538 82.4235 17.4156 82.7437 17.5083C83.4639 17.7555 84.0401 18.2808 84.3281 18.9605H88.0091C87.833 17.8791 87.225 16.9058 86.3287 16.1487C85.9926 15.8089 85.5925 15.5308 85.1283 15.299C84.5681 15.0209 83.928 14.8201 83.2078 14.7119C82.7437 14.6192 82.2634 14.5729 81.7673 14.5729C80.711 14.5729 79.7029 14.7583 78.8225 15.1136C78.7424 15.1445 78.6624 15.1754 78.5824 15.2063C78.5345 15.2218 78.5024 15.2372 78.4544 15.2681C78.0383 15.4535 77.6543 15.6698 77.3181 15.9324C76.1658 16.8131 75.6057 17.9563 75.6057 19.3623C75.6057 20.3974 75.9576 21.278 76.6459 22.0042C77.35 22.7301 78.5666 23.4409 80.3109 24.136C80.6151 24.2751 81.0632 24.4296 81.6393 24.6305C83.3518 25.2485 84.2001 25.9746 84.2001 26.8397C84.2001 27.4887 83.9601 27.9986 83.4799 28.3693C82.9997 28.7401 82.3435 28.9254 81.4953 28.9254C80.5191 28.9254 79.7189 28.7247 79.0946 28.3076C79.0786 28.292 79.0625 28.292 79.0306 28.2766C78.4384 27.8594 77.9904 27.2568 77.8143 26.5153H74.2612C74.2933 27.226 74.5333 27.8904 74.9174 28.5238C75.4936 29.528 76.2619 30.2851 77.206 30.7948C78.2144 31.3356 79.4628 31.629 80.9511 31.66C80.9831 31.66 81.0152 31.66 81.0311 31.66C81.0792 31.66 81.1271 31.66 81.1911 31.66C83.2718 31.66 84.9203 31.2119 86.1045 30.3159C87.2889 29.4199 87.8812 28.1838 87.8812 26.608C87.8812 25.5111 87.5611 24.6151 86.9049 23.8734C86.2487 23.1474 85.1764 22.4985 83.6719 21.9114C83.3358 21.7723 82.8717 21.6024 82.2476 21.4016C80.3109 20.7218 79.3507 19.9803 79.3507 19.146C79.3507 19.0223 79.3667 18.9142 79.3828 18.7906C79.7668 18.049 80.503 17.5083 81.3832 17.3692Z" fill="currentcolor" />
      <path d="M132.806 14.6038C128.725 14.6038 125.412 17.8018 125.412 21.7414V31.66H128.821V22.035C128.821 19.9183 130.613 18.1881 132.806 18.1881C134.999 18.1881 136.791 19.9183 136.791 22.035V31.66H140.2V21.7414C140.2 17.8018 136.887 14.6038 132.806 14.6038Z" fill="currentcolor" />
    </SVGIcon>
  )
}

export default Ascendex