import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const CalidoValleyLogo = (props: SVGIconProps) => {
  const { width = 440, height = 74, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} {...svgProps}>
      <path d="M230.476 34.4152H271.202L266.65 47.3163H213.063L229.755 0.0121346H234.042L218.867 43.016H263.88L265.397 38.7156H224.671L238.329 0.0121346H242.616L230.476 34.4152Z" fill="url(#paint0_linear_6546_1464)" />
      <path d="M205.284 8.6129H168.845L161.258 30.1148H197.697L205.284 8.6129ZM194.928 25.8144H167.062L171.614 12.9133H199.48L194.928 25.8144ZM214.932 5.57165L200.201 47.3163H187.34L190.375 38.7156H162.509L159.474 47.3163H155.187L159.739 34.4152H196.178L193.143 43.016H197.43L210.015 7.35285C209.299 6.24131 208.772 5.42315 208.056 4.31161H169.106L165.001 7.35285L150.899 47.3163H146.612L161.343 5.57165L168.848 0.0121346H211.35L214.93 5.57257L214.932 5.57165Z" fill="url(#paint1_linear_6546_1464)" />
      <path d="M309.065 0.0121346L292.372 47.3163H288.085L303.26 4.31252H298.973L283.798 47.3163H279.511L296.204 0.0121346H309.065Z" fill="url(#paint2_linear_6546_1464)" />
      <path d="M369.971 0.0121346L373.551 5.57256L360.782 41.7568L353.277 47.3172H305.232L318.889 8.61381H363.903L353.28 38.7165H316.84L324.428 17.2146H328.715L322.645 34.4161H350.51L358.098 12.9142H321.659L311.036 43.0169H353.018C354.518 41.9053 355.623 41.0872 357.122 39.9756L368.634 7.35376L366.675 4.31252H320.406L321.924 0.0121346H369.969L369.971 0.0121346Z" fill="url(#paint3_linear_6546_1464)" />
      <path d="M144.903 0.0121346L148.483 5.57165L144.375 17.2137H131.514L133.032 12.9133H105.169L97.5814 34.4152H125.444L126.962 30.1148H131.249L128.213 38.7156H91.7776L102.401 8.6129H138.836L137.319 12.9133H141.606L143.567 7.35285L141.609 4.31161H102.662L98.5578 7.35285L87.0465 39.9747C87.7622 41.0863 88.289 41.9044 89.0047 43.016H127.952L132.056 39.9747L135.536 30.1148H139.823L135.715 41.7568L128.21 47.3163H85.7105L82.1301 41.7568L94.8993 5.57257L102.403 0.0130456H144.903V0.0121346Z" fill="url(#paint4_linear_6546_1464)" />
      <path d="M393.917 0.0121346L386.412 5.57256L373.643 41.7568L377.223 47.3172H419.725L427.23 41.7577L439.999 5.57348L436.419 0.0130456H393.917V0.0121346ZM423.572 39.9756L419.468 43.0169H380.517C379.802 41.9053 379.275 41.0872 378.559 39.9756L390.07 7.35376L394.175 4.31252H433.125L435.084 7.35376L423.572 39.9756ZM393.913 8.6129L383.29 38.7156H419.73L430.352 8.6129H393.913ZM389.095 34.4152L396.682 12.9133H424.548L416.96 34.4152H389.095Z" fill="url(#paint5_linear_6546_1464)" />
      <path d="M292.934 61.0202H278.114L276.738 62.0388L272.508 73.9869H273.944L274.964 71.1051H288.943L287.923 73.9869H289.359L293.589 62.0388C293.35 61.6661 293.173 61.3919 292.934 61.0202ZM289.453 69.6647H275.474L278.024 62.4606H292.003L289.453 69.6647Z" fill="white" />
      <path d="M388.829 61.0202H374.009L372.633 62.0388L368.403 73.9869H369.839L370.859 71.1051H384.838L383.818 73.9869H385.254L389.484 62.0388C389.245 61.6661 389.068 61.3919 388.829 61.0202ZM385.347 69.6647H371.368L373.919 62.4606H387.898L385.347 69.6647Z" fill="white" />
      <path d="M300.098 62.4615L296.527 72.5474H310.505L311.013 71.1124H312.449L311.791 72.9692L310.415 73.9878H295.595C295.356 73.6152 295.179 73.3409 294.94 72.9692L298.81 62.0397L300.186 61.0211H315.006L315.661 62.0397L315.001 63.9029H313.565L314.076 62.4624L300.098 62.4615Z" fill="white" />
      <path d="M334.619 66.7838L334.108 68.2242H320.129L318.599 72.5465H334.014L333.503 73.9869H316.652L321.243 61.0202H338.094L337.583 62.4606H322.168L320.638 66.7829H334.617L334.619 66.7838Z" fill="white" />
      <path d="M409.357 67.2029L400.662 70.0847L399.282 73.9851H397.846L399.227 70.0847C399.227 70.0847 392.731 67.5518 392.506 67.2029L394.696 61.0174H396.132L394.274 66.2663L400.405 68.7818L408.27 66.2143L410.11 61.0174H411.546L409.356 67.2029H409.357Z" fill="white" />
      <path d="M360.73 73.9878H352.696L351.578 72.2485L349.229 73.9878H341.178L339.597 71.5279L343.319 61.0147H344.755L341.347 70.6414L342.571 72.5465H348.855L351.429 70.6414L353.815 63.902H355.251L352.865 70.6414L354.089 72.5465H360.355L362.929 70.6414L366.337 61.0147H367.773L364.051 71.5279L360.728 73.9878H360.73Z" fill="white" />
      <path d="M269.688 67.2056L271.517 62.0397C271.278 61.667 271.101 61.3928 270.862 61.0211H255.027L252.477 68.2251L250.437 73.9878H251.893L253.423 69.6656H265.766L266.804 71.2809L265.85 73.9878H267.288L268.457 70.6842C268.287 70.4181 267.339 68.9449 267.339 68.9449C267.339 68.9449 269.329 67.4726 269.689 67.2056H269.688ZM266.28 68.2242H253.932L255.972 62.4615H269.931L268.462 66.6088L266.279 68.2242H266.28Z" fill="white" />
      <path d="M149.701 61.0202H134.881L133.505 62.0388L129.275 73.9869H130.711L131.731 71.1051H145.71L144.69 73.9869H146.126L150.356 62.0388C150.117 61.6661 149.94 61.3919 149.701 61.0202ZM146.219 69.6647H132.24L134.791 62.4606H148.77L146.219 69.6647Z" fill="white" />
      <path d="M213.458 66.7838L212.947 68.2242H198.968L197.438 72.5465H212.853L212.342 73.9869H195.492L200.082 61.0202H216.933L216.422 62.4606H201.007L199.477 66.7829H213.456L213.458 66.7838Z" fill="white" />
      <path d="M190.272 73.9878H173.421L178.011 61.0211H179.447L175.366 72.5474H190.781L190.272 73.9878Z" fill="white" />
      <path d="M236.819 67.2029L228.124 70.0847L226.744 73.9851H225.308L226.688 70.0847C226.688 70.0847 220.193 67.5518 219.968 67.2029L222.158 61.0174H223.594L221.736 66.2663L227.866 68.7818L235.732 66.2143L237.572 61.0174H239.008L236.819 67.2029Z" fill="white" />
      <path d="M168.199 73.9878H151.349L155.939 61.0211H157.375L153.294 72.5474H168.709L168.199 73.9878Z" fill="white" />
      <path d="M127.208 61.0205L126.003 64.4253L116.57 72.5441H116.092L112.193 63.9497L113.229 61.0205H111.793L110.583 64.439L114.897 73.9845H115.064H116.5H116.746L127.258 64.9364L128.644 61.0205H127.208Z" fill="white" />
      <path d="M35.9108 37.8555H26.4794L24.4476 43.6428L26.8282 47.3163H32.5902L35.9108 37.8555Z" fill="#C9365D" />
      <path d="M20.3686 28.3947L23.6892 18.9338H14.2579L12.2134 24.7585L14.5694 28.3947H20.3686Z" fill="#D98433" />
      <path d="M8.14707 9.47292L11.4686 0.012085H2.03722L0 5.90689L2.27791 9.42281L8.14707 9.47292Z" fill="#F68B26" />
      <path d="M54.7734 37.8555H45.342L43.3402 43.5581L45.7762 47.3163H51.4528L54.7734 37.8555Z" fill="#5679B5" />
      <path d="M39.6464 37.8555H45.3421L48.6626 28.3947H39.2313L37.2231 34.1154L39.6464 37.8555Z" fill="#5679B5" />
      <path d="M33.5165 28.3947H39.2312L42.5518 18.9338H33.1205L31.106 24.6737L33.5165 28.3947Z" fill="#C9365D" />
      <path d="M27.3876 18.9338H33.1205L36.442 9.47297H27.0107L24.9889 15.232L27.3876 18.9338Z" fill="#C9365D" />
      <path d="M27.0107 9.47297L30.3313 0.0121346H20.9L18.8718 5.79032L21.2578 9.47297H27.0107Z" fill="#D98433" />
      <path d="M73.636 37.8555H64.2047L62.1984 43.5735L64.6234 47.3163H70.3155L73.636 37.8555Z" fill="#804791" />
      <path d="M58.4936 37.8555H64.2047L67.5253 28.3947H58.0939L56.0803 34.1318L58.4936 37.8555Z" fill="#804791" />
      <path d="M52.3638 28.3947H58.094L61.4155 18.9338H51.9841L49.9633 24.6892L52.3638 28.3947Z" fill="#5679B5" />
      <path d="M46.2349 18.9338H51.9842L55.3048 9.47297H45.8734L43.8462 15.2475L46.2349 18.9338Z" fill="#5679B5" />
      <path d="M45.8733 9.47297L49.1939 0.0121346H39.7626L37.729 5.80581L40.105 9.47297H45.8733Z" fill="#ED355E" />
      <path d="M71.2747 28.3947H76.9576L80.2782 18.9338H70.8469L68.8433 24.6418L71.2747 28.3947Z" fill="#804791" />
      <path d="M65.1448 18.9338H70.8468L74.1674 9.47297H64.7361L62.7261 15.2001L65.1448 18.9338Z" fill="#804791" />
      <path d="M64.7361 9.47297L68.0567 0.0121346H58.6253L56.609 5.75752L59.0159 9.47297H64.7361Z" fill="#5679B5" />
      <path d="M83.5987 9.47297L86.9193 0.0121346H77.488L75.4662 5.77209L77.864 9.47297H83.5987Z" fill="#804791" />
      <defs>
        <linearGradient id="paint0_linear_6546_1464" x1="242.132" y1="-0.197418" x2="242.132" y2="48.2402" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F68B26" />
          <stop offset="0.299" stopColor="#E66D39" />
          <stop offset="0.761" stopColor="#D14553" />
          <stop offset="1" stopColor="#C9365D" />
        </linearGradient>
        <linearGradient id="paint1_linear_6546_1464" x1="180.772" y1="-0.197418" x2="180.772" y2="48.2402" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F68B26" />
          <stop offset="0.306" stopColor="#F26C39" />
          <stop offset="0.763" stopColor="#EE4453" />
          <stop offset="1" stopColor="#ED355E" />
        </linearGradient>
        <linearGradient id="paint2_linear_6546_1464" x1="294.287" y1="-0.197418" x2="294.287" y2="48.2402" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F68B26" />
          <stop offset="0.306" stopColor="#F26C39" />
          <stop offset="0.763" stopColor="#EE4453" />
          <stop offset="1" stopColor="#ED355E" />
        </linearGradient>
        <linearGradient id="paint3_linear_6546_1464" x1="339.392" y1="-0.197418" x2="339.392" y2="48.2402" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F68B26" />
          <stop offset="0.306" stopColor="#F26C39" />
          <stop offset="0.763" stopColor="#EE4453" />
          <stop offset="1" stopColor="#ED355E" />
        </linearGradient>
        <linearGradient id="paint4_linear_6546_1464" x1="115.307" y1="-0.197418" x2="115.307" y2="48.2402" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F68B26" />
          <stop offset="0.306" stopColor="#F26C39" />
          <stop offset="0.763" stopColor="#EE4453" />
          <stop offset="1" stopColor="#ED355E" />
        </linearGradient>
        <linearGradient id="paint5_linear_6546_1464" x1="406.821" y1="-0.197418" x2="406.821" y2="48.2402" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F68B26" />
          <stop offset="0.306" stopColor="#F26C39" />
          <stop offset="0.763" stopColor="#EE4453" />
          <stop offset="1" stopColor="#ED355E" />
        </linearGradient>
      </defs>
    </SVGIcon>
  )
}

export default CalidoValleyLogo