import { useReducer } from 'react'
import { toast } from 'react-toastify'
import { HttpClientApiFieldError } from '@/network/httpClients'
import t from 'helpers/translation/getTranslation'
import isEmpty from 'helpers/isEmpty'
import {
    UseHttpClientProps,
    UseHttpClientAction,
    UseHttpClientFetcherType,
    UseHttpClientResult,
    UseHttpClientState,
} from './types'

function useHttpClient<T>(props: UseHttpClientProps<T>): UseHttpClientResult<T> {
    const {
        httpClient,
        showToastError = true,
        ignoreErrorCodes = [],
        successToast,
        onSuccess,
        onError,
    } = props

    const initialState: UseHttpClientState<T> = {
        error: undefined,
        data: undefined,
        loading: false,
    }

    const fetchReducer = (
        state: UseHttpClientState<T>,
        action: UseHttpClientAction<T>,
    ): UseHttpClientState<T> => {
        switch (action.type) {
            case 'loading':
                return { ...initialState, loading: true }
            case 'fetched':
                return { ...initialState, loading: false, data: action.payload }
            case 'error':
                return { ...initialState, loading: false, error: action.payload }
            default:
                return state
        }
    }

    const [state, dispatch] = useReducer(fetchReducer, initialState)

    const execute: UseHttpClientResult<T>['execute'] = async (
        ...args: Parameters<UseHttpClientFetcherType<T>>
    ) => {
        dispatch({ type: 'loading' })

        const { response, data, error } = await httpClient(...args)

        if (!response.ok) {
            dispatch({ type: 'error', payload: error as HttpClientApiFieldError[] })

            if (showToastError && error && error?.length > 0) {
                // Filter out errors that can be ignored from the notification
                const acceptedErrors = error.filter(
                    (er) => er.code && !ignoreErrorCodes.includes(er.code),
                )

                if (acceptedErrors && acceptedErrors?.length > 0) {
                    const firstError = acceptedErrors[0]

                    const message = isEmpty(firstError.message)
                        ? t('errors.genericContactAdmin')
                        : firstError.message

                    toast.error(message)
                }
            }

            if (onError) onError(error as HttpClientApiFieldError[])
        } else if (response.ok) {
            dispatch({ type: 'fetched', payload: data as T })

            if (successToast) {
                toast.success(successToast)
            }

            if (onSuccess) onSuccess(data as T)

            return data as T
        }
    }

    return { ...state, execute }
}

export default useHttpClient
