import React, { PropsWithChildren } from 'react'

import s from './styles.module.css'

export default function Error(props: PropsWithChildren) {
  const { children } = props

  return (
    <div className={s.error}>
      {children}
    </div>
  )
}
