import React, { forwardRef } from 'react'
import { motion } from 'framer-motion'
import cn from 'classnames'

import { SlideProps } from './types'
import s from './styles.module.css'

type Ref = HTMLDivElement

const Slide = forwardRef<Ref, SlideProps>((props, ref) => {
  const { children, active } = props

  return (
    <motion.div
      ref={ref}
      className={cn(s.slide)}
      animate={{
        filter: active ? 'brightness(1) blur(0px)' : 'brightness(0.2) blur(5px)',
      }}
      transition={{
        duration: 0.5,
        ease: [0.56, 0.03, 0.12, 1.00],
      }}>
      {children}
    </motion.div>
  )
})

export default Slide
