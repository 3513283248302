
import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const CopiIcon = (props: SVGIconProps) => {
  const { width = 66, height = 62, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} aria-label="Close" {...svgProps}>
      <mask id="mask0_8004_3186" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="6" y="2" width="55" height="56">
        <path d="M33.5165 57.4491C48.5646 57.4491 60.7635 45.2502 60.7635 30.2021C60.7635 15.154 48.5646 2.95508 33.5165 2.95508C18.4684 2.95508 6.26953 15.154 6.26953 30.2021C6.26953 45.2502 18.4684 57.4491 33.5165 57.4491Z" fill="white" />
      </mask>
      <g mask="url(#mask0_8004_3186)">
        <path d="M33.8235 92.9591C69.2216 92.9591 97.9175 64.2632 97.9175 28.8651C97.9175 -6.53308 69.2216 -35.2289 33.8235 -35.2289C-1.57465 -35.2289 -30.2705 -6.53308 -30.2705 28.8651C-30.2705 64.2632 -1.57465 92.9591 33.8235 92.9591Z" fill="url(#paint0_linear_8004_3186)" />
      </g>
      <g filter="url(#filter0_d_8004_3186)">
        <path d="M61.7204 30.202C61.7204 45.778 49.0934 58.404 33.5184 58.404C17.9434 58.404 5.31641 45.777 5.31641 30.202C5.31641 14.627 17.9434 2 33.5184 2C49.0934 2 61.7204 14.627 61.7204 30.202ZM33.5184 6.386C20.3654 6.386 9.70241 17.049 9.70241 30.202C9.70241 43.355 20.3654 54.018 33.5184 54.018C46.6714 54.018 57.3344 43.355 57.3344 30.202C57.3344 17.049 46.6714 6.386 33.5184 6.386Z" fill="black" />
      </g>
      <mask id="mask1_8004_3186" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="5" y="2" width="57" height="57">
        <path d="M61.7204 30.202C61.7204 45.778 49.0934 58.404 33.5184 58.404C17.9434 58.404 5.31641 45.777 5.31641 30.202C5.31641 14.627 17.9434 2 33.5184 2C49.0934 2 61.7204 14.627 61.7204 30.202ZM33.5184 6.386C20.3654 6.386 9.70241 17.049 9.70241 30.202C9.70241 43.355 20.3654 54.018 33.5184 54.018C46.6714 54.018 57.3344 43.355 57.3344 30.202C57.3344 17.049 46.6714 6.386 33.5184 6.386Z" fill="white" />
      </mask>
      <g mask="url(#mask1_8004_3186)">
        <path d="M-27.1987 45.0937L18.2598 90.5522L89.4258 19.3861L43.9673 -26.0724L-27.1987 45.0937Z" fill="url(#paint1_linear_8004_3186)" />
      </g>
      <g filter="url(#filter1_d_8004_3186)">
        <path d="M42.2952 38.271C41.6462 38.574 41.1652 39.167 41.4822 40.026C41.4922 40.052 41.5002 40.078 41.5092 40.104C41.5712 40.319 41.6552 40.942 40.8062 41.483C40.7742 41.5 40.7422 41.518 40.7102 41.534C40.6852 41.553 40.6582 41.57 40.6312 41.587C40.5712 41.62 40.5062 41.652 40.4392 41.683C40.0392 41.868 39.6272 42.025 39.2202 42.166C38.9682 42.253 38.8072 42.302 38.8072 42.302C38.1402 42.504 37.4902 42.639 36.8082 42.711C29.2472 43.51 25.2742 37.493 25.2892 30.515C25.3022 24.198 29.1742 17.284 35.9472 16.627C37.6912 16.458 40.0542 16.89 40.9442 18.651C41.4962 17.248 42.5212 15.766 44.1102 15.481C43.5722 13.809 41.3462 14.887 40.5022 15.623C40.5002 15.625 40.4972 15.627 40.4942 15.629C38.9822 15.181 37.6102 15.114 36.7732 15.146C36.5092 15.156 36.2152 15.179 35.8902 15.214C31.9282 15.637 28.2962 17.717 25.6742 20.679C23.3702 23.281 21.7972 26.592 21.5102 30.078C21.2242 29.997 20.8732 29.964 20.4412 30.03C18.8382 30.273 18.1172 31.51 18.1172 31.51C20.2722 31.607 21.5372 33.944 22.1392 35.745C22.8542 37.904 24.1552 39.98 25.9452 41.392C28.7012 43.567 32.3372 44.465 35.8282 44.222C35.8282 44.224 35.8282 44.225 35.8282 44.226C35.9202 44.219 36.0092 44.21 36.0992 44.202C36.1082 44.202 36.1172 44.2 36.1272 44.2C36.2182 44.192 36.3112 44.184 36.4012 44.174C36.4292 44.171 36.4572 44.169 36.4852 44.166C36.6192 44.152 36.7502 44.135 36.8812 44.118V44.114C39.0662 43.663 40.9872 43.021 42.7312 41.566C43.5992 40.896 44.3122 39.949 43.9112 38.86C43.5942 37.998 42.7702 38.049 42.2952 38.271Z" fill="black" />
      </g>
      <mask id="mask2_8004_3186" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="18" y="14" width="27" height="31">
        <path d="M42.2952 38.271C41.6462 38.574 41.1652 39.167 41.4822 40.026C41.4922 40.052 41.5002 40.078 41.5092 40.104C41.5712 40.319 41.6552 40.942 40.8062 41.483C40.7742 41.5 40.7422 41.518 40.7102 41.534C40.6852 41.553 40.6582 41.57 40.6312 41.587C40.5712 41.62 40.5062 41.652 40.4392 41.683C40.0392 41.868 39.6272 42.025 39.2202 42.166C38.9682 42.253 38.8072 42.302 38.8072 42.302C38.1402 42.504 37.4902 42.639 36.8082 42.711C29.2472 43.51 25.2742 37.493 25.2892 30.515C25.3022 24.198 29.1742 17.284 35.9472 16.627C37.6912 16.458 40.0542 16.89 40.9442 18.651C41.4962 17.248 42.5212 15.766 44.1102 15.481C43.5722 13.809 41.3462 14.887 40.5022 15.623C40.5002 15.625 40.4972 15.627 40.4942 15.629C38.9822 15.181 37.6102 15.114 36.7732 15.146C36.5092 15.156 36.2152 15.179 35.8902 15.214C31.9282 15.637 28.2962 17.717 25.6742 20.679C23.3702 23.281 21.7972 26.592 21.5102 30.078C21.2242 29.997 20.8732 29.964 20.4412 30.03C18.8382 30.273 18.1172 31.51 18.1172 31.51C20.2722 31.607 21.5372 33.944 22.1392 35.745C22.8542 37.904 24.1552 39.98 25.9452 41.392C28.7012 43.567 32.3372 44.465 35.8282 44.222C35.8282 44.224 35.8282 44.225 35.8282 44.226C35.9202 44.219 36.0092 44.21 36.0992 44.202C36.1082 44.202 36.1172 44.2 36.1272 44.2C36.2182 44.192 36.3112 44.184 36.4012 44.174C36.4292 44.171 36.4572 44.169 36.4852 44.166C36.6192 44.152 36.7502 44.135 36.8812 44.118V44.114C39.0662 43.663 40.9872 43.021 42.7312 41.566C43.5992 40.896 44.3122 39.949 43.9112 38.86C43.5942 37.998 42.7702 38.049 42.2952 38.271Z" fill="white" />
      </mask>
      <g mask="url(#mask2_8004_3186)">
        <path d="M83.1827 48.0912L33.9766 -1.11496L5.04105 27.8206L54.2472 77.0267L83.1827 48.0912Z" fill="url(#paint2_linear_8004_3186)" />
      </g>
      <path opacity="0.5" d="M27.7942 29.7131C33.5755 29.7131 38.2622 25.0264 38.2622 19.2451C38.2622 13.4638 33.5755 8.7771 27.7942 8.7771C22.0129 8.7771 17.3262 13.4638 17.3262 19.2451C17.3262 25.0264 22.0129 29.7131 27.7942 29.7131Z" fill="url(#paint3_radial_8004_3186)" />
      <path d="M27.7946 23.4831C30.1352 23.4831 32.0326 21.5857 32.0326 19.2451C32.0326 16.9045 30.1352 15.0071 27.7946 15.0071C25.4541 15.0071 23.5566 16.9045 23.5566 19.2451C23.5566 21.5857 25.4541 23.4831 27.7946 23.4831Z" fill="url(#paint4_radial_8004_3186)" />
      <path opacity="0.5" d="M10.468 35.5329C16.2493 35.5329 20.936 30.8462 20.936 25.0649C20.936 19.2836 16.2493 14.5969 10.468 14.5969C4.68668 14.5969 0 19.2836 0 25.0649C0 30.8462 4.68668 35.5329 10.468 35.5329Z" fill="url(#paint5_radial_8004_3186)" />
      <path d="M10.4685 29.3029C12.8091 29.3029 14.7065 27.4055 14.7065 25.0649C14.7065 22.7244 12.8091 20.8269 10.4685 20.8269C8.12788 20.8269 6.23047 22.7244 6.23047 25.0649C6.23047 27.4055 8.12788 29.3029 10.4685 29.3029Z" fill="url(#paint6_radial_8004_3186)" />
      <path opacity="0.5" d="M55.4836 33.9509C61.2649 33.9509 65.9516 29.2642 65.9516 23.4829C65.9516 17.7016 61.2649 13.0149 55.4836 13.0149C49.7023 13.0149 45.0156 17.7016 45.0156 23.4829C45.0156 29.2642 49.7023 33.9509 55.4836 33.9509Z" fill="url(#paint7_radial_8004_3186)" />
      <path d="M55.4841 27.7209C57.8247 27.7209 59.7221 25.8235 59.7221 23.4829C59.7221 21.1423 57.8247 19.2449 55.4841 19.2449C53.1435 19.2449 51.2461 21.1423 51.2461 23.4829C51.2461 25.8235 53.1435 27.7209 55.4841 27.7209Z" fill="url(#paint8_radial_8004_3186)" />
      <path opacity="0.5" d="M23.05 61.9521C28.8313 61.9521 33.518 57.2654 33.518 51.4841C33.518 45.7028 28.8313 41.0161 23.05 41.0161C17.2687 41.0161 12.582 45.7028 12.582 51.4841C12.582 57.2654 17.2687 61.9521 23.05 61.9521Z" fill="url(#paint9_radial_8004_3186)" />
      <path d="M23.0505 55.7221C25.3911 55.7221 27.2885 53.8247 27.2885 51.4841C27.2885 49.1435 25.3911 47.2461 23.0505 47.2461C20.7099 47.2461 18.8125 49.1435 18.8125 51.4841C18.8125 53.8247 20.7099 55.7221 23.0505 55.7221Z" fill="url(#paint10_radial_8004_3186)" />
      <defs>
        <filter id="filter0_d_8004_3186" x="3.68941" y="0.373" width="60.7443" height="60.744" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="0.543" dy="0.543" />
          <feGaussianBlur stdDeviation="1.085" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.137255 0 0 0 0 0.121569 0 0 0 0 0.12549 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8004_3186" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8004_3186" result="shape" />
        </filter>
        <filter id="filter1_d_8004_3186" x="16.4902" y="12.9692" width="30.3332" height="34.0045" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="0.543" dy="0.543" />
          <feGaussianBlur stdDeviation="1.085" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.137255 0 0 0 0 0.121569 0 0 0 0 0.12549 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8004_3186" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8004_3186" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_8004_3186" x1="79.1448" y1="74.1856" x2="-11.4971" y2="-16.4563" gradientUnits="userSpaceOnUse">
          <stop offset="0.089" stopColor="#693B10" />
          <stop offset="0.235" stopColor="#D6B25F" />
          <stop offset="0.279" stopColor="#E7D18E" />
          <stop offset="0.326" stopColor="#F8EFBA" />
          <stop offset="0.35" stopColor="#FFFBCC" />
          <stop offset="0.529" stopColor="#C3922E" />
          <stop offset="0.754" stopColor="#693B10" />
          <stop offset="0.825" stopColor="#EEDD9E" />
          <stop offset="0.868" stopColor="#DBC27F" />
          <stop offset="1" stopColor="#A47424" />
        </linearGradient>
        <linearGradient id="paint1_linear_8004_3186" x1="53.9008" y1="54.9366" x2="8.40065" y2="9.46878" gradientUnits="userSpaceOnUse">
          <stop offset="0.089" stopColor="#693B10" />
          <stop offset="0.235" stopColor="#D6B25F" />
          <stop offset="0.279" stopColor="#E7D18E" />
          <stop offset="0.326" stopColor="#F8EFBA" />
          <stop offset="0.35" stopColor="#FFFBCC" />
          <stop offset="0.529" stopColor="#C3922E" />
          <stop offset="0.754" stopColor="#693B10" />
          <stop offset="0.825" stopColor="#EEDD9E" />
          <stop offset="0.868" stopColor="#DBC27F" />
          <stop offset="1" stopColor="#A47424" />
        </linearGradient>
        <linearGradient id="paint2_linear_8004_3186" x1="19.5092" y1="13.3524" x2="68.7146" y2="62.5579" gradientUnits="userSpaceOnUse">
          <stop stopColor="#AF8E4E" />
          <stop offset="0.19" stopColor="#F4EBC0" />
          <stop offset="0.783" stopColor="#A37E3B" />
          <stop offset="0.86" stopColor="#F0E1BA" />
        </linearGradient>
        <radialGradient id="paint3_radial_8004_3186" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(27.7942 19.2451) scale(10.468 10.468)">
          <stop stopColor="white" />
          <stop offset="0.711" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="paint4_radial_8004_3186" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(27.7946 19.2451) scale(4.238 4.238)">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="paint5_radial_8004_3186" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(10.468 25.0649) scale(10.468)">
          <stop stopColor="white" />
          <stop offset="0.711" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="paint6_radial_8004_3186" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(10.4685 25.0649) scale(0.5)">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="paint7_radial_8004_3186" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(55.4836 23.4829) scale(10.468 10.468)">
          <stop stopColor="white" />
          <stop offset="0.711" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="paint8_radial_8004_3186" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(55.4841 23.4829) scale(4.238 4.238)">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="paint9_radial_8004_3186" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(23.05 51.4841) scale(10.468)">
          <stop stopColor="white" />
          <stop offset="0.711" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="paint10_radial_8004_3186" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(23.0505 51.4841) scale(4.238)">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
      </defs>
    </SVGIcon>
  )
}

export default CopiIcon


