/**
 * Reduce string down to specific number of words
 * @param str string
 * @param count number
 * @param ellipsis boolean
 * @returns string
 */
const getWordsFromString = (str: string, count: number, ellipsis = false): string => {
    const strArray = str.split(' ')

    if (strArray.length <= count) return str

    return strArray.slice(0, count).join(' ') + (ellipsis ? '...' : '')
}

export default getWordsFromString
