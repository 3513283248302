/* eslint-disable react/no-danger */
import React from 'react'
import Typography from '../../Typography'

import s from './styles.module.css'

const PostTitle = (props: { children: string }) => {
  const { children } = props

  return (
    <Typography
      as="h1"
      className={s.post_title}
      dangerouslySetInnerHTML={{ __html: children }}
    />
  )
}

export default PostTitle