import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const DoubleArrow = (props: SVGIconProps) => {
  const { width = 15, height = 18, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} {...svgProps}>
      <path fillRule="evenodd" clipRule="evenodd" d="M13.5758 10.0567C13.7323 9.90312 13.9445 9.81682 14.1657 9.81682C14.387 9.81682 14.5992 9.90312 14.7556 10.0567C14.9121 10.2103 15 10.4187 15 10.6359C15 10.8532 14.9121 11.0615 14.7556 11.2151L8.08992 17.7597C8.01252 17.8358 7.92057 17.8963 7.81935 17.9375C7.71812 17.9788 7.6096 18 7.5 18C7.3904 18 7.28188 17.9788 7.18066 17.9375C7.07943 17.8963 6.98748 17.8358 6.91008 17.7597L0.244352 11.2151C0.166882 11.1391 0.105431 11.0488 0.063505 10.9494C0.0215791 10.85 1.63254e-09 10.7435 0 10.6359C-1.63254e-09 10.5284 0.0215791 10.4218 0.063505 10.3225C0.105431 10.2231 0.166882 10.1328 0.244352 10.0567C0.321821 9.98067 0.41379 9.92033 0.515008 9.87917C0.616226 9.83801 0.724711 9.81682 0.834269 9.81682C0.943827 9.81682 1.05231 9.83801 1.15353 9.87917C1.25475 9.92033 1.34672 9.98067 1.42419 10.0567L7.5 16.0237L13.5758 10.0567ZM13.5758 0.239908C13.6533 0.163847 13.7453 0.103512 13.8465 0.0623493C13.9477 0.0211849 14.0562 0 14.1657 0C14.2753 0 14.3838 0.0211849 14.485 0.0623493C14.5862 0.103512 14.6782 0.163847 14.7556 0.239908C14.8331 0.315968 14.8946 0.406265 14.9365 0.505642C14.9784 0.605021 15 0.711535 15 0.819099C15 0.926666 14.9784 1.03318 14.9365 1.13256C14.8946 1.23194 14.8331 1.32223 14.7556 1.39829L8.08992 7.94284C8.01252 8.01902 7.92057 8.07947 7.81935 8.12071C7.71812 8.16195 7.6096 8.18318 7.5 8.18318C7.3904 8.18318 7.28188 8.16195 7.18066 8.12071C7.07943 8.07947 6.98748 8.01902 6.91008 7.94284L0.244352 1.39829C0.166882 1.32223 0.105431 1.23194 0.063505 1.13256C0.0215791 1.03318 0 0.926666 0 0.819099C0 0.711535 0.0215791 0.605021 0.063505 0.505642C0.105431 0.406265 0.166882 0.315968 0.244352 0.239908C0.400807 0.0862961 0.613007 0 0.834269 0C0.943827 0 1.05231 0.0211849 1.15353 0.0623493C1.25475 0.103512 1.34672 0.163847 1.42419 0.239908L7.5 6.2069L13.5758 0.239908Z" />
    </SVGIcon>
  )
}

export default DoubleArrow
