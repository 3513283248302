import React from 'react'
import cn from 'classnames'

import { BLOCKCHAIN_TYPE_BASE, BLOCKCHAIN_TYPE_BNB, BLOCKCHAIN_TYPE_CARDANO, BLOCKCHAIN_TYPE_ETHEREUM } from 'consts/blockchain'

import { Base, Bnb, Cardano, Ethereum } from '../Icon'
import { BlockchainIconProps } from './types'

import s from './styles.module.css'

const BlockchainIcon = (props: BlockchainIconProps) => {
  const { blockchain, includeSvgName = false, includeTextName = false, className, ...iconProps } = props
  let icon

  switch (blockchain.toLowerCase()) {
    case BLOCKCHAIN_TYPE_CARDANO:
      icon = <Cardano showTitle={includeSvgName} {...iconProps} />
      break
    case BLOCKCHAIN_TYPE_BASE:
      icon = <Base showTitle={includeSvgName} {...iconProps} />
      break
    case BLOCKCHAIN_TYPE_BNB:
      icon = <Bnb showTitle={includeSvgName} {...iconProps} />
      break
    case BLOCKCHAIN_TYPE_ETHEREUM:
      icon = <Ethereum showTitle={includeSvgName} {...iconProps} />
      break
  }

  return (
    <span className={cn(s.blockchain_icon, className)}>
      {icon}
      {includeTextName && blockchain}
    </span>
  )
}

export default BlockchainIcon