import React from 'react'

import s from './styles.module.css'
import { SvgMaskProps } from './types'

/**
 * The component is used to set up specific svg shape as a background
 * @param props 
 * @returns 
 */
const SvgMask = (props: SvgMaskProps) => {
  const { children, className, mask, id } = props

  // Allows anything to be pass in and keeps it safe
  const svgId = btoa(id)

  return (
    <>
      <div
        className={className}
        style={{
          'WebkitClipPath': `url(#${svgId})`,
          'clipPath': `url(#${svgId})`
        }}
      >
        {children}
      </div>
      <svg className={s.svg}>
        <clipPath
          id={svgId}
          clipPathUnits="objectBoundingBox"
        >
          <path d={mask} />
        </clipPath>
      </svg>
    </>
  )
}

export default SvgMask
