import React from 'react'
import { motion } from 'framer-motion';

import Image from '../../Image';
import Link from '../../Link';
import Typography from '../../Typography';

import s from './styles.module.css'
import { GalleryPreviewProps } from './types';

const MotionLink = motion(Link)

const imageVariants = {
  onScreen: {
    opacity: 1
  },
  initial: {
    opacity: 0
  },
};

const captionVariants = {
  onHover: {
    opacity: 1,
    x: 0,
    transition: {
      type: "tween",
      duration: 0.2
    }
  },
  initial: {
    opacity: 0,
    x: "-100%"
  },
};

const GalleryPreview = (props: GalleryPreviewProps) => {
  const { caption, href, onClick, onKeyDown, ...imageProps } = props

  return (
    <div
      className={s.image_wrapper}>
      <MotionLink
        viewport={{ once: true }}
        initial="initial"
        whileInView="onScreen"
        whileHover="onHover"
        whileFocus="onHover"
        variants={imageVariants}
        onClick={onClick}
        onKeyDown={onKeyDown}
        hover={false}
        className={s.link}
        scroll={false}
        href={href ?? "#"}>
        <Image
          rounded
          width={700}
          height={400}
          className={s.image}
          {...imageProps} />
        <Typography
          variants={captionVariants}
          className={s.figure}
          as={motion.figure}>
          {caption}
        </Typography>
      </MotionLink>
    </div>
  )
}

export default GalleryPreview
