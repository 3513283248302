/* eslint-disable no-console */
import React, { useEffect, useState, useRef, useCallback } from "react";
import cx from 'classnames';

import { useSticky } from "./StickyContainer";

import { StickyComponentType, StickyOffsetListType, StickyProps } from "./types";

/**
 * Content to become sticky
 * Note:: If inner content requires margin to position add it to Sticky
 * @param props 
 * @returns 
 */
const Sticky = (props: StickyProps) => {
  const {
    debug = false,
    minWidth = false,
    contain = true,
    children,
    containerId,
    fixed = true,
    className
  } = props;
  const { subscribe, unsubscribe } = useSticky();
  const placeholder = useRef<HTMLDivElement>(null);
  const content = useRef<HTMLDivElement>(null);
  const [style, setStyle] = useState<Record<string, string | number>>({});
  const [calculatedHeight, setCalculatedHeight] = useState<number>(0);

  const [initialOffset, setInitialOffsetState] = useState<number>(0);
  const initialStateRef = useRef<number>(initialOffset);

  const stickyContainerClasses = cx("sticky-container", className)

  // Let's get hacky - Why this?
  // Because then handler function is an event the function will read from the past
  // See this for more info or other solutions - https://stackoverflow.com/questions/55265255/react-usestate-hook-event-handler-using-initial-state
  const setInitialOffset = (value: number) => {
    initialStateRef.current = value;
    setInitialOffsetState(value);
  };

  const [isSticky, setIsStickyState] = useState<boolean>(false);
  const isStickyRef = useRef<boolean>(isSticky);

  const setIsSticky = (value: boolean) => {
    isStickyRef.current = value;
    setIsStickyState(value);
  };

  const getInitialOffset = () => initialStateRef.current;

  if (!subscribe)
    throw new TypeError("Expected Sticky to be mounted within StickyContainer");

  const getHeight = (): number => {
    const contentContainer = content.current

    if (!contentContainer) return 0

    const contentClientRect = contentContainer.getBoundingClientRect();

    const styles = window.getComputedStyle(contentContainer);
    const margin =
      parseFloat(styles.marginTop) + parseFloat(styles.marginBottom);

    return contentClientRect.height + margin;
  };

  const handler = useCallback(
    (type: string, distanceFromTop: number, distanceFromBottom: number, offset: StickyOffsetListType, scrollOffset: number) => {
      const contentContainer = content.current
      const placeholdContainer = placeholder.current

      if (!contentContainer || !placeholdContainer) return

      const contentClientRect = contentContainer.getBoundingClientRect();
      const { top } = contentClientRect;
      const height = getHeight();

      const bottomDifference = distanceFromBottom - height;
      let stickyInitialOffset = getInitialOffset();

      if (type === "resize") {
        // Get current position of the placeholder
        const placeholderClientRect = placeholdContainer.getBoundingClientRect();

        if (placeholderClientRect.top !== stickyInitialOffset) {
          stickyInitialOffset = placeholdContainer.offsetTop;
          setInitialOffset(stickyInitialOffset);
        }
      }

      // Get current offset. If a container has been specified get the offset from the stacked nodes otherwise get all
      const actualOffset =
        fixed || !containerId || !offset[containerId] ? offset.all : offset[containerId];

      // TODO:: Can we make contain nicer? Might need to switch from fixed to absolute to do this..
      let shouldStick =
        scrollOffset >= stickyInitialOffset - offset.all &&
        contain &&
        distanceFromBottom > 0;

      if (minWidth !== false && (typeof minWidth !== 'boolean' && window.innerWidth < minWidth)) {
        shouldStick = false;
      }

      let styleTop = scrollOffset + (containerId ? (offset[containerId] ?? 0) : 0);

      if (fixed)
        styleTop =
          bottomDifference > 0
            ? 0 + actualOffset
            : bottomDifference + actualOffset;

      if (shouldStick) {
        setStyle({
          position: fixed ? "fixed" : "absolute",
          top: styleTop,
          left: contentClientRect.left,
          width: "100%",
          zIndex: 450
        });
      }

      setIsSticky(shouldStick);
      setCalculatedHeight(height);

      if (debug)
        console.log(
          `Sticky DEBUG: ${debug}`,
          "\n",
          `should stick?: ${shouldStick}`,
          "\n",
          `distance from bottom: ${distanceFromBottom}`,
          "\n",
          `distance from top: ${distanceFromTop}`,
          "\n",
          `scroll offset: ${scrollOffset}`,
          "\n",
          `current offset: ${top}`,
          "\n",
          `initial offset: ${stickyInitialOffset}`,
          "\n",
          `stack offset: ${actualOffset}`,
          "\n",
          `calculatedHeight: ${height}`,
          "\n",
          `is sticky: ${scrollOffset} >= ${stickyInitialOffset} - ${actualOffset} = ${scrollOffset >= stickyInitialOffset - actualOffset ? "YES" : "NO"
          }`,
          "\n",
          `New style top: ${scrollOffset} - ${stickyInitialOffset} + ${actualOffset} =  ${scrollOffset - stickyInitialOffset + actualOffset
          }px`,
          "\n",
          `Offset all: ${offset.all}px`
        );
    },
    [contain, debug, minWidth, containerId, fixed]
  );

  useEffect(() => {
    const contentContainer = content.current

    // Create object to subscribe requires, handler and getHeight
    const component = {
      containerId,
      handler,
      getHeight,
      node: contentContainer
    } as StickyComponentType;

    subscribe(component);

    if (initialOffset === null && contentContainer) {
      const contentClientRect = contentContainer.getBoundingClientRect();
      const { top } = contentClientRect;
      setInitialOffset(top);
    }

    return () => {
      unsubscribe(component);
    };
  }, [containerId, handler, initialOffset, subscribe, unsubscribe]);

  useEffect(() => {
    const placeholderElement = placeholder.current;

    // Add styles to placeholder to ensure layout does not shift
    if (placeholderElement)
      placeholderElement.style.paddingBottom = `${isSticky ? calculatedHeight : 0
        }px`;
  }, [isSticky, calculatedHeight]);

  return (
    <div className={isSticky ? "is-sticky" : ""}>
      <div className="sticky-placeholder" ref={placeholder} />
      <div className={stickyContainerClasses} ref={content} style={style}>
        {children}
      </div>
    </div>
  );
};

export default Sticky;
