
import React from 'react'

import Card, { CardBody } from '@/components/ui/Card'

import Image from '@/components/ui/Image'

import s from './styles.module.css'
import { GalleryCardProps } from './types'


/**
 * Gallery card component
 * @param props 
 * @returns 
 */
function GalleryCard(props: GalleryCardProps) {
  const { title, thumbnail, href, children } = props

  return (
    <Card href={href}>
      <Image
        rounded={false}
        draggable={false}
        alt={thumbnail?.alt}
        src={thumbnail?.src || '/_assets/placeholder.webp'}
        width={400}
        height={250}
        className={s.image}
      />
      <CardBody title={title}>
        {children}
      </CardBody>
    </Card>
  )
}

export default GalleryCard