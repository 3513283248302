import { GetServerSideProps, GetServerSidePropsContext, GetServerSidePropsResult } from 'next'
import { getServerSession } from 'next-auth'

import { options } from 'pages/api/auth/[...nextauth]'
import { ROUTE_LOGIN } from 'consts/routes'
import { GateServer } from '@/network/auth/gate'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const withGate =
    <P extends { [key: string]: any }>(requiredRoles: string[] = []) =>
    (getServerSidePropsFunc?: GetServerSideProps<P>) =>
    async (context: GetServerSidePropsContext): Promise<GetServerSidePropsResult<P>> => {
        const session = await getServerSession(context.req, context.res, options)

        if (!session) {
            return {
                redirect: {
                    permanent: false,
                    destination: ROUTE_LOGIN,
                },
            }
        }

        // If a roles are required do a role check
        if (requiredRoles.length > 0) {
            const hasAccess = await (
                await GateServer(session.accessToken, session.user.id)
            ).anyRoles(requiredRoles)

            if (!hasAccess) {
                return {
                    notFound: true,
                }
            }
        }

        if (getServerSidePropsFunc) return await getServerSidePropsFunc(context)

        return {
            props: {},
        } as GetServerSidePropsResult<P>
    }

export default withGate
