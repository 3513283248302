/* eslint-disable react/no-danger */
import React, { useMemo } from 'react'

import Date from '../../Date'
import CoverImage from '../CoverImage'
import Link from '../../Link'

import s from './styles.module.css'
import { PostFeaturedProps } from './types'
import { ROUTE_BLOG_BASE } from '../../../../consts'
import { buildPath, getWordsFromString, stripTags, t } from '../../../../helpers'
import Typography from '../../Typography'

/**
 * Featured post layout
 * @param props 
 * @returns 
 */
const PostFeatured = (props: PostFeaturedProps) => {
  const {
    title,
    coverImage,
    publishedDate,
    content,
    excerpt,
    slug,
  } = props

  const getExcerpt = useMemo(() => {
    if (excerpt) return excerpt

    return getWordsFromString(stripTags(content), 50, true)
  }, [excerpt, content])

  return (
    <article key={slug} className={s.article}>
      {
        coverImage &&
        <CoverImage
          slug={slug}
          title={title}
          width={550}
          height={310}
          coverImage={coverImage}
          imageClassName={s.image}
          className={s.image_wrapper} />
      }
      <div className={s.content}>
        <Typography as="h3" variant="primary" className={s.title}>{t("pages.blog.featureTitle")}</Typography>
        <Typography as="h3" className={s.title}>{title}</Typography>
        <header className={s.header}>
          <Typography className={s.date} uppercase variant="secondary" as="span">
            <Date dateString={publishedDate} />
          </Typography>
        </header>
        <Typography as="p" className={s.excerpt}>{getExcerpt}</Typography>
        <Link
          variant="primary"
          href={buildPath(ROUTE_BLOG_BASE, slug)}
          className={s.link}>
          {t("pages.blog.viewMoreLink")}
        </Link>
      </div>
      {/* <div className={s.tags}>
        <Tags tags={tags} />
      </div> */}
    </article>
  )
}

export default PostFeatured