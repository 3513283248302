import React from 'react'
import { signOut } from 'next-auth/react'
import cn from 'classnames'

import { Typography, Divider, Button, Link, Dropdown, Avatar } from '@/components/ui'
import { useAuth } from '@/providers/AuthenticationProvider'
import NavLink from '@/components/ui/Navigation/NavLink'
import MenuItem from '@/components/ui/Dropdown/MenuItem'
import { Arrow, Exit } from '@/components/ui/Icon'

import { ROUTE_LOGIN } from 'consts/routes'
import { PLAYER_ACCOUNT_MENU_OPTIONS } from 'consts/navigation'
import { Gate } from '@/network/auth/gate'
import { t } from 'helpers/translation'

import { AccountMenuProps } from './types'
import s from './styles.module.css'

const AccountMenu = (props: AccountMenuProps) => {
  const { menuItemsClassName } = props
  const { isLoggedIn, user } = useAuth()

  const menuItemClasses = cn(s.menu_items, menuItemsClassName)

  if (isLoggedIn && user) {
    const { username, displayName } = user

    return (
      <>
        <Dropdown
          menuClassName={s.menu}
          buttonClassName={s.menu_button}
          menuItemsClassName={menuItemClasses}
          label={(
            <>
              <Typography>{displayName ?? username}</Typography>
              <Avatar
                avatar={user.avatar}
                size="sm" />
              <Arrow rotation="-90" />
            </>
          )}>

          <Divider className={s.divider} />

          {
            PLAYER_ACCOUNT_MENU_OPTIONS.map(({ label, href, permissions, showInDropdown }, index) => {

              // TODO:: Allow sub menus
              if (!showInDropdown || !href) return false

              const hasPermission = permissions ? Gate(user).anyRoles(permissions) : true

              if (!hasPermission) return false

              return (
                // eslint-disable-next-line react/no-array-index-key
                <MenuItem className={s.menu_item} key={index}>
                  <Link href={href}>{label}</Link>
                </MenuItem>
              )
            })
          }

          <MenuItem className={s.menu_item}>
            <Button
              className={s.logout}
              onClick={() => signOut({ callbackUrl: '/' })}>
              <span>
                {t("navigation.menus.logout")}
              </span>
              <Exit />
            </Button>
          </MenuItem>
        </Dropdown>
      </>
    )
  }

  return (
    <NavLink href={ROUTE_LOGIN}>
      {t("navigation.menus.account")}
    </NavLink>
  )
}

export default AccountMenu