import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const FilterRemove = (props: SVGIconProps) => {
  const { width = 15, height = 15, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} aria-label="Remove" {...svgProps}>
      <path d="M14.9995 7.5C14.9995 11.642 11.6415 15 7.49951 15C3.35751 15 -0.000488281 11.642 -0.000488281 7.5C-0.000488281 3.357 3.35751 0 7.49951 0C11.6415 0 14.9995 3.357 14.9995 7.5ZM10.1475 3.54201L7.49951 6.283L4.85451 3.54201L3.67752 4.761L6.32352 7.5L3.67752 10.241L4.85451 11.457L7.49951 8.718L10.1475 11.457L11.3225 10.241L8.67651 7.5L11.3225 4.761L10.1475 3.54201Z" />
    </SVGIcon>
  )
}

export default FilterRemove
