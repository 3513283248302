import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const Facebook = (props: SVGIconProps) => {
  const { width = 12, height = 26, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} aria-label="Facebook" {...svgProps}>
      <path d="M2.93996 14H0.709961V9.70002H2.93996V6.16001C2.93996 5.29001 3.22995 4.41001 3.22995 4.41001C3.66995 3.03001 4.63996 1.63001 6.51996 1.08001C8.39996 0.520014 11.83 1.01001 11.83 1.01001V4.84001C11.83 4.84001 11.58 4.84001 9.43996 4.84001C7.29996 4.84001 7.40996 6.65001 7.40996 6.65001V9.71001H11.6799L11.1199 14.01H7.39995V25.09H2.93996V14Z" />
    </SVGIcon>
  )
}

export default Facebook