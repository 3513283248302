import { PageBackgroundProps } from '@/components/ui/PageBackground'
import React from 'react'
import { SidebarProps } from '../../Sidebar/types'

export type LayoutProps = {
    children: React.ReactNode
    preContainer?: React.ReactElement
    postContainer?: React.ReactElement
    sidebar?: React.ReactNode
    sidebarProps?: SidebarProps
    title?: string | React.ReactNode
    titleClassName?: string
    subtitle?: string
    separator?: string
    contain?: boolean
    background?: PageBackgroundProps
    hideAnnouncements?: boolean
    showBreadcrumbs?: boolean
    removeDefaultPageStyle?: boolean
}
