import { useEffect, RefObject } from 'react'

/**
 * Trigger callback when click outside of element
 * @param ref
 * @param handler
 */
function useOnClickOutside<T extends HTMLElement = HTMLElement>(
    ref: RefObject<T> | Array<RefObject<T>>,
    handler: (event: Event) => void,
) {
    useEffect(() => {
        const listener: typeof handler = (event) => {
            // If ref is an array check each element
            if (ref instanceof Array) {
                const outside = ref.some(
                    (item) => !item.current || item.current.contains(event.target as Node),
                )

                if (outside) return
            } else if (!ref.current || ref.current.contains(event.target as Node)) {
                return
            }
            handler(event)
        }

        document.addEventListener('mousedown', listener)
        document.addEventListener('touchstart', listener)

        return () => {
            document.removeEventListener('mousedown', listener)
            document.removeEventListener('touchstart', listener)
        }
    }, [ref, handler])
}

export default useOnClickOutside
