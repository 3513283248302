import React, { useMemo, useState } from 'react'
import cn from 'classnames'

import useLocalStorage from 'hooks/useLocalStorage'
import Button from '../Button'
import VisuallyHidden from '../VisuallyHidden'
import { Gridx2, Gridx3, List } from '../Icon'
import Box from '../Box'

import { GridListProps, GridListView } from './types'

import s from './styles.module.css'

export const GRID_SM_VIEW_TITLE = 'Change view to smaller columns'
export const GRID_LG_VIEW_TITLE = 'Change view to larger columns'
export const GRID_LIST_VIEW_TITLE = 'Change view to a list'

const GridList = (props: GridListProps) => {
  const {
    children,
    controls,
    showSmCols = true,
    showLgcols = true,
    showList = true,
    gridListId = 'grid_size',
    smColsClassName,
    lgColsClassName,
    listClassName,
    defaultView = 'lg',
    additionalViewControls,
    controlsClassName,
    precontent
  } = props

  const { value: gridView, setValue: setGridView } = useLocalStorage<GridListView>(gridListId, defaultView)

  const gridClasses = cn(
    s.list,
    gridView === "sm" && (smColsClassName ?? s.grid_cols_sm),
    gridView === "lg" && (lgColsClassName ?? s.grid_cols_lg),
    gridView === "list" && (listClassName ?? s.grid_list),
  )

  const onViewChange = (state: GridListView) => {
    setGridView(state)
  }

  const viewControls = useMemo(() => (
    <Box display="flex">
      {
        showLgcols &&
        <Button
          variant={gridView === "lg" ? "primary" : "default"}
          onClick={() => onViewChange("lg")}>
          <Gridx2 />
          <VisuallyHidden>{GRID_LG_VIEW_TITLE}</VisuallyHidden>
        </Button>
      }
      {
        showSmCols &&
        <Button
          variant={gridView === "sm" ? "primary" : "default"}
          onClick={() => onViewChange("sm")}>
          <Gridx3 />
          <VisuallyHidden>{GRID_SM_VIEW_TITLE}</VisuallyHidden>
        </Button>
      }
      {
        showList &&
        <Button
          variant={gridView === "list" ? "primary" : "default"}
          onClick={() => onViewChange("list")}>
          <List />
          <VisuallyHidden>{GRID_LIST_VIEW_TITLE}</VisuallyHidden>
        </Button>
      }
      {additionalViewControls}
    </Box>
  ), [gridView, showSmCols, showLgcols, showList])

  const showComponentControls = showList || showSmCols || showLgcols

  return (
    <>
      {
        (showComponentControls || controls) &&
        <div className={cn(s.controls, controlsClassName)}>
          {
            controls && typeof controls === "function" ?
              controls(viewControls)
              :
              <>
                {controls}
                {viewControls}
              </>
          }
        </div>
      }
      {precontent}
      <div className={gridClasses}>
        {
          children && typeof children === "function" ? children(gridView) : children
        }
      </div>
    </>
  )
}

export default GridList
