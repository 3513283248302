import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const Rocketship = (props: SVGIconProps) => {
  const { width = 36, height = 36, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} {...svgProps}>
      <path d="M0.500748 14.9215L8.14854 7.27317C8.57342 6.84827 9.07416 6.54476 9.65078 6.36266C10.2274 6.18055 10.8192 6.1502 11.4262 6.2716L13.7933 6.77239C12.1545 8.71483 10.8647 10.4752 9.92392 12.0534C8.98312 13.6316 8.07267 15.5437 7.19256 17.7896L0.500748 14.9215ZM9.83287 19.0644C10.5309 16.8791 11.4796 14.8153 12.6789 12.8729C13.8783 10.9304 15.3271 9.10938 17.0254 7.40975C19.6961 4.7389 22.7461 2.74365 26.1755 1.42401C29.6048 0.104366 32.8066 -0.298083 35.7807 0.216663C36.2967 3.19102 35.9021 6.39301 34.5971 9.82262C33.2922 13.2522 31.3043 16.3025 28.6337 18.9733C26.9645 20.6426 25.1436 22.0921 23.171 23.322C21.1983 24.5518 19.1195 25.5151 16.9344 26.2119L9.83287 19.0644ZM22.3971 13.6013C23.0951 14.2993 23.9528 14.6484 24.97 14.6484C25.9873 14.6484 26.8443 14.2993 27.5411 13.6013C28.2392 12.9032 28.5882 12.0461 28.5882 11.03C28.5882 10.0138 28.2392 9.15612 27.5411 8.45685C26.8431 7.75878 25.9861 7.40975 24.97 7.40975C23.954 7.40975 23.0963 7.75878 22.3971 8.45685C21.6991 9.15491 21.3501 10.0126 21.3501 11.03C21.3501 12.0473 21.6991 12.9044 22.3971 13.6013ZM21.1225 35.4992L18.209 28.8069C20.4548 27.9267 22.3746 27.0162 23.9685 26.0754C25.5624 25.1345 27.3299 23.8446 29.271 22.2057L29.7262 24.573C29.8476 25.18 29.8173 25.7797 29.6352 26.3722C29.4531 26.9646 29.1496 27.4727 28.7247 27.8964L21.1225 35.4992ZM3.41419 24.8917C4.47639 23.8294 5.76619 23.2904 7.28361 23.2746C8.80103 23.2588 10.0908 23.7827 11.153 24.8462C12.2152 25.9084 12.7463 27.1983 12.7463 28.7159C12.7463 30.2334 12.2152 31.5233 11.153 32.5856C10.3943 33.3443 9.12758 33.9969 7.3528 34.5432C5.57803 35.0895 3.1271 35.5751 0 36C0.424877 32.8739 0.910451 30.4307 1.45672 28.6703C2.00299 26.91 2.65548 25.6504 3.41419 24.8917Z" fill="currentcolor" />
    </SVGIcon>
  )
}

export default Rocketship
