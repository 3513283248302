import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const Tokensfarm = (props: SVGIconProps) => {
  const { width = 155, height = 41, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} aria-label="Tokensfarm" {...svgProps}>
      <path d="M36.7675 11.4844V14.1308H31.6548V26.9037H28.6178V14.1308H23.5051V11.4844H36.7675Z" fill="currentcolor" />
      <path d="M35.2851 21.3921C35.2851 20.2257 35.54 19.2123 36.0499 18.352C36.5742 17.4917 37.2952 16.8356 38.2129 16.3836C39.1306 15.9316 40.172 15.7056 41.3373 15.7056C42.488 15.7056 43.5149 15.9316 44.418 16.3836C45.3357 16.8356 46.0567 17.4917 46.5811 18.352C47.1054 19.2123 47.3676 20.2257 47.3676 21.3921C47.3676 22.5732 47.1054 23.6011 46.5811 24.476C46.0567 25.3363 45.3357 25.9924 44.418 26.4444C43.5149 26.8964 42.488 27.1224 41.3373 27.1224C40.172 27.1224 39.1306 26.8964 38.2129 26.4444C37.2952 25.9924 36.5742 25.3363 36.0499 24.476C35.54 23.6011 35.2851 22.5732 35.2851 21.3921ZM38.1692 21.3921C38.1692 22.3691 38.446 23.1491 38.9995 23.7324C39.5675 24.301 40.3468 24.5854 41.3373 24.5854C42.2987 24.5854 43.0561 24.301 43.6096 23.7324C44.1777 23.1491 44.4617 22.3691 44.4617 21.3921C44.4617 20.4298 44.1777 19.6643 43.6096 19.0956C43.0561 18.527 42.2987 18.2426 41.3373 18.2426C40.3468 18.2426 39.5675 18.527 38.9995 19.0956C38.446 19.6643 38.1692 20.4298 38.1692 21.3921Z" fill="currentcolor" />
      <path d="M49.4859 11.4844H52.37V20.0798H53.4625L57.0894 15.9243H60.6071L55.8003 21.2609L60.5852 26.9037H57.0675L53.3314 22.3763H52.37V26.9037H49.4859V11.4844Z" fill="currentcolor" />
      <path d="M60.8041 21.3265C60.8041 20.16 61.0371 19.154 61.5032 18.3083C61.9693 17.4626 62.6248 16.821 63.4696 16.3836C64.3145 15.9316 65.2831 15.7056 66.3755 15.7056C68.1526 15.7056 69.5436 16.1867 70.5487 17.1491C71.5537 18.1114 72.0563 19.4456 72.0563 21.1515V22.0045H63.6663C63.7391 22.9085 64.0158 23.6157 64.4965 24.1261C64.9772 24.6364 65.6472 24.8916 66.5066 24.8916C67.2349 24.8916 67.8321 24.7312 68.2982 24.4104C68.7644 24.075 69.0702 23.6157 69.2159 23.0325H72.0781C71.8888 24.2281 71.3207 25.2123 70.3739 25.9851C69.4271 26.7433 68.1453 27.1224 66.5285 27.1224C65.3341 27.1224 64.3072 26.8746 63.4478 26.3788C62.5884 25.8831 61.9329 25.1978 61.4814 24.3229C61.0298 23.448 60.8041 22.4492 60.8041 21.3265ZM69.2378 20.3423C69.1795 19.5549 68.8954 18.9498 68.3856 18.527C67.8904 18.0895 67.2422 17.8708 66.4411 17.8708C65.6545 17.8708 65.0136 18.0895 64.5184 18.527C64.0231 18.9644 63.7464 19.5695 63.6881 20.3423H69.2378Z" fill="currentcolor" />
      <path d="M74.1941 15.9243H77.0781V17.4553L76.5537 19.1612H77.2966C77.5297 18.126 77.9958 17.2949 78.6949 16.6679C79.3941 16.0263 80.3118 15.7056 81.4479 15.7056C82.8171 15.7056 83.8877 16.1357 84.6597 16.996C85.4463 17.8417 85.8396 19.0738 85.8396 20.6922V26.9037H82.9336V21.1297C82.9336 20.0798 82.7443 19.3362 82.3656 18.8988C81.9869 18.4614 81.3605 18.2426 80.4866 18.2426C79.4378 18.2426 78.6075 18.5999 77.9958 19.3143C77.384 20.0142 77.0781 21.0932 77.0781 22.5513V26.9037H74.1941V15.9243Z" fill="currentcolor" />
      <path d="M93.1721 27.1224C91.3805 27.1224 90.0113 26.7652 89.0645 26.0507C88.1323 25.3217 87.6662 24.2719 87.6662 22.9013H90.5502C90.5502 24.301 91.4388 25.0009 93.2158 25.0009C93.8567 25.0009 94.3301 24.9134 94.636 24.7385C94.9419 24.5489 95.0948 24.25 95.0948 23.8417C95.0948 23.5647 95.0001 23.3387 94.8108 23.1637C94.636 22.9887 94.3374 22.8429 93.915 22.7263L90.7032 21.8514C89.9603 21.6327 89.3267 21.2901 88.8023 20.8235C88.2925 20.3423 88.0376 19.6862 88.0376 18.855C88.0376 17.849 88.4454 17.0762 89.2611 16.5367C90.0768 15.9826 91.213 15.7056 92.6696 15.7056C94.2718 15.7056 95.5245 16.0409 96.4276 16.7116C97.3307 17.3678 97.7822 18.3228 97.7822 19.5768H94.8982C94.8982 18.3957 94.1626 17.8052 92.6914 17.8052C92.1234 17.8052 91.6864 17.8927 91.3805 18.0677C91.0746 18.2426 90.9217 18.5051 90.9217 18.855C90.9217 19.1175 91.0309 19.3435 91.2494 19.5331C91.4679 19.7226 91.7956 19.8757 92.2326 19.9924L94.7889 20.6485C95.7794 20.911 96.5587 21.2973 97.1268 21.8077C97.7094 22.3034 98.0007 22.996 98.0007 23.8855C98.0007 24.9353 97.5929 25.7372 96.7772 26.2913C95.9615 26.8454 94.7598 27.1224 93.1721 27.1224Z" fill="currentcolor" />
      <path d="M99.9071 11.4844H110.788V14.1089H102.966V18.6145H110.111V21.1734H102.966V26.9037H99.9071V11.4844Z" fill="currentcolor" />
      <path d="M111.993 23.8855C111.993 22.9231 112.306 22.1722 112.932 21.6327C113.573 21.0786 114.622 20.7287 116.079 20.5829L119.858 20.2111C119.844 19.4383 119.64 18.8696 119.247 18.5051C118.853 18.1406 118.263 17.9583 117.477 17.9583C116.734 17.9583 116.144 18.126 115.707 18.4614C115.285 18.7967 115.052 19.2998 115.008 19.9705H112.233C112.306 18.6436 112.808 17.6011 113.741 16.8429C114.673 16.0847 115.889 15.7056 117.389 15.7056C118.992 15.7056 120.288 16.1211 121.279 16.9522C122.269 17.7688 122.764 18.9134 122.764 20.386V24.3666H124.359V26.9037H122.087C121.606 26.9037 121.228 26.7725 120.951 26.51C120.689 26.2476 120.558 25.8758 120.558 25.3946V25.0884L121.016 23.8417H120.274C119.749 26.0289 118.278 27.1224 115.86 27.1224C114.389 27.1224 113.377 26.7871 112.823 26.1164C112.27 25.4456 111.993 24.702 111.993 23.8855ZM114.833 23.448C114.833 23.9001 114.986 24.2427 115.292 24.476C115.598 24.6947 116.006 24.8041 116.516 24.8041C117.491 24.8041 118.293 24.527 118.919 23.973C119.545 23.4189 119.858 22.7044 119.858 21.8296L116.603 22.2451C115.977 22.318 115.525 22.442 115.248 22.6169C114.972 22.7773 114.833 23.0544 114.833 23.448Z" fill="currentcolor" />
      <path d="M126.086 17.6302C126.086 16.4929 126.669 15.9243 127.834 15.9243H134.651V18.4614H128.992V26.9037H126.086V17.6302Z" fill="currentcolor" />
      <path d="M136.242 15.9243H139.126V17.2803L138.601 19.0081H139.344C139.577 17.9583 139.985 17.1491 140.568 16.5804C141.165 15.9972 141.995 15.7056 143.059 15.7056C144.908 15.7056 145.994 16.8064 146.314 19.0081H146.838C147.072 17.9583 147.479 17.1491 148.062 16.5804C148.645 15.9972 149.468 15.7056 150.531 15.7056C151.769 15.7056 152.665 16.1722 153.218 17.1053C153.786 18.0239 154.07 19.2341 154.07 20.736V26.9037H151.186V21.0641C151.186 20.335 151.128 19.7736 151.012 19.38C150.91 18.9717 150.728 18.6801 150.465 18.5051C150.203 18.3301 149.825 18.2426 149.329 18.2426C147.509 18.2426 146.598 19.7299 146.598 22.7044V26.9037H143.714V21.0641C143.714 20.335 143.656 19.7736 143.539 19.38C143.437 18.9717 143.255 18.6801 142.993 18.5051C142.731 18.3301 142.352 18.2426 141.857 18.2426C140.036 18.2426 139.126 19.7299 139.126 22.7044V26.9037H136.242V15.9243Z" fill="currentcolor" />
      <ellipse cx="12.8133" cy="5.97569" rx="5.79336" ry="5.79336" transform="rotate(-25.3654 12.8133 5.97569)" fill="currentcolor" />
      <ellipse cx="5.61174" cy="16.3604" rx="4.33853" ry="5.76711" transform="rotate(-85.3654 5.61174 16.3604)" fill="currentcolor" />
      <ellipse cx="9.07819" cy="26.9014" rx="2.75487" ry="5.21984" transform="rotate(-136.15 9.07819 26.9014)" fill="currentcolor" />
      <ellipse cx="17.2454" cy="31.6747" rx="1.34208" ry="4.09104" transform="rotate(-176.093 17.2454 31.6747)" fill="currentcolor" />
    </SVGIcon>
  )
};

export default Tokensfarm



