import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const List = (props: SVGIconProps) => {
  const { width = 28, height = 26, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} aria-label="List View" {...svgProps}>
      <rect width="2" height="2" fill="currentcolor" />
      <rect y="6" width="2" height="2" fill="currentcolor" />
      <rect y="12" width="2" height="2" fill="currentcolor" />
      <rect y="18" width="2" height="2" fill="currentcolor" />
      <rect y="24" width="2" height="2" fill="currentcolor" />
      <rect x="6" width="22" height="2" fill="currentcolor" />
      <rect x="6" y="6" width="22" height="2" fill="currentcolor" />
      <rect x="6" y="12" width="22" height="2" fill="currentcolor" />
      <rect x="6" y="18" width="22" height="2" fill="currentcolor" />
      <rect x="6" y="24" width="22" height="2" fill="currentcolor" />
    </SVGIcon>
  )
}

export default List

