import React from 'react'
import { motion } from 'framer-motion';

import { SlideTrackProps } from './types';
import s from './styles.module.css'

const SlideTrack = (props: SlideTrackProps) => {
  const { children, onDragEnd, onDragStart, onMouseDown, onMouseUp, onHoverStart, onHoverEnd, dragX, currentIndex } = props

  return (
    <motion.div
      initial={false}
      drag="x"
      dragConstraints={{
        left: 0,
        right: 0,
      }}
      style={{
        x: dragX,
      }}
      animate={{
        translateX: `calc(-${currentIndex * 100}% + ${10 + currentIndex * 20}vw)`,
      }}
      transition={{
        duration: 1.2,
        ease: [0.56, 0.03, 0.12, 1.00],
      }}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}
      onHoverStart={onHoverStart}
      onHoverEnd={onHoverEnd}
      className={s.slide_track}
    >
      {children}
    </motion.div>
  )
}

export default SlideTrack
