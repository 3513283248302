import React, { forwardRef } from 'react'
import cx from 'classnames'

import s from './styles.module.css'
import { FormGroupProps } from './types'

const FormGroup = forwardRef<HTMLDivElement, FormGroupProps>((props, ref) => {
  const {
    className,
    children,
    ...groupProps
  } = props

  const classes = cx(
    s.formGroup,
    className
  )

  return (
    <div
      ref={ref}
      className={classes}
      {...groupProps}>
      {children}
    </div>
  )
})

export default FormGroup