import { UserAvatarCropDataType } from 'types'
import removeTrailingSlash from './removeTrailingSlash'

const FILESTACK_BASE_URL = 'https://cdn.filestackcontent.com/'

/**
 * Is url from filestack
 * @param url
 * @returns
 */
const isFromFilestack = (url: string): boolean => url.includes(FILESTACK_BASE_URL)

/**
 * Return filestack handle
 * @param url
 * @returns
 */
const getFilestackHandle = (url: string): string => {
    const segments = url.split('/')
    return segments[segments.length - 1]
}

/**
 * Create a cropped url for filestack from a url and crop data
 * @param str
 * @returns
 */
const createCroppedImage = (url: string, cropData: UserAvatarCropDataType): string => {
    const handle = getFilestackHandle(url)
    const params = `crop=dim:[${cropData.x},${cropData.y},${cropData.width},${cropData.height}]`

    return `${FILESTACK_BASE_URL}${params}/${handle}`
}

export type FileStackImageProcessingProps = {
    resize?: {
        height?: number | `${number}`
        width?: number | `${number}`
    }
    output?: {
        format?: string
        quality?: number | `${number}`
    }
}

/**
 *
 * Format filestack image
 * @param url
 * @param params
 * @returns
 */
const formatFileStackImage = (url: string, params: FileStackImageProcessingProps = {}): string => {
    if (!isFromFilestack(url)) return url

    const handle = getFilestackHandle(url)
    const defaultParams: FileStackImageProcessingProps = { output: { format: 'webp' } }
    const mergedParams = { ...defaultParams, ...params }
    const { resize, output } = mergedParams

    // Collect transformation and output parameters
    const transformParams = []
    if (resize?.width) transformParams.push(`width:${resize.width}`)
    if (resize?.height) transformParams.push(`height:${resize.height}`)

    const outputParams = []
    if (output?.format) outputParams.push(`format:${output.format}`)
    if (output?.quality) outputParams.push(`quality:${output.quality}`)

    // Return the original URL if no parameters are defined
    if (transformParams.length === 0 && outputParams.length === 0) return url

    // Construct the transform and output strings if they exist
    const segments = [
        outputParams.length ? `output=${outputParams.join(',')}` : null,
        transformParams.length ? `resize=${transformParams.join(',')}` : null,
    ].filter(Boolean)

    const filestackBaseUrl = removeTrailingSlash(FILESTACK_BASE_URL)
    return `${filestackBaseUrl}/${segments.join('/')}/${handle}`
}

export { formatFileStackImage, createCroppedImage }
