import React, { useRef, useEffect, useState } from 'react'

import { TransakConfiguration, TransakPayEmbedProps } from './types'
import LoadingWrapper from '../../LoadingWrapper'

const buildTransakUrl = (config: TransakConfiguration): string => {
  const { apiKey, environment, ...rest } = config

  const queryParams = new URLSearchParams({
    apiKey,
    environment,
  })

  for (const [key, value] of Object.entries(rest)) {
    if (value === undefined || value === null || value === '') {
      continue
    }

    if (Array.isArray(value)) {
      if (value.length > 0) {
        queryParams.append(key, value.join(','))
      }
    } else if (typeof value === 'boolean') {
      queryParams.append(key, value.toString())
    } else {
      queryParams.append(key, value.toString())
    }
  }

  const baseUrl = environment === "PRODUCTION" ? "https://global.transak.com" : "https://global-stg.transak.com"

  return `${baseUrl}/?${queryParams.toString()}`
}

export const TRANSAK_ENVIRONMENT_STAGING = 'STAGING'
export const TRANSAK_ENVIRONMENT_PRODUCTION = 'PRODUCTION'

const TransakPayEmbed = (props: TransakPayEmbedProps) => {
  const { config, onOrderCreated, onOrderSuccessful } = props
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const [loading, setLoading] = useState(true)

  const url = buildTransakUrl(config)

  useEffect(() => {
    const handleMessage = (message: MessageEvent) => {
      if (iframeRef.current && message.source !== iframeRef.current.contentWindow) return

      // This will trigger when the user marks payment is made
      if (message?.data?.event_id === 'TRANSAK_ORDER_CREATED') {
        if (onOrderCreated) onOrderCreated(message?.data?.data)
      }

      // This will trigger when the user marks payment is made
      if (message?.data?.event_id === 'TRANSAK_ORDER_SUCCESSFUL') {
        if (onOrderSuccessful) onOrderSuccessful(message?.data?.data)
      }
    }

    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [])

  const handleIframeLoad = () => {
    setLoading(false)
  }

  return (
    <LoadingWrapper
      variant="primary"
      size="md"
      isLoading={loading}
      renderChildrenHidden
      loaderClassName='h-[640px] flex items-center'
    >
      <iframe
        ref={iframeRef}
        src={url}
        className='h-[640px]'
        allow="camera; microphone; payment"
        style={{
          width: '100%',
          border: 'none',
        }}
        onLoad={handleIframeLoad}
      />
    </LoadingWrapper>
  )
}

export default TransakPayEmbed
