import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'


const iconOutline = (
  <path fill="none" d="M14.3351 9.98277L2.24012 17.337C1.47374 17.8037 0.491211 17.2535 0.491211 16.3545V1.64598C0.491211 0.746962 1.47374 0.196743 2.24012 0.663446L14.3351 8.0177C15.072 8.46476 15.072 9.53572 14.3351 9.98768V9.98277Z" stroke="currentcolor" strokeWidth="1" strokeLinejoin="round" />
)

const iconSolid = (
  <path d="M23.44 12.4C23.37 13.26 23.23 14.12 23 14.95C22.67 16.16 21.8 16.87 20.56 17.01C19.39 17.14 18.2 17.23 17.02 17.23C12.71 17.21 8.39999 17.21 4.08999 17.08C1.70999 17.01 0.759997 16.08 0.459997 13.72C0.0699974 10.57 0.0699961 7.40003 0.489996 4.25003C0.569996 3.62003 0.809986 2.98003 1.11999 2.43003C1.66999 1.47003 2.63999 1.12003 3.69999 1.08003C6.27999 0.970029 8.85999 0.880029 11.52 0.780029C14.21 0.870029 16.99 0.940028 19.77 1.07003C21.94 1.17003 23 2.16003 23.28 4.33003C23.65 7.01003 23.65 9.71003 23.44 12.4ZM9.49999 12.26C11.55 11.15 13.55 10.08 15.62 8.96003C13.54 7.88003 11.55 6.84003 9.49999 5.78003C9.49999 7.98003 9.49999 10.08 9.49999 12.26Z" />
)

const YouTube = (props: SVGIconProps) => {
  const { width = 24, height = 18, outline = false, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} aria-label="YouTube" {...svgProps}>
      {outline ? iconOutline : iconSolid}
    </SVGIcon>
  )
}

export default YouTube
