import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const UsdcIcon = (props: SVGIconProps) => {
  const { width = 24, height = 24, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} {...svgProps}>
      <path d="M11.7886 23.1123C18.1926 23.1123 23.3448 17.9602 23.3448 11.5562C23.3448 5.15213 18.1926 0 11.7886 0C5.38455 0 0.232422 5.15213 0.232422 11.5562C0.232422 17.9602 5.38455 23.1123 11.7886 23.1123Z" fill="white" />
      <path d="M14.966 13.3858C14.966 11.7005 13.9548 11.1227 11.9325 10.882C10.4879 10.6894 10.199 10.3042 10.199 9.63002C10.199 8.95581 10.6806 8.52256 11.6436 8.52256C12.5103 8.52256 12.9918 8.81146 13.2325 9.53372C13.2807 9.67817 13.4251 9.77448 13.5696 9.77448H14.3399C14.5325 9.77448 14.677 9.63002 14.677 9.43752V9.38937C14.4844 8.33005 13.6176 7.51149 12.5102 7.41519V6.25957C12.5102 6.06697 12.3657 5.92252 12.125 5.87427H11.4027C11.2101 5.87427 11.0657 6.01872 11.0174 6.25957V7.36704C9.57289 7.55964 8.65812 8.52266 8.65812 9.72642C8.65812 11.3154 9.62114 11.9413 11.6435 12.1821C12.9917 12.4229 13.425 12.7118 13.425 13.4822C13.425 14.2526 12.7508 14.7822 11.8361 14.7822C10.5841 14.7822 10.1507 14.2525 10.0062 13.5302C9.95819 13.3377 9.81374 13.2413 9.66929 13.2413H8.85063C8.65812 13.2413 8.51367 13.3858 8.51367 13.5784V13.6265C8.70618 14.8302 9.47669 15.6969 11.0657 15.9378V17.0934C11.0657 17.2859 11.2101 17.4303 11.4509 17.4786H12.1731C12.3657 17.4786 12.5102 17.3341 12.5584 17.0934V15.9378C14.003 15.6969 14.966 14.6857 14.966 13.3857V13.3858Z" fill="#414141" />
      <path d="M9.33257 18.4418C5.57682 17.0937 3.65079 12.9045 5.04716 9.19684C5.76942 7.17451 7.35839 5.63369 9.33257 4.91143C9.52517 4.81513 9.62148 4.67067 9.62148 4.42992V3.75581C9.62148 3.56321 9.52517 3.41876 9.33257 3.37061C9.28442 3.37061 9.18812 3.37061 9.13997 3.41866C4.56565 4.86318 2.06182 9.7265 3.50634 14.3007C4.37305 16.9972 6.44353 19.0676 9.13997 19.9343C9.33257 20.0307 9.52527 19.9344 9.57333 19.7417C9.62148 19.6937 9.62148 19.6454 9.62148 19.5492V18.875C9.62148 18.7306 9.47702 18.5382 9.33257 18.4418ZM14.4365 3.41876C14.2439 3.32245 14.0512 3.41876 14.0032 3.61136C13.955 3.65951 13.955 3.70766 13.955 3.80396V4.47807C13.955 4.67067 14.0995 4.86328 14.2439 4.95958C17.9997 6.3077 19.9257 10.4968 18.5294 14.2045C17.8071 16.2268 16.2181 17.7677 14.2439 18.4899C14.0513 18.5862 13.955 18.7307 13.955 18.9714V19.6455C13.955 19.8381 14.0513 19.9826 14.2439 20.0308C14.2921 20.0308 14.3884 20.0308 14.4365 19.9827C19.0109 18.5382 21.5147 13.6749 20.0702 9.10064C19.2035 6.35605 17.0847 4.28547 14.4365 3.41876Z" fill="#414141" />    </SVGIcon>
  )
}

export default UsdcIcon
