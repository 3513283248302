import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const iconOutline = (
  <path fill="none" fillRule="evenodd" clipRule="evenodd" d="M14.7732 0.522159C16.4385 0.490827 17.3235 1.09657 18.1719 1.86942C18.8893 1.80675 19.8215 1.40989 20.3766 1.1279C20.5547 1.02869 20.8636 0.971245 21.0417 0.87725C20.7275 1.7232 20.1724 2.34461 19.5178 2.85114C19.3711 2.9608 19.2297 3.11224 19.0464 3.18534V3.19579C19.9786 3.18534 20.7484 2.76759 21.4816 2.53782V2.54827C21.0993 3.15923 20.5808 3.77542 20.0257 4.21406C19.8005 4.39161 19.5806 4.56916 19.3554 4.7467C19.3659 5.73365 19.3397 6.67359 19.1564 7.49866C18.0829 12.3081 15.2393 15.577 10.7356 16.9765C9.11737 17.4778 6.50419 17.6866 4.65558 17.2271C3.73913 16.9974 2.90647 16.7415 2.12618 16.4021C1.69152 16.2141 1.29352 16.0104 0.911233 15.7754C0.785548 15.6971 0.659864 15.624 0.53418 15.5457C0.953128 15.5561 1.43492 15.6031 1.90623 15.5979C2.33566 15.5927 2.74413 15.5457 3.13166 15.4621C4.10571 15.248 4.96979 14.966 5.71342 14.5326C6.07477 14.3237 6.6194 14.0731 6.876 13.7702C6.39421 13.7806 5.95432 13.6658 5.59821 13.5404C4.20521 13.0496 3.39874 12.1514 2.86981 10.7989C3.28876 10.8459 4.50371 10.9556 4.7865 10.7154C4.25758 10.684 3.74437 10.3812 3.38303 10.1514C2.26234 9.45166 1.35113 8.28195 1.3616 6.48038C1.50823 6.54827 1.65487 6.62138 1.8015 6.68926C2.08429 6.80937 2.36708 6.87203 2.70223 6.93991C2.84363 6.97124 3.12642 7.04957 3.28876 6.99213H3.26781C3.0531 6.74148 2.697 6.57438 2.48229 6.30284C1.76484 5.41511 0.926943 4.1044 1.35113 2.47516C1.4611 2.06263 1.79626 1.63443 1.97955 1.30023C1.98479 1.30023 1.99526 1.30545 2.0005 1.31067C2.08429 1.48299 2.27281 1.61354 2.38802 1.75976C2.7546 2.21406 3.20497 2.62138 3.66582 2.98169C5.23687 4.20362 6.65082 4.95558 8.91837 5.51433C9.49443 5.65532 10.1595 5.76498 10.8455 5.76498C10.6518 5.20623 10.7146 4.29762 10.8665 3.75453C11.2488 2.38639 12.0814 1.40467 13.3016 0.87725C13.5949 0.751924 13.9143 0.657929 14.2547 0.584822C14.4275 0.563934 14.6056 0.543047 14.7784 0.522159H14.7732Z" stroke="currentcolor" strokeWidth="1" strokeLinejoin="round" />
)

const iconSolid = (
  <path d="M14.7 0.330024C16.39 0.300024 17.3 0.920025 18.16 1.70002C18.89 1.64002 19.84 1.23002 20.4 0.940025C20.58 0.840025 20.89 0.780024 21.08 0.680024C20.76 1.54002 20.19 2.18002 19.53 2.69002C19.38 2.80002 19.24 2.96002 19.05 3.03002V3.04002C20 3.03003 20.78 2.60002 21.53 2.37002V2.38002C21.14 3.00002 20.61 3.63002 20.05 4.08002C19.82 4.26002 19.59 4.44003 19.37 4.62003C19.38 5.63002 19.36 6.58002 19.17 7.43002C18.08 12.34 15.18 15.67 10.6 17.1C8.94997 17.61 6.29997 17.82 4.40997 17.36C3.47997 17.13 2.62997 16.87 1.83997 16.52C1.39997 16.33 0.989968 16.12 0.599968 15.88C0.469968 15.8 0.339971 15.72 0.219971 15.65C0.649971 15.66 1.13997 15.71 1.61997 15.7C2.05997 15.69 2.46997 15.65 2.86997 15.56C3.85997 15.34 4.73997 15.06 5.48997 14.61C5.85997 14.4 6.40997 14.14 6.66997 13.83C6.17997 13.84 5.72997 13.72 5.36997 13.6C3.95997 13.1 3.12997 12.18 2.59997 10.8C3.02997 10.85 4.25997 10.96 4.54997 10.71C4.00997 10.68 3.48997 10.37 3.11997 10.14C1.97997 9.43002 1.04997 8.23002 1.05997 6.40002C1.20997 6.47002 1.35997 6.54002 1.50997 6.61002C1.79997 6.73002 2.08997 6.80002 2.42997 6.87003C2.56997 6.90003 2.85997 6.98002 3.02997 6.92002C3.01997 6.92002 3.01997 6.92002 3.00997 6.92002C2.78997 6.66002 2.42997 6.49002 2.20997 6.22002C1.47997 5.31002 0.629967 3.98002 1.05997 2.31002C1.16997 1.89002 1.50997 1.45002 1.69997 1.11002C1.70997 1.11002 1.70997 1.12002 1.71997 1.12002C1.79997 1.30002 1.98997 1.43002 2.10997 1.58002C2.47997 2.04002 2.93997 2.46002 3.40997 2.82002C5.00997 4.07002 6.44997 4.84002 8.75997 5.41002C9.34997 5.55002 10.02 5.66002 10.72 5.67002C10.52 5.10002 10.59 4.17002 10.74 3.62002C11.13 2.23002 11.97 1.22002 13.22 0.680024C13.52 0.550024 13.85 0.460023 14.19 0.380023C14.35 0.370023 14.53 0.350024 14.7 0.330024Z" />
)

const Twitter = (props: SVGIconProps) => {
  const { width = 22, height = 18, outline = false, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} aria-label="Twitter" {...svgProps}>
      {outline ? iconOutline : iconSolid}
    </SVGIcon>
  )
}

export default Twitter
