import React from 'react'
import cn from 'classnames'
import {
  useReactTable,
  getCoreRowModel,
  HeaderGroup,
  flexRender,
  Header,
  Row,
  Cell,
  getSortedRowModel,
} from '@tanstack/react-table'

import { ITableProps, RawDataType } from './types'
import Sorter from './Sorter'
import Pagination from './Pagination'
import LoadingWrapper from '../LoadingWrapper'

import s from './styles.module.css'

const Table = <RawData extends RawDataType>(props: ITableProps<RawData>) => {
  const { children, data, initialSortState, compact = false, empty = "No results to show", sorting, columns, pagination, isLoading, isHeaderHidden, className, tableClassName, insertRow, shouldHighlightRow, highlightClassName } = props

  const table = useReactTable({
    data,
    columns,
    initialState: {
      sorting: initialSortState
    },
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting: sorting?.state,
    },
    enableSortingRemoval: true,
    onSortingChange: sorting?.onChange,
    getSortedRowModel: getSortedRowModel(),
    manualSorting: sorting?.manualSort
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderTh = (header: Header<RawData, any>) => {
    const { getContext, id, column, isPlaceholder } = header
    const {
      columnDef,
      getCanSort,
      getToggleSortingHandler,
      getIsSorted
    } = column

    const canBeSorted = getCanSort()

    return (
      <th key={id} className={s.th}>
        {!isPlaceholder && (
          <div {...canBeSorted && {
            onClick: getToggleSortingHandler(),
            className: s.sortingWrapper
          }}>
            {flexRender(columnDef.header, getContext())}
            {canBeSorted && <Sorter className="ml-2" sort={getIsSorted()} />}
          </div>
        )}
      </th>
    )
  }

  const renderHeadTr = (headerGroup: HeaderGroup<RawData>) => (
    <tr key={headerGroup.id}>
      {headerGroup.headers.map(renderTh)}
    </tr>
  )

  const renderHead = () => table.getHeaderGroups().map(renderHeadTr)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderTd = (cell: Cell<RawData, any>) => (
    <td
      key={cell.id}
      className={s.td}
    >
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </td>
  )

  const renderBodyTr = (row: Row<RawData>, index: number) => {
    const highlight = shouldHighlightRow && highlightClassName ? shouldHighlightRow(row.original) : false

    return [
      <tr
        key={row.id}
        className={cn(s.tr, highlight && highlightClassName)}
      >
        {row.getVisibleCells().map(renderTd)}
      </tr>,
      insertRow && insertRow(index)
    ]
  }

  const renderTableBody = () => {
    const { rows } = table.getRowModel()

    return (
      <tbody className={s.tbody}>
        {!isLoading && rows.map(renderBodyTr)}
        {
          data.length === 0 && !isLoading && (
            <tr>
              <td colSpan={99}>
                <div className={s.table_empty}>
                  {empty}
                </div>
              </td>
            </tr>
          )
        }
      </tbody>
    )
  }

  return (
    <>
      <div className={cn(s.tableWrapper, className)}>
        <table className={cn(s.table, compact && s.compact, tableClassName)}>
          {!isHeaderHidden && (
            <thead className={s.thead}>{renderHead()}</thead>
          )}
          {renderTableBody()}
        </table>
        {!!isLoading && (
          <LoadingWrapper
            className={s.table_loader}
            variant="primary"
            size="md" />
        )}
        {children}
      </div>
      {!isLoading && pagination && (
        <Pagination {...pagination} />
      )}
    </>
  )
}

export default Table
