import useAuthMutation from 'hooks/useAuthMutation'
import { MasterLedgerHttpClient } from '../network'
import { MasterLedgerCreateClaimRequest, MasterLedgerGetClaimParametersRequest } from '../types'

export const useGetClaimParametersMutation = () =>
    useAuthMutation({
        showErrorToast: true,
        mutationFn: (payload: MasterLedgerGetClaimParametersRequest, token) =>
            MasterLedgerHttpClient.setAccessToken(token).GetClaimParameters(payload),
    })

export const useCreateClaimMutation = () =>
    useAuthMutation({
        showErrorToast: true,
        mutationFn: (payload: MasterLedgerCreateClaimRequest, token) =>
            MasterLedgerHttpClient.setAccessToken(token).CreateClaim(payload),
    })
