/**
 * Interpolate variables into string
 * @param template 
 * @param args 
 * @returns 
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const parseString = (template: string, ...args: any[]): any[] => {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const output: any[] = [];

  if (typeof template === 'string' && template.includes('%s')) {
    const interpolatedArgs = template.split('%s')

    interpolatedArgs.forEach((part, index) => {
      output.push(part)
      // Push parameter based on index
      if (args.length > index && index !== interpolatedArgs.length - 1) {
        output.push(args[index]);
      }
    });
  } else {
    output.push(template);
  }

  return output;
}

export default parseString
