
import React from 'react'
import { Menu } from '@headlessui/react'
import { motion } from 'framer-motion'
import cx from 'classnames'

import s from './styles.module.css'
import { DropdownProps } from './types'

/**
 * Dropdown component
 * @param props 
 * @returns 
 */
const Dropdown = (props: DropdownProps) => {
  const { label, children, buttonClassName, menuClassName, menuItemsClassName } = props

  const menuVariants = {
    closed: { scaleY: 0 },
    open: {
      scaleY: 1,
      transition: {
        type: "tween",
        duration: 0.1,
        delayChildren: 0.05,
        staggerChildren: 0.075,
      },
    }
  }

  const menuClasses = cx(s.menu, menuClassName)
  const menuItemsClasses = cx(s.menu_items, menuItemsClassName)
  const buttonClasses = cx(s.menu_button, buttonClassName)

  return (
    <Menu as="div" className={menuClasses}>
      {({ open }) => (
        <>
          <Menu.Button className={buttonClasses}>
            {label && typeof label === "function" ? label(open) : label}
          </Menu.Button>
          <Menu.Items
            as={motion.div}
            initial="closed"
            animate={open ? "open" : "closed"}
            variants={menuVariants}
            className={menuItemsClasses}>
            {children}
          </Menu.Items>

        </>
      )
      }
    </Menu >
  )
}

export default Dropdown