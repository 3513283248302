// eslint-disable jsx-a11y/control-has-associated-label
import React, { Fragment, forwardRef, useEffect, useState } from 'react'
import cn from 'classnames'
import { useRanger, Ranger, RangerOptions } from '@tanstack/react-ranger'
import useDebounceFunction from 'hooks/useDebounceFunction'

import Input from '../Input'
import { RangeBaseProps } from './types'
import s from './styles.module.css'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const RangeBase = forwardRef<HTMLInputElement, RangeBaseProps>((props, ref) => {
  const {
    value = [0],
    max = 100,
    min = 0,
    stepSize,
    steps,
    ticks,
    onChange,
    onDrag,
    name,
    showTicks = true,
    getBackgroundColor,
    getHandleColor,
    disabled,
    minValue,
    maxValue
  } = props
  const rangerRef = React.useRef<HTMLDivElement>(null)

  /**
   * Restrict values within Range bounds
   * @param values 
   * @returns 
   */
  const capValues = (values: ReadonlyArray<number>) => values.map(capValue => {
    if (minValue && capValue < minValue) {
      return minValue
    }

    if (maxValue && capValue > maxValue) {
      return maxValue
    }

    if (min && capValue < min) {
      return min
    }

    if (max && capValue > max) {
      return max
    }

    return capValue
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const eventHandler = (instance: Ranger<HTMLDivElement>, callback?: (...event: any[]) => void) => {
    const values = capValues(instance.sortedValues)

    const event = {
      target: {
        name,
        value: values.length === 1 ? values[0] : values
      }
    }

    if (callback) {
      callback(event)
    }
  }

  // Range options and init
  const options = {
    getRangerElement: () => rangerRef.current,
    values: Array.isArray(value) ? value : [value],
    min,
    max,
    ticks,
    steps,
    stepSize,
    disabled,
    onChange: (instance: Ranger<HTMLDivElement>) => {
      eventHandler(instance, onChange)
    },
    onDrag: (instance: Ranger<HTMLDivElement>) => {
      eventHandler(instance, onDrag)
    }
  } as RangerOptions<HTMLDivElement>

  const rangerInstance = useRanger<HTMLDivElement>(options)

  // Local state to track input values
  const [inputValues, setInputValues] = useState<readonly number[]>(Array.isArray(value) ? value : [value])

  // Update input values if 
  useEffect(() => {
    // Check length because after init ranger sortvalues is empty
    setInputValues(Array.isArray(value) ? value : [value])
  }, [value])

  const onManualEntry = (newValue: number, index: number) => {
    // Update existing values
    const values = [...rangerInstance.sortedValues]
    values[index] = newValue
    // Trigger on change
    if (onChange)
      onChange({
        target: {
          name,
          value: capValues(values)
        }
      })

    // Cap the input values if they exceeded the threshold now
    setInputValues(capValues(values))
  }

  const debouncedOnManualEntry = useDebounceFunction(onManualEntry, 800);

  return (
    <div
      className={cn(s.range_wrapper, disabled && s.disabled)}>
      <div
        ref={rangerRef}
        className={cn(s.track, !showTicks && s.no_ticks)}>
        {
          showTicks &&
          <div>
            {rangerInstance.getTicks().map(({ value: rangeValue, key, percentage }) => (
              <div
                key={key}
                className={s.tick}
                style={{
                  left: `${percentage}%`
                }}>
                <div
                  className={s.tick_label}>
                  {rangeValue}
                </div>
              </div>
            ))}
          </div>
        }
        <div>
          {
            // eslint-disable-next-line react/no-array-index-key
            rangerInstance.getSteps().map(({ left, width }, i) => {

              const background = getBackgroundColor && getBackgroundColor(i)

              return (
                <div
                  key={i}
                  className={s.segment}
                  style={{
                    background,
                    left: `${left}%`,
                    width: `${width}%`
                  }} />
              )
            })}
        </div>
        {rangerInstance
          .handles()
          .map(
            (
              {
                value: rangeValue,
                onKeyDownHandler,
                onMouseDownHandler,
                onTouchStart,
              },
              i,
            ) => {
              const background = getHandleColor && getHandleColor(i)

              // TODO:: Add solution for input overlap when multiple handles
              return (
                // eslint-disable-next-line react/no-array-index-key
                <Fragment key={`handle_${name}_${i}`}>
                  <button
                    type="button"
                    disabled={disabled}
                    className={cn(s.handle)}
                    onKeyDown={onKeyDownHandler}
                    onMouseDown={onMouseDownHandler}
                    onTouchStart={onTouchStart}
                    role="slider"
                    aria-valuemin={rangerInstance.options.min}
                    aria-valuemax={rangerInstance.options.max}
                    aria-valuenow={rangeValue}
                    style={{
                      background,
                      left: `${rangerInstance.getPercentageForValue(rangeValue)}%`
                    }}
                  />
                  <Input
                    name={`${name}_input`}
                    className={s.handle_input}
                    value={inputValues[i]}
                    size={inputValues[i]?.toString().length}
                    onChange={(evt) => {
                      const v = evt.currentTarget.value
                      // Only allow numbers
                      if (/^\d*$/.test(v)) {
                        // Update input field
                        const newInputValues = [...inputValues]
                        newInputValues[i] = Number(v)
                        // We don't cap values until after debounce because this lets the user erase the value and then type
                        setInputValues(newInputValues)
                        // Update range slider values after debounce
                        debouncedOnManualEntry(Number(v), i)
                      }
                    }}
                    style={{
                      background,
                      left: `${rangerInstance.getPercentageForValue(rangeValue)}%`
                    }} />
                </Fragment>
              )
            },
          )}
      </div>
    </div>
  )
})

export default RangeBase