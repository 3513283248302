import React from 'react'
import cx from 'classnames'

import Separator from '@/components/ui/Separator'
import Typography from '@/components/ui/Typography'

import { TitleProps } from './types'
import s from './styles.module.css'

/**
 * Component that will display a title and subtitle split by a separator
 * @param props 
 * @returns 
 */
function Title(props: TitleProps) {
  const { as, title, subtitle, separator = "|", className, subtitleClassName, separatorClassName } = props

  const titleClasses = cx(s.title, className)
  const separatorClasses = cx(s.separator, separatorClassName)
  const subTitleClasses = cx(s.subtitle, subtitleClassName)

  return (
    <>
      {
        title &&
        <>
          <Typography
            as={as}
            className={titleClasses}
            variant="default">
            {title}
            {
              subtitle &&
              <>
                <Separator className={separatorClasses} separator={separator} />
                <Typography
                  className={subTitleClasses}
                  variant="primary"
                  as="span">
                  {subtitle}
                </Typography>
              </>
            }
          </Typography>
        </>

      }
    </>
  )
}

Title.defaultProps = {
  as: "h2",
  title: null,
  subtitle: null,
  className: null,
  subtitleClassName: null,
  separatorClassName: null,
}

export default Title