import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const Bnb = (props: SVGIconProps) => {
  const { width, height = 13, showTitle = true, ...svgProps } = props
  const svgWidth = width || (showTitle ? 78 : 12)

  return (
    <SVGIcon width={svgWidth} height={height} aria-label="Copy" {...svgProps}>
      <path d="M2.1627 1.99122L5.60277 0L9.04285 1.99122L7.7781 2.72683L5.60277 1.47122L3.42744 2.72683L2.1627 1.99122ZM9.04285 4.50244L7.7781 3.76683L5.60277 5.02244L3.42744 3.76683L2.1627 4.50244V5.97366L4.33804 7.22925V9.74047L5.60277 10.4761L6.86752 9.74047V7.22925L9.04285 5.97366V4.50244ZM9.04285 8.48488V7.01366L7.7781 7.74925V9.22047L9.04285 8.48488ZM9.94079 9.00488L7.76546 10.2605V11.7317L11.2055 9.74047V5.75803L9.94079 6.49366V9.00488ZM8.67609 3.24683L9.94079 3.98244V5.45366L11.2055 4.71805V3.24683L9.94079 2.51122L8.67609 3.24683ZM4.33804 10.7932V12.2644L5.60277 13L6.86752 12.2644V10.7932L5.60277 11.5288L4.33804 10.7932ZM2.1627 8.48488L3.42744 9.22047V7.74925L2.1627 7.01366V8.48488ZM4.33804 3.24683L5.60277 3.98244L6.86752 3.24683L5.60277 2.51122L4.33804 3.24683ZM1.26473 3.98244L2.52947 3.24683L1.26473 2.51122L0 3.24683V4.71805L1.26473 5.45366V3.98244ZM1.26473 6.49366L0 5.75803V9.74047L3.44007 11.7317V10.2605L1.26473 9.00488V6.49366Z" fill="currentcolor" />
      {
        showTitle &&
        <>
          <path d="M25.1324 8.49505V8.47442C25.1324 7.50288 24.6171 7.01712 23.7822 6.69672C24.2976 6.40731 24.7305 5.95252 24.7305 5.13603V5.11536C24.7305 3.97845 23.8235 3.24463 22.3497 3.24463H19V10.4795H22.4321C24.0605 10.4795 25.1324 9.81802 25.1324 8.49505ZM23.1536 5.40475C23.1536 5.94218 22.7104 6.16957 22.0095 6.16957H20.546V4.63992H22.1126C22.7825 4.63992 23.1536 4.90865 23.1536 5.38408V5.40475ZM23.5555 8.2987C23.5555 8.83614 23.1329 9.08422 22.4321 9.08422H20.546V7.49254H22.3806C23.1948 7.49254 23.5555 7.79224 23.5555 8.27801V8.2987Z" fill="currentcolor" />
          <path d="M32.9518 10.4795V3.24463H31.3852V7.69924L28.0047 3.24463H26.5411V10.4795H28.1077V5.88021L31.6017 10.4795H32.9518Z" fill="currentcolor" />
          <path d="M40.9191 8.49505V8.47442C40.9191 7.50288 40.4038 7.01712 39.5689 6.69672C40.0843 6.40731 40.5172 5.95252 40.5172 5.13603V5.11536C40.5172 3.97845 39.6102 3.24463 38.1364 3.24463H34.7867V10.4795H38.2188C39.8472 10.4795 40.9191 9.81802 40.9191 8.49505ZM38.9402 5.40475C38.9402 5.94218 38.4971 6.16957 37.7962 6.16957H36.3327V4.63992H37.8993C38.5692 4.63992 38.9402 4.90865 38.9402 5.38408V5.40475ZM39.3422 8.2987C39.3422 8.83614 38.9196 9.08422 38.2188 9.08422H36.3327V7.49254H38.1672C38.9815 7.49254 39.3422 7.79224 39.3422 8.27801V8.2987Z" fill="currentcolor" />
          <path d="M50.4849 9.20133L49.6707 8.37448C49.0523 8.95325 48.4855 9.31503 47.5991 9.31503C46.2181 9.31503 45.2183 8.15743 45.2183 6.73111C45.2183 5.30482 46.2181 4.16791 47.5991 4.16791C48.4236 4.16791 49.0421 4.52966 49.6192 5.06711L50.4334 4.12657C49.7429 3.4651 48.9389 3 47.6094 3C45.4244 3 43.8888 4.68469 43.8888 6.75181C43.8888 8.83955 45.4554 10.4829 47.5579 10.4829C48.908 10.4829 49.7326 9.9868 50.4849 9.20133Z" fill="currentcolor" />
          <path d="M56.6306 7.30994V10.3589H57.8983V3.12402H56.6306V6.13165H53.1883V3.12402H51.9205V10.3589H53.1883V7.30994H56.6306Z" fill="currentcolor" />
          <path d="M63.585 3.07251H62.4102L59.2357 10.3591H60.5342L61.2764 8.61237H64.6879L65.4198 10.3591H66.7594L63.585 3.07251ZM64.224 7.48576H61.7404L62.9768 4.59183L64.224 7.48576Z" fill="currentcolor" />
          <path d="M68.1719 3.12402V10.3589H69.4396V3.12402H68.1719Z" fill="currentcolor" />
          <path d="M76.5139 8.12645L72.6486 3.12402H71.4738V10.3589H72.721V5.2118L76.6991 10.3589H77.7611V3.12402H76.5139V8.12645Z" fill="currentcolor" />
        </>
      }

    </SVGIcon>
  )
};

export default Bnb

