import React from 'react'
import cx from 'classnames';

import { ContainerProps } from './types'
import s from './styles.module.css'

/**
 * Container based on tailwind classes
 * @param props 
 * @returns 
 */
const Container = (props: ContainerProps) => {
  const { children, className, contain = true } = props;

  const classes = cx(contain && s.wrapper, className)

  return <div className={classes}>{children}</div>
}

export default Container