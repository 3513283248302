import React from 'react'
import { Tab as HeadlessTab } from '@headlessui/react'
import cn from 'classnames'

import { TabPanelProps } from './types'
import s from './styles.module.css'

/**
 * TabPanel
 * @param props 
 * @returns 
 */
function TabPanel(props: TabPanelProps) {
  const { children, className } = props

  return (
    <HeadlessTab.Panel className={cn(s.tab, className)}>
      {children}
    </HeadlessTab.Panel>
  )
}

export default TabPanel