import React from 'react'
import cn from 'classnames';
import { motion } from 'framer-motion'
import useVariant from 'hooks/useVariant';

import s from './styles.module.css'
import { PulsingCircleProps } from './types';

const VARIANTS_CIRCLES = (delay: number) => ({
  initial: {
    opacity: 0.5,
    scale: 1
  },
  animate: {
    scale: 2.5,
    opacity: 0,
    transition: {
      type: "tween",
      duration: 1.5,
      ease: "easeOut",
      repeat: Infinity,
      delay,
      repeatDelay: 0.5,
    },
    transitionEnd: {
      opacity: 0.5,
      scale: 1
    }
  }
})

const PulsingCircle = (props: PulsingCircleProps) => {
  const { pulse = true, variant = "primary" } = props

  const classes = useVariant({ variant, filled: true })

  return (
    <div
      className={s.circle}>
      <div
        className={cn(s.center, classes)}
      />
      {
        pulse &&
        <>
          <motion.div
            key="circle_1"
            initial="initial"
            animate="animate"
            className={classes}
            variants={VARIANTS_CIRCLES(0)}
          />
          <motion.div
            key="circle_2"
            initial="initial"
            animate="animate"
            className={classes}
            variants={VARIANTS_CIRCLES(0.5)}
          />
        </>}
    </div>
  );
};

export default PulsingCircle