import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const Page = (props: SVGIconProps) => {
  const { width = 13, height = 18, outline, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} {...svgProps}>
      <path fill="none" d="M0.382812 1.53484V16.4079C0.382812 17.0777 0.926295 17.6173 1.59225 17.6173H11.2563C11.8916 17.6173 12.4083 17.1007 12.4083 16.4653V5.99753C12.4083 5.58417 12.2438 5.18996 11.9529 4.89908L7.88823 0.838267C7.59736 0.547388 7.19931 0.382812 6.78979 0.382812H1.53484C0.899504 0.382812 0.382812 0.899504 0.382812 1.53484Z" stroke="currentcolor" strokeWidth="1" strokeMiterlimit="10" />
      <path fill="none" d="M7.0498 0.382812V4.54314C7.0498 5.18996 7.57415 5.7143 8.22097 5.7143H12.343" stroke="currentcolor" strokeWidth="1" strokeMiterlimit="10" />
    </SVGIcon>
  )
}

export default Page
