import React, { forwardRef, useEffect, useState } from 'react'
import cx from 'classnames'
import NextLink from 'next/link'

import { useActiveRoute, useVariant } from '@/hooks'

import { LinkProps } from './types'
import s from './styles.module.css'

type Ref = HTMLAnchorElement

/**
 * NextJs link wrapped to use variants hook
 */
const Link = forwardRef<Ref, LinkProps>((props, ref) => {
  const {
    children,
    variant = "default",
    asButton = false,
    hover = true,
    filled = false,
    outline = false,
    target,
    className,
    activeClassName,
    disableActiveHighlight = false,
    disabled = false,
    href,
    as,
    ...linkProps
  } = props

  const active = useActiveRoute({
    href: href as string,
    as: as as string
  })

  const variantClass = useVariant({
    variant,
    color: true,
    hover: disabled ? false : hover,
    filled,
    outline
  })

  const classes = cx(
    s.link,
    [`${variantClass}`],
    asButton && s.link_button,
    disabled && s.disabled,
    className
  )

  const [linkClasses, setLinkClasses] = useState(classes)

  useEffect(() => {
    const activeClasses = cx(s.active, activeClassName)

    const newClassName =
      active
        ? `${classes} ${!disableActiveHighlight && activeClasses}`.trim()
        : classes

    if (newClassName !== linkClasses) {
      setLinkClasses(newClassName)
    }
  }, [active, activeClassName, linkClasses, classes, disabled, disableActiveHighlight])

  // Potentially fixes security issue - https://mathiasbynens.github.io/rel-noopener/#recommendations
  // This should happen automatically now in up to date browsers
  if (target && target === "_blank") {
    linkProps.rel = "no-referrer"
  }

  return (
    <NextLink
      ref={ref}
      href={href}
      as={as}
      className={linkClasses}
      target={disabled ? undefined : target}
      tabIndex={disabled ? -1 : undefined}
      {...linkProps}>
      {children}
    </NextLink>
  )
})

export default Link