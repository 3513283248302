import { WalletChain } from 'hooks/useWallet'
import { Blockchain } from './Blockchain'

// Access token will expire after 1 hour
export const AUTH_ACCESS_TOKEN_LIFESPAN_SECS = 60 * 60
// Refresh token will expire after 2 weeks
export const AUTH_REFRESH_TOKEN_LIFESPAN_SECS = 60 * 60 * 24 * 7
// Will try refresh 5 minutes before expiration
export const AUTH_ACCESS_TOKEN_REFRESH_SECS_BEFORE_EXPIRE = 5 * 60

export function createAccessTokenExpireTimeStamp(): number {
    return Math.floor(Date.now() + AUTH_ACCESS_TOKEN_LIFESPAN_SECS * 1000)
}

export type ApiError = {
    error: {
        code: number
        message: string
    }
}

export type SignInPayloadType = {
    email: string
    password: string
    source?: string | null
}

export type SignInDesktopPayloadType = {
    email: string
    password: string
}

export type MfaSignInPayloadType = {
    session: string
    userCode: string
}

export type SuccessSignInResponseType = {
    accessToken: string
    refreshToken: string
    id: number
    sub: string
}

export type SignInGrantResponseType = {
    id: number
    sub: string
    h: string
    d: string
}

export type SignValidateResponseType = string

export type MfaSignInResponseType = {
    id: number
    session: string
    forceMfa: boolean
}

export type UnconfirmedUserSignInResponseType = {
    id: number
    email: string
    status: string
}

export type SignInResponseType =
    | SuccessSignInResponseType
    | MfaSignInResponseType
    | UnconfirmedUserSignInResponseType

export enum UserStatus {
    Unconfirmed = 'UNCONFIRMED',
}

export type SignInResponse2FaType = {
    id: number
    session: string
    forceMfa: boolean
}

export type SignIn2FaRequestType = {
    session: string
    userCode: string
}

export type UserRoleType = {
    id: number
    name: string
    discordId?: number
    assetCollectionId?: number
    assetVariantId?: number
    color?: string
}

export type GameRoleType = {
    id: number
    name: string
    description: string
    slug: string
}

export type UserGameRolesType = {
    id: number
    name: string
    playerRoles: UserRoleType[]
    gameComponents: GameRoleType[]
}

export type User2FaSettingsType = {
    enabled: boolean
    verified: boolean
    secretCode?: string
}

export type UserVerifiedWallet = {
    id: number
    address: string
    walletName: string
    stakeAddress?: string
    blockchainType?: WalletChain
    chainId?: string
    paymentAddresses?: string[]
}

export type IntegrationDiscordUserDataType = {
    username: string
    joinedAt: string
    roleIds: number[]
}

export enum IntegrationTypeEnum {
    DISCORD = 'Discord',
}

export type IntegrationType = {
    name: IntegrationTypeEnum
    userData: IntegrationDiscordUserDataType
}

export type UserAvatarCropDataType = {
    x: number
    y: number
    width: number
    height: number
}

export type UserKycSessionStatus =
    | 'APPROVED'
    | 'REJECTED'
    | 'RESUBMISSION_REQUIRED'
    | 'SUBMISSION_REQUIRED'
    | 'PENDING_VERIFICATION'

export type UserKycSessionStep = {
    stepId: string
    status: UserKycSessionStatus
    type: string
}

export type UserKycSession = {
    sessionId: string
    status: UserKycSessionStatus
    steps: UserKycSessionStep[]
}

export type UserType = {
    id: number
    sub: string
    discriminator: string
    discordUsername?: string
    email: string
    pendingEmail?: string
    username?: string
    displayName?: string
    playerRoles: UserRoleType[]
    gameRoles: UserGameRolesType[]
    integrations: IntegrationType[]
    mfaSettings: User2FaSettingsType
    createdAt: string
    verifiedWalletAddresses: UserVerifiedWallet[]
    avatar: UserAvatarType
    deletionRequestedAt?: string
    synapsSession: UserKycSession | null
    /**
     * Only available on me API call
     */
    gameComponents?: string[]
    roles?: string[]
}

export type UserDeleteRequestType = {
    email: string
    password: string
}

export type UserAvatarType = {
    url: string
    cropData: UserAvatarCropDataType
}

export type PlayerUserType = {
    playerUser: UserType
}

export type RefreshAccessTokenResponseType = {
    accessToken: string
}

export type MfaAssoicateResponseType = {
    secretCode: string
}

export type MfaConnectRequestType = {
    userCode: number
}

export type MfaEnableRequestType = {
    enabled: boolean
}

export type RegisterUserPayloadType = {
    username: string
    email: string
    password: string
    passwordConfirmation: string
    tester: boolean
}

export type RegisterUserResponseType = {
    email: string
    status: string
}

export type RegisterUserPayloadKeyType = keyof RegisterUserPayloadType

export type UserUpdateAvatarRequestType = {
    url: string
    cropData: UserAvatarCropDataType
    playerUserId: number
}

export type UserUpdatePasswordRequestType = {
    previousPassword: string
    newPassword: string
    newPasswordConfirmation: string
}

export type UserUpdateEmailRequestType = {
    pendingEmail: string
}

export type UserCancelEmailRequestType = {
    pendingEmail: string
}

export type EmailConfirmRequestType = {
    email: string
    code: string
}

export type UserUpdateUsernameRequestType = {
    username: string
}

export type UserPasswordRecoveryRequestType = {
    email: string
}

export type UserPasswordRecoveryResponseType = {
    attribute: string
    destination: string
}

export type UserPasswordRecoveryConfirmRequestType = {
    code: string
    sub: string
    password: string
    passwordConfirmation: string
}

export type UserPasswordRecoveryConfirmResponseType = {
    status: 'CONFIRMED' | ApiError
}

export type UserVerifyCardanoWalletRequestType = {
    captchaToken?: string
    unsignedMessage: string
    signedMessage: string
    cardanoPublicKey?: string
    cardanoPaymentAddresses?: string[]
    walletName: string
    address: string
}

export type UserVerifyCardanoHardwareWalletRequestType = {
    captchaToken?: string
    cardanoPaymentAddresses?: string[]
    walletName: string
    address: string
    verificationTx: string
    verificationSignature: string
}

export type UserVerifyEvmWalletRequestType = {
    captchaToken?: string
    unsignedMessage: string
    signedMessage: string
    walletName: string
    address: string
}

export type UserUnverifyWalletResponseType = {
    id: number
}

export type UserBulkUnverifyWalletRequestType = {
    id: number[]
}

export type UserBulkUnverifyWalletResponseType = {
    ids: number[]
}

export type DiscordAuthorizeResponse = {
    redirectUrl: string
}

export type UserResendCodeRequestType = {
    email: string
}

export type UserResendCodeResponseType = {
    email: string
    status: string
}

export type CheckUsernameResponseType = {
    isAvailable: boolean
    isProfanity: boolean
}

export type PlayerUserCopiBalanceResponse = {
    totalCopiBalance: number
    copiRequiredToRegisterAllNodes: number
    maxRegisteredNodeCount: number
    nodesInWallet: number
    balances: {
        balance: number
        blockchain: Blockchain
        updatedAt: string
    }[]
}

export type UserOwnedAsset = {
    tag: string
    name: string
    rarity: string
    imageUrl?: string
    videoUrl?: string
    ownerWalletAddress: string
    currentOwnerWalletAddressSince: string
    currentOwnerPlayerUserSince: string
    restrictedUntil?: string
    nftCollectionName: string
}

export type UserOwnedAssetsResponse = {
    nfts: UserOwnedAsset[]
}
