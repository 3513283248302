import React, { useState, useEffect } from 'react'

import Link from '../Link';
import Separator from '../Separator';

import s from './styles.module.css'
import { CrumbProps } from './type';

/**
 * Display individual crumb
 * @param props 
 * @returns 
 */
const Crumb = (props: CrumbProps) => {
  const {
    text: defaultText,
    textGenerator,
    href,
    last = false,
    separator = ">",
    length = 50
  } = props

  const [text, setText] = useState(decodeURI(defaultText));

  useEffect(() => {

    if (!textGenerator) { return; }

    async function fetchData() {
      if (textGenerator) {
        const currText = await textGenerator()
        setText(currText)
      }
    }

    fetchData()
  }, [textGenerator, defaultText]);

  // Substring last crumb 
  if (last && text) {
    const lastCrumb = text.length < length ? text : `${text.substring(0, length)}...`

    return <span>{lastCrumb}</span>
  }

  return (
    <>
      <Link variant="secondary" className={s.crumb} href={href}>{text}</Link>
      {!last && <Separator className={s.separator} separator={separator} />}
    </>
  )
}

export default Crumb
