import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const JpegStore = (props: SVGIconProps) => {
  const { width = 141, height = 30, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} aria-label="Jpeg.store" {...svgProps}>
      <path d="M36.0129 30.0245C35.1038 30.0245 34.4545 29.94 34 29.7387L34.3376 27.1342C34.6233 27.2446 35.0194 27.3031 35.5843 27.3031C36.5778 27.3031 37.1427 26.3678 37.1427 24.9779V12.0336H35.6168V9.45508H40.3179V25.0948C40.3179 28.4072 38.6167 30.0245 36.0129 30.0245Z" />
      <path d="M49.6423 29.5702H42.9023V26.9917H44.4282V12.0339H42.9023V9.45541H47.0645L47.4865 11.1831C48.3112 9.93603 49.5839 9.11768 51.2851 9.11768C54.681 9.11768 57.0316 12.0664 57.0316 16.5089C57.0316 20.9514 54.7395 23.9586 51.4799 23.9586C49.8955 23.9586 48.5644 23.2246 47.6294 21.9451V26.9852H49.6423V29.5637V29.5702ZM50.5513 12.0599C48.6229 12.0599 47.4086 13.7615 47.4086 16.5349C47.4086 19.3082 48.6229 21.1527 50.5773 21.1527C52.5318 21.1527 53.6941 19.3407 53.6941 16.5608C53.6941 13.781 52.5058 12.0534 50.5513 12.0534V12.0599Z" />
      <path d="M73.2321 12.0339H71.6997V22.5751C71.6997 27.6477 68.8947 30.0248 64.1676 30.0248C62.7521 30.0248 60.7132 29.739 59.6613 29.174L60.2262 26.6215C61.0509 27.0436 62.4079 27.3034 63.765 27.3034C66.5116 27.3034 68.5245 26.1408 68.5245 22.5751V21.6658C67.5895 23.1077 66.1156 23.9586 64.4468 23.9586C61.2197 23.9586 58.8691 20.8994 58.8691 16.5089C58.8691 12.1183 61.2781 9.11768 64.6741 9.11768C66.4013 9.11768 67.7064 9.99449 68.4986 11.3259L68.8102 9.45541H73.2321V12.0339ZM65.3559 21.1527C67.3103 21.1527 68.5245 19.3147 68.5245 16.5349C68.5245 13.755 67.3038 12.0599 65.3818 12.0599C63.4598 12.0599 62.2131 13.7875 62.2131 16.5673C62.2131 19.3472 63.4339 21.1592 65.3559 21.1592V21.1527Z" />
      <path d="M76.6922 20.0811C77.9064 20.0811 78.6466 20.9579 78.6466 22.036C78.6466 23.1142 77.9129 23.965 76.6922 23.965C75.4714 23.965 74.7637 23.1142 74.7637 22.036C74.7637 20.9579 75.4974 20.0811 76.6922 20.0811Z" />
      <path d="M83.6015 19.1455V21.2953C84.5365 21.4642 85.4716 21.5226 86.3222 21.5226C87.939 21.5226 88.9259 20.9835 88.9259 19.8794C88.9259 18.9182 88.361 18.4895 86.7442 18.0673L84.7898 17.5542C82.212 16.8723 80.8809 15.6837 80.8809 13.417C80.8809 10.2474 83.4002 9.11084 86.3481 9.11084C88.413 9.11084 90.257 9.64992 91.2505 10.189V14.0405H88.6987V11.8582C87.9065 11.6568 87.1143 11.5464 86.3481 11.5464C84.9911 11.5464 83.7444 11.9166 83.7444 13.1052C83.7444 13.8391 84.1664 14.2938 85.5819 14.664L87.6208 15.1446C90.0298 15.7097 91.757 16.619 91.757 19.3923C91.757 22.8736 89.1207 23.9517 86.3481 23.9517C84.595 23.9517 82.4652 23.7244 81.0497 23.1269V19.1325H83.6015V19.1455Z" />
      <path d="M93.237 9.46179H95.2174V6H98.3861V9.45529H101.561V12.0338H98.3861V18.6066C98.3861 20.646 99.0938 21.2436 100.139 21.2436C100.763 21.2436 101.159 21.1331 101.698 20.9578L102.068 23.4194C101.25 23.7051 100.568 23.9 99.6588 23.9C96.6264 23.9 95.2109 22.1723 95.2109 18.1195V12.0338H93.2305V9.45529L93.237 9.46179Z" />
      <path d="M109.75 9.11768C113.718 9.11768 116.263 12.1183 116.263 16.4829C116.263 20.8475 113.711 23.965 109.75 23.965C105.789 23.965 103.205 20.8215 103.205 16.4829C103.205 12.1443 105.783 9.11768 109.75 9.11768ZM109.75 12.0664C107.685 12.0664 106.549 13.9629 106.549 16.4829C106.549 19.0029 107.653 21.0164 109.75 21.0164C111.848 21.0164 112.893 19.0029 112.893 16.4569C112.893 13.9109 111.789 12.0664 109.75 12.0664Z" />
      <path d="M125.041 23.6207H118.301V21.0422H119.827V12.0662H118.301V9.46176H122.463L123.002 11.8129C123.541 10.3126 124.619 9.12402 126.229 9.12402C126.768 9.12402 127.391 9.23444 127.93 9.46176L127.45 12.352C126.768 12.1831 126.32 12.1571 125.865 12.1571C124.08 12.1571 123.002 13.8263 123.002 17.9896V21.0487H125.041V23.6272V23.6207Z" />
      <path d="M134.873 9.11768C138.723 9.11768 140.905 11.839 140.905 15.9178V17.022H131.873C132.042 19.516 133.263 21.2697 135.665 21.2697C137.366 21.2697 138.496 20.6721 139.262 19.9382L140.736 21.8672C139.606 23.1402 137.704 23.965 135.386 23.965C131.334 23.965 128.672 21.4125 128.672 16.4829C128.672 12.0664 130.964 9.11768 134.873 9.11768ZM132.009 14.6968H137.756C137.47 12.6834 136.451 11.5792 134.866 11.5792C133.282 11.5792 132.347 12.7094 132.003 14.6968H132.009Z" />
      <path d="M39.4137 4H35.8555V6.85776H39.4137V4Z" />
      <path d="M22.0379 9.15784L14.0123 1.46786C13.5318 1.00672 12.8955 0.753418 12.2332 0.753418H2.57131C1.15579 0.753418 0 1.90951 0 3.3254V27.428C0 28.8504 1.15579 30 2.57131 30H20.2588C21.6808 30 22.8301 28.8439 22.8301 27.428V11.0219C22.8301 10.3204 22.5379 9.64495 22.0379 9.16433V9.15784Z" />
      <path d="M11.7274 11.1195V2.46826H2.57195C2.09794 2.46826 1.71484 2.85146 1.71484 3.32559V25.3174H21.1165V11.1195H11.7274Z" fill="#121212" />
      <path d="M1.71484 27.4282C1.71484 27.9024 2.09794 28.2856 2.57195 28.2856H20.2594C20.7334 28.2856 21.1165 27.9024 21.1165 27.4282V25.3174H1.71484V27.4282Z" fill="#F3C800" />
    </SVGIcon >
  )
}

export default JpegStore