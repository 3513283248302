import React from 'react'
import cn from 'classnames'

import { SlideNavigationDotsProps } from './types'
import s from './styles.module.css'

const SlideNavigationDots = (props: SlideNavigationDotsProps) => {
  const { onClick, index, active } = props

  return (
    <button
      key={index}
      onClick={() => onClick(index)}
      className={cn(s.slide_navigation_item, s.slide_navigation_dots, active && s.active)}
    >
      <div className={s.slide_navigation_item_dots} />
    </button>
  )
}

export default SlideNavigationDots
