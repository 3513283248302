import React from 'react'
import cn from 'classnames'
import { AnimatePresence, motion, Transition } from 'framer-motion'

import useVariant from 'hooks/useVariant'

import s from './styles.module.css'
import { LoaderProps } from './types'

const loadingCircleVariants = {
  start: {
    y: "0%",
    scale: 1.2
  },
  end: {
    y: "100%",
    scale: 0.6
  },
}

const loadingContainerVariants = {
  start: {
    transition: {
      staggerChildren: 0.3,
    },
  },
  end: {
    transition: {
      staggerChildren: 0.3,
    },
  },
}

const loadingCircleTransition = {
  duration: 0.75,
  repeat: Infinity,
  repeatType: "reverse",
  ease: "easeInOut"
} as Transition;

/**
 * Simple infinite loader
 * @param props 
 * @returns 
 */
function Loader(props: LoaderProps) {
  const { className, dotClassName, size = "sm", variant = "default" } = props

  const color = useVariant({ variant, background: true })

  const containerClasses = cn(s.loader, className)
  const dotClasses = cn(s.circle, size && s[size], color, dotClassName)

  return (
    <AnimatePresence initial>
      <motion.div
        className={containerClasses}
        variants={loadingContainerVariants}
        initial="start"
        animate="end"
      >
        <motion.span
          className={dotClasses}
          variants={loadingCircleVariants}
          transition={loadingCircleTransition}
        />
        <motion.span
          className={dotClasses}
          variants={loadingCircleVariants}
          transition={loadingCircleTransition}
        />
        <motion.span
          className={dotClasses}
          variants={loadingCircleVariants}
          transition={loadingCircleTransition}
        />
      </motion.div>
    </AnimatePresence>
  )
}

export default Loader