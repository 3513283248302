import React, { useCallback, useEffect } from 'react'
import { useRouter } from 'next/router'
import Script from 'next/script'

import { useNonce } from '@/providers/NonceContext'
import { getConsentMode, useGoogleTags } from 'hooks/useGoogleTags'

import { GoogleAnalyticsProps } from './types'

/**
 * Component will init Google Analytics
 * @param props 
 * @returns 
 */
const GoogleAnalytics = (props: GoogleAnalyticsProps) => {
  const { gtagId, gtmId } = props
  const router = useRouter()
  const { pageview } = useGoogleTags()
  const nonce = useNonce()
  const handleRouteChange = useCallback((url: string) => {
    pageview(url)
  }, [pageview])

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange)
    router.events.on('hashChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
      router.events.off('hashChangeComplete', handleRouteChange)
    }
  }, [router.events, pageview, handleRouteChange])

  if (!gtagId && !gtmId) return <></>

  const consentDefaults = getConsentMode({
    necessary: true,
    marketing: false,
    preferences: false,
    analytics: false,
    redact: false
  })

  if (process.env.NODE_ENV !== 'production') return <></>

  return (
    <>
      {
        gtagId &&
        <>
          <Script
            id='google-analytics'
            nonce={nonce}
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('consent', 'default', ${JSON.stringify(consentDefaults)});

                gtag('set', 'anonymizeIp', true);
                
                gtag('config', '${gtagId}', {
                    page_path: window.location.pathname,
                });
                `,
            }}
          />
        </>
      }

      {
        gtmId &&
        <>
          <noscript>
            <iframe
              title={gtmId}
              src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
              height="0"
              width="0"
              style={{ display: 'none', visibility: 'hidden' }} />
          </noscript>
          <Script
            id='google-tag-manager'
            nonce={nonce}
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${gtmId}');
                `,
            }}
          />
        </>
      }
    </>
  )
}

export default GoogleAnalytics