const stripTags = (str: string) => {
    const regex = /(<style(.|\n|\r)*?<\/style>)|(<script(.|\n|\r)*?<\/script>)/gm

    let parsed = str.replace(regex, '')
    parsed = parsed.replace(/<\/?[^>]+(>|$)/g, ' ')

    return parsed
}

export default stripTags
