import React, { useRef } from 'react'
import cn from 'classnames'

import useClickOrDrag from 'hooks/useClickOrDrag'

import { SlideItemProps } from './types'
import s from './styles.module.css'

const SlideItem = (props: SlideItemProps) => {
  const { children, backgroundUrl, className, bodyClassName, blur = true, containContent = true, onClick } = props
  const slideItemRef = useRef<HTMLDivElement | null>(null)
  const clickedOnSlide = useClickOrDrag(slideItemRef, 10)

  const classes = cn({
    [`${s.slide_item}`]: true,
    [`${s.slide_item_no_bg}`]: !backgroundUrl,
    [`${className}`]: !!className
  })

  const handleOnClick = (evt: React.MouseEvent<HTMLDivElement>) => {
    if (!clickedOnSlide) return

    if (onClick)
      onClick(evt)
  }

  return (
    <div
      role="presentation"
      onClick={handleOnClick}
      ref={slideItemRef}
      className={classes}>
      {
        backgroundUrl &&
        <div
          className={cn(s.slide_item_background, blur && s.blur)}
          style={{
            backgroundImage: `url(${backgroundUrl})`,
          }} />
      }
      <div className={cn(s.slide_item_body, containContent && s.slide_item_body_contain, bodyClassName)}>
        {children}
      </div>
    </div>
  )
}

export default SlideItem 
