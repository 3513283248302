import { API_GET_TOKEN_DISTRIBUTION, API_GET_TOKEN_UNLOCK_SCHEDULE } from 'consts/apiRoutes'
import { TokenDistributionResponse, TokenUnlockScheduleResponse } from 'types'
import HttpClientBase from './HttpClientBase'

/**
 * Used for misc website API calls
 */
class SiteHttpClient extends HttpClientBase {
    static setAccessToken(accessToken: string) {
        super.setAccessToken(accessToken)

        return this
    }

    static async GetTokenDistribution() {
        return this.request<TokenDistributionResponse>({
            url: API_GET_TOKEN_DISTRIBUTION,
            method: 'GET',
        })
    }

    static async GetTokenUnlockSchedule() {
        return this.request<TokenUnlockScheduleResponse>({
            url: API_GET_TOKEN_UNLOCK_SCHEDULE,
            method: 'GET',
        })
    }
}

export default SiteHttpClient