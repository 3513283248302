import React from 'react'

import isAbsolutePath from 'helpers/isAbsolutePath'

import { NavBar, NavItem } from '../../ui/Navigation'
import NavLink from '../../ui/Navigation/NavLink'
import chunk from '../../../helpers/chunkArray'

import { Link, SitemapProps } from './types'
import s from './styles.module.css'

/**
 * Sitemap from list of links
 * @param props 
 * @returns 
 */
const Sitemap = (props: SitemapProps) => {
  const { links, columnSize = 6 } = props

  const formattedLinks = chunk(links, columnSize)

  const sitemap = formattedLinks.map((column, index) => {
    const rows = column.map((link, columnIndex) => {
      const { label, href } = link as Link
      const isAbsolute = isAbsolutePath(href)

      return (
        // eslint-disable-next-line react/no-array-index-key
        <NavItem className={s.navitem} key={columnIndex}>
          <NavLink
            variant="default"
            target={isAbsolute ? "_blank" : undefined}
            activeClassName={s.active}
            className={s.link}
            href={href}>
            {label}
          </NavLink>
        </NavItem>
      )
    })

    return (
      // eslint-disable-next-line react/no-array-index-key
      <NavBar className={s.navbar} key={index}>
        {rows}
      </NavBar>
    )
  })

  return (
    <div className={s.sitemap}>
      {sitemap}
    </div>
  )
}

export default Sitemap