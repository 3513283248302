/**
 * Determines if a user is assigned all given roles.
 * @param {[string]} roles - list of roles.
 * @param {[string]} requiredRoles - the roles to check against.
 * @returns {boolean}
 */
const all = (roles: string[], requiredRoles: string[]) =>
    requiredRoles
        .map((role) => roles.includes(role))
        .reduce((allIncludes, includes) => (includes ? allIncludes : false), true)

export default all
