import React from 'react'
import cn from 'classnames'

import { PanelProps } from './types'
import s from './styles.module.css'
import Mask from '../Mask'

/**
 * Panel provides a white background with opacity
 * @param props 
 * @returns 
 */
function Panel(props: PanelProps) {
  const { children, className, rounded = "lg", variant = "light", notched = false } = props

  const classes = cn({
    [s.panel]: true,
    [s[`rounding_${rounded}`]]: true,
    [s[variant]]: true,
    [`${className}`]: true
  })

  const panel = (
    <div className={classes}>
      {children}
    </div>
  )

  if (notched) {
    return (
      <Mask className={classes}>
        {children}
      </Mask>
    )
  }

  return (
    <div className={classes}>
      {children}
    </div>
  )
}

export default Panel