import React, { useMemo } from 'react'

import Alert from '@/components/ui/Alert'
import Box from '@/components/ui/Box'
import Typography from '@/components/ui/Typography'
import Conditional from '@/components/ui/Conditional'

import { t } from '@/helpers'

import CategoryList from '../CategoryList'
import PostFeatured from '../PostFeatured'
import PostPreview from '../PostPreview'
import { SortButton } from '../Sort'

import { PostType } from '../types'
import { PostsProps } from './types'
import s from './styles.module.css'

/**
 * List all posts
 * @param props 
 * @returns 
 */
const Posts = (props: PostsProps) => {
  const { posts = [], categories = [], pathname } = props

  const featured = useMemo(() => posts.filter((post) => post.isFeatured), [posts])
  const previews = useMemo(() => posts.filter((post) => !post.isFeatured), [posts])

  return (
    <>
      <div className={s.header}>
        <CategoryList
          categories={categories} />
        <Box className="items-center sm:flex hidden">
          <Typography
            as="span"
            className="mr-4"
            uppercase>
            {t("pages.blog.sortButton")}:
          </Typography>
          <SortButton pathname={pathname} value="publishedDate">Date</SortButton>
        </Box>
      </div>
      <Conditional
        condition={featured.length === 0 && previews.length === 0}
        component={(
          <Alert type='warning'>No posts found.</Alert>
        )}>
        <div className={s.featured}>
          {
            featured.map((post: PostType) => <PostFeatured
              key={post.id}
              title={post.title}
              coverImage={post.coverImage}
              publishedDate={post.publishedDate}
              content={post.content}
              excerpt={post.excerpt}
              slug={post.slug}
              tags={post.tags} />
            )
          }
        </div>
        <div className={s.posts}>
          {
            previews.map((post: PostType) => <PostPreview
              key={post.id}
              title={post.title}
              coverImage={post.coverImage}
              publishedDate={post.publishedDate}
              content={post.content}
              excerpt={post.excerpt}
              slug={post.slug}
              tags={post.tags} />
            )
          }
        </div>
      </Conditional>

    </>
  )
}

export default Posts