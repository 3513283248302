export enum MegaMenuPanelImagePosition {
    LEFT = 'left',
    RIGHT = 'right',
}

export type MegaMenuPanelProps = {
    imagePosition?: MegaMenuPanelImagePosition
    image?: {
        alt: string
        src: string
        position?: MegaMenuPanelImagePosition
    }
    options: {
        label: string
        description: string
        href: string
    }[]
}
