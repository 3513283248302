import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const SlotPackIcon = (props: SVGIconProps) => {
  const { width = 37, height = 37, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} aria-label="Search" {...svgProps}>
      <path d="M31.6691 0H5.3309C2.39145 0 0 2.39144 0 5.33094V31.6691C0 34.6086 2.39145 37 5.3309 37H31.6691C34.6086 37 37 34.6086 37 31.6691V5.33094C37 2.39144 34.6086 0 31.6691 0ZM35.1636 31.6691C35.1636 33.5959 33.596 35.1636 31.6691 35.1636H5.3309C3.40401 35.1636 1.83644 33.596 1.83644 31.6691V5.33094C1.83644 3.40406 3.40401 1.83644 5.3309 1.83644H31.6691C33.5959 1.83644 35.1636 3.40401 35.1636 5.33094V31.6691Z" fill="white" />
      <path d="M22.6801 6.85453V5.74194C22.6801 4.60737 21.7571 3.68433 20.6225 3.68433H16.5699C15.4353 3.68433 14.5123 4.60737 14.5123 5.74194V6.85453C10.8773 7.51858 8.11328 10.7087 8.11328 14.5325V28.931C8.11328 31.0407 9.82961 32.757 11.9393 32.757H25.2532C27.3629 32.757 29.0792 31.0407 29.0792 28.931V14.5325C29.0792 10.7087 26.3152 7.51858 22.6802 6.85453H22.6801ZM16.5699 5.52082H20.6225C20.7445 5.52082 20.8437 5.62004 20.8437 5.742V6.72742H16.3487V5.742C16.3487 5.62004 16.4479 5.52082 16.5699 5.52082H16.5699ZM27.2427 28.931C27.2427 30.0281 26.3502 30.9206 25.2532 30.9206H11.9392C10.8422 30.9206 9.94966 30.0281 9.94966 28.931V14.5325C9.94966 11.2413 12.6272 8.56379 15.9183 8.56379H21.2741C24.5652 8.56379 27.2427 11.2413 27.2427 14.5325V28.931Z" fill="white" />
      <path d="M22.3172 18.061H14.7836C13.2951 18.061 12.084 19.2721 12.084 20.7607V25.8661C12.084 27.3547 13.2951 28.5657 14.7836 28.5657H22.3172C23.8057 28.5657 25.0168 27.3547 25.0168 25.8661V20.7607C25.0168 19.2721 23.8057 18.061 22.3172 18.061ZM23.1803 25.8661C23.1803 26.3421 22.7931 26.7293 22.3172 26.7293H14.7836C14.3077 26.7293 13.9204 26.3421 13.9204 25.8661V20.7607C13.9204 20.2847 14.3076 19.8975 14.7836 19.8975H22.3172C22.7931 19.8975 23.1803 20.2847 23.1803 20.7607V25.8661Z" fill="white" />
      <path d="M16.4574 16.7932H20.1955C21.5699 16.7932 22.688 15.6751 22.688 14.3007V12.2655C22.688 10.8911 21.5699 9.77295 20.1955 9.77295H16.4574C15.083 9.77295 13.9648 10.8911 13.9648 12.2655V14.3007C13.9648 15.675 15.0829 16.7932 16.4574 16.7932ZM15.8013 12.2655C15.8013 11.9037 16.0956 11.6094 16.4574 11.6094H20.1955C20.5573 11.6094 20.8515 11.9037 20.8515 12.2655V14.3007C20.8515 14.6625 20.5573 14.9567 20.1955 14.9567H16.4574C16.0956 14.9567 15.8013 14.6625 15.8013 14.3007V12.2655Z" fill="white" />
    </SVGIcon>
  )
}

export default SlotPackIcon
