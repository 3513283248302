import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import useInterval from 'hooks/useInterval';

import { TimerProps } from './types';

const Timer = (props: TimerProps) => {
  const {
    onTimeUp,
    seconds = 600,
    timerLowSeconds = 60,
    className,
    timerLowClassName = "text-skin-danger",
    isTimerRunning
  } = props
  const [time, setTime] = useState(seconds)
  const [timerClasses, setTimerClasses] = useState<string>()
  const [isRunning, setIsRunning] = useState(isTimerRunning)

  useEffect(() => {
    if (!isTimerRunning) {
      setTime(seconds) // Reset time to 10 minutes
    }
    if (isTimerRunning !== isRunning)
      setIsRunning(isTimerRunning)
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  }, [isTimerRunning])

  useInterval(() => {
    if (isRunning) {
      if (time > 0) {
        setTime(time - 1)

        // Change color during the last minute
        if (time <= timerLowSeconds && timerLowClassName && timerClasses !== timerLowClassName) {
          setTimerClasses(timerLowClassName)
        }
      } else {
        // Timer has reached 0, trigger an event
        if (onTimeUp)
          onTimeUp()

        setIsRunning(false)
        setTime(seconds) // Reset time 
      }
    }
  }, isRunning ? 1000 : null)

  const formatTime = (secs: number) => {
    const minutes = Math.floor(secs / 60);
    const remainingSeconds = secs % 60;

    return !isRunning ? '0:00' : `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  return (
    <span className={cn(className, timerClasses)}>
      {formatTime(time)}
    </span>
  );
};

export default Timer;