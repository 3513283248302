import { useEffect, useRef } from 'react'
import { ConfettiParticle, ConfettiProps } from './types'
import s from './styles.module.css'

const SimpleConfetti = (props: ConfettiProps) => {
  const {
    colors = ['#ff0', '#ff6347', '#87ceeb', '#32cd32', '#ff69b4'],
    confettiCount = 100,
    shapes = ['circle', 'square', 'triangle'],
  } = props

  const canvasRef = useRef<HTMLCanvasElement>(null)
  const animationFrameRequestRef = useRef<number>()
  const confettiParticles: ConfettiParticle[] = []

  useEffect(() => {
    const canvas = canvasRef.current
    if (!canvas) return

    const ctx = canvas.getContext('2d')
    if (!ctx) return

    const W = window.innerWidth
    const H = window.innerHeight

    canvas.width = W
    canvas.height = H

    for (let i = 0; i < confettiCount; i++) {
      confettiParticles.push({
        x: Math.random() * W,
        y: Math.random() * H - H,
        r: Math.random() * 5 + 2,
        d: Math.random() * confettiCount,
        vx: Math.random() * 3 - 1.5,
        vy: Math.random() * 3 + 2,
        rotation: Math.random() * 360,
        rotationSpeed: Math.random() * 2 - 1,
        color: colors[Math.floor(Math.random() * colors.length)],
        shape: shapes[Math.floor(Math.random() * shapes.length)],
      })
    }

    const drawConfetti = () => {
      ctx.clearRect(0, 0, W, H)
      confettiParticles.forEach(particle => {
        ctx.save()
        ctx.translate(particle.x, particle.y)
        ctx.rotate((particle.rotation * Math.PI) / 180)

        ctx.beginPath()
        ctx.fillStyle = particle.color

        switch (particle.shape) {
          case 'circle':
            ctx.arc(0, 0, particle.r, 0, Math.PI * 2, false)
            break
          case 'square':
            ctx.rect(-particle.r, -particle.r, particle.r * 2, particle.r * 2)
            break
          case 'triangle':
            ctx.moveTo(0, -particle.r)
            ctx.lineTo(particle.r, particle.r)
            ctx.lineTo(-particle.r, particle.r)
            ctx.closePath()
            break
          default:
            break
        }

        ctx.fill()
        ctx.restore()

        // Update particle position and rotation
        particle.x += particle.vx
        particle.y += particle.vy
        // Apply gravity
        particle.vy += 0.005
        particle.rotation += particle.rotationSpeed

        // Reset particle if it falls out of view
        if (particle.y > H + 10) {
          particle.y = -10
          particle.x = Math.random() * W
          particle.vx = Math.random() * 3 - 1.5
          particle.vy = Math.random() * 3 + 2
          particle.shape = shapes[Math.floor(Math.random() * shapes.length)]
        }
      })
    }

    const updateConfetti = () => {
      drawConfetti()
      animationFrameRequestRef.current = requestAnimationFrame(updateConfetti)
    }

    updateConfetti()

    return () => {
      if (animationFrameRequestRef.current)
        window.cancelAnimationFrame(animationFrameRequestRef.current)
    }
  }, [shapes, colors, confettiCount])

  return <canvas ref={canvasRef} className={s.confetti_container} />
}

export default SimpleConfetti
