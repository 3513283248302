import React, { useState, useEffect, useRef } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import classNames from 'classnames'

import Mask from '@/components/ui/Mask'
import Image from '@/components/ui/Image'
import Conditional from '@/components/ui/Conditional'
import { UserAvatarType } from '@/types/Api'
import { createCroppedImage } from 'helpers/filestack'

import { AvatarProps } from './types'
import s from './styles.module.css'

/**
 * Get avatar url
 * @param avatar 
 * @param original 
 * @returns 
 */
const getAvatarUrl = (avatar?: UserAvatarType, original = false) => {
  if (!avatar || !avatar.url)
    return '/_assets/account/default-avatar.png'

  const { cropData, url } = avatar

  if (original)
    return url

  return createCroppedImage(url, cropData)
}

/**
 * User profile avatar
 * @param props 
 * @returns 
 */
const Avatar = (props: AvatarProps) => {
  const { avatar, className, size, width, height, imageClassName, highlight, children, ...imageProps } = props

  // We use this to avoid animating initially 
  const isMounted = useRef(false);
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  const componentWrapperClasses = classNames(s.wrapper, className)
  const avatarClasses = classNames(s.avatar, imageClassName)

  const imageSrc = getAvatarUrl(avatar)

  // Using a loading state fixes image popping instead of animating
  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  useEffect(() => {
    if (imageLoaded)
      isMounted.current = true;
  }, [imageLoaded])

  useEffect(() => {
    setImageLoaded(false)
  }, [imageSrc])

  const variants = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { duration: 0.25 } },
    exit: { opacity: 0, transition: { duration: 0.25 } },
  };

  return (
    <div className={componentWrapperClasses}>
      <Conditional
        wrap
        condition={highlight && imageLoaded}
        component={({ children: innerChildren }) => (
          <Mask className={classNames(s.border_mask, size && s[size])}>
            {innerChildren}
          </Mask>
        )}>
        <Mask className={size && s[size]}>
          <div className={classNames(s.avatar_wrapper, size && s[size])}>
            <AnimatePresence initial={false}>
              <motion.div
                className="relative w-full h-full"
                key={imageSrc}
                variants={variants}
                initial={isMounted.current ? "initial" : false}
                // eslint-disable-next-line no-nested-ternary
                animate={!isMounted.current ? false : imageLoaded ? "animate" : "initial"}
                exit="exit">
                <Image
                  draggable={false}
                  fill
                  className={avatarClasses}
                  src={imageSrc}
                  priority
                  onLoad={handleImageLoad}
                  sizes="(max-width: 768px) 100vw,
                        (max-width: 1200px) 50vw,
                        33vw"
                  {...imageProps} />
              </motion.div>
            </AnimatePresence>
          </div>
        </Mask>
      </Conditional>
      {imageLoaded && children}
    </div>
  )
}

export default Avatar