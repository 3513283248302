import React from 'react'
import Head from 'next/head';

const StructuredData = (props: { data: unknown }) => {
  const { data } = props

  return (
    <Head>
      <script
        key="structured-data"
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
      />
    </Head>
  );
}

export default StructuredData