import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import useHubSpotForm from 'hooks/useHubSpotForm/useHubSpotForm'
import { Input } from '@/components/ui/Form'
import Button from '@/components/ui/Button'
import t from 'helpers/translation/getTranslation'

import s from './styles.module.css'

/**
 * Newsletter sign up
 * @param props 
 * @returns 
 */
const NewsletterForm = () => {
  // ID for honeypot to reduce spam bots
  const honeypotId = "solace_ravine"

  const { register, handleSubmit, reset } = useForm()
  const { onSubmit, error, data } = useHubSpotForm("20499765", "92a74046-750a-4796-a43b-80072c3c10d5", honeypotId)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFormSubmit = (formData: Record<string, any>) => {
    onSubmit(formData)
  }

  useEffect(() => {
    if (data) {
      reset()
      toast.success(t("footer.newletterSubmitSuccess"))
    }
    else if (error) {
      toast.error(t("errors.genericContactAdmin"))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, reset])

  return (
    <>
      <form className={s.newsletter_form} onSubmit={handleSubmit(onFormSubmit)}>
        <div className={s.input_container}>
          <Input
            type="email"
            id="newsletter_email"
            placeholder={t("footer.newsletterPlaceholder") as string}
            className={s.input}
            {...register("email", { required: true })} />

          <input
            type="checkbox"
            id={honeypotId}
            className="hidden"
            {...register(honeypotId)} />

          <Button
            type="submit"
            className={s.submit}>
            {t("footer.newsletterSubmitButton")}
          </Button>
        </div>
      </form>
    </>
  )
}

export default NewsletterForm