/* eslint-disable no-param-reassign */
import React, { forwardRef, useMemo } from 'react'
import { AnimatePresence, motion, Variants } from "framer-motion"

import { DrawerAnimationVariants, DrawerProps } from './types'

/**
 * Update varient configurations to animate on/off screen based on direction
 * @param direction 
 * @returns 
 */
const getVariants = (direction: string): Variants => {
  const variants = {
    isVisible: {
      display: "initial",
      opacity: 1,
      transition: {
        type: "spring",
        duration: 0.5,
        bounce: 0.25
      }
    },
    isHidden: {
      opacity: 0,
      transition: {
        ease: "easeOut"
      },
      transitionEnd: {
        display: "none"
      }
    }
  } as DrawerAnimationVariants

  // eslint-disable-next-line default-case
  switch (direction) {
    case "top":
      variants.isVisible.y = "0%"
      variants.isHidden.y = "-100%"
      break
    case "bottom":
      variants.isVisible.y = "0%"
      variants.isHidden.y = "100%"
      break
    case "left":
      variants.isVisible.x = "0%"
      variants.isHidden.x = "-100%"
      break
    case "right":
      variants.isVisible.x = "0%"
      variants.isHidden.x = "100%"
      break
  }

  return variants
}

type Ref = HTMLDivElement

/**
 * Slide in drawer from defined location
 * @param props 
 * @returns 
 */
const Drawer = forwardRef<Ref, DrawerProps>((props, ref) => {
  const { disableAnimation, children, className, isVisible = false, direction = "bottom" } = props

  const variant = useMemo(() => getVariants(direction), [direction])
  const visibleAnimate = isVisible ? "isVisible" : "isHidden"
  const animate = disableAnimation ? undefined : visibleAnimate

  return (
    <>
      <AnimatePresence initial={false}>
        <motion.div
          ref={ref}
          tabIndex={-1}
          animate={animate}
          className={className}
          variants={variant}>
          {children}
        </motion.div>
      </AnimatePresence>
    </>
  )
})

export default Drawer