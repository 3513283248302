import React from 'react'
import cx from 'classnames'

import s from './styles.module.css'
import { StyledRadioButtonProps } from './types'

const StyledRadioButtonGroup = (props: StyledRadioButtonProps) => {
  const {
    className,
    hasError,
    children,
  } = props

  const inputClasses = cx(
    s.radio_button_group,
    hasError && s.error,
    className
  )

  return (
    <div className={inputClasses}>
      {children}
    </div>
  )
}

export default StyledRadioButtonGroup