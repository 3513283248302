import React from 'react'
import { AnimatePresence, motion } from "framer-motion";
import { useAccordion } from '../Accordion';
import { AccordionPanelProps } from './types'

import s from './styles.module.css'

function AccordionPanel(props: AccordionPanelProps) {
  const { children } = props
  const { isActive } = useAccordion();

  return (
    <AnimatePresence initial={false}>
      {isActive && (
        <motion.div
          initial={{
            height: 0,
            opacity: 0
          }}
          animate={{
            height: "auto",
            opacity: 1
          }}
          exit={{
            height: 0,
            opacity: 0,
            transition: {
              height: {
                duration: 0.4,
                ease: "easeOut",
              }
            }
          }}
          transition={{ type: "spring", duration: 0.4, bounce: 0 }}
        >
          <div className={s.accordion_panel}>{children}</div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default AccordionPanel