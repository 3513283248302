import React from 'react';
import cn from 'classnames';
import Loader from '../Loader';
import Typography from '../Typography';

import { LoadingWrapperProps } from './types';
import s from './styles.module.css'

const LoadingWrapper = (props: LoadingWrapperProps) => {
  const { children, variant = "default", className, loaderClassName, size = "sm", message = '', isLoading = true, renderChildrenHidden = false } = props

  return (
    <div className={cn(s.loading_wrapper, size && s[size], className)}>
      {isLoading && (
        <div className={loaderClassName}>
          <div className={s.loading}>
            <Loader
              size={size}
              variant={variant}
              className={s.loader}
              dotClassName={s.loader_dots} />
          </div>
          {
            message &&
            <Typography className={s.loading_message}>{message}</Typography>
          }
        </div>
      )}
      {
        renderChildrenHidden ?
          <div className={cn(isLoading && s.loader_children_hidden)}>{children}</div>
          :
          !isLoading && children
      }
    </div>
  );
};

export default LoadingWrapper;