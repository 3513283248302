import React, { PropsWithChildren } from 'react'

import { ConditionalProps } from './types';

/**
 * Component to streamline conditional if statements in render
 * @param props 
 * @returns 
 */
const Conditional = (props: ConditionalProps) => {
  const { condition, wrap = false, component, children } = props

  if (condition)
    return (
      <>
        {wrap && typeof component === "function" ? component({ children }) : component}
      </>
    )

  return <>{children}</>
}

Conditional.defaultProps = {
  condition: false,
  wrap: false,
  component: ({ children }: PropsWithChildren) => children,
  children: null
}

export default Conditional