import React, { useMemo } from 'react'
import { motion } from 'framer-motion'

import Typography from '../../Typography'
import { DoubleArrow } from '../../Icon'
import NavLink from '../../Navigation/NavLink'
import Image from '../../Image'
import Link from '../../Link'

import { GallerySlideProps } from './types'
import s from './styles.module.css'

const MotionLink = motion(Link)

const GallerySlide = (props: GallerySlideProps) => {
  const { item, previousUrl, backLabel = "Go Back", galleryUrls = [] } = props
  const { media, content, title } = item
  const { src, alt } = media

  const current = useMemo(() =>
    galleryUrls.findIndex((galleryNav) => galleryNav.active === true),
    [galleryUrls])

  const nextSlideUrl =
    useMemo(() =>
      current + 1 < galleryUrls.length ? galleryUrls[current + 1] : galleryUrls[0],
      [current, galleryUrls])

  const prevSlideUrl =
    useMemo(() =>
      current - 1 < 0 ? galleryUrls[galleryUrls.length - 1] : galleryUrls[current - 1],
      [current, galleryUrls])

  return (
    <>
      <Link
        className={s.link}
        href={previousUrl}>
        <DoubleArrow rotation="90" />
        {backLabel}
      </Link>
      <div className={s.image_wrapper}>
        <MotionLink
          whileHover={{ scale: 1.4 }}
          whileTap={{ scale: 1 }}
          className={s.gallery_nav_prev}
          href={prevSlideUrl.url}
          aria-label="Move to previous image">
          <DoubleArrow rotation="90" />
        </MotionLink>

        <Image
          src={src}
          alt={alt}
          className={s.image}
          width="3840"
          height="2160" />

        <MotionLink
          whileHover={{ scale: 1.4 }}
          whileTap={{ scale: 1 }}
          className={s.gallery_nav_next}
          href={nextSlideUrl.url}
          aria-label="Move to next image">
          <DoubleArrow rotation="-90" />
        </MotionLink>
      </div>
      <div className={s.content_wrapper}>
        <div className={s.title}>
          <nav className={s.gallery_links}>
            {
              galleryUrls.map((galleryNav) => (
                <NavLink
                  key={galleryNav.url}
                  unstyled
                  href={galleryNav.url}
                  className={s.gallery_link}
                  activeClassName={s.gallery_link_active} />)
              )
            }
          </nav>
          <Typography
            as="h2"
            variant="primary">
            {title}
          </Typography>
        </div>
        <Typography
          as="p"
          className={s.content}
          variant="secondary">
          {content}
        </Typography>
      </div>
    </>
  )
}

export default GallerySlide