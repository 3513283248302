import { ArrayParam, withDefault } from 'use-query-params'
import { UseFiltersFilterConfiguration } from 'hooks/useFilters'

export const LOCAL_STORAGE_MARKETPLACE_AFFILIATE_LINK_GUID_ID = 'ccw_affiliate_guid'

export const FILTER_VALUES_MARKETPLACE = {
    tags: [
        {
            value: 'apparel',
            displayName: 'Apparel',
        },
        {
            value: 'packs',
            displayName: 'Packs',
        },
        {
            value: 'ships',
            displayName: 'Ships',
        },
    ],
    rarity: [
        {
            value: 'common',
            displayName: 'Common',
        },
        {
            value: 'uncommon',
            displayName: 'Uncommon',
        },
        {
            value: 'rare',
            displayName: 'Rare',
        },
        {
            value: 'legendary',
            displayName: 'Legendary',
        },
        {
            value: 'mythic',
            displayName: 'Mythic',
        },
        {
            value: 'any',
            displayName: 'Variants',
        },
    ],
}

export const FILTER_CONFIGURATION_MARKETPLACE = [
    {
        id: 'tags',
        alias: 'tags',
        displayName: 'Category',
        type: withDefault(ArrayParam, []),
        multiple: true,
        caseSensitive: true,
    },
    {
        id: 'rarity',
        alias: 'rarity',
        displayName: 'Rarity',
        type: withDefault(ArrayParam, []),
        multiple: true,
        caseSensitive: true,
    },
] as UseFiltersFilterConfiguration
