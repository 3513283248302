import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { t } from '@/helpers'

const useConfirmRedirectIfDirty = (isDirty: boolean) => {
    const router = useRouter()

    // prompt the user if they try and leave with unsaved changes
    useEffect(() => {
        const handleWindowClose = (e: BeforeUnloadEvent) => {
            if (!isDirty) return
            e.preventDefault()

            e.returnValue = t('forms.unsavedChanges') as string
        }

        const handleBrowseAway = () => {
            if (!isDirty) return

            // eslint-disable-next-line no-alert
            if (window.confirm(t('forms.unsavedChanges') as string)) return

            router.events.emit('routeChangeError')
        }

        window.addEventListener('beforeunload', handleWindowClose)
        router.events.on('routeChangeStart', handleBrowseAway)

        return () => {
            window.removeEventListener('beforeunload', handleWindowClose)
            router.events.off('routeChangeStart', handleBrowseAway)
        }
    }, [isDirty, router.events])
}

export default useConfirmRedirectIfDirty
