import classNames from 'classnames'
import React from 'react'

import s from './styles.module.css'

/**
 * Simple divider
 * @param props 
 * @returns 
 */
const Divider = (props: { className?: string }) => {
  const { className } = props

  const classes = classNames(s.divider, className)

  return <hr className={classes} />
}

Divider.defaultProps = {
  className: null
}

export default Divider
