import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const Tree = (props: SVGIconProps) => {
  const { width = 49, height = 63, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} aria-label="Tree Icon" {...svgProps}>
      <path d="M48.1726 52.8433L38.3398 35.8762C37.6843 34.7511 38.4981 33.3559 39.8091 33.3559H41.3235C42.6346 33.3559 43.4483 31.9607 42.7928 30.8356L25.433 0.906953C24.9357 0.0518489 23.6925 0.0518489 23.1952 0.906953L5.83534 30.8356C5.17982 31.9607 5.99357 33.3559 7.3046 33.3559H8.81906C10.1301 33.3559 10.9438 34.7511 10.2883 35.8762L0.4556 52.8433C-0.0416871 53.6984 0.591224 54.7785 1.5858 54.7785H17.7024C18.4257 54.7785 18.9908 55.3636 18.9908 56.0612V61.4168C18.9908 62.1369 19.5785 62.6995 20.2793 62.6995H28.3489C29.0722 62.6995 29.6373 62.1144 29.6373 61.4168V56.0612C29.6373 55.3411 30.225 54.7785 30.9257 54.7785H47.0424C48.0369 54.7785 48.6698 53.6984 48.1726 52.8433Z" fill="currentcolor" />
    </SVGIcon>
  )
}


export default Tree