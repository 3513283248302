import React from 'react'
import cx from 'classnames';

import s from './styles.module.css'
import { Rotation, SVGIconProps } from './types';

function getRotation(rotation: Rotation): string {
  switch (rotation) {
    case "90": return "rotate-90"
    case "-90": return "-rotate-90"
    case "180": return "rotate-180"
    default: return ""
  }
}

/**
 * SVG wrapper
 * @param props 
 * @returns 
 */
const SVGIcon = (props: SVGIconProps) => {
  const { width = 22, height = 22, className, children, rotation = "0", ...rest } = props

  const classes = cx(s.icon, className, getRotation(rotation))

  return (
    <svg
      width={width}
      height={height}
      className={classes}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}>
      {children}
    </svg>
  );
}

export default SVGIcon
