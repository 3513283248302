import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const PriceTag = (props: SVGIconProps) => {
  const { width = 42, height = 42, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} {...svgProps}>
      <path d="M16.686 41.5326C15.9963 41.5326 15.3052 41.2698 14.7795 40.7442L1.75319 27.7178C0.7019 26.6665 0.7019 24.9575 1.75319 23.9062L23.9036 1.75447C24.4186 1.2395 25.1363 0.936655 25.8594 0.966006L38.6483 1.20481C40.1038 1.2315 41.2659 2.39352 41.2925 3.85038L41.5314 16.6379C41.5447 17.3664 41.2565 18.0801 40.7429 18.5938L18.5925 40.7442C18.0668 41.2698 17.3771 41.5326 16.686 41.5326ZM25.8101 1.86787C25.3378 1.86787 24.8775 2.05865 24.5426 2.39352L2.3909 24.5439C1.69182 25.243 1.69182 26.3797 2.3909 27.0788L15.4173 40.1051C16.095 40.7829 17.2757 40.7829 17.9534 40.1051L40.1038 17.9547C40.4454 17.6118 40.6375 17.1382 40.6281 16.6539L40.3893 3.86639C40.372 2.89781 39.5995 2.12536 38.631 2.10801L25.8421 1.86921C25.8314 1.86921 25.8194 1.86921 25.8101 1.86921V1.86787Z" fill="black" />
      <path d="M40.1038 17.9547L17.9534 40.1051C17.2757 40.7829 16.095 40.7829 15.4173 40.1051L2.3909 27.0788C1.69182 26.3797 1.69182 25.243 2.3909 24.5439L24.5426 2.39352C24.8775 2.05865 25.3378 1.86787 25.8101 1.86787V1.86921C25.8194 1.86921 25.8314 1.86921 25.8421 1.86921L38.631 2.10801C39.5995 2.12536 40.372 2.89781 40.3893 3.86639L40.6281 16.6539C40.6375 17.1382 40.4454 17.6118 40.1038 17.9547ZM40.1038 17.9547L40.4227 18.2736M16.686 41.5326C15.9963 41.5326 15.3052 41.2698 14.7795 40.7442L1.75319 27.7178C0.7019 26.6665 0.7019 24.9575 1.75319 23.9062L23.9036 1.75447C24.4186 1.2395 25.1363 0.936655 25.8594 0.966006L38.6483 1.20481C40.1038 1.2315 41.2659 2.39352 41.2925 3.85038L41.5314 16.6379C41.5447 17.3664 41.2565 18.0801 40.7429 18.5938L18.5925 40.7442C18.0668 41.2698 17.3771 41.5326 16.686 41.5326Z" stroke="black" strokeWidth="0.266824" strokeMiterlimit="10" />
      <path d="M34.3604 11.116C33.5973 11.116 32.8342 10.8252 32.2538 10.2448C31.0918 9.0828 31.0918 7.19235 32.2538 6.03033C33.4159 4.86831 35.3063 4.86831 36.4683 6.03033C37.6303 7.19235 37.6303 9.08413 36.4683 10.2448C35.8867 10.8265 35.1235 11.116 34.3604 11.116ZM34.3604 6.06101C33.8294 6.06101 33.2958 6.2638 32.8915 6.66804C32.0817 7.47785 32.0817 8.79596 32.8915 9.60577C33.7014 10.4156 35.0195 10.4156 35.8293 9.60577C36.6391 8.79596 36.6391 7.47785 35.8293 6.66804C35.425 6.26246 34.8927 6.06101 34.3604 6.06101Z" fill="black" stroke="black" strokeWidth="0.266824" strokeMiterlimit="10" />
      <path d="M16.5753 20.7777C19.2862 23.4886 22.5015 17.373 25.59 20.4629C27.0521 21.9251 27.1656 23.8795 25.7661 25.5565L26.5985 26.389C26.788 26.5785 26.788 26.8306 26.5985 27.0201C26.4091 27.2095 26.157 27.2095 25.9688 27.0201L25.1497 26.2009C23.7622 27.411 22.2373 27.3736 21.1153 26.9574C20.9646 26.9067 20.7378 26.8306 20.611 26.7052C20.4349 26.5291 20.4349 26.2516 20.611 26.0755C20.8258 25.8607 21.078 25.9621 21.178 26.0128C22.5015 26.5798 23.7622 26.3277 24.7708 25.3191C26.1583 23.9316 26.1583 22.2933 24.9602 21.0952C22.4388 18.5737 19.2235 24.688 15.9456 21.4101C14.7222 20.1867 14.6088 18.3336 15.8202 16.8714L15.001 16.0522C14.8116 15.8628 14.8116 15.6107 15.001 15.4225C15.1905 15.2344 15.4426 15.2331 15.6321 15.4225L16.4512 16.2417C17.6359 15.2584 18.9474 15.1317 20.044 15.4225C20.2201 15.4732 20.3722 15.4986 20.4856 15.612C20.6617 15.7881 20.6617 16.0656 20.4856 16.2417C20.3589 16.3684 20.1454 16.4058 19.7919 16.3044C19.0101 16.0776 17.8374 16.115 16.7661 17.1876C15.6307 18.3229 15.6307 19.8345 16.5766 20.7804L16.5753 20.7777Z" fill="black" stroke="black" strokeWidth="0.266824" strokeMiterlimit="10" />
    </SVGIcon>
  )
}

export default PriceTag