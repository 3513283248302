import React, { forwardRef } from 'react'
import cx from 'classnames'

import s from './styles.module.css'
import { InputProps } from './types'

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    className,
    hasError,
    inputSize,
    type = "text",
    ...inputProps
  } = props

  const inputClasses = cx(
    s.input,
    hasError && s.error,
    inputSize && s[inputSize],
    className
  )

  return (
    <input
      ref={ref}
      type={type}
      className={inputClasses}
      {...inputProps} />
  )
})

export default Input