import { getProperty } from 'dot-prop'
import parseString from './parseString'

// Review if we move forward with other languages
import lang from '../../lang/en.json'

/**
 * Return interpolated string
 * @param path
 * @param args
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const t = (path: string, ...args: any[]): string => {
    const str: string = getProperty(lang, path)

    if (str && args && args.length > 0) {
        return parseString(str, ...args).join('')
    }

    return str
}

export default t
