import cx from 'classnames'
import s from './styles.module.css'
import { UseVariantHookProps } from './types'

/* eslint-disable no-restricted-globals */
const useVariant = (props: UseVariantHookProps): string => {
    const { variant = 'default', color, background, hover, filled, outline, pseudoElement } = props

    const variantType = variant.toLowerCase()
    const colorClassName = `${variantType}_color`
    const backgroundClassName = `${variantType}_bg`
    const filledClassName = `${variantType}_filled`
    const outlineClassName = `${variantType}_outline`

    const pseudo = pseudoElement ? `_${pseudoElement}` : ''

    return cx({
        [`${s.theme}`]: true,
        [`${s[`${colorClassName}${pseudo}`]}`]: color,
        [`${s[`${backgroundClassName}${pseudo}`]}`]: background,
        [`${s[`${filledClassName}${pseudo}`]}`]: filled,
        [`${s.hover}`]: hover,
        [`${s.outline} ${s[`${outlineClassName}${pseudo}`]}`]: outline,
    })
}

export default useVariant
