function timeout(ms: number): Promise<never> {
    return new Promise((_, reject) => {
        setTimeout(() => {
            reject(new Error('Timeout'))
        }, ms)
    })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function awaitTimeout<T>(fn: () => Promise<any>, seconds: number): Promise<T> {
    const result = await Promise.race([fn(), timeout(seconds * 1000)])

    return result
}

export default awaitTimeout
