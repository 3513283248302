import { DefaultSeoProps } from 'next-seo'

const config: DefaultSeoProps = {
    title: 'Explore a new cutting edge open world MMO',
    titleTemplate: '%s | Cornucopias',
    canonical: process.env.NEXT_PUBLIC_SITE_URL,
    description:
        'Join a player-driven MMORPG in a unique world of adventure and economics. Hone your skills as a trader, artisan, or entrepreneur. Explore Cornucopian land masses, play mini-games, own digital assets, and discover the truth beyond the floating domes.',
    openGraph: {
        type: 'website',
        locale: 'en_US',
        url: process.env.NEXT_PUBLIC_SITE_URL,
        siteName: 'Cornucopias',
        images: [
            {
                url: 'https://cdn.filestackcontent.com/resize=width:1280,height:720/0JYEvXhRSEKWEFm4CFfp',
                width: 1280,
                height: 720,
                alt: 'Cornucopias Game',
            },
        ],
    },
    twitter: {
        handle: '@CornucopiasGame',
        site: '@CornucopiasGame',
        cardType: 'summary_large_image',
    },
    additionalLinkTags: [
        {
            rel: 'icon',
            href: '/_assets/favicon/apple-touch-icon.png',
            sizes: '180x180',
        },
        {
            rel: 'icon',
            href: '/_assets/favicon/favicon-32x32.png',
            sizes: '32x32',
        },
        {
            rel: 'icon',
            href: '/_assets/favicon/favicon-16x16.png',
            sizes: '16x16',
        },
        {
            rel: 'mask-icon',
            href: '/_assets/favicon/site.webmanifest',
        },
        {
            rel: 'shortcut icon',
            href: '/_assets/favicon/favicon.ico',
            color: '#000000',
        },
        {
            rel: 'mask-icon',
            href: '/_assets/favicon/safari-pinned-tab.svg',
            color: '#000000',
        },
    ],
    additionalMetaTags: [
        {
            name: 'msapplication-TileColor',
            content: '#000000',
        },
        {
            name: 'msapplication-config',
            content: '/_assets/favicon/browserconfig.xml',
        },
        {
            name: 'theme-color',
            content: '#000000',
        },
    ],
}

export default config
