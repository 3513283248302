import { useEffect, useRef } from 'react'

import useIsomorphicLayoutEffect from './useIsomorpicLayoutEffect'

/**
 * Recall function every X milliseconds
 * @param callback
 * @param delay
 */
function useInterval(callback: () => void, delay: number | null, callInitial = false) {
    const savedCallback = useRef(callback)

    // Remember the latest callback if it changes.
    useIsomorphicLayoutEffect(() => {
        savedCallback.current = callback
    }, [callback])

    // Set up the interval.
    useEffect(() => {
        // Don't schedule if no delay is specified.
        // Note: 0 is a valid value for delay.
        if (!delay && delay !== 0) {
            return
        }

        const id = setInterval(() => savedCallback.current(), delay)

        if (callInitial) savedCallback.current()

        return () => clearInterval(id)
    }, [delay, callInitial])
}

export default useInterval
