import React from 'react'

import Link from '@/components/ui/Link'
import Dropdown from '@/components/ui/Dropdown'
import Typography from '@/components/ui/Typography'
import { DoubleArrow } from '@/components/ui/Icon'
import MenuItem from '@/components/ui/Dropdown/MenuItem'

import { buildPath, t } from '@/helpers'
import { ROUTE_BLOG_BASE, ROUTE_BLOG_CATEGORY } from '@/consts'

import s from './styles.module.css'
import { CategoryListProps } from './types'

/**
 * Category List for blog
 * @param props 
 * @returns 
 */
const CategoryList = (props: CategoryListProps) => {
  const { categories = [] } = props

  return (
    <div>
      <div className={s.list}>
        <Link
          href={buildPath(ROUTE_BLOG_BASE)}>
          {t("pages.blog.allCategoriesLabel")}
        </Link>
        {
          categories.map((category) => {
            const { displayName, slug } = category

            return (
              <Link
                key={slug}
                href={buildPath(ROUTE_BLOG_CATEGORY, slug)}>
                {displayName}
              </Link>
            )
          })
        }
      </div>
      <div className={s.list_mobile}>

        <Dropdown
          menuClassName="!block"
          buttonClassName={s.menu_button}
          menuItemsClassName={s.menu_items}
          label={(
            <>
              <Typography
                className={s.menu_button_label}
                as="span"
                uppercase>
                Category
              </Typography>
              <DoubleArrow />
            </>
          )}>
          <MenuItem className={s.menu_item}>
            {
              ({ close }) => (
                <Link
                  onClick={close}
                  className={s.menu_item_link}
                  href={buildPath(ROUTE_BLOG_BASE)}>
                  {t("pages.blog.allCategoriesLabel")}
                </Link>
              )
            }
          </MenuItem>
          {
            categories.map((category) => {
              const { displayName, slug } = category

              return (
                <MenuItem
                  key={slug}
                  className={s.menu_item}>
                  {
                    ({ close }) => (
                      <Link
                        onClick={close}
                        className={s.menu_item_link}
                        href={buildPath(ROUTE_BLOG_CATEGORY, slug)}>
                        {displayName}
                      </Link>
                    )
                  }
                </MenuItem>
              )
            })
          }
        </Dropdown>
      </div>
    </div>
  )
}

export default CategoryList