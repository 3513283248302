import React, { useMemo } from 'react'
import cn from 'classnames'

import Button from '../../Button'
import { DoubleArrow } from '../../Icon'
import { PaginationProps } from './types'

import s from './styles.module.css'

const MAX_VISIBLE_PAGES = 7
const SEPARATELY_RENDERED_PAGES = 2
const MAX_INTERNAL_PAGES = MAX_VISIBLE_PAGES - SEPARATELY_RENDERED_PAGES
const FAST_TRAVEL_STEP = 3

const Pagination = (props: PaginationProps) => {
  const { offset, limit, total, setOffset, showTotal = true, totalText, className } = props

  const pageCount = useMemo(() => (Math.ceil(total / limit)), [total, limit])
  const internalPageCount = pageCount - SEPARATELY_RENDERED_PAGES

  const currentPage = useMemo(() => (offset / limit) + 1, [offset, limit])

  const isPrevDisplayed = useMemo(() => (
    pageCount > MAX_VISIBLE_PAGES && currentPage > MAX_INTERNAL_PAGES
  ), [pageCount, currentPage])
  const isNextDisplayed = useMemo(() => (
    pageCount > MAX_VISIBLE_PAGES && currentPage < internalPageCount
  ), [pageCount, currentPage, internalPageCount])

  const internalPageRange = useMemo(() => {
    if (internalPageCount < 0) {
      return []
    }
    const itemsLength = Math.min(MAX_INTERNAL_PAGES, internalPageCount)

    return Array(itemsLength).fill(0).map((_, index) => {
      if (isPrevDisplayed) {
        if (isNextDisplayed) {

          const maxRange = currentPage >= internalPageCount - 1 ? SEPARATELY_RENDERED_PAGES + 1 : SEPARATELY_RENDERED_PAGES

          return index + currentPage - maxRange
        }

        return index + pageCount - MAX_INTERNAL_PAGES
      }

      return index + SEPARATELY_RENDERED_PAGES
    })
  }, [internalPageCount, isPrevDisplayed, isNextDisplayed, pageCount, currentPage])

  const onChangePage = (page: number) => {
    const pageOffset = limit * (page - 1)

    if (setOffset)
      setOffset(pageOffset)
  }

  const renderPrevFastTravel = () => (
    <li>
      <Button
        onClick={() => onChangePage(currentPage - FAST_TRAVEL_STEP)}
        className={cn(s.item, s.inactiveItem, s.dots)}
      >
        <DoubleArrow rotation="90" />
      </Button>
    </li>
  )

  const renderNextFastTravel = () => (
    <li>
      <Button
        onClick={() => onChangePage(currentPage + FAST_TRAVEL_STEP)}
        className={cn(s.item, s.inactiveItem, s.dots)}
      >
        <DoubleArrow rotation="-90" />
      </Button>
    </li>
  )

  const renderInternalPages = (page: number) => (
    <li
      key={page}
    >
      <Button
        onClick={() => onChangePage(page)}
        className={cn(s.item, {
          [s.activeItem]: currentPage === page,
          [s.inactiveItem]: currentPage !== page
        })}
      >
        {page}
      </Button>
    </li>
  )

  return (
    <div className={cn(s.wrapper, className)}>
      {
        showTotal &&
        <p>
          {
            totalText ? totalText(total) : `Total ${total} Item${total !== 1 ? 's' : ''}`
          }
        </p>
      }
      {pageCount > 1 && (
        <ul className={s.list}>
          <li>
            <Button
              onClick={() => onChangePage(1)}
              className={cn(s.item, {
                [s.activeItem]: currentPage === 1,
                [s.inactiveItem]: currentPage !== 1
              })}
            >
              1
            </Button>
          </li>
          {isPrevDisplayed && renderPrevFastTravel()}
          {internalPageRange.map(renderInternalPages)}
          {isNextDisplayed && renderNextFastTravel()}
          <li>
            <Button
              onClick={() => onChangePage(pageCount)}
              className={cn(s.item, {
                [s.activeItem]: currentPage === pageCount,
                [s.inactiveItem]: currentPage !== pageCount
              })}
            >
              {pageCount}
            </Button>
          </li>
        </ul>
      )}
    </div>
  )
}

export default Pagination
