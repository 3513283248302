import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const Instagram = (props: SVGIconProps) => {
  const { width = 19, height = 19, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} aria-label="Instagram" {...svgProps}>
      <path d="M9.35 4.41995C6.78 4.37995 4.70001 6.40995 4.67001 8.98995C4.64001 11.56 6.66999 13.63 9.24999 13.66C11.82 13.68 13.89 11.65 13.9 9.06995C13.92 6.49995 11.91 4.45995 9.35 4.41995ZM9.26998 12.02C7.61998 12.01 6.30999 10.7 6.30999 9.04995C6.30999 7.36995 7.62 6.04995 9.29 6.04995C10.96 6.04995 12.28 7.36995 12.28 9.03995C12.28 10.72 10.95 12.03 9.26998 12.02Z" />
      <path d="M14.09 3.15997C13.49 3.15997 13 3.64997 13 4.23997C13 4.81997 13.52 5.32997 14.1 5.31997C14.69 5.31997 15.17 4.82997 15.17 4.22997C15.17 3.60997 14.71 3.15997 14.09 3.15997Z" />
      <path d="M18.1901 4.84998C18.1201 2.18998 16.1301 0.259978 13.4701 0.119978C13.2801 0.109978 13.0901 0.069978 12.9001 0.039978C10.5001 0.039978 8.10007 0.039978 5.70007 0.039978C5.21007 0.109978 4.72006 0.149977 4.25006 0.259977C2.00006 0.749977 0.520076 2.49998 0.390076 4.82998C0.380076 5.03998 0.330059 5.23998 0.310059 5.44998C0.310059 7.84998 0.310059 10.25 0.310059 12.65C0.380059 13.12 0.42005 13.6 0.52005 14.07C0.99005 16.33 2.76005 17.89 5.08005 17.95C7.90005 18.02 10.7201 18.02 13.5301 17.95C16.1501 17.88 18.1301 15.9 18.2001 13.28C18.2601 10.46 18.2601 7.64998 18.1901 4.84998ZM16.5901 12.93C16.5801 13.67 16.3901 14.39 15.9501 15C15.2901 15.91 14.3401 16.28 13.2601 16.32C11.9401 16.36 10.62 16.33 9.30005 16.33C8.01005 16.33 6.72005 16.37 5.43005 16.32C3.30005 16.25 2.05006 15.02 2.00006 12.89C1.94006 10.33 1.94006 7.75998 2.00006 5.19998C2.05006 3.00998 3.27007 1.78998 5.48007 1.73998C8.03007 1.67998 10.5801 1.67998 13.1301 1.73998C15.3101 1.78998 16.5401 3.02998 16.5901 5.22998C16.6401 7.79998 16.6101 10.37 16.5901 12.93Z" />
    </SVGIcon >
  )
}

export default Instagram