import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const SlotPackIcon = (props: SVGIconProps) => {
  const { width = 37, height = 37, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} aria-label="Search" {...svgProps}>
      <path d="M31.6691 0H5.3309C2.39145 0 0 2.39144 0 5.33094V31.6691C0 34.6086 2.39145 37 5.3309 37H31.6691C34.6086 37 37 34.6086 37 31.6691V5.33094C37 2.39144 34.6086 0 31.6691 0ZM35.1636 31.6691C35.1636 33.5959 33.596 35.1636 31.6691 35.1636H5.3309C3.40407 35.1636 1.83644 33.596 1.83644 31.6691V5.33094C1.83644 3.40406 3.40401 1.83644 5.3309 1.83644H31.6691C33.5959 1.83644 35.1636 3.40401 35.1636 5.33094V31.6691Z" fill="white" />
      <path d="M28.6942 20.7583L25.1869 18.0288C25.0129 17.8934 24.913 17.6892 24.9128 17.4689L24.9112 14.2691C24.9112 13.2152 24.6291 12.1778 24.0955 11.269L20.4505 5.0607C20.04 4.36148 19.3109 3.94409 18.5 3.94409C17.6892 3.94409 16.9601 4.36148 16.5495 5.06076L12.9045 11.269C12.3709 12.1779 12.0889 13.2152 12.0889 14.2687L12.0873 17.4686C12.0871 17.6892 11.9871 17.8934 11.8131 18.0289L8.30583 20.7584C7.21779 21.6052 6.59375 22.8816 6.59375 24.2603V26.1777C6.59375 26.9908 7.14439 27.6978 7.9328 27.8969L9.34135 28.2526V30.8928C9.34135 31.9208 10.1777 32.7571 11.2056 32.7571H14.8087C15.8367 32.7571 16.6729 31.9208 16.6729 30.8928V29.1489H20.3271V30.8928C20.3271 31.9208 21.1634 32.7571 22.1914 32.7571H25.7945C26.8224 32.7571 27.6587 31.9208 27.6587 30.8928V28.2526L29.0673 27.8969C29.8557 27.6978 30.4063 26.9908 30.4063 26.1777V24.2603C30.4063 22.8817 29.7823 21.6052 28.6942 20.7584V20.7583ZM14.8364 30.8928C14.8364 30.9081 14.824 30.9206 14.8087 30.9206H11.2056C11.1902 30.9206 11.1777 30.9081 11.1777 30.8928V28.7163L12.0197 28.9289C12.5977 29.0749 13.193 29.1489 13.789 29.1489H14.8365V30.8928H14.8364ZM25.8223 30.8928C25.8223 30.9081 25.8098 30.9206 25.7945 30.9206H22.1914C22.176 30.9206 22.1635 30.9081 22.1635 30.8928V29.1489H23.2111C23.8071 29.1489 24.4024 29.0749 24.9804 28.9289L25.8223 28.7163V30.8928ZM28.5699 26.1284L24.5307 27.1484C24.0996 27.2572 23.6555 27.3124 23.211 27.3124H13.789C13.3444 27.3124 12.9004 27.2572 12.4693 27.1484L8.43007 26.1284V24.2602C8.43007 23.4521 8.79587 22.7039 9.43357 22.2075L12.9408 19.478C13.5647 18.9926 13.9229 18.2607 13.9236 17.4698L13.9252 14.2691C13.9252 13.5418 14.1199 12.826 14.4881 12.1988L18.1331 5.99057C18.2404 5.80779 18.4091 5.78053 18.5 5.78053C18.5909 5.78053 18.7595 5.80785 18.8668 5.99051L22.5118 12.1988C22.8801 12.826 23.0748 13.5418 23.0748 14.2695L23.0764 17.4701C23.077 18.2607 23.4352 18.9925 24.0591 19.4781L27.5663 22.2075C28.2041 22.7039 28.5699 23.4522 28.5699 24.2602V26.1284V26.1284Z" fill="white" />
      <path d="M18.4988 10.5846C16.9928 10.5846 15.7676 11.8098 15.7676 13.3158V17.2518C15.7676 17.759 16.1787 18.1701 16.6858 18.1701C17.1929 18.1701 17.604 17.759 17.604 17.2518V13.3158C17.604 12.8224 18.0054 12.421 18.4988 12.421C18.9921 12.421 19.3936 12.8224 19.3936 13.3158V17.2518C19.3936 17.759 19.8046 18.1701 20.3118 18.1701C20.8189 18.1701 21.23 17.759 21.23 17.2518V13.3158C21.23 11.8098 20.0048 10.5846 18.4988 10.5846Z" fill="white" />
    </SVGIcon>
  )
}

export default SlotPackIcon