import React from 'react'
import { Controller, FieldValues } from 'react-hook-form'
import Select from './Select'
import { ControlledSelectProps } from './types'

const ControlledSelect = <T extends FieldValues>({
  name,
  control,
  onChange,
  rules,
  ...props
}: ControlledSelectProps<T>) => {

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => (
        <Select
          {...props}
          inputRef={field.ref}
          value={field.value}
          hasError={!!fieldState.error}
          onChange={(value) => {

            field.onChange(value)
            if (onChange) {
              onChange(value)
            }
          }}
        />
      )}
    />
  )
}

export default ControlledSelect
