import React, { useState, useCallback, useMemo } from 'react'
import cn from 'classnames'

import Button from '../Button'
import { DropdownButtonOption, DropdownButtonProps, DropdownButtonSelectedButtonProps } from './types'

import DropdownMenu from '../Dropdown/Menu/Menu'
import Typography from '../Typography'
import { Arrow } from '../Icon'

import s from './styles.module.css'

const DropdownButton = (props: DropdownButtonProps) => {
  const {
    options = [],
    defaultOption = 0,
    defaultOptionText = "Select Option",
    defaultButtonText = "Select An Option",
    buttonText: buttonTextProp = "Buy",
    onDropdownSelect,
    onButtonClick,
    className,
    selectedButtonClassName,
    dropdownOptionClassName,
    dropdownMenuClassName,
    dropdownSelectedOptionButtonClassName,
    renderSelectedButton,
    renderDropdownSelectedOption,
    renderDropdownOption } = props

  const defaultOptionItem = { id: "0", label: defaultOptionText }
  const dropdownOptions = [defaultOptionItem, ...options]
  const [selectedOption, setSelectedOption] = useState(dropdownOptions[defaultOption])
  const buttonText = selectedOption.id === "0"
    ? defaultButtonText
    : buttonTextProp

  const handleOnDropdownSelect = useCallback(
    (option: DropdownButtonOption) => {
      setSelectedOption(option)

      if (onDropdownSelect) onDropdownSelect(option)
    },
    [setSelectedOption, onDropdownSelect],
  )

  const handleOnButtonClick = useCallback(
    (option: DropdownButtonOption) => {
      if (onButtonClick) onButtonClick(option)
    },
    [setSelectedOption, onButtonClick],
  )

  const selectedButtonProps = useMemo(() => ({
    disabled: !selectedOption || selectedOption.id === "0",
    className: cn(s.button_select, selectedButtonClassName),
    onClick: () => handleOnButtonClick(selectedOption),
  }) as DropdownButtonSelectedButtonProps, [selectedOption, selectedButtonClassName])

  return (
    <div className={cn(s.dropdown_button_wrapper, className)}>
      <DropdownMenu
        buttonClassName={cn(s.dropdown_button, dropdownSelectedOptionButtonClassName)}
        menuItemsClassName={cn(s.dropdown_menu, dropdownMenuClassName)}
        menuItemClassName={s.dropdown_menu_item}
        options={dropdownOptions.slice(1)}
        label={(open) => (
          <div className={s.inner_mask}>
            <Typography
              className={s.dropdown_label}
              as="span">
              {
                renderDropdownSelectedOption && selectedOption?.id !== "0" ?
                  renderDropdownSelectedOption(selectedOption)
                  :
                  (selectedOption?.label ?? defaultOptionText)
              }
              { }
              <Arrow
                className={s.dropdown_label_carat}
                rotation={open ? "-90" : "180"} />
            </Typography>
          </div>
        )}>
        {
          (option) => (
            <Button
              className={cn(s.dropdown_menu_item_button, dropdownOptionClassName)}
              onClick={() => handleOnDropdownSelect(option)}>
              {
                renderDropdownOption ?
                  renderDropdownOption(option)
                  :
                  option.label
              }
            </Button>
          )
        }
      </DropdownMenu>
      {
        renderSelectedButton ?
          renderSelectedButton(selectedOption, selectedButtonProps)
          :
          <Button {...selectedButtonProps}>
            {buttonText}
          </Button>
      }
    </div>
  )
}

export default DropdownButton