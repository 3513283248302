import React from 'react'
import cn from 'classnames'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

import s from './styles.module.css'

const Hamburger = (props: SVGIconProps) => {
  const { width = 100, height = 60, active = false, className, ...svgProps } = props

  const lineWidth = 90
  const lineHeight = 10
  const lineOffset = 25
  const classes = cn(s.hamburger, active && s.active, className)

  return (
    <SVGIcon width={width} height={height} className={classes} {...svgProps}>
      <rect width={lineWidth} height={lineHeight} />
      <rect y={lineOffset} width={lineWidth} height={lineHeight} />
      <rect y={lineOffset} width={lineWidth} height={lineHeight} />
      <rect y={lineOffset * 2} width={lineWidth} height={lineHeight} />
    </SVGIcon>
  )
}

export default Hamburger