import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const Drip = (props: SVGIconProps) => {
  const { width = 48, height = 63, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} aria-label="Drip icon" {...svgProps}>
      <path d="M42.616 25.1651C42.6612 25.2326 29.6639 6.96036 25.3466 0.884618C24.6684 -0.0604975 23.2896 -0.0604975 22.6114 0.884618C18.2941 6.93786 5.27424 25.2326 5.34205 25.1651H5.36461C1.68016 29.9357 -0.218567 36.1464 0.776007 42.8297C2.29047 53.181 10.88 61.417 21.3231 62.5646C35.4731 64.1173 47.4532 53.136 47.4532 39.3418C47.4532 33.9862 45.6223 29.0806 42.5933 25.1426L42.616 25.1651Z" fill="currentcolor" />
    </SVGIcon>
  )
}
export default Drip