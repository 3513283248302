import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const Arrow = (props: SVGIconProps) => {
  const { width = 9, height = 18, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} {...svgProps}>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.18318 0.83427C8.18318 1.05553 8.09688 1.26773 7.94327 1.42419L1.97628 7.5L7.94327 13.5758C8.01933 13.6533 8.07967 13.7453 8.12083 13.8465C8.16199 13.9477 8.18318 14.0562 8.18318 14.1657C8.18318 14.2753 8.16199 14.3838 8.12083 14.485C8.07967 14.5862 8.01933 14.6782 7.94327 14.7556C7.86721 14.8331 7.77691 14.8946 7.67754 14.9365C7.57816 14.9784 7.47164 15 7.36408 15C7.25651 15 7.15 14.9784 7.05062 14.9365C6.95124 14.8946 6.86095 14.8331 6.78489 14.7556L0.240338 8.08992C0.164154 8.01252 0.103711 7.92057 0.0624698 7.81934C0.0212286 7.71812 0 7.6096 0 7.5C0 7.3904 0.0212286 7.28188 0.0624698 7.18066C0.103711 7.07943 0.164154 6.98748 0.240338 6.91008L6.78489 0.244352C6.9385 0.0878963 7.14684 0 7.36408 0C7.58132 0 7.78966 0.0878963 7.94327 0.244352C8.09688 0.400808 8.18318 0.613008 8.18318 0.83427Z" />
    </SVGIcon>
  )
}

export default Arrow