import React from 'react'
import cx from 'classnames';

import Button from '@/components/ui/Button';
import NavLink from '../../Navigation/NavLink';

import { useActiveRoute } from '../../../../hooks';

import s from './styles.module.css'
import { ConnectedNavItemProps } from './types';

const ConnectedNavItem = (props: ConnectedNavItemProps) => {
  const { children, href, active, onClick, ...linkProps } = props

  const isActive = useActiveRoute({ href: href as string }) || active

  const classes = cx(s.link, { [`${s.active}`]: isActive })

  // If no href is present component returns a span
  const isLink = href !== undefined && href !== null

  const innerContent = (
    <>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">

        <rect
          className={s.outerBox}
          x="0.5"
          y="0.5"
          width="17"
          height="17"
          rx="2.5"
          stroke="white" />

        <rect
          className={s.innerBox}
          x="4"
          y="4"
          width="10"
          height="10"
          rx="1"
          fill="currentcolor" />
      </svg>

      {children}
    </>
  )

  if (isLink)
    return (
      <NavLink
        hover={false}
        onClick={onClick}
        unstyled
        href={href}
        className={classes}
        {...linkProps}>
        {innerContent}
      </NavLink>
    )

  return (
    <Button
      onClick={onClick}
      uppercase={false}
      className={classes}>
      {innerContent}
    </Button>)

}

export default ConnectedNavItem
