import React from 'react'
import { format, parse } from 'date-fns'
import { UTCDate } from "@date-fns/utc";

import { DateProps } from './type'

const DateFormatter = (props: DateProps) => {
  const { dateString, className, isIso = false, formatTo = 'LLLL	d, yyyy', formatFrom = "yyyy-MM-dd HH:mm:ss", suffix } = props
  const date = isIso ? new UTCDate(dateString) : parse(dateString, formatFrom, new Date())

  return <time className={className} dateTime={dateString} suppressHydrationWarning>{format(date, formatTo)}{suffix}</time>
}

export default DateFormatter