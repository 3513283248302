import React, { createContext, useContext, useState, useMemo, useEffect, useCallback } from 'react'
import { signOut, useSession } from 'next-auth/react'
import { useRouter } from 'next/router'

import { destroyCookie, parseCookies } from 'nookies'
import { ERROR_REFRESH_ACCESS_TOKEN } from 'consts/auth'
import { COOKIE_AUTH_REDIRECT } from '@/consts'
import { PROTECTED_ROUTES } from 'consts/routes'
import { IntegrationTypeEnum, UserType } from 'types'

import { AuthenticationProviderProps, AuthenticationContextType } from './types'

const AuthenticationContext = createContext<AuthenticationContextType | undefined>(undefined)

const AuthenticationProvider = (props: AuthenticationProviderProps) => {
  const { pathname } = useRouter()
  const { children, user: initialUser } = props

  const [user, setUser] = useState<UserType | undefined>(initialUser)

  const [walletsToSync, setWalletsToSync] = useState<string[]>([])
  const { data: session, status } = useSession()

  // If session has access token a user has been logged in 
  const isLoggedIn = !!session?.accessToken
  const isAuthenticated = status === "authenticated" && !!user
  const requiresMfa = session?.forceMfa ? session.forceMfa === true : false

  // If cookie exists perform auth redirect
  useEffect(() => {
    if (isLoggedIn) {
      const id = COOKIE_AUTH_REDIRECT
      const cookies = parseCookies()
      const redirect = cookies[id]

      if (redirect) {
        window.location.href = redirect
        destroyCookie(null, id, {
          path: '/',
        })
      }
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (session?.error === ERROR_REFRESH_ACCESS_TOKEN || PROTECTED_ROUTES.includes(pathname) && status === "unauthenticated") {
      // Refresh has failed for some reason, force user to sign in again
      signOut();
    }
  }, [pathname, session, status, user, isAuthenticated]);

  useEffect(() => {
    setUser(initialUser)
  }, [initialUser])

  // TODO:: THIS WILL NO LONGER WORK
  // useEffect(() => {
  //   if (isLoggedIn)
  //     shouldResync()
  // }, [isLoggedIn])

  // TODO:: THIS WILL NO LONGER WORK
  // const shouldResync = useCallback(async () => {
  //   try {
  //     if (isLoggedIn && user) {
  //       const stakeAddresses = user.verifiedWalletAddresses
  //         .filter(wallet => !!wallet.stakeAddress)
  //         .map(wallet => wallet.stakeAddress as string)

  //       // This is a cardano specific check
  //       const verifiedAddresses = user.verifiedWalletAddresses.map((wallet) => wallet.address)

  //       const addresses = await getStakeAddressesWithNewAssets(stakeAddresses, verifiedAddresses)
  //       setWalletsToSync(addresses)

  //       // SWITCH TO GO SERVER SIDE
  //       // const { data: addresses } = await PlayerHttpClient.GetUserAddressToSync(stakeAddresses, verifiedAddresses)

  //       // if (addresses)
  //       //   setWalletsToSync(addresses)
  //     }
  //   } catch (error) {
  //     /* Do nothing */
  //   }
  // }, [isLoggedIn, user])

  /**
   * Remove all addresses or remove addresses with id
   */
  const removeUserWallets = useCallback((ids?: number[]) => {
    if (user) {
      if (!ids)
        setUser({
          ...user,
          verifiedWalletAddresses: []
        })
      else {
        const addresses = (user.verifiedWalletAddresses ?? []).filter((address) => !ids.includes(address.id))

        setUser({
          ...user,
          verifiedWalletAddresses: addresses
        })
      }
    }
  }, [user])

  const getUserIntegration = useCallback((integration: IntegrationTypeEnum) => (user?.integrations ?? []).find((service) => service.name === integration), [user])

  const setDisplayName = useCallback((displayName: string) => {
    if (user)
      setUser({
        ...user,
        displayName
      })
  }, [user])

  const value = useMemo(
    () => ({
      user,
      setUser,
      setDisplayName,
      walletsToSync,
      setWalletsToSync,
      removeUserWallets,
      getUserIntegration,
      isLoggedIn,
      isAuthenticated,
      requiresMfa,
    }),
    [
      user,
      setDisplayName,
      walletsToSync,
      setWalletsToSync,
      removeUserWallets,
      isLoggedIn,
      isAuthenticated,
      requiresMfa,
      getUserIntegration,
    ]
  );

  return <AuthenticationContext.Provider value={value}>{children}</AuthenticationContext.Provider>
}

const useAuth = () => {
  const context = useContext(AuthenticationContext)
  if (!context) {
    throw new Error('AuthenticationContext must be used within an < AuthenticationProvider />')
  }

  return context
}

export { useAuth, AuthenticationContext }
export default AuthenticationProvider