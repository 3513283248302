import React from 'react'
import cn from 'classnames'
import { motion } from 'framer-motion'
import { Arrow } from '../../Icon'
import { useAccordion } from '../Accordion'
import { AccordionHeaderProps } from './types'

import s from './styles.module.css'

function AccordionHeader(props: AccordionHeaderProps) {
  const { children, label } = props
  const { isActive, index, onChangeIndex } = useAccordion()

  return (
    <div className={cn(s.accordion_header, isActive && s.active)}>
      <motion.button
        type="button"
        className={s.accordion_header_button}
        onClick={() => onChangeIndex(index)}
      >
        <Arrow
          className={s.accordion_header_carat}
          rotation={isActive ? "-90" : "180"} />
        {label}
      </motion.button>
      {children}
    </div>
  )
}

export default AccordionHeader