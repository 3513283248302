import React, { forwardRef } from 'react'
import cx from 'classnames'

import s from "./styles.module.css"
import { BoxProps } from './types'

type Ref = HTMLDivElement

/**
 * Simple component that can be used to show or hide content based on tailwind device sizes
 * @param props 
 * @returns 
 */
const Box = forwardRef<Ref, BoxProps>((props, ref) => {
  const {
    children,
    className = "",
    display = "block",
    notched = false,
    above,
    below,
    ...rest
  } = props

  const classes = cx(
    above ? `${above}:${display} hidden` : "",
    below ? `${display} ${below}:hidden` : "",
    !above && !below && display,
    { [`${s.notched}`]: notched },
    className
  )

  return (
    <div
      ref={ref}
      className={classes}
      {...rest}>
      {children}
    </div>
  )
})

export default Box