import React from 'react'
import Link from '../Link'
import Typography from '../Typography'

import s from './styles.module.css'

function CaptchaCompliance() {
  return (
    <Typography as="p" className={s.captcha}>
      This site is protected by hCaptcha and its <Link variant="primary" href="https://www.hcaptcha.com/privacy" target="_blank">Privacy Policy</Link> and <Link variant="primary" href="https://www.hcaptcha.com/terms" target="_blank">Terms of Service</Link> apply.
    </Typography>
  )
}

export default CaptchaCompliance