import React from 'react'
import { Hamburger } from '../../Icon'

import s from './styles.module.css'
import { MobileMenuButtonProps } from './types'

/**
 * Mobile menu button
 * @param props 
 * @returns 
 */
const MobileMenuButton = (props: MobileMenuButtonProps) => {
  const { active = false, ...buttonProps } = props

  return (
    <button type="button" className={s.btn} {...buttonProps}>
      <Hamburger
        active={active}
        title="Mobile Menu"
        className={s.icon} />
    </button>
  )
}

export default MobileMenuButton