import { Blockchain } from '@/types/Blockchain'
import {
    BLOCKCHAIN_TYPE_CARDANO,
    BLOCKCHAIN_TYPE_BASE,
    BLOCKCHAIN_TYPE_BNB,
    BLOCKCHAIN_TYPE_ETHEREUM,
} from 'consts/blockchain'

import isProduction from '../isProduction'

const getTxExplorerUrl = (txHash: string, chain: Blockchain): string | undefined => {
    const prod = isProduction()

    switch (chain.toLowerCase()) {
        case BLOCKCHAIN_TYPE_CARDANO:
            return `https://${!prod ? 'preprod.' : ''}cardanoscan.io/transaction/${txHash}`
        case BLOCKCHAIN_TYPE_ETHEREUM:
            return `https://${!prod ? 'sepolia.' : ''}etherscan.io/tx/${txHash}`
        case BLOCKCHAIN_TYPE_BNB:
            return `https://${!prod ? 'testnet.' : ''}bscscan.com/tx/${txHash}`
        case BLOCKCHAIN_TYPE_BASE:
            return prod
                ? `https://basescan.org/tx/${txHash}`
                : `https://testnet.bscscan.com/tx/${txHash}`
    }
}

export default getTxExplorerUrl
