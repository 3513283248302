import useAuthApi from 'hooks/useAuthApi'
import { GameHttpClient } from '../network'
import { LeaderboardHolocacheRequest, LeaderboardRaceRequest } from '../types'

export const GAME_RACE_LEADERBOARD_QUERY_KEY = 'game_race_leaderboard'
export const GAME_HOLOCACHE_LEADERBOARD_QUERY_KEY = 'game_holocache_leaderboard'

/**
 * Get race leaderboards
 * @param params
 * @param refetchInterval
 * Refetch leaderboards, if number is provided it will refetch after X milliseconds
 * @returns
 */
export const useGameRaceLeaderboards = (
    params: LeaderboardRaceRequest,
    refetchInterval: number | false = false,
) =>
    useAuthApi({
        queryKey: [GAME_RACE_LEADERBOARD_QUERY_KEY, params],
        queryFn: () => GameHttpClient.GetRaceLeaderboard(params),
        retry: false,
        refetchOnWindowFocus: false,
        refetchInterval,
        showErrorToast: true,
    })

/**
 * Get holocache leaderboards
 * @param params
 * @param refetchInterval
 * Refetch leaderboards, if number is provided it will refetch after X milliseconds
 * @returns
 */
export const useGameHolocacheLeaderboards = (
    params: LeaderboardHolocacheRequest,
    refetchInterval: number | false = false,
) =>
    useAuthApi({
        queryKey: [GAME_HOLOCACHE_LEADERBOARD_QUERY_KEY, params],
        queryFn: () => GameHttpClient.GetHolocacheLeaderboard(params),
        retry: false,
        refetchOnWindowFocus: false,
        refetchInterval,
    })
