import React, { useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { Popover } from '@headlessui/react'
import { usePopper } from 'react-popper'
import cn from 'classnames'
import useVariant from 'hooks/useVariant'

import { TooltipProps } from './types'
import s from './styles.module.css'

const Tooltip = (props: TooltipProps) => {
  const { children, button, placement = "bottom", size = "md", className, tooltipClassName, variant = "primary", offsetX = 0, offsetY = 15 } = props
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>()
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>()
  const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>()
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      {
        name: "arrow",
        options: {
          element: arrowRef
        }
      },
      {
        name: 'offset',
        options: {
          offset: [offsetX, offsetY],
        },
      },
    ],
  })

  const variantClass = useVariant({
    variant,
    color: true,
    filled: true,
    hover: true
  })

  const defaultButton = <span className={cn(s.help, variantClass)}>?</span>

  const variants = {
    animate: {
      opacity: 1,
      transition: {
        duration: 0.2
      }
    },
    initial: {
      opacity: 0
    }
  };

  return (
    <Popover className={cn(s.tooltip_wrapper, s[size], className)}>
      <Popover.Button
        className={s.button}
        ref={setReferenceElement}>
        {button || defaultButton}
      </Popover.Button>

      <Popover.Panel
        ref={setPopperElement}
        className={cn(s.tooltip_panel)}
        style={styles.popper}
        {...attributes.popper}
      >
        {
          ({ open }) => (
            <>
              <AnimatePresence>
                {
                  open && (
                    <motion.div
                      variants={variants}
                      initial="initial"
                      animate="animate">
                      <div
                        className={cn(s.tooltip, tooltipClassName)}>
                        {children}
                      </div>
                      <div
                        ref={setArrowRef}
                        {...attributes.arrow}
                        style={styles.arrow}
                        className={s.tooltip_arrow} />
                    </motion.div>
                  )
                }

              </AnimatePresence>
            </>
          )
        }
      </Popover.Panel>
    </Popover>
  )
}

export default Tooltip