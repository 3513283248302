import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const TokenRiot = (props: SVGIconProps) => {
  const { width = 178, height = 20, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} aria-label="Token Riot" viewBox={`0 0 241 ${height}`} {...svgProps}>
      <g filter="url(#filter0_d_4574_1827)">
        <path d="M14.8236 3.91422H4.5918V0.799561H28.6714V3.91422H18.4396V17.764H14.8201V3.91422H14.8236Z" fill="#D7D7D5" />
        <path d="M37.8975 0.799316H52.8301C55.5784 0.799316 57.8272 3.05917 57.8272 5.82083V12.7457C57.8272 15.5074 55.5784 17.7673 52.8301 17.7673H37.8975C35.1492 17.7673 32.9004 15.5074 32.9004 12.7457V5.82083C32.9004 3.05917 35.1492 0.799316 37.8975 0.799316ZM38.6964 14.6491H52.0346C53.2296 14.6491 54.2111 13.6663 54.2111 12.462V6.10115C54.2111 4.89682 53.2331 3.91397 52.0346 3.91397H38.6964C37.5014 3.91397 36.5199 4.89682 36.5199 6.10115V12.462C36.5199 13.6628 37.498 14.6491 38.6964 14.6491Z" fill="#D7D7D5" />
        <path d="M81.9938 17.7638L76.3665 10.8389H65.6664V17.7638H62.0469V0.799316H65.6664V7.72423H76.3665L81.9938 0.799316H86.6637L79.7691 9.28156L86.6637 17.7638H81.9938Z" fill="#D7D7D5" />
        <path d="M114.123 17.7638H95.8838C93.1356 17.7638 90.8867 15.504 90.8867 12.7423V5.81742C90.8867 3.05575 93.1356 0.795898 95.8838 0.795898H114.123V3.91055H96.6828C95.4878 3.91055 94.5062 4.8934 94.5062 6.09773V12.4586C94.5062 13.6594 95.4878 14.6457 96.6828 14.6457H114.123V17.7604V17.7638ZM114.123 7.72428H98.298V10.8389H114.123V7.72428Z" fill="#D7D7D5" />
        <path d="M137.432 17.7638C136.185 17.7638 135.121 17.2655 134.319 16.3034L133.527 15.3551C133.527 15.3551 133.52 15.3448 133.516 15.3413L125.344 5.55436L124.879 4.99718C124.573 4.63034 124.149 4.42962 123.67 4.42962H121.969V17.7603H118.35V0.799316H124.194C125.441 0.799316 126.505 1.29766 127.307 2.25974L128.099 3.20798C128.099 3.20798 128.106 3.21837 128.11 3.22183L136.282 13.0088L136.747 13.5659C137.053 13.9328 137.477 14.1335 137.956 14.1335H139.657V0.799316H143.276V17.7638H137.432Z" fill="#D7D7D5" />
        <path d="M167.041 17.7638L162.756 12.4931H153.296C152.101 12.4931 151.12 13.476 151.12 14.6803V17.7638H147.5V14.4C147.5 12.7077 148.354 11.1365 149.759 10.2056C149.911 10.1052 150.066 10.0152 150.228 9.93218C150.672 9.70377 151.151 9.54112 151.643 9.4546C151.915 9.40615 152.194 9.38192 152.473 9.38192H154.512V9.37154H166.049C166.917 9.37154 167.623 8.65863 167.623 7.78999V5.50245C167.623 4.63034 166.913 3.92089 166.049 3.92089H147.503V0.806237H166.672C168.907 0.806237 170.739 2.64735 170.739 4.8899V8.41638C170.739 8.57211 170.729 8.73131 170.712 8.88358C170.533 10.4305 169.475 11.7525 168.015 12.2682L167.988 12.2786C167.791 12.3478 167.588 12.3997 167.378 12.4377L167.416 12.4827L171.714 17.7707H167.044L167.041 17.7638ZM179.538 0.799316V17.7638H175.919V0.799316H179.538ZM188.765 0.799316H203.697C206.445 0.799316 208.694 3.05917 208.694 5.82083V12.7458C208.694 15.5074 206.445 17.7673 203.697 17.7673H188.765C186.016 17.7673 183.768 15.5074 183.768 12.7458V5.82083C183.768 3.05917 186.016 0.799316 188.765 0.799316ZM189.564 14.6492H202.902C204.097 14.6492 205.078 13.6663 205.078 12.462V6.10115C205.078 4.89682 204.1 3.91397 202.902 3.91397H189.564C188.369 3.91397 187.387 4.89682 187.387 6.10115V12.462C187.387 13.6628 188.365 14.6492 189.564 14.6492ZM223.152 3.91397H212.92V0.799316H237V3.91397H226.768V17.7638H223.148V3.91397H223.152Z"
          fill="url(#paint0_linear_343_5833)" />
      </g>
      <defs>
        <filter id="filter0_d_4574_1827" x="0.591797" y="0.795898" width="240.408" height="24.9749" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4574_1827" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4574_1827" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_4574_1827" x1="155" y1="0.431151" x2="237.07" y2="17.0843" gradientUnits="userSpaceOnUse">
          <stop stopColor="#ED1E79" />
          <stop offset="1" stopColor="#00CFFF" />
        </linearGradient>
      </defs>
      <defs>
        <linearGradient
          id="paint0_linear_343_5833"
          x1="0"
          y1="0"
          x2="50%"
          y2="100%"
        >
          <stop offset="0%" stopColor="#FF0098">
            <animate attributeName="stop-color" values="#FF0098; #00CFFF; #FF0098" dur="4s" repeatCount="indefinite" />
          </stop>
          <stop offset="100%" stopColor="#00CFFF">
            <animate attributeName="stop-color" values="#00CFFF; #FF0098; #00CFFF" dur="4s" repeatCount="indefinite" />
          </stop>
        </linearGradient>
      </defs>
    </SVGIcon >
  )
}

export default TokenRiot