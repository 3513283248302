import React from 'react'

import { ConnectedNav } from '@/components/ui';
import { useSite } from '@/providers/SiteProvider';
import { ConnectedNavProps } from '@/components/ui/ConnectedNav/types';

const SidebarConnectedNav = (props: ConnectedNavProps) => {
  const { onNavItemClick, ...sideProps } = props
  const { setSidebarMobileVisible } = useSite()

  return (
    <ConnectedNav
      {...sideProps}
      onNavItemClick={() => {
        setSidebarMobileVisible(false)

        if (onNavItemClick)
          onNavItemClick()
      }} />
  )
}

export default SidebarConnectedNav