import React, { useRef } from 'react'

import Button from '@/components/ui/Button'
import { FileInputProps } from './types';

/**
 * Stylable file input 
 * @param props 
 * @returns 
 */
const FileInput = (props: FileInputProps) => {
  const {
    label = "Click to select file",
    name = "file",
    className,
    ...inputProps
  } = props
  const inputRef = useRef<HTMLInputElement | null>(null);

  const onUploadClick = () => {
    inputRef.current?.click();
  };

  return (
    <>
      <Button
        className={className}
        onClick={onUploadClick}
      >
        {label}
      </Button>
      <input
        name={name}
        ref={inputRef}
        type="file"
        className="hidden"
        {...inputProps}
      />
    </>
  )
}

export default FileInput