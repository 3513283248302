import React from 'react'
import { AccordionItemProps } from './types'

import s from './styles.module.css'

function AccordionItem(props: AccordionItemProps) {
  const { children } = props

  return <div className={s.accordion_item}>{children}</div>
}

export default AccordionItem