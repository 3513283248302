import React from 'react'
import cx from 'classnames'

import { ButtonProps } from './types'
import s from './styles.module.css'
import { useVariant } from '../../../hooks'
import Typography from '../Typography'
import Loader from '../Loader'

const Button = (props: ButtonProps) => {
  const {
    children,
    variant = "default",
    filled = false,
    outline = false,
    uppercase = true,
    isLoading = false,
    jiggle = false,
    icon,
    onClick = undefined,
    className,
    ...componentProps
  } = props

  const variantClass = useVariant({
    variant,
    color: true,
    filled,
    outline
  })

  const classes = cx(
    s.button,
    [`${variantClass}`],
    jiggle && s.button_jiggle,
    className
  )

  // Prevent click event if state is loading
  const handleOnClick = isLoading ? undefined : onClick
  let buttonContent = children

  if (isLoading)
    buttonContent = <div className={s.button_loader}><Loader /></div>
  else if (icon)
    buttonContent = (
      <div className={s.button_contents}>
        <span className={s.button_icon}>{icon}</span>
        {children}
      </div>
    )

  return (
    <Typography
      as="button"
      uppercase={uppercase}
      type="button"
      className={classes}
      onClick={handleOnClick}
      {...componentProps}>
      {buttonContent}
    </Typography>
  )
}

export default Button