import React from 'react'
import cn from 'classnames'

import s from './styles.module.css'
import { AlertProps } from './types'
import Mask from '../Mask'

const Alert = (props: AlertProps) => {
  const { children, center = false, type = "default", className, title } = props

  const classes = cn(
    s.alert,
    type && s[type],
    center && s.center,
    className)

  return (
    <Mask type="pseudo-notched-outline" className={classes}>
      {title && <p><strong>{title}</strong></p>}
      {children}
    </Mask>
  )
}

export default Alert