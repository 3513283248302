import React from 'react'
import cx from 'classnames'

import { NavBarProps } from './types'
import s from './styles.module.css'

/**
 * NavBar container
 * @param props 
 * @returns 
 */
const NavBar = (props: NavBarProps) => {
  const { children, className = '', horizontal = false } = props

  const classes = cx(s.navbar, { [`${s.horizontal}`]: horizontal }, className)

  return (
    <ul className={classes}>
      {children}
    </ul>
  )
}

export default NavBar