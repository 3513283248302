import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const Mexc = (props: SVGIconProps) => {
  const { width = 581, height = 89, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} aria-label="Mexc" {...svgProps}>
      <path d="M137.562 62.5907L107.376 10.1815C100.788 -0.732962 84.5633 -0.83129 78.1719 10.6732L46.5101 65.1472C40.6104 75.1768 47.8867 87.6645 59.7844 87.6645H123.403C135.301 87.7628 144.249 74.7835 137.562 62.5907Z" fill="#003087" />
      <path d="M94.0028 66.7205L92.1346 63.4756C90.3647 60.4275 86.5298 53.9378 86.5298 53.9378L60.9644 9.49327C54.3764 -0.339597 38.6438 -1.22457 32.0558 11.2632L2.26221 62.7873C-3.9325 73.6035 3.44217 87.6645 16.8149 87.7628H80.0402H106.982H123.01C106.687 87.8612 101.476 79.3065 94.0028 66.7205Z" fill="#1877F2" />
      <path d="M94.0028 66.5238L92.1346 63.2789C90.3647 60.2308 86.5299 53.7411 86.5299 53.7411L70.0107 24.6358L46.3134 65.1472C40.4137 75.1767 47.69 87.6645 59.5878 87.6645H79.9418H106.884H123.01C106.589 87.5662 101.476 79.2082 94.0028 66.5238Z" fill="url(#paint0_linear_10907_331)" />
      <path d="M530.556 24.481C534.98 20.3512 542.748 18.3846 554.056 18.3846H580.507V3.34033H549.238C541.765 3.34033 535.472 4.12699 530.556 5.70024C525.737 7.2735 521.509 9.73169 517.871 13.1732C514.036 16.9097 510.988 21.5311 508.825 27.0375C506.662 32.6422 505.58 38.7386 505.58 45.13C505.58 51.2264 506.662 57.1261 508.825 62.5342C510.988 67.9423 514.036 72.662 517.871 76.3985C521.411 79.84 525.639 82.2982 530.359 83.8715C535.177 85.4448 541.568 86.2314 549.238 86.2314H580.507V71.3838H554.056C548.058 71.3838 543.928 71.1871 541.568 70.6954C539.11 70.2038 536.849 69.4172 534.98 68.2372C531.244 65.8773 528.392 62.8291 526.524 58.9943C524.754 55.2578 523.869 50.5381 523.869 45.0317C523.869 35.5921 526.131 28.6108 530.556 24.481Z" fill="white" />
      <path d="M300.368 86.428H374.115V71.8754H318.362V51.128H367.035V37.362H318.362V18.0896H374.115V3.43866H300.368V86.428Z" fill="white" />
      <path d="M392.502 3.43866L427.016 44.9333L392.502 86.428H414.823L442.06 53.5863V36.3787L414.823 3.43866H392.502Z" fill="white" />
      <path d="M476.278 3.43866L449.041 36.3787V53.5863L476.278 86.428H498.599L463.987 44.9333L498.599 3.43866H476.278Z" fill="white" />
      <path d="M228.097 44.3434L194.96 3.43866H177.851V86.428H195.845V31.069L224.557 65.4841H231.538L260.25 30.7741V86.428H278.244V3.43866H261.332L228.097 44.3434Z" fill="white" />
      <defs>
        <linearGradient id="paint0_linear_10907_331" x1="37.8559" y1="46.7869" x2="111.314" y2="73.9321" gradientUnits="userSpaceOnUse">
          <stop stopColor="#003087" stopOpacity="0" />
          <stop offset="1" stopColor="#003087" />
        </linearGradient>
      </defs>
    </SVGIcon>
  )
}

export default Mexc