import React, { PropsWithChildren } from 'react'
import cx from 'classnames'

import s from './styles.module.css'

const DataList = (props: PropsWithChildren & React.HTMLAttributes<HTMLDListElement>) => {
  const { children, className } = props

  const classes = cx(s.list, className)

  return (
    <dl className={classes}>
      {children}
    </dl>
  )
}

export default DataList