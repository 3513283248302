import React from 'react'

import Link from '../Link'
import Typography from '../Typography'

import s from './styles.module.css'
import { AttributionProps } from './types'

function Attribution(props: AttributionProps) {
  const { label, href } = props

  return (
    <Typography as="span" className={s.poweredBy}>
      Data powered by <Link href={href} target="_blank">{label}</Link>
    </Typography>
  )
}

export default Attribution