import { bech32 } from 'bech32'
import { Buffer } from 'buffer'

enum NetworkId {
    MAINNET = 1,
    TESTNET = 0,
}

const decodeStakeHexAddress = (hexAddress: string) => {
    const hex = hexAddress.toLowerCase()
    const addressType = hex.charAt(0)

    if (!['e', 'f'].includes(addressType)) {
        throw new TypeError('Unsupported wallet type')
    }

    const networkId = Number(hex.charAt(1)) as NetworkId
    const addressBytes = Buffer.from(hex, 'hex')
    const words = bech32.toWords(addressBytes)
    let prefix

    if (networkId === NetworkId.MAINNET) {
        prefix = 'stake'
    } else if (networkId === NetworkId.TESTNET) {
        prefix = 'stake_test'
    } else {
        throw new TypeError('Unsupported network type')
    }

    return bech32.encode(prefix, words, 1000)
}
const encodeBechAddress = (bech32Address: string): string => {
    const decoded = bech32.decode(bech32Address)

    const addressBytes = Buffer.from(bech32.fromWords(decoded.words))
    const hexAddress = addressBytes.toString('hex')

    return hexAddress
}

const decodePaymentHexAddress = (hexAddress: string) => {
    const hex = hexAddress.toLowerCase()

    const networkId = Number(hex.charAt(1)) as NetworkId
    const addressBytes = Buffer.from(hex, 'hex')
    const words = bech32.toWords(addressBytes)
    let prefix

    if (networkId === NetworkId.MAINNET) {
        prefix = 'addr'
    } else if (networkId === NetworkId.TESTNET) {
        prefix = 'addr_test'
    } else {
        throw new TypeError('Unsupported network type')
    }

    return bech32.encode(prefix, words, 1000)
}

export { decodeStakeHexAddress, decodePaymentHexAddress, encodeBechAddress }
