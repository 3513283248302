import React from 'react'
import cx from 'classnames'

import Link from '@/components/ui/Link'

import { NavLinkProps } from './types'
import s from './styles.module.css'

/**
 * Navigation Link that uses Next/Router
 * @param props
 * @returns 
 */
const NavLink = (props: NavLinkProps) => {
  const {
    children,
    unstyled = false,
    className: linkClassName,
    ...navProps
  } = props;

  const classes = cx(linkClassName, { [`${s.navlink}`]: !unstyled })

  // TODO:: Make this so user cannot interact if is active link. See: https://www.w3.org/WAI/tutorials/menus/structure/
  return <Link className={classes} {...navProps}>{children}</Link>

}

export default NavLink