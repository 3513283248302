
import React, { useEffect } from 'react'
import { AnimatePresence, motion, Variants } from 'framer-motion'
import cx from 'classnames'

import useKeyPress from 'hooks/useKeyPress'
import { useMegaMenu } from './MegaMenuProvider'

import s from './styles.module.css'
import { MegaMenuProps } from './types'

const variant: Variants = {
  isVisible: {
    opacity: 1,
    height: "auto",
    transition: {
      height: {
        type: "spring",
        duration: 0.5,
        bounce: 0.25,
      }
    }
  },
  isHidden: {
    opacity: 0,
    height: 0,
    transition: {
      height: {
        duration: 0.4,
        ease: "easeOut",
      }
    }
  }
}
/**
 * Mega Menu container
 * @param props 
 * @returns 
 */
const MegaMenu = (props: MegaMenuProps) => {
  const { children, className } = props
  const { active, close, megaMenuRef } = useMegaMenu()

  const escape = useKeyPress("Escape")

  useEffect(() => {
    if (escape) {
      close()
    }
  }, [escape, close])

  const classes = cx(
    s.mega_menu,
    className
  )

  // Note: We use an inner wrapper for padding to correct an animating issue when animating height to 'auto'
  return (
    <AnimatePresence initial={false}>
      {
        active &&
        <motion.div
          tabIndex={0}
          ref={megaMenuRef}
          initial="isHidden"
          exit="isHidden"
          animate="isVisible"
          className={classes}
          variants={variant}>
          <div className={s.mega_menu_inner}>
            {children && children(active)}
          </div>
        </motion.div>
      }
    </AnimatePresence>
  )

}

export default MegaMenu