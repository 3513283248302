import React, { useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import { parseISO } from 'date-fns'
import cn from 'classnames'

import { CountdownTimeState, CountdownTimerProps } from './types'
import getFormattedUTCDate from 'helpers/getFormattedUTCDate'

import s from './styles.module.css'

function calculateTimeLeft(end: Date) {
  const difference = +end - +new Date()
  return difference <= 0 ? null : difference
}

function formatMilliseconds(ms: number) {
  return {
    days: Math.floor(ms / (1000 * 60 * 60 * 24)),
    hours: Math.floor((ms / (1000 * 60 * 60)) % 24),
    minutes: Math.floor((ms / 1000 / 60) % 60),
    seconds: Math.floor((ms / 1000) % 60)
  }
}

function getCountdownState(countdownStartDate: Date | null, countdownEndDate: Date | null, openBeforeSeconds: number) {
  const now = new Date()
  const saleStarted = countdownStartDate ? now >= countdownStartDate : true
  const openTime = countdownStartDate ? new Date(countdownStartDate.getTime() - openBeforeSeconds * 1000) : null
  const timeLeft = countdownEndDate ? calculateTimeLeft(countdownEndDate) : true

  if (!countdownStartDate && !countdownEndDate) {
    return CountdownTimeState.NotStarted
  }
  else if (!saleStarted) {
    if (openTime && now >= openTime) {
      return CountdownTimeState.Open
    }
    return CountdownTimeState.NotStarted
  } else if (timeLeft) {
    return CountdownTimeState.Active
  } else {
    return CountdownTimeState.Finished
  }
}

const CountdownTimer = (props: CountdownTimerProps) => {
  const {
    endDate,
    startDate,
    openBeforeSeconds = 0,
    noTimeLeftMessage,
    countdownActivePrefix,
    countdownFinishedPrefix,
    countdownOpenPrefix,
    className,
    countdownFinishedClassName,
    countdownNotStartedClassName,
    countdownActiveClassName,
    countdownOpenClassName,
    displayNotStarted = true,
    displayOpen = true,
    displayActive = true,
    displayFinished = true,
    daysText = 'd',
    hoursText = 'h',
    minutesText = 'm',
    secondsText = 's',
    showSeconds = true,
    timeEndingClassName,
    timeEndingThresholdSeconds = 60,
    children
  } = props
  const countdownStartDate = startDate ? parseISO(startDate) : null
  const countdownEndDate = endDate ? parseISO(endDate) : null

  const [timeLeft, setTimeLeft] = useState(countdownEndDate ? calculateTimeLeft(countdownEndDate) : null)
  const [saleState, setSaleState] = useState<CountdownTimeState>(getCountdownState(countdownStartDate, countdownEndDate, openBeforeSeconds))
  const countdownStartDateString = getFormattedUTCDate(startDate)
  const formattedTimeLeft = timeLeft ? formatMilliseconds(timeLeft) : null

  useEffect(() => {
    const timer = setInterval(() => {
      const newState = getCountdownState(countdownStartDate, countdownEndDate, openBeforeSeconds)
      setSaleState(newState)

      // Update timeLeft if the state is Active
      if (countdownEndDate && newState === CountdownTimeState.Active) {
        setTimeLeft(calculateTimeLeft(countdownEndDate))
      }
    }, 1000)

    return () => clearInterval(timer)
  }, [countdownEndDate, countdownStartDate])

  const renderChildren = typeof children === "function" ? children(saleState) : children

  if (!startDate && !endDate) {
    return renderChildren
  }

  if (saleState === CountdownTimeState.Finished) {
    return (
      <>
        {renderChildren}
        {
          displayFinished &&
          <div className={cn(className, countdownFinishedClassName)}>
            {noTimeLeftMessage}
          </div>
        }
      </>
    )
  }

  if (saleState === CountdownTimeState.NotStarted) {
    return (
      <>
        {renderChildren}
        {
          displayNotStarted &&
          <div className={cn(className, countdownNotStartedClassName)}>
            {countdownFinishedPrefix} {countdownStartDateString} UTC
          </div>
        }
      </>
    )
  }

  if (saleState === CountdownTimeState.Open) {
    return (
      <>
        {renderChildren}
        {
          displayOpen &&
          <div className={cn(className, countdownOpenClassName)}>
            {countdownOpenPrefix} {countdownStartDateString} UTC
          </div>
        }
      </>
    )
  }

  return (
    <>
      {renderChildren}
      {timeLeft && displayActive &&
        <div className={cn(s.countdown_timer, className, countdownActiveClassName)}>
          <span className={s.countdown_timer_prefix}>
            {countdownActivePrefix}
          </span>
          <div className={cn(s.countdown_timer_time, {
            // Convert seconds prop to MS
            [`${timeEndingClassName}`]: timeEndingClassName && timeLeft <= timeEndingThresholdSeconds * 1000
          })}>
            <span className={s.countdown_timer_item}>
              <span className={s.time_box}>{formattedTimeLeft?.days}</span>
              <span className={s.time_text}>{daysText}</span>
            </span>
            <span className={s.countdown_timer_item}>
              <span className={s.time_box}>{formattedTimeLeft?.hours}</span>
              <span className={s.time_text}>{hoursText}</span>
            </span>
            <span className={s.countdown_timer_item}>
              <span className={s.time_box}>{formattedTimeLeft?.minutes}</span>
              <span className={s.time_text}>{minutesText}</span>
            </span>
            {
              showSeconds &&
              <span className={s.countdown_timer_item}>
                <span className={s.time_box}>{formattedTimeLeft?.seconds}</span>
                <span className={s.time_text}>{secondsText}</span>
              </span>
            }
          </div>
        </div>
      }
    </>
  )
}


export default dynamic(() => Promise.resolve(CountdownTimer), {
  ssr: false,
});