import { useState } from 'react'

function useModal() {
  const [open, setOpen] = useState<boolean>(false)

  const handleCancel = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  return {
    open: handleOpen,
    close: handleCancel,
    isOpen: open
  }

}

export default useModal
