import { useMediaQuery } from 'react-responsive'

// Note:: this will need changed if the tailwind config is extended
import { theme } from '../../tailwind.config.cjs'

const breakpoints = theme.screens
type BreakpointKey = keyof typeof breakpoints

const useTailwindMediaQuery = <K extends BreakpointKey>(breakpointKey: K) =>
    useMediaQuery({ query: `(min-width: ${breakpoints[breakpointKey]})` })

export default useTailwindMediaQuery
