import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import MarketplaceHttpClient from '@/network/httpClients/MarketplaceHttpClient'
import useAuthApi from 'hooks/useAuthApi'
import { MarketplacePreAlphaPaymentStatusResponse } from '../types'

export const MARKETPLACE_QUERY_KEY = 'marketplace'

/**
 * Get stats on a sale (Azure)
 * @param saleKey
 * @returns
 */
export const useMarketplaceSaleOverview = (saleKey: string) =>
    useQuery({
        queryKey: [MARKETPLACE_QUERY_KEY, saleKey],
        queryFn: () => MarketplaceHttpClient.GetSaleOverviewStats(saleKey),
        retry: false,
        refetchOnWindowFocus: false,
    })

/**
 * Check status of pre-alpha purchase
 * @param sub string
 * @param paymentId string
 * @returns
 */
export const useGetPreAlphaPaymentStatus = (
    sub: string,
    paymentId: string,
    options?: Omit<UseQueryOptions<string>, 'queryKey' | 'queryFn'>,
) =>
    useAuthApi({
        queryKey: [MARKETPLACE_QUERY_KEY, 'pre_alpha_payment', paymentId],
        queryFn: () => MarketplaceHttpClient.GetPreAlphaAccessPaymentStatus(sub, paymentId),
        retry: false,
        refetchOnWindowFocus: false,
        ...options,
    })
