import React from 'react'

import { DropdownMenu } from '../../Dropdown'
import { Sort } from '../../Icon'
import Typography from '../../Typography'
import FilterItem from './FilterItem'

import s from './styles.module.css'
import { FilterListProps, Option } from './types'

/**
 * Filter UI for blog
 * @param props 
 * @returns 
 */
const FilterList = (props: FilterListProps) => {
  const { options = [], label, pathname, queryKey } = props

  return (
    <DropdownMenu
      label={(
        <>
          <Typography as="span" uppercase>{label}</Typography>
          <Sort direction="none" />
        </>
      )}
      options={options}
      menuItemClassName={s.menu_item}>
      {
        (item: Option, bag, index) => <FilterItem
          key={index}
          onClick={bag.close}
          value={item.value}
          label={item.label}
          pathname={pathname}
          queryKey={queryKey}
        />
      }
    </DropdownMenu>
  )
}

export default FilterList