import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const Email = (props: SVGIconProps) => {
  const { width = 36, height = 32, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} aria-label="Email Icon" {...svgProps}>
      <path d="M10 28C8.4 28 6.8 26.6 6 26C2 23.2 0.8 22.2 0 21.6V30C0 31.1044 0.895625 32 2 32H18C19.1044 32 20 31.1044 20 30V21.6C19.2 22.2 18 23.2 14 26C13.2 26.6 11.6 28 10 28ZM18 16H2C0.895625 16 0 16.8956 0 18V19C1.6 20.2 1.4 20.2 7.2 24.4C7.8 24.8 9 26 10 26C11 26 12.2 24.8 12.8 24.6C18.6 20.4 18.4 20.4 20 19.2V18C20 16.8956 19.1044 16 18 16ZM34 10H14C12.8956 10 12 10.8956 12 12V14H18C20.0756 14 21.7869 15.5887 21.9819 17.6137L22 17.6V26H34C35.1044 26 36 25.1044 36 24V12C36 10.8956 35.1044 10 34 10ZM32 18H28V14H32V18ZM10 12C10 9.79437 11.7944 8 14 8H28V2C28 0.895625 27.1044 0 26 0H6C4.89563 0 4 0.895625 4 2V14H10V12Z" fill="currentcolor" />
    </SVGIcon>
  )
}

export default Email
