import { NetworkType } from 'hooks/useWallet/useCardano'

export const CARDANO_NETWORK_TYPE = (process.env.NEXT_PUBLIC_CARDANO_NETWORK_TYPE ??
    'preprod') as NetworkType

/**
 * Check if current environment is using mainnet
 * @returns
 */
export function isMainnet(): boolean {
    return CARDANO_NETWORK_TYPE === NetworkType.MAINNET
}
export function isTestnet(): boolean {
    return CARDANO_NETWORK_TYPE === NetworkType.TESTNET
}
