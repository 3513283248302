import { CoinGeckoCurrencies, CoinGeckoTokenPrices, COINGECKO_API } from './types'

const getTokenPrice = async (
    chainId: string,
    contractIds: string[],
    vsCurrencies: CoinGeckoCurrencies[] = ['usd'],
): Promise<CoinGeckoTokenPrices | null> => {
    let prices = null

    await fetch(
        `${COINGECKO_API}/simple/token_price/${chainId}?contract_addresses=${contractIds.join(
            ',',
        )}&vs_currencies=${vsCurrencies.join(',')}`,
    )
        .then((response) => response.json())
        .then((result) => {
            prices = result ?? null
        })
        .catch(() => {
            prices = null
        })

    return prices
}

export default getTokenPrice
