import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const Gridx2 = (props: SVGIconProps) => {
  const { width = 26, height = 26, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} aria-label="Grid 2 Columns" {...svgProps}>
      <rect width="11" height="11" fill="currentcolor" />
      <rect x="15" width="11" height="11" fill="currentcolor" />
      <rect y="15" width="11" height="11" fill="currentcolor" />
      <rect x="15" y="15" width="11" height="11" fill="currentcolor" />
    </SVGIcon>
  )
}

export default Gridx2
