import { useSyncExternalStore } from 'react'
import { parseISO } from 'date-fns'

// An empty subscribe function for useSyncExternalStore
const emptySubscribe = () => () => {}

const useSafeIsoDate = (isoDate: string | null | undefined): Date | null => {
    // This should silence hydration errors
    const date = useSyncExternalStore(
        emptySubscribe,
        () => (isoDate ? parseISO(isoDate) : null),
        () => null,
    )

    return date
}

export default useSafeIsoDate
