import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const Holocache = (props: SVGIconProps) => {
  const { width = 17, height = 17, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} {...svgProps}>
      <path d="M3.7988 2.58826L2.74988 3.63718V12.4895L3.7988 13.5385H12.3291L13.3781 12.4895V3.63718L12.3291 2.58826H3.7988ZM11.4459 11.6063H4.68203V4.52041H11.4459V11.6063Z" />
      <path d="M1.95754 6.42847H0V9.6995H1.95754V6.42847Z" />
      <path d="M16.128 6.42847H14.1704V9.6995H16.128V6.42847Z" />
      <path d="M9.6995 14.3308H6.42847V16.128H9.6995V14.3308Z" />
      <path d="M9.6995 0H6.42847V1.7972H9.6995V0Z" />
      <path d="M9.6995 6.42847H6.42847V9.6995H9.6995V6.42847Z" />
    </SVGIcon>
  )
}

export default Holocache