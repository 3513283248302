import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const SlotHeadIcon = (props: SVGIconProps) => {
  const { width = 37, height = 37, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} aria-label="Search" {...svgProps}>
      <path d="M31.6691 37.0001H5.33095C2.39144 37.0001 0 34.6086 0 31.6692V5.33101C0 2.39156 2.39144 0.00012207 5.33095 0.00012207H31.6691C34.6086 0.00012207 37 2.39156 37 5.33101V31.6692C37 34.6086 34.6086 37.0001 31.6691 37.0001ZM5.33095 1.83656C3.40406 1.83656 1.83644 3.40413 1.83644 5.33101V31.6692C1.83644 33.596 3.40401 35.1636 5.33095 35.1636H31.6691C33.5959 35.1636 35.1636 33.5961 35.1636 31.6692V5.33101C35.1636 3.40419 33.596 1.83656 31.6691 1.83656H5.33095Z" fill="white" />
      <path d="M22.1488 20.9224C21.7292 20.9224 21.3505 20.633 21.254 20.2064C21.1423 19.7118 21.4526 19.2201 21.9472 19.1083C22.5095 18.9813 22.9176 18.4695 22.9176 17.8915C22.9176 17.2036 22.358 16.6441 21.6702 16.6441C21.163 16.6441 20.752 16.233 20.752 15.7258C20.752 15.2187 21.163 14.8076 21.6702 14.8076C23.3707 14.8076 24.7541 16.191 24.7541 17.8915C24.7541 19.343 23.7663 20.58 22.3521 20.8996C22.284 20.915 22.2159 20.9224 22.1488 20.9224V20.9224Z" fill="white" />
      <path d="M24.7856 32.4055H16.4891C15.4592 32.4055 14.6213 31.5676 14.6213 30.5378V28.7188H12.5935C10.3465 28.7188 8.51835 26.8908 8.51835 24.6438V22.6954C8.51835 22.5428 8.42682 22.4073 8.28524 22.3502L7.20886 21.9167C6.53511 21.6453 6.03141 21.09 5.82694 20.3931C5.62252 19.6962 5.74631 18.9568 6.16656 18.3645L8.3661 15.265C8.47726 15.1084 8.54486 14.9238 8.56168 14.7309C8.80179 11.9805 10.0541 9.42958 12.088 7.54804C14.1337 5.65559 16.7956 4.6067 19.5834 4.59448C19.5993 4.59448 19.6154 4.59436 19.6313 4.59436C22.5129 4.59436 25.2607 5.73066 27.3737 7.79728C29.4988 9.87578 30.6964 12.6166 30.746 15.5148C30.8016 18.7679 29.4374 21.8711 27.003 24.029C26.7808 24.226 26.6533 24.5122 26.6533 24.8143V30.5377C26.6533 31.5676 25.8155 32.4055 24.7856 32.4055L24.7856 32.4055ZM16.4577 28.7188V30.5378C16.4577 30.5551 16.4718 30.5691 16.4891 30.5691H24.7856C24.8029 30.5691 24.817 30.5551 24.817 30.5378V24.8144C24.817 23.9871 25.1697 23.2 25.7849 22.6547C27.8173 20.8532 28.9563 18.2623 28.9098 15.5463C28.8686 13.1344 27.867 10.8487 26.0896 9.11027C24.3221 7.38155 22.0299 6.43091 19.6311 6.43091C19.6179 6.43091 19.6047 6.43091 19.5915 6.43103C17.2644 6.44118 15.0425 7.3167 13.335 8.89621C11.6369 10.4671 10.5914 12.5961 10.3911 14.8908C10.3459 15.4085 10.1635 15.9055 9.86366 16.328L7.66418 19.4273C7.53689 19.6067 7.56249 19.7855 7.58906 19.8761C7.61563 19.9668 7.69069 20.1311 7.89482 20.2132L8.9712 20.6468C9.81172 20.9852 10.3547 21.7894 10.3547 22.6954V24.6438C10.3547 25.8781 11.359 26.8824 12.5934 26.8824H17.0596C17.9804 26.8824 18.7818 26.2913 19.0538 25.4116C19.2035 24.9271 19.7175 24.6558 20.2023 24.8055C20.6867 24.9553 20.9581 25.4694 20.8084 25.9539C20.2971 27.6077 18.7906 28.7188 17.0596 28.7188H16.4577Z" fill="white" />
    </SVGIcon>
  )
}

export default SlotHeadIcon


