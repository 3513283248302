import React, { PropsWithChildren } from 'react'
import cn from 'classnames'

import s from './styles.module.css'

/**
 * List item
 * @param props 
 * @returns 
 */
const ListItem = (props: { className?: string } & PropsWithChildren) => {
  const { children, className } = props

  return (
    <article className={cn(s.list_item, className)} >
      {children}
    </article >
  )
}

export default ListItem

