import React from 'react'
import { useScroll, useSpring, motion, useTransform } from 'framer-motion';

import s from './styles.module.css'

const GalleryScrollIndicator = () => {
  const { scrollYProgress } = useScroll()
  const y = useSpring(scrollYProgress)
  const height = useTransform(y, (value) => `${value * 100}%`)

  return (
    <div className={s.progress_container}>
      <motion.div className={s.progress} style={{ height }} />
    </div>
  )
}

export default GalleryScrollIndicator
