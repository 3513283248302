import dynamic from 'next/dynamic'

// Component uses local storage, lazy load
const Flow = dynamic(() => import('./Flow'), {
    ssr: false,
})

export * from './types'

export default Flow
