import React from 'react'
import cn from 'classnames'

import { BLOCKCHAIN_CURRENCY_TYPE_ADA, BLOCKCHAIN_CURRENCY_TYPE_COPI, BLOCKCHAIN_CURRENCY_TYPE_USDC, BLOCKCHAIN_TYPE_CARDANO } from 'consts/blockchain'

import { Ada, CopiIcon, Usdc } from '../Icon'
import { BlockchainCurrencyIconProps } from './types'

import s from './styles.module.css'

const BlockchainCurrencyIcon = (props: BlockchainCurrencyIconProps) => {
  const { currency, className, chains = [], ...iconProps } = props
  let icon
  let title

  switch (currency.toLowerCase()) {
    case BLOCKCHAIN_CURRENCY_TYPE_ADA:
      icon = <Ada {...iconProps} />
      title = "ADA (Cardano)"
      break
    case BLOCKCHAIN_CURRENCY_TYPE_COPI:
      icon = <CopiIcon {...iconProps} />
      title = `COPI (Cardano / Base)`
      break
    case BLOCKCHAIN_CURRENCY_TYPE_USDC:
      icon = <Usdc {...iconProps} />
      title = "USDC (Base)"
      break
  }

  return (
    <span
      title={title}
      className={cn(s.blockchain_icon, className)}>
      {icon}
    </span>
  )
}

export default BlockchainCurrencyIcon