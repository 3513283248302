/**
 * Remove trailing slash from string
 * @returns
 */
const removeTrailingSlash = (str: string): string => {
    if (!str) return str

    return str.replace(/\/+$/, '')
}

export default removeTrailingSlash
