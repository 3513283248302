import React, { useCallback, useRef, useEffect } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { useRouter } from 'next/router'
import cx from 'classnames'

import StyledButton from '@/components/ui/StyledButton'
import Typography from '@/components/ui/Typography'
import { Question, Tick } from '@/components/ui/Icon'
import Mask from '@/components/ui/Mask'
import Alert from '@/components/ui/Alert'

import s from './styles.module.css'
import { WizardProps } from './types'

const Wizard = (props: WizardProps) => {
  const { forms, skipButtonText, initialKey, url = '' } = props
  const skippedPages = useRef<number[]>([])
  const router = useRouter()
  const { step } = router.query;
  const current = step || initialKey || 0

  const currentForm = forms.find((form) => form.key === current)
  const {
    key: currentKey,
    component: CurrentForm,
    canSkip = true,
    canGoBack = true
  } = currentForm || {}

  const formIndex = forms.findIndex((form) => form.key === currentKey)
  const stepCount = forms.length

  // Remove any steps from skipped array ahead of form index
  useEffect(() => {
    const sPages = skippedPages.current
    skippedPages.current = sPages.filter(value => value < formIndex)
  }, [formIndex, step])

  const getNextForm = useCallback(
    () => {
      const nextForm = forms[formIndex + 1]
      const sPages = skippedPages.current

      if (!sPages.includes(formIndex))
        sPages.push(formIndex)

      router.push(`${url}?step=${nextForm.key}`)
    },
    [formIndex, forms, router, url],
  )

  const getPreviousForm = useCallback(
    () => {
      const nextForm = forms[formIndex - 1]
      const sPages = skippedPages.current

      if (sPages.includes(formIndex)) {
        skippedPages.current = sPages.filter(value => value !== formIndex)
      }

      router.push(`${url}?step=${nextForm.key}`)
    },
    [formIndex, forms, router, url],
  )

  return (
    <>
      <ul className={s.indicators}>
        {
          forms.map((form, index) => {
            const { label, showInProgress, key } = form
            const hasCompleted = index < formIndex
            const active = index === formIndex
            const skipped = skippedPages.current.includes(index)

            const indicatorClasses = cx(
              s.indicator,
              hasCompleted && s.done,
              hasCompleted && skipped && s.skipped,
              active && s.active
            )

            // Exlcude hidden steps
            if (!showInProgress) return false

            return (
              <li key={key} className={indicatorClasses}>
                <Mask className={s.indicator_background}>
                  <Mask
                    type={hasCompleted || active ? "notched" : "pseudo-notched-outline"}
                    className={s.indicator_status}>
                    {
                      // eslint-disable-next-line no-nested-ternary
                      hasCompleted && !active && skipped ? <Question /> : hasCompleted ? <Tick /> : index + 1
                    }
                  </Mask>
                </Mask>
                <Typography className={s.indicator_label}>
                  {label}
                </Typography>
              </li>
            )
          }
          )
        }
      </ul>

      <AnimatePresence initial={false} mode="wait">
        <motion.div
          key={currentKey}
          initial={{
            opacity: 0
          }}
          animate={{
            opacity: 1
          }}
          exit={{
            opacity: 0
          }}>
          {
            CurrentForm ?
              <CurrentForm /> :
              <Alert type="error">Form does not exist.</Alert>
          }
        </motion.div>
      </AnimatePresence>

      {
        CurrentForm && canSkip && (formIndex < (stepCount - 1)) &&
        <StyledButton
          onClick={getNextForm}
          className="w-full"
          variant="outline"
          color="secondary">
          {skipButtonText || "Skip this step"}
        </StyledButton>
      }

      {
        CurrentForm && canGoBack &&
        <StyledButton
          onClick={getPreviousForm}
          className="w-full"
          variant="outline"
          color="secondary">
          Go Back
        </StyledButton>

      }

    </>
  )
}

export default Wizard