import React from 'react'
import cx from 'classnames'
import { ImageProps } from 'next/image'

import s from './styles.module.css'
import Image from '../../../Image'

/**
 * Mega menu image
 * @param props 
 * @returns 
 */
const NavImage = (props: ImageProps) => {
  const { className, ...imageProps } = props

  const classes = cx(s.image, className)

  return (
    <div className={s.wrapper}>
      <Image className={classes} {...imageProps} />
    </div>
  )
}

export default NavImage