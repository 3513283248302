import React from 'react'
import NextLink from 'next/link'
import cn from 'classnames'

import SvgMask from '@/components/ui/SvgMask'

import { TabProps } from './types'
import s from './styles.module.css'

// Paths must be relative - See: https://yoksel.github.io/relative-clip-path/
// export const TAB_FIRST_ACTIVE_MASK = 'M1,1 L0,1 L0,0.275 C0,0.123,0.022,0,0.048,0 L0.887,0 C0.9,0,0.912,0.029,0.921,0.081 L0.986,0.449 C0.995,0.501,1,0.571,1,0.644 L1,1'
export const TAB_FIRST_MASK = 'M1,1 L0,1 L0,0.275 C0,0.123,0.022,0,0.048,0 L0.887,0 C0.9,0,0.912,0.029,0.921,0.081 L0.986,0.449 C0.995,0.501,1,0.571,1,0.644 L1,1'
// export const TAB_ACTIVE_MASK = 'M1,1 L0,1 L0,0.275 C0,0.123,0.022,0,0.048,0 L0.887,0 C0.9,0,0.912,0.029,0.921,0.081 L0.986,0.449 C0.995,0.501,1,0.571,1,0.644 L1,1'
export const TAB_MASK = 'M0.053,0.685 L0.053,1 L1,1 L1,0.685 C1,0.607,0.995,0.533,0.986,0.478 L0.921,0.086 C0.912,0.031,0.9,0,0.887,0 L0.046,0 C0.024,0,0.006,0.09,0,0.213 L0.036,0.433 C0.047,0.5,0.053,0.59,0.053,0.685'

const Tab = (props: TabProps) => {
  const { children, isActive, className, activeClassName, href, first = false } = props

  const link = (
    <NextLink
      className={s.link}
      href={href}>
      {children}
    </NextLink>
  )

  return isActive ? (
    <SvgMask
      id={href}
      className={cn(s.tab, first && s.first, s.active, className, activeClassName)}
      mask={first ? TAB_FIRST_MASK : TAB_MASK}>
      {link}
    </SvgMask>
  ) : (
    <SvgMask
      id={href}
      className={cn(s.tab, first && s.first, className)}
      mask={first ? TAB_FIRST_MASK : TAB_MASK}>
      {link}
    </SvgMask>
  )
}

export default Tab
