import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const Question = (props: SVGIconProps) => {
  const { width = 12, height = 12, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} {...svgProps}>
      <path d="M6.19449 9.71985H5.6149C5.20672 9.71985 4.875 10.0516 4.875 10.4598V11.2601C4.875 11.6683 5.20672 12 5.6149 12H6.19449C6.60269 12 6.9344 11.6683 6.9344 11.2601V10.4598C6.9344 10.0504 6.60269 9.71985 6.19449 9.71985Z" fill="currentcolor" />
      <path d="M2.10753 3.52441L3.17669 3.65759C3.51582 3.69952 3.83767 3.50468 3.95976 3.18528C4.1139 2.78204 4.32848 2.46388 4.60347 2.23204C4.99317 1.90402 5.47779 1.73877 6.05738 1.73877C6.65794 1.73877 7.13641 1.89662 7.49156 2.21108C7.84672 2.52677 8.02306 2.90412 8.02306 3.34683C8.02306 3.66499 7.92317 3.95602 7.7234 4.21992C7.59392 4.38763 7.1956 4.74031 6.53092 5.27921C5.86501 5.81811 5.42231 6.30396 5.20034 6.73558C4.97344 7.17706 4.89452 7.66662 4.87478 8.15744C4.85753 8.57795 5.19294 8.92817 5.61469 8.92817H6.21894C6.60369 8.92817 6.92185 8.63344 6.95638 8.25116C6.98104 7.97617 7.02667 7.76777 7.09574 7.62595C7.21165 7.383 7.51131 7.06238 7.99224 6.66284C8.92328 5.8921 9.53 5.28291 9.81487 4.83404C10.0985 4.38639 10.2416 3.91162 10.2416 3.40972C10.2416 2.50334 9.85557 1.70794 9.08237 1.02477C8.3104 0.341589 7.26961 0 5.96244 0C4.71941 0 3.71684 0.336656 2.95227 1.0112C2.30115 1.58586 1.90531 2.26657 1.76596 3.0521C1.72278 3.28147 1.8794 3.49604 2.10753 3.52441Z" fill="currentcolor" />
    </SVGIcon>
  )
}

export default Question