import React, { ComponentPropsWithoutRef, ElementType } from 'react'
import cx from 'classnames'

import { LinkProps } from './types'
import s from './styles.module.css'

/**
 * Navigation Link without router
 * @param props
 * @returns 
 */
const Link = <T extends ElementType = "a">(props: LinkProps<T> & Omit<ComponentPropsWithoutRef<T>, keyof LinkProps<T>>) => {
  const { as, active = false, children, className, ...linkProps } = props
  const Component = as || "a"

  const classes = cx(s.navlink, { [`${s.active}`]: active }, className)

  return (
    <Component className={classes} {...linkProps}>{children}</Component>
  )
}

export default Link