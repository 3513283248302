import React, { forwardRef } from 'react'
import cx from 'classnames'

import s from './styles.module.css'
import { StyledRadioButtonProps } from './types'

const StyledRadioButton = forwardRef<HTMLInputElement, StyledRadioButtonProps>((props, ref) => {
  const {
    className,
    id,
    hasError,
    children,
    size = 'md',
    ...inputProps
  } = props

  const inputClasses = cx(
    s.radio_button,
    hasError && s.error,
    s[size],
    className
  )

  return (
    <label className={inputClasses} htmlFor={id}>
      <input
        type="radio"
        id={id}
        ref={ref}
        {...inputProps}
      />
      <span>{children}</span>
    </label>
  )
})

export default StyledRadioButton