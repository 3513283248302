import React from 'react'

import Box from '@/components/ui/Box'
import Image from '@/components/ui/Image'
import Link from '@/components/ui/Link'
import SvgMask from '@/components/ui/SvgMask'
import Typography from '@/components/ui/Typography'
import { CARD_MASK } from '@/consts'

import s from './styles.module.css'
import { GalleryCategoryType } from './types'

/**
 * Gallery Category Card
 * @param props 
 * @returns 
 */
function GalleryCategory(props: GalleryCategoryType) {
  const { href, title, thumbnail } = props

  return (
    <Box
      className={s.card}>
      <Link
        className={s.link}
        href={href}>
        <SvgMask id={href} mask={CARD_MASK}>
          <div className={s.image_wrapper}>
            <Image
              alt={thumbnail?.alt}
              src={thumbnail?.src || '/_assets/placeholder.webp'}
              sizes="(max-width: 768px) 100vw,
                    (max-width: 1200px) 50vw,
                    33vw"
              fill
              className={s.image}
            />
          </div>
        </SvgMask>
        <Typography
          as="h4"
          className={s.title}>
          <span>{title}</span>
        </Typography>
      </Link>
    </Box>
  )
}

export default GalleryCategory