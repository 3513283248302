import React, { useMemo, useRef } from 'react'
import { useRouter } from 'next/router'

import { Sort as SortIcon } from '../../Icon'

import s from './styles.module.css'
import { SortButtonProps } from './types'
import { SortDirection } from '../../Icon/types'
import Link from '../../Link'

const SortButton = (props: SortButtonProps) => {
  const currentSort = useRef<SortDirection>('none')
  const router = useRouter()
  const { orderBy } = router.query
  const { children, pathname, value, ...linkProps } = props
  const [sortby, sort] = String(orderBy).split(' ')
  const isActive = sortby === value
  const query = {} as Record<string, string>

  // Reset other values
  if (!isActive)
    currentSort.current = 'none'
  else
    currentSort.current = sort as SortDirection

  const nextSort = useMemo(() => {
    let next = currentSort.current

    if (sort === "none" || !isActive)
      next = "asc"
    else if (sort === "asc")
      next = "desc"
    else
      next = "none"

    return next
  }, [sort, isActive])

  if (nextSort !== "none")
    query.orderBy = `${value} ${nextSort}`

  return (
    <Link
      className={s.button}
      href={
        {
          pathname,
          query
        }
      }
      {...linkProps}>
      <span>{children}</span>
      <SortIcon sort={currentSort.current} />
    </Link>
  )

}

export default SortButton