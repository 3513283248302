import React from 'react'

import { CnftIo, JpegStore, TokenRiot } from '../Icon'
import Link from '../Link'

import { ExternalMarketplaceLinkProps } from './types'

const logos = {
  'jpg.store': <JpegStore className="h-[26px]" />,
  'cnft.io': <CnftIo />,
  'tokenriot': <TokenRiot className="h-5" />
}

/**
 * Show marketplace link as logo
 * @param props 
 * @returns 
 */
const ExternalMarketplaceLink = (props: ExternalMarketplaceLinkProps) => {
  const { name, url, displayName, className } = props

  const logo = logos[name] ?? displayName

  return (
    <Link
      target="_blank"
      className={className}
      href={url}>
      {logo}
    </Link>
  )
}

export default ExternalMarketplaceLink
