export default class InjectWalletListener {
    interval: number

    onChangeCallback: (wallets: string[]) => void

    timeoutId: NodeJS.Timeout | null

    private wallets: Array<string>

    constructor(onChangeCallback: (wallets: string[]) => void) {
        this.interval = 20
        this.timeoutId = null
        this.onChangeCallback = onChangeCallback
        this.wallets = []
    }

    private checkWallets() {
        const injectedWallets = window.cardano && Object.keys(window.cardano).sort()
        if (JSON.stringify(this.wallets) !== JSON.stringify(injectedWallets)) {
            this.wallets = injectedWallets
            this.onChangeCallback(this.wallets)
        }
    }

    start() {
        this.timeoutId = setTimeout(() => {
            this.checkWallets()

            if (this.interval < 10000) {
                this.interval *= 1.5
            }

            this.start()
        }, this.interval)
    }

    stop() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId)
        }
    }
}
