import React from 'react'
import { Typography, Button } from '@/components/ui'
import { Arrow } from '@/components/ui/Icon';
import { DropdownMenu } from '@/components/ui/Dropdown';

import { SelectMenuProps } from './types'
import s from './styles.module.css'

const SelectMenu = (props: SelectMenuProps) => {
  const { onChange, options, value, label, placeholder = "Select an option" } = props

  const selected = options.find(option => option.value === value)

  return (
    <div>
      {
        label &&
        <Typography
          className={s.filter_dropdown_field_label}
          uppercase>
          {label}
        </Typography>
      }
      <DropdownMenu
        menuClassName={s.filter_dropdown}
        buttonClassName={s.filter_dropdown_button}
        menuItemsClassName={s.filter_dropdown_items}
        menuItemClassName={s.filter_dropdown_item}
        options={options}
        label={(open) => (
          <Typography
            className={s.filter_dropdown_label}
            as="span">
            {selected?.label ?? placeholder}
            <Arrow
              className={s.filter_dropdown_label_carat}
              rotation={open ? "-90" : "180"} />
          </Typography>
        )}>
        {
          (item) => (
            <Button
              className={s.filter_dropdown_item_button}
              onClick={() => onChange(item)}>
              {item.label}
            </Button>
          )
        }
      </DropdownMenu>
    </div>
  )
}

export default SelectMenu