import { useState, useMemo, useCallback } from 'react'
import { SortingState, ColumnSort } from '@tanstack/react-table'

export const useSorting = (props: ColumnSort[]) => {
    const [state, setState] = useState<SortingState>(props)

    const queryString = useMemo(
        () =>
            state
                .map(
                    ({ id: columnName, desc: isDesc }) =>
                        `${columnName} ${isDesc ? 'desc' : 'asc'}`,
                )
                .join(',') || undefined,
        [state],
    )

    return { state, onChange: setState, queryString }
}

type UsePaginationProps = {
    offset: number
    limit: number
}

export const usePagination = (props: UsePaginationProps) => {
    const [{ offset, limit }, setPagination] = useState(props)

    const setOffset = useCallback(
        (pageOffset: number) => {
            setPagination({ offset: pageOffset, limit })
        },
        [limit],
    )

    return { offset, limit, setOffset }
}

type UseTableProps = {
    columnSort: ColumnSort[]
    pagination: UsePaginationProps
}

export const useTableFilters = (props: UseTableProps) => {
    const { columnSort, pagination: defaultPagination } = props
    const sorting = useSorting(columnSort)
    const pagination = usePagination(defaultPagination)

    return { sorting, pagination }
}
