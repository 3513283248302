import pino from "pino"

const logger = pino({
  level: 'info',
  transport: {
    target: 'pino-pretty',
    options: {
      colorize: true,
    }
  },
  streams: [
    {
      stream: process.stdout,
    },
  ],
});

export default logger
