import React, { useEffect, useId, useRef, useCallback } from 'react'
import ReSelect, { Props as ReactSelectProps, SelectInstance } from 'react-select'
import cx from 'classnames'

import s from './styles.module.css'
import { SelectOption, SelectProps } from './types'

const Select = (props: SelectProps) => {
  const {
    className,
    placeholder,
    value,
    defaultValue = "",
    selectSize = "md",
    hasError,
    options = [],
    onChange,
    inputRef,
    isDisabled,
    ...selectProps
  } = props
  const selectRef = useRef(null);

  const selectClasses = cx(
    s.select,
    hasError && s.error,
    isDisabled && s.disabled,
    selectSize && s[selectSize],
    className
  )

  // Field won't clear itself so let's force it
  useEffect(() => {
    if (!selectRef || !selectRef.current)
      return

    if (!value || value === "") {
      (selectRef.current as SelectInstance<SelectOption>)?.clearValue()
    }
  }, [value, selectRef])

  return (
    <ReSelect
      instanceId={useId()}
      ref={(node: any) => {
        // Set our element ref
        selectRef.current = node

        if (typeof inputRef === 'function' && node) {
          inputRef(node)
        }
      }}
      className={selectClasses}
      classNamePrefix="custom-select"
      placeholder={placeholder}
      isDisabled={isDisabled}
      defaultValue={options.find(option => (option as SelectOption).value === defaultValue)}
      value={options.find(option => (option as SelectOption).value === value)}
      options={options}
      onChange={(option) => onChange?.((option as SelectOption)?.value)}
      {...(selectProps as ReactSelectProps)}
    />
  )
}

export default Select