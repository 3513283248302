import { useState } from 'react'

import { getTokenPrice as getTokenPriceFromCoinGecko } from '@/utils/CoinGecko'

import { getPriceFromCEX, getPriceFromMuesliSwap } from './utils'
import useInterval from '../useInterval'
import { PriceSource } from './types'

const dataSources = [
    {
        name: 'Muesli',
        href: 'https://muesliswap.com/',
        get: async () => {
            const price = await getPriceFromMuesliSwap(
                'b6a7467ea1deb012808ef4e87b5ff371e85f7142d7b356a40d9b42a0.Cornucopias [via ChainPort.io]_ADA',
            )

            return price
        },
    },
    {
        name: 'Cexplorer',
        href: 'https://cexplorer.io/',
        get: async () => {
            const price = await getPriceFromCEX('asset1c6uau7pufsxhnm7eg0eerhu4snwfd9sn7kvvvz')

            return price
        },
    },
    {
        name: 'CoinGecko',
        chain: 'bnb',
        href: 'https://www.coingecko.com/',
        get: async () => {
            const contractIds = ['0xfea292e5ea4510881bdb840e3cec63abd43f936f']
            const prices = await getTokenPriceFromCoinGecko('binance-smart-chain', contractIds, [
                'usd',
            ])

            return prices ? prices[contractIds[0]].usd : 0
        },
    },
    {
        name: 'CoinGecko',
        chain: 'eth',
        href: 'https://www.coingecko.com/',
        get: async () => {
            const contractIds = ['0x42baf1f659d765c65ade5bb7e08eb2c680360d9d']
            const prices = await getTokenPriceFromCoinGecko('ethereum', contractIds, ['usd'])

            return prices ? prices[contractIds[0]].usd : 0
        },
    },
    {
        name: 'CoinGecko',
        chain: 'base',
        href: 'https://www.coingecko.com/',
        get: async () => {
            const contractIds = ['0x0a953dd9fc813fefaf6015b804c9dfa0624690c0']
            const prices = await getTokenPriceFromCoinGecko('base', contractIds, ['usd'])

            return prices ? prices[contractIds[0]].usd : 0
        },
    },
]

/**
 *
 * @param priceSource What API and chain to use
 * @param delay Seconds
 * @param decimals Price to what precision
 * @returns
 */
function useGetPrice(priceSource: PriceSource, delay = 120, decimals = 4) {
    const [price, setPrice] = useState<number>(0)
    const source = dataSources.find(
        (dSource) =>
            dSource.name === priceSource.api &&
            (!priceSource.chain || (priceSource.chain && priceSource.chain === dSource.chain)),
    )

    const callApi = async () => {
        const get = source?.get

        if (!get) return

        const value = await get()

        setPrice(value)
    }

    useInterval(callApi, delay * 1000, true)

    return {
        name: source?.name,
        href: source?.href,
        price: price.toPrecision(decimals),
    }
}

export default useGetPrice
