import React from 'react'
import Dropdown from '../Dropdown'

import { DropdownMenuProps } from './types'
import MenuItem from '../MenuItem'
import { DropdownMenuRenderProps } from '../MenuItem/types'

/**
 * Menu component built from a list of options
 * @param props 
 * @returns 
 */
const DropdownMenu = (props: DropdownMenuProps) => {
  const { options = [], children, menuItemClassName, ...dropdownProps } = props

  return (
    <Dropdown {...dropdownProps}>
      {
        options.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <MenuItem className={menuItemClassName} key={index}>
            {
              (bag: DropdownMenuRenderProps) => children(item, bag, index)
            }
          </MenuItem>
        ))
      }
    </Dropdown>
  )
}

export default DropdownMenu