import React, { useEffect, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import cx from 'classnames'

import { Copy } from '@/components/ui/Icon'
import Button from '@/components/ui/Button'
import Typography from '@/components/ui/Typography'
import { useClipboard } from '@/hooks'

import { CopyButtonProps } from './types'

import s from './styles.module.css'

/**
 * Copy text button
 * @param props 
 * @returns 
 */
const CopyButton = (props: CopyButtonProps) => {
  const { text, className, copiedLabel = "Copied" } = props
  const [didCopy, setDidCopy] = useState<boolean>(false)
  const [, copy] = useClipboard()
  const buttonClasses = cx(s.copy_button, className)

  useEffect(() => {
    const timeId = setTimeout(() => {
      setDidCopy(false)
    }, 3000)

    return () => {
      clearTimeout(timeId)
    }
  }, [didCopy]);

  return (
    <Button
      title='Copy'
      className={buttonClasses}
      onClick={() => {
        copy(text)
        setDidCopy(true)
      }}>
      <Copy />
      <AnimatePresence>
        {
          didCopy &&
          <motion.div
            animate={{
              opacity: 1
            }}
            exit={{
              opacity: 0
            }}
            className={s.copied_text}>
            <Typography>
              {copiedLabel}
            </Typography>
          </motion.div>
        }
      </AnimatePresence>
    </Button>
  )
}

export default CopyButton