import { useEffect } from 'react'

const useEventListener = (
    event: keyof WindowEventMap,
    listener: EventListenerOrEventListenerObject | null,
    useCapture?: boolean,
): void => {
    useEffect(() => {
        if (listener) {
            window.addEventListener(event, listener, useCapture)

            return () => {
                window.removeEventListener(event, listener, useCapture)
            }
        }

        return () => {}
    }, [event, listener, useCapture])
}

export default useEventListener
