import { UseFiltersFilterConfiguration } from 'hooks/useFilters'
import { NumberParam, StringParam } from 'use-query-params'

export const NODE_STATUS_INSUFFICIENT_BALANCE = 'insufficientBalance'

export const FILTER_CONFIGURATION_NODES = [
    {
        id: 'page',
        alias: 'page',
        displayName: 'page',
        type: NumberParam,
        multiple: false,
        caseSensitive: true,
    },
    {
        id: 'sort',
        alias: 'sort',
        displayName: 'sort',
        type: StringParam,
        multiple: false,
        caseSensitive: true,
    },
    {
        id: 'order',
        alias: 'order',
        displayName: 'order',
        type: StringParam,
        multiple: false,
        caseSensitive: true,
    },
] as UseFiltersFilterConfiguration
