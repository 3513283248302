/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react'

import { chunkArray, isAbsolutePath } from 'helpers'

import Link from '../Link'
import Image from '../../../Image'

import s from './styles.module.css'
import { MegaMenuPanelImagePosition, MegaMenuPanelProps } from './types'

/**
 * Menu displayed when about option is clicked
 * @returns 
 */
const MenuPanel = (props: MegaMenuPanelProps) => {
  const { image, options, imagePosition = MegaMenuPanelImagePosition.RIGHT } = props
  const menuImagePosition = image?.position ?? imagePosition

  // Get the first two options for the first row
  const row1 = options.slice(0, 2)
  // Get the next two options for the second row
  const row2 = options.slice(2, 4)
  // Get the remaining options but chunk them into groups of 3, if no image has been set chunk full options array
  const rest = chunkArray(options.slice(image ? 4 : 0), 3)

  const menuImage = useMemo(() => {
    if (!image) return <></>

    return (
      <div className={s.column}>
        <Image
          width={300}
          height={300}
          className={s.image}
          alt={image.alt}
          src={image.src}
        />
      </div>
    )
  }
    , [image])

  return (
    <>
      {
        image &&
        <div className={s.row}>
          {
            menuImagePosition === 'left' && menuImage
          }

          <div className={s.column_two_third}>
            <div className={s.row}>
              {
                row1.map(({ label, href, description }) => (
                  <div
                    key={href}
                    className={s.column_half}>
                    <Link
                      href={href}
                      target={isAbsolutePath(href) ? "_blank" : undefined}
                      description={description}>
                      {label}
                    </Link>
                  </div>
                ))
              }
            </div>

            <div className={s.row}>
              {
                row2.map(({ label, href, description }) => (
                  <div
                    key={href}
                    className={s.column_half}>
                    <Link
                      href={href}
                      target={isAbsolutePath(href) ? "_blank" : undefined}
                      description={description}>
                      {label}
                    </Link>
                  </div>
                ))
              }
            </div>
          </div>

          {
            menuImagePosition === 'right' && menuImage
          }
        </div>
      }

      {
        rest.map((row, index) => (
          <div
            key={index}
            className={s.row}>
            {
              row.map(({ label, href, description }) => (
                <div
                  key={href}
                  className={s.column}>
                  <Link
                    href={href}
                    target={isAbsolutePath(href) ? "_blank" : undefined}
                    description={description}>
                    {label}
                  </Link>
                </div>
              ))
            }
          </div>
        ))
      }
    </>
  )
}

export default MenuPanel