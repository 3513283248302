import React, { useState } from 'react'
import { QueryClient, QueryClientProvider, HydrationBoundary } from '@tanstack/react-query'
import { ToastContainer } from 'react-toastify'
import type { NextPage } from 'next'

import { DefaultSeo, NextSeo, NextSeoProps } from 'next-seo'
import type { AppContext, AppInitialProps, AppProps } from 'next/app'
import { getSession, SessionProvider } from "next-auth/react"
import { getServerSession, Session } from 'next-auth'
import { options } from 'pages/api/auth/[...nextauth]'
import { NextAdapter } from 'next-query-params'
import { QueryParamProvider } from 'use-query-params'
import { useRouter } from 'next/router'
import NextApp from "next/app"
import Head from 'next/head'
import { WagmiProvider } from 'wagmi'

import MasterLayout from '@/components/shared/Layout/Master'
import { CookieConsent, FlashMessage, GoogleAnalytics } from '@/components/ui'
import { PlayerHttpClient } from '@/network/httpClients'
import { wagmiConfig } from 'consts/wagmi'
import { useGoogleTags } from '@/hooks'

import { UserType } from 'types'
import { PAGE_ERROR_CODES, PROTECTED_ROUTES, reactQueryConfig } from '@/consts'
import NonceContext from '@/providers/NonceContext'
import { PostTypeTypes } from '@/components/ui/Blog/types'
import TwitterAnalytics from '@/components/ui/TwitterAnalytics'
import { PageBackgroundProps } from '@/components/ui/PageBackground'
import AuthenticationProvider from '@/providers/AuthenticationProvider'

import SiteProvider from '../providers/SiteProvider'
import SEO from '../network/data/default-next-seo.config'
import logger from '../logger'

import "react-toastify/dist/ReactToastify.css";
import '../styles/globals.css'

export type NextPageWithLayout<P = Record<string, unknown>, IP = P> = NextPage<P, IP> & {
  Layout?: React.ComponentType
}

type AppPropsWithLayout = AppProps & {
  user: UserType | undefined,
  session: Session,
  nonce: string,
  Component: {
    Title?: string
    LayoutContain?: boolean
    HideAnnouncements?: boolean
    Subtitle?: string
    Separator?: string
    PageBackground?: PageBackgroundProps
    Seo: NextSeoProps
  } & NextPageWithLayout
}

const App = (props: AppPropsWithLayout) => {
  const { Component, pageProps, session, nonce, user } = props
  const [queryClient] = useState(() => new QueryClient(reactQueryConfig))

  const router = useRouter()
  const { gtagId, gtmId } = useGoogleTags()
  const twitterTrackingId = process.env.NEXT_PUBLIC_TWITTER_PIXEL_ID

  const Layout = Component.Layout ?? MasterLayout
  // Grab all properties that can be defined at component level and pass to layout
  const pageTitle = Component.Title ?? null
  const pageSubtitle = Component.Subtitle ?? null
  const layoutContain = Component.LayoutContain ?? true
  const pageTitleSeparator = Component.Separator ?? undefined
  const pageBackground = Component.PageBackground ?? undefined
  const hideAnnouncements = Component.HideAnnouncements ?? false
  const pageSeo = Component.Seo ?? undefined

  const defaultSeo = {
    ...SEO,
    // Dynically set canomical, this can be override, also remove parameters
    canonical: (`${process.env.NEXT_PUBLIC_SITE_URL}${router.asPath === "/" ? "" : router.asPath}`).split("?")[0]
  }

  return (
    <>
      <NonceContext.Provider value={nonce}>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        </Head>
        <GoogleAnalytics gtagId={gtagId} gtmId={gtmId} />
        <TwitterAnalytics trackingId={twitterTrackingId} />
        <WagmiProvider config={wagmiConfig}>
          <QueryParamProvider adapter={NextAdapter}>
            <QueryClientProvider client={queryClient}>
              <HydrationBoundary state={pageProps.dehydratedState}>
                <SessionProvider session={session || {}} refetchOnWindowFocus refetchInterval={600}>
                  <AuthenticationProvider user={user}>
                    <SiteProvider>
                      <FlashMessage />
                      <Layout
                        title={pageTitle}
                        subtitle={pageSubtitle}
                        separator={pageTitleSeparator}
                        background={pageBackground}
                        hideAnnouncements={hideAnnouncements}
                        contain={layoutContain}
                        {...pageProps}>
                        <DefaultSeo {...defaultSeo} />
                        {
                          pageSeo && <NextSeo {...pageSeo} />
                        }
                        <Component {...pageProps} />
                      </Layout>
                      <CookieConsent />
                      <ToastContainer
                        theme="dark"
                        position="bottom-right" />
                    </SiteProvider>
                  </AuthenticationProvider>
                </SessionProvider>
              </HydrationBoundary>
            </QueryClientProvider>
          </QueryParamProvider>
        </WagmiProvider>
      </NonceContext.Provider>
    </>
  );
}

App.getInitialProps = async (context: AppContext) => {
  const startTime = performance.now();

  const initialPropsPromise = NextApp.getInitialProps(context);
  const nonce = context.ctx.req?.headers["x-nonce"]

  // Fetch session
  const [initialProps, session]: [AppInitialProps, Session | null] = await Promise.all([
    initialPropsPromise,
    (async () => {
      if (typeof window === "undefined" && context.ctx.req && context.ctx.res) {
        // Get session server side only
        // @ts-expect-error TODO:: Cannot figure out how to resolve this TS error
        return await getServerSession(context.ctx.req, context.ctx.res, options);
      } else {
        return await getSession();
      }
    })()
  ]);

  let initialUserProps

  try {
    if (session?.accessToken) {
      const userClient = PlayerHttpClient.setAccessToken(session.accessToken);

      // TODO:: THIS IS TEMPORARY! THIS NEEDS REVIEWED
      // This is only done to retrieve the players full profile on pages that "MAY" need the data.
      // Each page individually needs reviewed.
      if (PROTECTED_ROUTES.includes(context.ctx.pathname)) {
        const user = await userClient.GetUserFull(session.user.id)
        initialUserProps = user.playerUser
      }
      else {
        initialUserProps = await userClient.GetUserShort();
      }
    }
  } catch (error) {
    // Eat the error, the user should be logged out at this point
    logger.error(`App load - Retrieve user: %s; Session expiry: %s`, error, session?.expires);
  }

  const { pageProps, ...otherInitialProps } = initialProps
  const { statusCode } = pageProps

  // Logging Error
  if (PAGE_ERROR_CODES.includes(statusCode)) {
    logger.error(`Code: ${statusCode}; Route: ${context.router.asPath};`);
  }

  const endTime = performance.now();
  logger.info(`App load - getInitialProps took ${(endTime - startTime).toFixed(2)} ms; Request url: %s`, context.ctx.pathname);

  return {
    pageProps,
    ...otherInitialProps,
    session,
    nonce,
    user: initialUserProps
  }
}

export default App