import React from 'react'
import cx from 'classnames'

import Link from '../../Link'
import Image from '../../Image'

import s from './styles.module.css'
import { CoverImageProps } from './types'
import { ROUTE_BLOG_BASE } from '../../../../consts'
import { buildPath } from '../../../../helpers'

const CoverImage = (props: CoverImageProps) => {
  const { title, coverImage, slug, width = 1220, height = 700, className, imageClassName } = props

  if (!coverImage || !coverImage?.src)
    return <></>

  const image = (
    <Image
      rounded
      width={width}
      height={height}
      alt={`Cover Image for ${title}`}
      src={coverImage?.src}
      className={imageClassName}
    />
  )

  return (
    <div className={cx(s.wrapper, className)}>
      {slug ? (
        <Link href={buildPath(ROUTE_BLOG_BASE, slug)} aria-label={title}>
          {image}
        </Link>
      ) : (
        image
      )}
    </div>
  )
}

export default CoverImage 