import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import cn from 'classnames';
import Image from 'next/image';

import Button from '../Button';
import { SoundFull, SoundMute } from '../Icon';

import s from './styles.module.css'
import { VideoProps } from './types';

/**
 * HTML 5 video player 
 * @param props 
 * @returns 
 */
const Video = (props: VideoProps) => {
  const {
    src,
    fallback,
    width = 1920,
    height = 1080,
    initialVolume = 0.1,
    containerClassName,
    title = "Video player",
    background = false,
    muted = true,
    allowSound = false,
    className,
    ...playerProps
  } = props

  // Minus will trigger out of view slightly earlier.
  const ref = useRef<HTMLVideoElement | null>(null)
  const { ref: inViewRef, inView } = useInView({
    rootMargin: '-10px'
  })

  const [playerMuted, setPlayerMuted] = useState(muted)
  const [showFallback, setShowFallback] = useState<boolean>(false)

  const containerClasses = cn(s.video_background, containerClassName)

  // On load set initial volumn
  useEffect(() => {
    if (ref.current) {
      ref.current.volume = initialVolume
    }
  }, [initialVolume])

  // Manage video when it's offscreen
  useEffect(() => {
    if (!inView) {
      setPlayerMuted(true)
      ref.current?.pause()
    }
    else {
      ref.current?.play()
    }
  }, [inView])

  // Create video props
  const videoProps = {
    width,
    height,
    src,
    muted: playerMuted,
    onError: () => {
      setShowFallback(true)
    },
    title,
    className: cn(s.video, className),
    ...playerProps
  }

  // Set multiple refs to an element
  const setRefs = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (node: any) => {
      // Set our element ref
      ref.current = node
      // Pass our video ref into the inView ref
      inViewRef(node)
    },
    [inViewRef]
  )

  const video = (
    <video
      ref={setRefs}
      poster={fallback}
      {...videoProps}>
      <source src={src} />
      <track kind="captions" />
      Your browser does not support the video tag.
    </video>
  )

  if (!background) return video

  return (
    <div className={containerClasses}>
      {video}
      {
        background && allowSound &&
        <Button
          className={s.sound_button}
          onClick={() => { setPlayerMuted(!playerMuted) }} >
          {!playerMuted ? <SoundFull className={s.sound_icon} /> : <SoundMute className={s.sound_icon} />}
        </Button>
      }
      {showFallback && fallback &&
        <Image
          alt=""
          className={s.fallback}
          width={width}
          height={height}
          src={fallback} />}
    </div>
  );
};

export default Video 
