import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import { destroyFlashMessage, getFlashMessage } from './utils'

const FlashMessage = () => {

  useEffect(() => {
    const flashMessage = getFlashMessage()

    if (flashMessage) {
      destroyFlashMessage()
      toast(flashMessage.message, { type: flashMessage.type })
    }
  }, [])

  return <></>
}

export default FlashMessage