import React, { useEffect, useRef, useState } from 'react'
import { animate } from 'framer-motion';
import cx from 'classnames';

import s from './styles.module.css'
import { CounterProps } from './types';

/**
 * Count number of decimals a number has
 * @param value 
 * @returns 
 */
function countDecimals(value: number): number {
  if (Math.floor(value) === value) return 0;

  return value.toString().split(".")[1]?.length || 0;
}

function Counter(props: CounterProps) {
  const { from, to, decimals = null, duration = 1 } = props
  const nodeRef = useRef<HTMLSpanElement>(null);
  const [isAnimating, setIsAnimating] = useState(false)

  useEffect(() => {
    const node = nodeRef.current;

    const precision = decimals === null ? countDecimals(to) : decimals

    const controls = animate(from, to, {
      duration,
      onUpdate(value: number) {
        if (node) {
          setIsAnimating(true)
          // Trying to avoid rounding here to keep the value
          const valueStr = value.toString()
          // Check if original value has a decimal
          const indexOfPeriod = valueStr.indexOf(".")
          const valueFormatted = indexOfPeriod === -1 ? valueStr : valueStr.slice(0, (indexOfPeriod) + precision + (precision > 0 ? 1 : 0))

          node.textContent = valueFormatted;
        }
      },
      onComplete() {
        // Delay the state change slightly
        setTimeout(() => setIsAnimating(false), 1500)
      }
    });

    return () => controls.stop();
  }, [from, to, decimals, duration]);

  // eslint-disable-next-line no-nested-ternary
  const direction = to === from ? undefined : to > from ? "positive" : "negative"
  const classes = cx(s.counter, isAnimating && s[`${direction}`], isAnimating && s.animating)

  return <span className={classes} ref={nodeRef} />;
}

export default Counter