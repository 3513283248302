import React from 'react'
import cn from 'classnames'

import SlideNavigationDots from './SlideNavigationDots'
import SlideNavigationThumbnail from './SlideNavigationThumbnail'
import { SlideNavigationProps } from './types'
import s from './styles.module.css'

const SlideNavigation = (props: SlideNavigationProps) => {
  const { slideCount = 0, startIndex = 0, onClick, currentIndex, className, thumbnails = [] } = props

  if (slideCount === 0) return

  const useThumbnails: boolean = thumbnails && thumbnails.length > 0

  return (
    <div className={cn(useThumbnails ? s.slide_navigation_with_thumbnails : s.slide_navigation_with_dots)}>
      <div className={s.slide_navigation_gradient} />

      <div className={cn(s.slide_navigation, className)}>
        {[...Array(slideCount)].map((_, index) => {

          const start = index + startIndex

          if (useThumbnails) {
            const thumbnail = thumbnails[start]

            if (!thumbnail) return false

            return (
              <SlideNavigationThumbnail
                key={`${thumbnail.src}_${start}`}
                src={thumbnail.src}
                label={thumbnail.label}
                variant={thumbnail.variant}
                labelHighlight={thumbnail.labelHighlight}
                index={start}
                active={start === currentIndex}
                onClick={onClick}
              />
            )
          }

          return (
            <SlideNavigationDots
              key={start}
              index={start}
              active={start === currentIndex}
              onClick={onClick} />
          )
        })}
      </div>
    </div>
  )
}

export default SlideNavigation
