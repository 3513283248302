import { VariantType } from 'hooks/useVariant'
import React, { ElementType, PropsWithChildren, ReactElement } from 'react'

export type StyledButtonVariantsType = 'outline' | 'filled'

export type StyledButtonColorVariantsType = 'primary' | 'secondary' | 'tertiary' | 'default'

type StyledButtonOwnProps<T extends React.ElementType> = {
    as?: T
    variant?: StyledButtonVariantsType
    className?: string
    color?: VariantType
    loading?: boolean
    size?: 'sm' | 'md' | 'lg' | 'xl'
    icon?: ReactElement
    disabled?: boolean
    hover?: boolean
} & PropsWithChildren

// Exclude own props to avoid prop collision
export type StyledButtonProps<T extends ElementType> = StyledButtonOwnProps<T> &
    Omit<React.ComponentPropsWithoutRef<T>, keyof StyledButtonOwnProps<T>>
