import React, { useCallback, useEffect } from 'react'
import { AnimatePresence, motion } from "framer-motion";
import { useSwipeable } from 'react-swipeable';
import cx from 'classnames';

import Button from '../Button';
import VisuallyHidden from '../VisuallyHidden';
import { Close, DoubleArrow } from '../Icon';
import Typography from '../Typography';
import Image from '../Image';

import s from './styles.module.css'
import { LightboxProps } from './types';
import { useKeyPress } from '../../../hooks';

/**
 * Simple gallery lightbox
 * @param props 
 * @returns 
 */
const Lightbox = (props: LightboxProps) => {
  const { images = [], setCurrentIndex, currentIndex, visible = false } = props
  // Handle keyboard keypresses
  const right = useKeyPress("ArrowRight")
  const left = useKeyPress("ArrowLeft")
  const escape = useKeyPress("Escape")

  const classes = cx(s.lightbox, currentIndex >= 0 && s.open, "overlay")

  const imageCount = images.length
  const currentImage = images[currentIndex] ?? { src: "", alt: "", caption: "" }
  const { src, alt, caption } = currentImage

  // Close lightbox on clicking off the image
  const clickHandler = (evt: React.MouseEvent) => {
    const { target } = evt
    if (target instanceof Element && target.classList.contains("overlay")) {
      setCurrentIndex(-1);
    }
  };

  const nextImage = useCallback(() => {
    let imageIndex = currentIndex

    if (imageIndex >= imageCount - 1) {
      imageIndex = 0
    } else {
      imageIndex = currentIndex + 1
    }

    setCurrentIndex(imageIndex)
  }, [currentIndex, setCurrentIndex, imageCount])

  const previousImage = useCallback(() => {
    let imageIndex = currentIndex

    if (imageIndex > 0) {
      imageIndex = currentIndex - 1
    } else {
      imageIndex = imageCount - 1
    }

    setCurrentIndex(imageIndex)
  }, [currentIndex, setCurrentIndex, imageCount])

  // Handle swipe events
  const swipeHandlers = useSwipeable({
    onSwipedLeft: nextImage,
    onSwipedRight: previousImage
  })

  // Update current image on keypresses
  useEffect(() => {
    // Don't do anything is lightbox is closed
    if (!visible) return

    if (left) {
      previousImage()
    }
    if (right) {
      nextImage()
    }
    if (escape) {
      setCurrentIndex(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [left, right, escape])

  return (
    <AnimatePresence>
      {
        visible &&
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={clickHandler}
          className={classes}>

          <div className={s.lightbox_image}>
            <div className={s.lightbox_image_header}>
              <Typography
                variant="default"
                className={s.lightbox_image_caption}
                as="p">
                {caption ?? alt}
              </Typography>
              <Button
                className={s.close_button}
                onClick={() => setCurrentIndex(-1)}
              >
                Close
                <VisuallyHidden>Close</VisuallyHidden>
                <Close className={s.close_icon} />
              </Button>
            </div>

            <div
              {...swipeHandlers}
              className={s.image_wrapper}>
              <motion.button
                whileHover={{ scale: 1.4 }}
                whileTap={{ scale: 1 }}
                className={s.nav_prev}
                onClick={previousImage}
                aria-label="Move to previous image">
                <DoubleArrow rotation="90" />
              </motion.button>

              <motion.div
                transition={{
                  duration: 0.25,
                  easings: ["easeIn", "easeOut"]
                }}
                initial={{ scale: 0.9 }}
                animate={{ scale: 1.0 }}>
                <Image
                  src={src}
                  width="3840"
                  height="2160"
                  alt={alt}
                />
              </motion.div>

              <motion.button
                whileHover={{ scale: 1.4 }}
                whileTap={{ scale: 1 }}
                className={s.nav_next}
                onClick={nextImage}
                aria-label="Move to next image">
                <DoubleArrow rotation="-90" />
              </motion.button>
            </div>
          </div>
        </motion.div>
      }
    </AnimatePresence>
  )

}

export default Lightbox
