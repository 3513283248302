import React, { forwardRef } from 'react'
import cx from 'classnames'

import s from './styles.module.css'
import { StyledInputProps } from './types'

const StyledInput = forwardRef<HTMLInputElement, StyledInputProps>((props, ref) => {
  const {
    icon,
    className,
    inputClassName,
    notched = false,
    type = "text",
    ...inputProps
  } = props

  const wrapperClasses = cx(
    s.input_group,
    className
  )

  const inputClasses = cx(
    s.input,
    notched && s.notched,
    icon && s.with_icon,
    inputClassName
  )

  return (
    <div className={wrapperClasses}>
      <input
        ref={ref}
        className={inputClasses}
        type={type}
        {...inputProps} />
      {notched && <div className={s.input_background} />}
      {icon}
    </div>
  )
})

export default StyledInput