import React from 'react'

import Typography from '@/components/ui/Typography'

import s from './styles.module.css'
import { StatProps } from './types'

/**
 * Display a statistic 
 * @param props 
 * @returns 
 */
const Stat = (props: StatProps) => {
  const { title, count, titleProps = {}, countProps = {} } = props

  return (
    <div className={s.stat}>
      <Typography
        as="h6"
        className={s.title}
        {...titleProps}>{title}</Typography>

      <Typography
        className={s.count}
        as="span"
        {...countProps}
      >
        {count}
      </Typography>
    </div>
  )
}

export default Stat