import React, { useRef, useEffect, useContext, useMemo } from "react";
import { StickyComponentType, StickyContainerProps, StickyContextType, StickyOffsetListType } from "./types";

const StickyContext = React.createContext<StickyContextType | null>(null);

export default function StickyContainer(props: StickyContainerProps) {
  const { children, className } = props;
  const subscribers = useRef<Array<StickyComponentType>>([]);
  const node = useRef<HTMLDivElement>(null);
  const events = [
    "resize",
    "scroll",
    "touchstart",
    "touchmove",
    "touchend",
    "pageshow",
    "load"
  ];

  const getElement = () => node.current;
  const getSubscribers = () => subscribers.current;

  const notifySubscribers = (evt: Event) => {
    const nodeElement = getElement()

    if (!nodeElement) return

    const { type } = evt;
    const bounds = nodeElement.getBoundingClientRect();
    const { top, bottom } = bounds;

    // Stacking offset, keep adding the height of each sticky...
    const offset = {
      all: 0
    } as StickyOffsetListType;

    const scrollOffset =
      window.pageYOffset || document.documentElement.scrollTop;

    getSubscribers().forEach((component) => {
      const { handler, getHeight, containerId } = component as StickyComponentType;

      if (containerId) {
        if (!offset[containerId]) offset[containerId] = 0;
      }

      handler(
        type,
        top,
        bottom,
        offset,
        scrollOffset
      );

      if (containerId) {
        offset[containerId] += getHeight();
      }
      offset.all += getHeight();
    });
  };

  useEffect(() => {
    events.forEach((event) => {
      window.addEventListener(event, notifySubscribers);
    });

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, notifySubscribers);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo(
    () => ({
      parent: getElement,
      subscribe: (component: StickyComponentType) => {
        subscribers.current = getSubscribers().concat(component);
      },
      unsubscribe: (component: StickyComponentType) => {
        subscribers.current = getSubscribers().filter(
          (current) => current !== component
        );
      }
    }),
    []
  );

  return (
    <StickyContext.Provider value={value}>
      <div
        ref={node}
        className={className}
      >
        {children}
      </div>
    </StickyContext.Provider>
  );
}

export const useSticky = () => {
  const context = useContext(StickyContext);
  if (!context) {
    throw new Error(
      "useStickyContext must be used within an < StickyProvider />"
    );
  }

  return context;
};
