import React from 'react'

import Link from '../Link'

import { BrandProps } from './types'
import { Cornucopias } from '../Icon'

/**
 * Site brand / logo
 * @param props 
 * @returns 
 */
const Brand = (props: BrandProps) => {
  const { href, className = "" } = props

  return (
    <Link prefetch={false} href={href} className={className}>
      <Cornucopias />
    </Link>
  )
}

export default Brand