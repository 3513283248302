import React from 'react'

import { Instagram, Discord, Twitter, YouTube, Medium, Facebook, Telegram, LinkedIn, Page } from '../Icon'

import { SocialPlatformType } from '../../../utils/Social'
import { SocialIconProps } from './types'

export const iconFactory = {
  discord: Discord,
  facebook: Facebook,
  youtube: YouTube,
  twitter: Twitter,
  telegram: Telegram,
  medium: Medium,
  instagram: Instagram,
  linkedin: LinkedIn,
  website: Page
}

/**
 * Returns icon for social platform from string
 * @param props 
 * @returns 
 */
const SocialIcon = (props: SocialIconProps) => {
  const { platform, ...iconProps } = props
  const Icon = iconFactory[platform as SocialPlatformType]

  if (!Icon) return null

  return <Icon {...iconProps} />
}

export default SocialIcon
