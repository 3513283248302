import { useEffect, useState } from 'react'

/**
 * Delay state changes, very handy for functions.
 * @param func
 * @param delay
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useDebounceFunction<T extends (...args: any[]) => any>(
    func: T,
    delay?: number,
): (...args: Parameters<T>) => void {
    const [debouncedFunc, setDebouncedFunc] = useState<(() => void) | null>(null)

    useEffect(() => {
        const executeDebouncedFunc = () => {
            if (debouncedFunc) {
                debouncedFunc()
            }
        }

        const timer = setTimeout(executeDebouncedFunc, delay || 500)

        return () => {
            clearTimeout(timer)
        }
    }, [debouncedFunc, delay])

    const execute = (...args: Parameters<T>) => {
        setDebouncedFunc(() => () => func(...args) as void)
    }

    return execute
}

export default useDebounceFunction
