import React, { PropsWithChildren } from 'react'

const Term = (props: PropsWithChildren) => {
  const { children } = props

  return (
    <dt>
      {children}
    </dt>
  )
}

export default Term