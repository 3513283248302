import React from 'react'

export enum CountdownTimeState {
    NotStarted,
    Open,
    Active,
    Finished,
}
export type CountdownTimerProps = {
    endDate?: string
    startDate?: string
    isUTC?: boolean
    openBeforeSeconds?: number
    noTimeLeftMessage?: string
    countdownFinishedPrefix?: string
    countdownActivePrefix?: string
    countdownOpenPrefix?: string
    className?: string
    countdownFinishedClassName?: string
    countdownActiveClassName?: string
    countdownNotStartedClassName?: string
    countdownOpenClassName?: string
    displayNotStarted?: boolean
    displayOpen?: boolean
    displayActive?: boolean
    displayFinished?: boolean
    daysText?: string
    hoursText?: string
    minutesText?: string
    secondsText?: string
    timeEndingClassName?: string
    timeEndingThresholdSeconds?: number
    showSeconds?: boolean
    children?: (state: CountdownTimeState) => React.ReactElement | React.ReactElement
}
