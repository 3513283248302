import isAbsolutePath from './isAbsolutePath'
import isProduction from './isProduction'

export function isUrlSafe(url: string) {
    const baseUrl = process.env.NEXT_PUBLIC_SITE_URL
    const isProd = isProduction()

    try {
        const validUrl = isAbsolutePath(url) ? new URL('', url) : new URL(encodeURI(url), baseUrl)

        // TODO:: Hardcoding this for now because the Azure Marketplace place existings on .io, therefore would not work if I used env value site_url
        const allowedDomains = isProd
            ? ['cornucopias.io', 'cornucopias.dev']
            : ['cornucopias.io', 'cornucopias.dev', 'localhost']
        const allowed = allowedDomains.some((allowedDomain) =>
            validUrl.hostname.includes(allowedDomain),
        )

        return allowed
    } catch (error) {
        return false
    }
}

export default function safeRedirect(url: string, fallback: string) {
    const baseUrl = process.env.NEXT_PUBLIC_SITE_URL

    try {
        // Sanitize and validate the absolute URL
        if (isUrlSafe(url)) {
            const validUrl = isAbsolutePath(url)
                ? new URL('', url)
                : new URL(encodeURI(url), baseUrl)

            // Perform the redirection
            window.location.href = validUrl.toString()
        } else {
            // Handle invalid or unsafe URLs
            window.location.href = fallback
        }
    } catch (error) {
        // Do nothing
    }
}
