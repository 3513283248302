import React, { createContext, useContext, useState, Children } from 'react'
import { AccordionContextType, AccordionProps } from './types'

const AccordionContext = createContext<AccordionContextType | null>(null)

function Accordion(props: AccordionProps) {
  const { children, multiple = false, defaultIndex = 0 } = props
  const [activeIndex, setActiveIndex] = useState<number | number[]>(
    multiple ? [defaultIndex] : defaultIndex
  )

  function onChangeIndex(index: number) {
    setActiveIndex((currentActiveIndex) => {
      if (multiple && Array.isArray(currentActiveIndex)) {
        if (currentActiveIndex.includes(index)) {
          return currentActiveIndex.filter((i) => i !== index)
        }

        return currentActiveIndex.concat(index)
      }

      return index === activeIndex ? -1 : index
    })
  }

  return Children.map(children, (child, index) => {
    const isActive =
      multiple && Array.isArray(activeIndex)
        ? activeIndex.includes(index)
        : activeIndex === index

    return (
      <AccordionContext.Provider value={{ isActive, index, onChangeIndex }}>
        {child}
      </AccordionContext.Provider>
    )
  })
}

export const useAccordion = () => {
  const context = useContext(AccordionContext)
  if (!context) {
    throw new Error(
      "useAccordion must be used within an < AccordionContext />"
    )
  }

  return context;
}

export default Accordion