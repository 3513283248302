import React, { useState } from 'react'
import Cropper, { Point } from "react-easy-crop"
import { ImageCropperProps } from './types';

import s from './styles.module.css'

const ImageCropper = (props: ImageCropperProps) => {
  const { url, aspect = 4 / 3, onCropComplete, cropData } = props
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);

  return (
    <div>
      <div className={s.cropper_preview}>
        <Cropper
          initialCroppedAreaPixels={cropData}
          showGrid={false}
          image={url}
          crop={crop}
          zoom={zoom}
          aspect={aspect}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
      <div className={s.cropper_actions}>
        <input
          className={s.cropper_action_slider}
          type="range"
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          aria-labelledby="Zoom"
          onChange={(e) => setZoom(Number(e.target.value))}
        />
      </div>
    </div>
  )
}


export default ImageCropper