import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const CubeStack = (props: SVGIconProps) => {
  const { width = 38, height = 38, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} {...svgProps}>
      <path d="M28.0058 14.9461V6.13694C28.0058 5.97551 27.9191 5.82609 27.7803 5.74604L19.9677 1.23538C19.5221 0.979224 18.9711 0.980559 18.5282 1.23538L10.7156 5.74604C10.5768 5.82609 10.4901 5.97551 10.4901 6.13694V14.9461C10.4901 15.5892 10.837 16.1895 11.3933 16.5111L19.0218 20.9163C19.0912 20.9564 19.1699 20.9764 19.2473 20.9764C19.3247 20.9764 19.4034 20.9564 19.4728 20.9163L27.1013 16.5111C27.6589 16.1895 28.0058 15.5892 28.0058 14.9461ZM18.9791 2.01717C19.1446 1.92245 19.35 1.92245 19.5154 2.01717L26.6503 6.13694L19.2473 10.4115L11.8429 6.13694L18.9778 2.01717H18.9791ZM11.392 14.9461V6.91873L14.1109 8.48766L18.7964 11.1933V19.7423L11.8442 15.7279C11.5641 15.5665 11.392 15.2677 11.392 14.9461ZM19.6996 19.7423V11.1933L24.9427 8.16614L27.1026 6.91873V14.9461C27.1026 15.2677 26.9292 15.5678 26.6517 15.7279L19.6996 19.7423Z" fill="black" stroke="black" strokeWidth="0.266824" strokeMiterlimit="10" />
      <path d="M10.5742 17.7131C10.1326 17.4583 9.58025 17.457 9.13598 17.7131L1.32337 22.2238C1.18462 22.3038 1.0979 22.4532 1.0979 22.6147V31.4239C1.0979 32.0669 1.44344 32.6673 2.0011 32.9888L9.62961 37.3941C9.69898 37.4341 9.77769 37.4541 9.85507 37.4541C9.93245 37.4541 10.0112 37.4341 10.0805 37.3941L17.709 32.9888C18.2667 32.6673 18.6122 32.0683 18.6122 31.4239V22.6147C18.6122 22.4532 18.5255 22.3038 18.3868 22.2238L10.5742 17.7131ZM9.58691 18.4949C9.75235 18.4002 9.9578 18.4002 10.1232 18.4949L17.2594 22.6147L9.85507 26.8892L2.45203 22.6147L9.58691 18.4949ZM1.99977 31.4239V23.3965L5.12695 25.2015L9.40281 27.671V36.22L2.4507 32.2057C2.17187 32.0442 1.99977 31.7454 1.99977 31.4225V31.4239ZM17.7104 31.4239C17.7104 31.7454 17.5369 32.0456 17.2581 32.207L10.306 36.2214V27.6723L14.874 25.0348L17.7104 23.3978V31.4239Z" fill="black" stroke="black" strokeWidth="0.266824" strokeMiterlimit="10" />
      <path d="M37.1739 22.2238L29.3613 17.7131C28.9157 17.4556 28.3647 17.4583 27.9231 17.7131L20.1091 22.2238C19.9704 22.3038 19.8837 22.4532 19.8837 22.6147V31.4239C19.8837 32.0669 20.2305 32.6673 20.7869 32.9888L28.4154 37.3941C28.4847 37.4341 28.5635 37.4541 28.6408 37.4541C28.7182 37.4541 28.7969 37.4341 28.8663 37.3941L36.4948 32.9888C37.0525 32.6673 37.398 32.0683 37.398 31.4239V22.6147C37.398 22.4532 37.3126 22.3038 37.1739 22.2238ZM28.3727 18.4949C28.5381 18.3988 28.7436 18.4002 28.909 18.4949L36.0439 22.6147L31.6933 25.1268L28.6408 26.8892L21.2365 22.6147L28.3727 18.4949ZM20.7855 31.4239V23.3965L23.6219 25.0334L28.1899 27.671V36.22L21.2378 32.2057C20.959 32.0442 20.7855 31.7454 20.7855 31.4225V31.4239ZM36.4961 31.4239C36.4961 31.7454 36.3227 32.0456 36.0439 32.207L29.0918 36.2214V27.6723L30.4232 26.9025L36.4948 23.3978V31.4252L36.4961 31.4239Z" fill="black" stroke="black" strokeWidth="0.266824" strokeMiterlimit="10" />
    </SVGIcon>
  )
}

export default CubeStack