import { addMinutes, format } from 'date-fns'

const getFormattedUTCDate = (dateStr?: string, formatStr?: string): string | null => {
    if (!dateStr) return null

    const date = new Date(dateStr)
    return format(addMinutes(date, date.getTimezoneOffset()), formatStr ?? 'do MMMM yyyy, HH:mm')
}

export default getFormattedUTCDate
