import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const Lock = (props: SVGIconProps) => {
  const { width = 32, height = 36, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} aria-label="Lock Icon" {...svgProps}>
      <path d="M28.0144 15.75H26.3336V10.6875C26.3336 4.79531 21.5571 0 15.6881 0C9.81905 0 5.04259 4.79531 5.04259 10.6875V15.75H3.36173C1.50577 15.75 0 17.2617 0 19.125V32.625C0 34.4883 1.50577 36 3.36173 36H28.0144C29.8704 36 31.3761 34.4883 31.3761 32.625V19.125C31.3761 17.2617 29.8704 15.75 28.0144 15.75ZM20.7307 15.75H10.6455V10.6875C10.6455 7.89609 12.9076 5.625 15.6881 5.625C18.4685 5.625 20.7307 7.89609 20.7307 10.6875V15.75Z" fill="currentcolor" />
    </SVGIcon>
  )
}

export default Lock
