import { getProperty } from 'dot-prop'
import parseString from './parseString'

// Review if we move forward with other languages
import lang from '../../lang/en.json'

/**
 * Return interpolated array
 * @param path
 * @param args
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const tRend = (path: string, ...args: any[]): any[] => {
    const str: string = getProperty(lang, path)

    if (str) {
        return parseString(str, ...args)
    }

    return [str]
}

export default tRend
