/**
 * Capitalize first character of string
 * @param str
 * @returns
 */
const capitalize = (str: string): string | undefined => {
    if (str) return str[0].toUpperCase() + str.slice(1)

    return str
}

export default capitalize
