import React, { PropsWithChildren } from 'react'

const Description = (props: PropsWithChildren) => {
  const { children } = props

  return (
    <dd>
      {children}
    </dd>
  )
}

export default Description