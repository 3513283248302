import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { LazyLoadProps } from './types'

const LazyLoad = (props: LazyLoadProps) => {
  const { className, children, debounce = false, msDelay = 250, rootMargin = "500px 0px 500px 0px", debug, ...rest } = props
  const [ref, inView] = useInView({
    rootMargin,
    ...rest
  });

  if (debug) {
    // eslint-disable-next-line no-console
    console.log(inView)
  }

  const [debouncedInView, setDebouncedInView] = useState<boolean>(debounce ? inView : true);

  useEffect(() => {
    let timeoutId: number | NodeJS.Timeout

    const handleScroll = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setDebouncedInView(inView);
      }, msDelay);
    };

    if (debounce) {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (debounce) {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, [inView, msDelay, debounce]);

  const variants = {
    offScreen: { opacity: 0, transition: { duration: 0.25 } },
    onScreen: { opacity: 1, transition: { duration: 0.25 } },
  }


  return (
    <div className={className} ref={ref}>
      <AnimatePresence>
        {
          debouncedInView && inView &&
          <motion.div
            className="h-full"
            variants={variants}
            initial="offScreen"
            animate={inView && "onScreen"}
          >
            {children}
          </motion.div>
        }
      </AnimatePresence>
    </div>
  )
}

export default LazyLoad
