import React, { useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import Alert from '../Alert'
import Conditional from '../Conditional'

import Lightbox from '../Lightbox'
import GalleryPreview from './GalleryPreview'

import s from './styles.module.css'
import { GalleryProps } from './types'

/**
 * 2 Column photo gallery with scroll indicator
 * @param props 
 * @returns 
 */
const Gallery = (props: GalleryProps) => {
  const { images, lightbox = true } = props
  const [lightboxIndex, setLightboxIndex] = useState<number>(-1)

  const variants = {
    animate: {
      opacity: 1,
      transition: {
        duration: 1
      }
    },
    initial: {
      opacity: 0
    }
  };

  return (
    <>
      <Conditional
        condition={images.length === 0}
        component={(
          <Alert type='warning'>No images to show.</Alert>
        )}>
        <AnimatePresence>
          <motion.div
            variants={variants}
            initial="initial"
            animate="animate"
            className={s.gallery}>
            {
              images.map((image, imageIndex) => {
                const { src, alt, caption, href } = image

                return (
                  <div
                    // Not exactly elegant, but keeps keys different between pages.
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${src}`}
                    className={s.gallery_column}>
                    <GalleryPreview
                      onClick={!lightbox ? undefined : () => {
                        setLightboxIndex(imageIndex)
                      }}
                      onKeyDown={!lightbox ? undefined : (evt) => {
                        const { key } = evt

                        if (key === "Enter")
                          setLightboxIndex(imageIndex)

                      }}
                      href={href}
                      src={src}
                      width="1920"
                      height="1080"
                      sizes="(max-width: 768px) 100vw,
                    (max-width: 1200px) 50vw"
                      caption={caption ?? alt}
                      alt={alt} />

                  </div>
                )
              })
            }
          </motion.div>

        </AnimatePresence>
      </Conditional>

      <Lightbox
        visible={lightboxIndex >= 0}
        images={images}
        currentIndex={lightboxIndex}
        setCurrentIndex={setLightboxIndex} />
    </>
  )
}

export default Gallery
