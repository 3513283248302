import React from 'react'
import cn from 'classnames'

import { TabListProps } from './types'
import s from './styles.module.css'

const TabList = (props: TabListProps) => {
  const { children, className } = props

  return (
    <div className={cn(s.tabs, className)}>
      {children}
    </div>
  )

}

export default TabList
