const formatNumber = (amount: number, decimals = 0): string => {
    if (!amount || amount === 0) return '0'

    if (decimals === 0) {
        return Math.trunc(amount).toLocaleString()
    }

    const factor = Math.pow(10, decimals)
    return (Math.trunc(amount * factor) / factor).toLocaleString()
}

export default formatNumber
