import React, { useMemo } from 'react'
import cn from 'classnames'

import Image from '../Image'

import { formatFileStackImage, getRandomInt } from '../../../helpers'
import { PageBackgroundProps } from './types'
import s from './styles.module.css'

const PageBackground = (props: PageBackgroundProps) => {
  const { images = [], greyScale = true, overlay = true } = props

  const int = useMemo(() => getRandomInt(0, images.length - 1), [images.length])

  if (images.length === 0 || !images[int]) return false

  const src = formatFileStackImage(images[int], { resize: { width: 1920 } })

  return (
    <div className={cn(s.background_images, overlay && s.background_overlay, greyScale && s.background_greyscale)}>
      <Image
        sizes="100vw"
        fill
        alt=""
        src={src}
      />
    </div>
  )
}

export default PageBackground
