import useAuthMutation from 'hooks/useAuthMutation'
import { NodeHttpClient } from '../network'

export const useRegisterNodeMutation = () =>
    useAuthMutation({
        mutationFn: (id: string, token) => NodeHttpClient.setAccessToken(token).RegisterNode(id),
    })

export const useDeregisterNodeMutation = () =>
    useAuthMutation({
        mutationFn: (id: string, token) => NodeHttpClient.setAccessToken(token).DeregisterNode(id),
    })
