/**
 * Create consistent path
 * Note:: This may have unexpected results if a part starts with a hash
 * @returns
 */
const buildPath = (...parts: string[]): string => {
    let isAbsolute = false
    const formatted = parts.map((part: string, index) => {
        if (!part) return ''

        if (typeof part !== 'string')
            throw new Error(`BuildPath function received a non string value - ${parts.join(',')}`)

        if (part.startsWith('/') && index === 0) isAbsolute = true

        let urlPiece = part
        urlPiece = urlPiece.endsWith('/') ? urlPiece.slice(0, -1) : urlPiece
        urlPiece = urlPiece.startsWith('/') ? urlPiece.slice(1) : urlPiece

        return urlPiece.toLowerCase()
    })

    return (isAbsolute ? '/' : '') + formatted.join('/')
}

export default buildPath
