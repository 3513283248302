/* eslint-disable @typescript-eslint/no-var-requires */
/** @type {import('tailwindcss').Config} */

const plugin = require('tailwindcss/plugin')

const defaultTheme = require('tailwindcss/defaultTheme')

module.exports = {
    mode: 'jit',
    purge: {
        enabled: false,
        content: ['./src/**/*.{js,jsx,ts,tsx,css,scss}'],
    },
    theme: {
        extend: {
            fontFamily: {
                poppins: ['Poppins'],
                bbstrata: ['BBStrata'],
                sans: ['Poppins', ...defaultTheme.fontFamily.sans],
            },
            colors: {
                skin: {
                    base: 'rgb(var(--color-base) / <alpha-value>)',
                    primary: 'rgb(var(--color-primary) / <alpha-value>)',
                    secondary: 'rgb(var(--color-secondary) / <alpha-value>)',
                    dark: 'rgb(var(--color-dark) / <alpha-value>)',
                    danger: 'rgb(var(--color-danger) / <alpha-value>)',
                    warning: 'rgb(var(--color-warning) / <alpha-value>)',
                    success: 'rgb(var(--color-success) / <alpha-value>)',
                    info: 'rgb(var(--color-info) / <alpha-value>)',
                    common: 'rgb(var(--color-common) / <alpha-value>)',
                    uncommon: 'rgb(var(--color-uncommon) / <alpha-value>)',
                    rare: 'rgb(var(--color-rare) / <alpha-value>)',
                    legendary: 'rgb(var(--color-legendary) / <alpha-value>)',
                    mythic: 'rgb(var(--color-mythic) / <alpha-value>)',
                },
            },
            textShadow: {
                sm: '0 1px 2px var(--tw-shadow-color)',
                DEFAULT: '0 2px 4px var(--tw-shadow-color)',
                lg: '0 8px 16px var(--tw-shadow-color)',
            },
            backgroundColor: {
                skin: {
                    base: 'rgb(var(--color-base) / <alpha-value>)',
                    primary: 'rgb(var(--color-primary) / <alpha-value>)',
                    secondary: 'rgb(var(--color-secondary) / <alpha-value>)',
                    dark: 'rgb(var(--color-dark) / <alpha-value>)',
                    danger: 'rgb(var(--color-danger) / <alpha-value>)',
                    warning: 'rgb(var(--color-warning) / <alpha-value>)',
                    success: 'rgb(var(--color-success) / <alpha-value>)',
                    info: 'rgb(var(--color-info) / <alpha-value>)',
                    common: 'rgb(var(--color-common) / <alpha-value>)',
                    uncommon: 'rgb(var(--color-uncommon) / <alpha-value>)',
                    rare: 'rgb(var(--color-rare) / <alpha-value>)',
                    legendary: 'rgb(var(--color-legendary) / <alpha-value>)',
                    mythic: 'rgb(var(--color-mythic) / <alpha-value>)',
                },
            },
            textColor: {
                skin: {
                    base: 'rgb(var(--color-base) / <alpha-value>)',
                    primary: 'rgb(var(--color-primary) / <alpha-value>)',
                    secondary: 'rgb(var(--color-secondary) / <alpha-value>)',
                    dark: 'rgb(var(--color-dark) / <alpha-value>)',
                    danger: 'rgb(var(--color-danger) / <alpha-value>)',
                    warning: 'rgb(var(--color-warning) / <alpha-value>)',
                    success: 'rgb(var(--color-success) / <alpha-value>)',
                    info: 'rgb(var(--color-info) / <alpha-value>)',
                    common: 'rgb(var(--color-common) / <alpha-value>)',
                    uncommon: 'rgb(var(--color-uncommon) / <alpha-value>)',
                    rare: 'rgb(var(--color-rare) / <alpha-value>)',
                    legendary: 'rgb(var(--color-legendary) / <alpha-value>)',
                    mythic: 'rgb(var(--color-mythic) / <alpha-value>)',
                },
            },
            borderColor: {
                skin: {
                    base: 'rgb(var(--color-base) / <alpha-value>)',
                    primary: 'rgb(var(--color-primary) / <alpha-value>)',
                    secondary: 'rgb(var(--color-secondary) / <alpha-value>)',
                    dark: 'rgb(var(--color-dark) / <alpha-value>)',
                    danger: 'rgb(var(--color-danger) / <alpha-value>)',
                    warning: 'rgb(var(--color-warning) / <alpha-value>)',
                    success: 'rgb(var(--color-success) / <alpha-value>)',
                    info: 'rgb(var(--color-info) / <alpha-value>)',
                    common: 'rgb(var(--color-common) / <alpha-value>)',
                    uncommon: 'rgb(var(--color-uncommon) / <alpha-value>)',
                    rare: 'rgb(var(--color-rare) / <alpha-value>)',
                    legendary: 'rgb(var(--color-legendary) / <alpha-value>)',
                    mythic: 'rgb(var(--color-mythic) / <alpha-value>)',
                },
            },
        },
        fontFamily: {
            sans: [
                'Inter',
                'ui-sans-serif',
                'system-ui',
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                '"Noto Sans"',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
                '"Noto Color Emoji"',
            ],
            serif: ['ui-serif', 'Georgia', 'Cambria', '"Times New Roman"', 'Times', 'serif'],
            mono: [
                'ui-monospace',
                'SFMono-Regular',
                'Menlo',
                'Monaco',
                'Consolas',
                '"Liberation Mono"',
                '"Courier New"',
                'monospace',
            ],
        },
        screens: {
            xs: '450px',
            sm: '640px',
            md: '768px',
            lg: '1024px',
            xl: '1280px',
            '2xl': '1536px',
            '3xl': '1800px',
        },
    },
    plugins: [
        plugin(function ({ matchUtilities, theme }) {
            matchUtilities(
                {
                    'text-shadow': (value) => ({
                        textShadow: value,
                    }),
                },
                { values: theme('textShadow') },
            )
        }),
        plugin(({ addBase, addComponents, addUtilities, theme }) => {
            const polygons = {
                tl: `
                  /* top-left 1 */ 0 var(--edge-size),
                  /* top-left 2 */ var(--edge-size) 0,
                  /* top-right */ 100% 0,
                  /* bottom-right */ 100% 100%,
                  /* bottom-left 1 */ 0 100%`,
                tr: `
                  /* top-left */ 0 0,
                  /* top-right 1 */ calc(100% - var(--edge-size) - 3px) 0%,
                  /* top-right 1 */ calc(100% - var(--edge-size) - 1px) calc(0% + 0.5px),
                  /* top-right 1 */ calc(100% - var(--edge-size) + 1px) calc(0% + 1.5px),
                  /* top-right 2 */ calc(100% - 3px) calc(var(--edge-size) - 2px),
                  /* top-right 2 */ calc(100% - 1px) calc(var(--edge-size) + 0.5px),
                  /* top-right 2 */ calc(100% - 0.5px) calc(var(--edge-size) + 1.5px),
                  /* top-right 2 */ calc(100%) calc(var(--edge-size) + 3px),
                  /* bottom-right */ 100% 100%,
                  /* bottom-left 1 */ 0 100%`,
                bl: `
                  /* top-left */ 0 0,
                  /* top-right */ 100% 0,
                  /* bottom-right */ 100% 100%,
                  /* bottom-left 1 */ calc(var(--edge-size) + 3px) 100%,
                  /* bottom-left 1 */ calc(var(--edge-size) + 1px) calc(100% - 0.5px),
                  /* bottom-left 1 */ calc(var(--edge-size) - 1px) calc(100% - 1.5px),
                  /* button-left 2 */ calc(0% + 1.5px) calc(100% - var(--edge-size) + 1px),
                  /* button-left 2 */ calc(0% + 0.5px) calc(100% - var(--edge-size) - 1px),
                  /* button-left 2 */ 0% calc(100% - var(--edge-size) - 2px)`,
                br: `
                  /* top-left */ 0 0,
                  /* top-right */ 100% 0,
                  /* bottom-right 1 */ 100% calc(100% - var(--edge-size)),
                  /* button-right 2 */ calc(100% - var(--edge-size)) 100%,
                  /* bottom-left */ 0 100%`,
                'bl-tr': `
                  /* top-left */ 0 0,
                  /* top-right 1 */ calc(100% - var(--edge-size) - 3px) 0%,
                  /* top-right 1 */ calc(100% - var(--edge-size) - 1px) calc(0% + 0.5px),
                  /* top-right 1 */ calc(100% - var(--edge-size) + 1px) calc(0% + 1.5px),
                  /* top-right 2 */ calc(100% - 3px) calc(var(--edge-size) - 2px),
                  /* top-right 2 */ calc(100% - 1px) calc(var(--edge-size) + 0.5px),
                  /* top-right 2 */ calc(100% - 0.5px) calc(var(--edge-size) + 1.5px),
                  /* top-right 2 */ calc(100%) calc(var(--edge-size) + 3px),
                  /* bottom-right */ 100% 100%,
                  /* bottom-left 1 */ calc(var(--edge-size) + 3px) 100%,
                  /* bottom-left 1 */ calc(var(--edge-size) + 1px) calc(100% - 0.5px),
                  /* bottom-left 1 */ calc(var(--edge-size) - 1px) calc(100% - 1.5px),
                  /* button-left 2 */ calc(0% + 1.5px) calc(100% - var(--edge-size) + 1px),
                  /* button-left 2 */ calc(0% + 0.5px) calc(100% - var(--edge-size) - 1px),
                  /* button-left 2 */ 0% calc(100% - var(--edge-size) - 2px)
                `,
            }

            const utilities = Object.entries(polygons).reduce((result, [shorthand, polygon]) => {
                const className = `.notched-cut-${shorthand}`

                return {
                    ...result,
                    [className]: {
                        '--edge-size': '1.5em',
                        clipPath: `polygon(${polygon})`,
                    },
                }
            }, {})

            addUtilities(utilities)

            addUtilities({
                '.no-scrollbar::-webkit-scrollbar': {
                    display: 'none',
                },
            })
        }),
    ],
}
