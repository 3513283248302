/* eslint-disable react/no-danger */
import React from 'react'
import Html from '../../Html'

import s from './styles.module.css'

export default function PostBody(props: { content: string }) {
  const siteUrl = process.env.NEXT_PUBLIC_SITE_URL || ""

  const { content } = props

  return (
    <div className={s.body}>
      <Html
        className={s.content}
        html={content}
        origin={siteUrl} />
    </div>
  )
}