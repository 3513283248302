import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const Facebook = (props: SVGIconProps) => {
  const { width = 19, height = 17, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} aria-label="Facebook" {...svgProps}>
      <path d="M10.6594 6.90475C11.4346 5.9728 12.397 5.3839 13.626 5.27146C16.1388 5.04085 18.0901 6.08334 18.7433 8.54377C18.8979 9.12695 18.9711 9.74253 18.9793 10.3448C19.0078 12.4126 18.9833 14.4823 18.9996 16.5502C19.0017 16.9046 18.8877 17.0076 18.5133 16.9999C17.4614 16.979 16.4095 16.9923 15.3575 16.9923C14.8183 16.9923 14.8163 16.9923 14.8163 16.4987C14.8163 14.6253 14.8204 12.7499 14.8122 10.8765C14.8122 10.5506 14.7898 10.219 14.7268 9.89881C14.5823 9.15554 14.2038 8.56282 13.3594 8.38368C12.4377 8.18928 11.6727 8.4561 11.1009 9.1746C10.7591 9.60341 10.6614 10.1008 10.6614 10.6192C10.6594 12.5784 10.6614 14.5376 10.6614 16.4968V16.9504C10.5292 16.9656 10.4315 16.9885 10.3318 16.9885C9.18832 16.9904 8.04482 16.9809 6.90132 16.9961C6.59611 16.9999 6.53711 16.8913 6.53711 16.6321C6.54525 13.0415 6.54525 9.45285 6.53711 5.86226C6.53711 5.56686 6.64698 5.4811 6.94201 5.483C8.04685 5.49253 9.14966 5.49444 10.2545 5.483C10.5841 5.47919 10.6859 5.59354 10.6655 5.88894C10.6431 6.22628 10.6594 6.56551 10.6594 6.90285V6.90475Z" fill="currentColor" />
      <path d="M4.41088 11.2654C4.41088 13.0168 4.4007 14.7702 4.41901 16.5216C4.42308 16.8838 4.32135 17.0076 3.91848 16.9981C2.84009 16.9714 1.7617 16.979 0.683311 16.9962C0.355725 17.0019 0.227539 16.9276 0.227539 16.5941C0.239747 13.0283 0.237713 9.46245 0.227539 5.89665C0.227539 5.56694 0.35776 5.47927 0.685346 5.48308C1.77594 5.49642 2.86858 5.49833 3.95917 5.48308C4.33152 5.47736 4.41901 5.61458 4.41698 5.93667C4.40477 7.7129 4.41088 9.48914 4.41088 11.2673V11.2654Z" fill="currentColor" />
      <path d="M4.65335 2.04694C4.65335 3.19615 3.66449 4.04806 2.31752 4.05568C1.00514 4.06521 0 3.18472 0 2.02597C0 0.861512 1.01735 -0.00945171 2.36228 7.74403e-05C3.69704 0.00960659 4.65539 0.865324 4.65335 2.04694Z" fill="currentColor" />    </SVGIcon>
  )
}

export default Facebook