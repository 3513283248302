import React from 'react'

import CoverImage from '../CoverImage'
import PostTitle from '../PostTitle'
import Date from '../../Date'

import s from './styles.module.css'
import { PostHeaderProps } from './types'

const PostHeader = (props: PostHeaderProps) => {
  const {
    title,
    coverImage,
    publishedDate,
    author
  } = props

  return (
    <>
      <div className={s.cover_image}>
        <CoverImage title={title} coverImage={coverImage} />
      </div>
      <PostTitle>{title}</PostTitle>
      <div className={s.post_meta}>
        {author && publishedDate && <>By {author} on <Date dateString={publishedDate} /></>}
        {!author && publishedDate && <>Published on <Date dateString={publishedDate} /></>}
      </div>
    </>
  )
}

export default PostHeader
