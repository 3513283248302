import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const Lightning = (props: SVGIconProps) => {
  const { width = 64, height = 64, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} {...svgProps}>
      <path d="M48.9,24.5635A1,1,0,0,0,48,24H37.58L45.9038,6.4277A.9994.9994,0,0,0,45,5H29a1,1,0,0,0-.9126.5908l-13,29A1,1,0,0,0,16,36h9.7592L21.0229,57.7871a1,1,0,0,0,1.7627.832l26-33A1.0013,1.0013,0,0,0,48.9,24.5635Z" />
    </SVGIcon>
  )
}

export default Lightning
