import React, { useMemo } from 'react'
import Link from '../../Link'
import { PostFooterTypes } from './types'

import s from './styles.module.css'
import Typography from '../../Typography'
import Separator from '../../Separator'

function PostFooter(props: PostFooterTypes) {
  const { links = [] } = props

  const blogLinks = useMemo(() =>
    links.map((link, index) => {
      const { url, id, name } = link

      return (
        <span key={id}>
          <Link
            variant="primary"
            className={s.link}
            key={id}
            href={url}
            target="_blank">
            {name}
          </Link>
          {
            index + 1 !== links.length &&
            <Separator
              className={s.separator} />
          }
        </span>
      )
    }), [links])

  return (
    <div>
      <Typography
        as="p"
        className={s.leading_text}>
        <strong>Learn more:</strong>
      </Typography>
      <div className={s.socials}>
        {blogLinks}
      </div>
    </div>
  )
}

export default PostFooter