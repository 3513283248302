/**
 * Create an alt string from filenames
 * @param src
 * @returns
 */
const generateAltFromSrc = (src: string): string => {
    if (typeof src !== 'string') return ''

    const filePath = src.split('/').pop()

    if (!filePath) return ''

    let filename = filePath.split('.')[0]

    filename = filename.replaceAll(/[-_]/gm, ' ')

    return filename
}

export default generateAltFromSrc
