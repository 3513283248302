import removeTrailingSlash from 'helpers/removeTrailingSlash'

export const ROUTE_AZURE_MARKETPLACE_BASE = removeTrailingSlash(
    process.env.NEXT_PUBLIC_AZURE_MARKETPLACE_URL as string,
)

export const ROUTE_BLOG_BASE = '/blog'
export const ROUTE_BLOG_TAG = `${ROUTE_BLOG_BASE}/tag`
export const ROUTE_BLOG_CATEGORY = `${ROUTE_BLOG_BASE}/category`

export const ROUTE_CAREERS_BASE = '/careers'
export const ROUTE_CAREERS_JOB = `${ROUTE_CAREERS_BASE}/job`

export const ROUTE_VIDEO_GALLERY_BASE = '/videos'
export const ROUTE_PHOTO_GALLERY_BASE = '/photo-gallery'
export const ROUTE_DIGITAL_ASSETS_BASE = '/in-game-assets'
export const ROUTE_TEAM = '/team'

export const ROUTE_ANNOUNCEMENTS = `/announcements`

export const ROUTE_PRESS_BASE = '/press'
export const ROUTE_PRESS_IN_THE_NEWS = `${ROUTE_PRESS_BASE}/in-the-news`
export const ROUTE_PRESS_BRAND_KIT = `${ROUTE_PRESS_BASE}/brand-kit`

export const ROUTE_ABOUT_GAME = '/about-game'
export const ROUTE_ABOUT_GAME_PLAY_YOUR_WAY = `${ROUTE_ABOUT_GAME}/play-your-way`
export const ROUTE_ABOUT_GAME_EXPLORATION = `${ROUTE_ABOUT_GAME}/exploration`
export const ROUTE_ABOUT_GAME_COMMERCE = `${ROUTE_ABOUT_GAME}/commerce`
export const ROUTE_ABOUT_GAME_RACING = `${ROUTE_ABOUT_GAME}/racing`
export const ROUTE_ABOUT_GAME_COMBAT = `${ROUTE_ABOUT_GAME}/combat`

export const ROUTE_ROADMAP = 'https://copiwiki.cornucopias.io/gameplay/about-the-game/road-map'
export const ROUTE_GAME_TESTING = '/game-testing'

export const ROUTE_WEB3 = '/web3'
export const ROUTE_WEB3_BLOCKCHAIN = `${ROUTE_WEB3}/blockchain`
export const ROUTE_WEB3_CARDANO = `${ROUTE_WEB3}/cardano`
export const ROUTE_WEB3_COPI_TOKEN = `${ROUTE_WEB3}/copi-token`
export const ROUTE_WEB3_TOKENONMICS = `${ROUTE_WEB3_COPI_TOKEN}#tokenomics`
export const ROUTE_WEB3_STAKING = `${ROUTE_WEB3_COPI_TOKEN}#copi-staking`

export const ROUTE_TUTORIAL_LIBRARY = `${ROUTE_VIDEO_GALLERY_BASE}#tutorials`
export const ROUTE_MARKETPLACE = '/marketplace'
export const ROUTE_CORNUCOPIAS_UNIVERSITY = '/cornucopias-university'
export const ROUTE_NFTS = ROUTE_DIGITAL_ASSETS_BASE

export const ROUTE_LEGAL = '/legal'
export const ROUTE_LEGAL_TERMS_OF_SERVICE = `${ROUTE_LEGAL}/terms-of-service`
export const ROUTE_LEGAL_PRIVACY_POLICY = `${ROUTE_LEGAL}/privacy-policy`
export const ROUTE_LEGAL_EULA = `${ROUTE_LEGAL}/eula`

export const ROUTE_HOW_TO_PLAY = '/how-to-play'
export const ROUTE_GET_THE_GAME = '/get-the-game'
export const ROUTE_HOW_TO_PLAY_QUALIFYING_GAME_ASSETS = `${ROUTE_HOW_TO_PLAY}#qualifying-assets`
export const ROUTE_CONTACT = '/contact'
export const ROUTE_LOGIN = '/login'
export const ROUTE_LOGIN_WITH_CALLBACK = (callback: string) =>
    `${ROUTE_LOGIN}?callbackUrl=${callback}`
export const ROUTE_AUTHENTICATE = '/authenticate'
export const ROUTE_REGISTER = '/register'
export const ROUTE_FORGOT_PASSWORD = '/forgot-password'
export const ROUTE_RECOVER_PASSWORD = '/recover/password'

export const ROUTE_NODES_REWARD_CALCULATOR = '/nodes/rewards'

export const ROUTE_LEADERBOARDS = '/leaderboards'
export const ROUTE_RACING_LEADERBOARD = `${ROUTE_LEADERBOARDS}/calido-valley-raceway`
export const ROUTE_HOLOCACHE_LEADERBOARD = `${ROUTE_LEADERBOARDS}/holocache`

export const ROUTE_ACCOUNT = '/account'
export const ROUTE_ACCOUNT_SETUP = `${ROUTE_ACCOUNT}/setup`
export const ROUTE_ACCOUNT_OVERVIEW = `${ROUTE_ACCOUNT}/overview`
export const ROUTE_ACCOUNT_CONTENT_CREATOR = `${ROUTE_ACCOUNT}/content-creator`
export const ROUTE_ACCOUNT_OVERVIEW_TESTING = `${ROUTE_ACCOUNT_OVERVIEW}#testing`
export const ROUTE_ACCOUNT_GAME_ASSETS = `${ROUTE_ACCOUNT}/my-game-assets`
export const ROUTE_ACCOUNT_REWARDS = `${ROUTE_ACCOUNT}/rewards`
export const ROUTE_ACCOUNT_PROFILE = `${ROUTE_ACCOUNT}/profile`
export const ROUTE_ACCOUNT_MANAGE_NODES = `${ROUTE_ACCOUNT}/manage-nodes`
export const ROUTE_ACCOUNT_HOME = ROUTE_ACCOUNT_OVERVIEW
export const ROUTE_ACCOUNT_SETTINGS = `${ROUTE_ACCOUNT}/settings`
export const ROUTE_ACCOUNT_SETTINGS_AVATAR_SECTION = `${ROUTE_ACCOUNT_SETTINGS}#avatar`
export const ROUTE_ACCOUNT_SETTINGS_PERSONAL_SECTION = `${ROUTE_ACCOUNT_SETTINGS}#personal`
export const ROUTE_ACCOUNT_SETTINGS_SECURITY_SECTION = `${ROUTE_ACCOUNT_SETTINGS}#security`
export const ROUTE_ACCOUNT_SETTINGS_WALLET_SECTION = `${ROUTE_ACCOUNT_SETTINGS}#wallet`
export const ROUTE_ACCOUNT_SETTINGS_ROLES_SECTION = `${ROUTE_ACCOUNT_SETTINGS}#roles`
export const ROUTE_ACCOUNT_SETTINGS_KYC_SECTION = `${ROUTE_ACCOUNT_SETTINGS}#kyc`
export const ROUTE_ACCOUNT_SETTINGS_CUSTOMTAGS_SECTION = `${ROUTE_ACCOUNT_SETTINGS}#customtags`
export const ROUTE_ACCOUNT_SETTINGS_DELETE_ACCOUNT_SECTION = `${ROUTE_ACCOUNT_SETTINGS}#account-delete`

export const ROUTE_DESKTOP_LAUNCHER = 'Cornucopias://'

// List of all routes that are protected used in middleware
export const PROTECTED_ROUTES = [
    ROUTE_ACCOUNT_OVERVIEW,
    ROUTE_ACCOUNT_GAME_ASSETS,
    ROUTE_ACCOUNT_SETTINGS,
    ROUTE_ACCOUNT_SETUP,
    ROUTE_ACCOUNT_CONTENT_CREATOR,
    ROUTE_ACCOUNT_MANAGE_NODES,
    ROUTE_ACCOUNT_REWARDS,
]

// External links to resources
export const CONTENT_CREATOR_PUBLIC_URL = 'https://links.cornucopias.io/cal-public'

// Azure marketplace urls
export const ROUTE_AZURE_MARKETPLACE = `${ROUTE_AZURE_MARKETPLACE_BASE}/nft-sale/`
export const ROUTE_AZURE_MARKETPLACE_AFFILIATES = `${ROUTE_AZURE_MARKETPLACE_BASE}/affiliate-links`
export const ROUTE_AZURE_MARKETPLACE_WITH_LOGIN = (url: string) =>
    `/login?source=AzureMarketplace&callbackUrl=${url}`

export const ROUTE_NODES_DOCUMENTATION = 'https://links.cornucopias.io/file-node-deck'
export const ROUTE_NODES_FILE_NODE_FAQ_DECK = 'https://links.cornucopias.io/file-node-faq'
export const ROUTE_NODES_NODE_POOL_INTRO_DECK = 'https://links.cornucopias.io/node-pool-intro-deck '
export const ROUTE_NODES_ILLUSTRATED =
    'https://www.dropbox.com/scl/fi/49eipdrd425ws026frgba/NODES_WIP_V005.mp4'

export const AZURE_MARKETPLACE_CARDANO_SALE_KEY = 'file-node-access-key'
export const AZURE_MARKETPLACE_BASE_SALE_KEY = 'file-node-access-key-base'
export const AZURE_MARKETPLACE_TEST_SALE_KEY = 'evm-test-1'
