import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const LeaderboardLogo = (props: SVGIconProps) => {
  const { width = 490, height = 26, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} {...svgProps}>
      <g clipPath="url(#clip0_6331_661)">
        <path d="M349.845 23.9485L347.134 25.9901H317.943C317.471 25.244 317.124 24.6947 316.653 23.9491L324.274 2.05154L326.985 0.010498H356.176L357.466 2.05154L349.845 23.9485ZM354.342 2.89662H326.808L319.775 23.1035H347.31L354.342 2.89662Z" fill="white" />
        <path d="M264.044 12.4024L267.684 2.05162C267.208 1.30549 266.857 0.756136 266.381 0.0100098H234.859L229.783 14.4435L225.721 25.9902H228.58H228.619L231.665 17.3302H256.236L258.303 20.566L256.403 25.9902H259.264L261.592 19.3712C261.252 18.8378 259.367 15.8868 259.367 15.8868C259.367 15.8868 263.327 12.9359 264.043 12.4024H264.044ZM257.261 14.4435H232.68L236.742 2.8967H264.528L261.605 11.2065L257.261 14.4435Z" fill="white" />
        <path d="M444.378 12.4024L448.03 2.05162C447.553 1.30549 447.2 0.756136 446.722 0.0100098H415.096L410.003 14.4435L405.928 25.9902H408.797H408.837L411.893 17.3302H436.545L438.619 20.566L436.713 25.9902H439.583L441.919 19.3712C441.577 18.8378 439.685 15.8868 439.685 15.8868C439.685 15.8868 443.659 12.9359 444.378 12.4024ZM437.573 14.4435H412.911L416.985 2.8967H444.863L441.931 11.2065L437.573 14.4435Z" fill="white" />
        <path d="M216.335 11.5568L215.322 14.4435H187.55L184.51 23.1035H215.135L214.122 25.9902H180.643L189.763 0.0100098H223.242L222.229 2.8967H191.603L188.563 11.5568H216.335Z" fill="white" />
        <path d="M131.639 0.0100098H101.892L99.1293 2.05105L90.6396 25.9902H93.5225L95.57 20.2169H123.628L121.58 25.9902H124.463L132.954 2.05105C132.473 1.30549 132.119 0.756136 131.639 0.0100098ZM124.652 17.3302H96.594L101.713 2.8967H129.771L124.652 17.3302Z" fill="white" />
        <path d="M401.539 0.0100098H371.793L369.03 2.05105L360.54 25.9897H363.423L365.471 20.2163H393.529L391.481 25.9897H394.364L402.854 2.05105C402.373 1.30493 402.02 0.755568 401.539 0.0100098ZM394.552 17.3296H366.494L371.613 2.89613H399.671L394.552 17.3296Z" fill="white" />
        <path d="M81.3667 11.5568L80.3433 14.4435H52.286L49.2145 23.1035H80.1547L79.1307 25.9902H45.3076L54.5214 0.0100098H88.3445L87.3205 2.8967H56.3803L53.3089 11.5568H81.3661H81.3667Z" fill="white" />
        <path d="M33.8231 25.9902H0L9.21384 0.0100098H12.0968L3.90636 23.1035H34.8465L33.8225 25.9902H33.8231Z" fill="white" />
        <path d="M173.073 6.77917L170.636 2.8967L168.824 0.0100098H147.067L144.356 2.05105L136.734 23.9486C137.206 24.6947 137.553 25.2441 138.024 25.9897H167.216L169.926 23.948L173.695 13.1207L174.893 9.67781L173.073 6.77861L173.073 6.77917ZM167.392 23.1035H139.858L146.891 2.8967H167.277L171.876 10.2226L167.393 23.1035H167.392Z" fill="white" />
        <path d="M488.18 6.77917L485.743 2.8967L483.931 0.0100098H462.173L459.463 2.05105L451.841 23.9486C452.313 24.6947 452.66 25.2441 453.131 25.9897H482.322L485.033 23.948L488.802 13.1207L490 9.67781L488.18 6.77861L488.18 6.77917ZM482.499 23.1035H454.965L461.998 2.8967H482.384L486.982 10.2226L482.499 23.1035Z" fill="white" />
        <path d="M312.779 2.05105C312.302 1.30549 311.95 0.755568 311.471 0.0100098H279.845L274.752 14.4435L270.677 25.9902H302.319L305.03 23.9771L307.686 16.4891C307.344 15.9556 305.453 13.0047 305.453 13.0047C305.453 13.0047 309.427 10.0537 310.145 9.52028L312.779 2.05105ZM277.66 14.4474H302.312L304.386 17.6833L302.426 23.103H274.604L277.659 14.4469L277.66 14.4474ZM307.699 8.32375L303.34 11.5608H278.678L281.734 2.89613H309.612L307.699 8.32375Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_6331_661">
          <rect width="490" height="26" fill="white" />
        </clipPath>
      </defs>
    </SVGIcon>
  )
}

export default LeaderboardLogo
