export const ACCOUNT_SETTING_SHOW_ASSET_VIDEOS_ID = 'cornucopias_setting_show_asset_videos'
export const ACCOUNT_MY_GAME_ASSETS_SETTING_GRID_SIZE_ID = 'cornucopias_setting_grid_size'
export const ACCOUNT_IN_GAME_ASSETS_SETTING_GRID_SIZE_ID =
    'cornucopias_in-game_assets_setting_grid_size'
export const ACCOUNT_DELETE_PERIOD_DAYS = 30

export const KYC_STATUS_APPROVED = 'APPROVED'
export const KYC_STATUS_REJECTED = 'REJECTED'
export const KYC_STATUS_RESUBMISSION_REQUIRED = 'RESUBMISSION_REQUIRED'
export const KYC_STATUS_SUBMISSION_REQUIRED = 'SUBMISSION_REQUIRED'
export const KYC_STATUS_PENDING_VERIFICATION = 'PENDING_VERIFICATION'
