
import React from 'react'
import cn from 'classnames'

import Typography from '@/components/ui/Typography'

import s from './styles.module.css'
import { CardBodyProps } from './types'

/**
 * Card body component
 * @param props 
 * @returns 
 */
function CardBody(props: CardBodyProps) {
  const { title, children, className, bodyClassName, titleClassName } = props

  return (
    <div className={cn(s.content, className)}>
      {title &&
        <Typography as="h3" className={cn(s.title, titleClassName)}>
          {title}
        </Typography>
      }
      <div className={cn(s.body, bodyClassName)}>
        {children}
      </div>
    </div>
  )

}

export default CardBody