import React from 'react'
import cx from 'classnames'

import { IframeProps } from './types'
import s from './styles.module.css'

const Iframe = (props: IframeProps) => {
  const {
    children,
    className,
    responsive = true,
    title,
    ...iFrameProps
  } = props

  const iFrameClasses = cx(
    responsive && s.iframe,
    className
  )

  return (
    <div className={cx(responsive && s.container)}>
      <iframe
        title={title}
        className={iFrameClasses}
        {...iFrameProps}>
        {children}
      </iframe>
    </div>
  )
}

export default Iframe