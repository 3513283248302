import {
    API_MARKETPLACE_CT_PAYMENT_ORDER_ACTIVE,
    API_MARKETPLACE_CT_PAYMENT_ORDER,
    API_MARKETPLACE_CT_PAYMENT_ORDER_CREATE,
    API_MARKETPLACE_CT_PAYMENT_ORDER_ADD_TAG,
    API_MARKETPLACE_CT_PAYMENT_ORDER_TAG_AVAILABILITY,
    API_MARKETPLACE_CT_PAYMENT_ORDER_TAG_REMOVE,
    API_MARKETPLACE_CT_PAYMENT_GET,
    API_MARKETPLACE_CT_PAYMENT_CREATE,
    API_MARKETPLACE_CT_PAYMENT_CANCEL,
    API_MARKETPLACE_CT_PAYMENTS,
    API_MARKETPLACE_CT_PAYMENT_ORDER_TAG_REMOVE_ALL,
    API_MARKETPLACE_CT_SETTINGS_ACTIVE,
    API_MARKETPLACE_CT_PAYMENT_ORDER_REFRESH,
} from 'consts/apiRoutes'

import {
    MarketplaceCustomTagAvailabilityRequestType,
    MarketplaceCustomTagAvailabilityResponseType,
    MarketplaceCustomTagCreateRequestType,
    MarketplaceCustomTagPaymentOrderCreateRequestType,
    MarketplaceCustomTagPaymentOrderResponseType,
    MarketplaceCustomTagPaymentResponseType,
    MarketplaceCustomTagSettingsActiveResponseType,
} from 'types'
import HttpClientBase from './HttpClientBase'

class CustomTagHttpClient extends HttpClientBase {
    static setAccessToken(accessToken: string) {
        super.setAccessToken(accessToken)

        return this
    }

    static async GetActiveSettings(playerSub: string) {
        this.requireAuth()

        return this.request<MarketplaceCustomTagSettingsActiveResponseType>({
            url: API_MARKETPLACE_CT_SETTINGS_ACTIVE(playerSub),
            method: 'GET',
        })
    }

    static async GetActivePaymentOrder(playerSub: string) {
        this.requireAuth()

        return this.request<MarketplaceCustomTagPaymentOrderResponseType>({
            url: API_MARKETPLACE_CT_PAYMENT_ORDER_ACTIVE(playerSub),
            method: 'GET',
        })
    }

    static async GetPaymentOrder(playerSub: string, id: number) {
        this.requireAuth()

        return this.request<MarketplaceCustomTagPaymentOrderResponseType>({
            url: API_MARKETPLACE_CT_PAYMENT_ORDER(playerSub, id),
            method: 'GET',
        })
    }

    static async CreatePaymentOrder(playerSub: string) {
        this.requireAuth()
        const payload = {
            createdBy: playerSub,
        }

        return this.request<MarketplaceCustomTagPaymentOrderResponseType>({
            url: API_MARKETPLACE_CT_PAYMENT_ORDER_CREATE(playerSub),
            data: payload,
            method: 'POST',
        })
    }

    static async AddToPaymentOrder(
        playerSub: string,
        id: number,
        payload: MarketplaceCustomTagCreateRequestType,
    ) {
        this.requireAuth()

        return this.request<MarketplaceCustomTagPaymentOrderResponseType>({
            url: API_MARKETPLACE_CT_PAYMENT_ORDER_ADD_TAG(playerSub, id),
            data: payload,
            method: 'POST',
        })
    }

    static async GetTagAvailability(
        playerSub: string,
        id: number,
        payload: MarketplaceCustomTagAvailabilityRequestType,
    ) {
        this.requireAuth()

        return this.request<MarketplaceCustomTagAvailabilityResponseType>({
            url: API_MARKETPLACE_CT_PAYMENT_ORDER_TAG_AVAILABILITY(playerSub, id),
            data: payload,
            method: 'POST',
        })
    }

    static async RemoveFromPaymentOrder(playerSub: string, paymentOrderId: number, id: number) {
        this.requireAuth()

        return this.request<MarketplaceCustomTagPaymentOrderResponseType>({
            url: API_MARKETPLACE_CT_PAYMENT_ORDER_TAG_REMOVE(playerSub, paymentOrderId, id),
            method: 'DELETE',
        })
    }

    static async ClearPaymentOrder(playerSub: string, paymentOrderId: number) {
        this.requireAuth()

        return this.request<MarketplaceCustomTagPaymentOrderResponseType>({
            url: API_MARKETPLACE_CT_PAYMENT_ORDER_TAG_REMOVE_ALL(playerSub, paymentOrderId),
            method: 'DELETE',
        })
    }

    static async RefreshPaymentOrder(playerSub: string, paymentOrderId: number) {
        this.requireAuth()

        return this.request<MarketplaceCustomTagPaymentOrderResponseType>({
            url: API_MARKETPLACE_CT_PAYMENT_ORDER_REFRESH(playerSub, paymentOrderId),
            method: 'GET',
        })
    }

    static async CreatePayment(
        playerSub: string,
        payload: MarketplaceCustomTagPaymentOrderCreateRequestType,
    ) {
        this.requireAuth()

        return this.request<MarketplaceCustomTagPaymentResponseType>({
            url: API_MARKETPLACE_CT_PAYMENT_CREATE(playerSub),
            data: payload,
            method: 'POST',
        })
    }

    static async CancelPayment(playerSub: string, id: number) {
        this.requireAuth()

        return this.request<MarketplaceCustomTagPaymentResponseType>({
            url: API_MARKETPLACE_CT_PAYMENT_CANCEL(playerSub, id),
            method: 'PUT',
        })
    }

    static async GetPayment(playerSub: string, id: number) {
        this.requireAuth()

        return this.request<MarketplaceCustomTagPaymentResponseType>({
            url: API_MARKETPLACE_CT_PAYMENT_GET(playerSub, id),
            method: 'GET',
        })
    }

    static async GetPayments(playerSub: string) {
        this.requireAuth()

        return this.request<MarketplaceCustomTagPaymentResponseType>({
            url: API_MARKETPLACE_CT_PAYMENTS(playerSub),
            method: 'GET',
        })
    }
}

export default CustomTagHttpClient
