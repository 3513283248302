import getSocials from './getSocials'
import { SocialPlatformType, SocialType } from './types'

const getSocial = (platform: SocialPlatformType): SocialType | undefined => {
    const socials = getSocials()

    return socials.find((social) => social.id === platform)
}

export default getSocial
