import React from 'react'

import Box from '../../Box'
import SortButton from './SortButton'

import s from './styles.module.css'
import { SortProps } from './types'
import Typography from '../../Typography'

/**
 * Sort navigation bar
 * @param props 
 * @returns 
 */
const SortBar = (props: SortProps) => {
  const { options = [], label: groupLabel, pathname } = props

  const buttons = options.map(({ label, value }) => (
    <SortButton key={value} pathname={pathname} value={value}>{label}</SortButton>
  ))

  return (
    <Box display='flex'>
      {
        groupLabel &&
        <Typography
          uppercase
          className={s.label}>
          {groupLabel}:
        </Typography>}
      {buttons}
    </Box>

  )
}

export default SortBar