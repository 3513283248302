import React from 'react'

import BaseLink from '@/components/ui/Link'
import Typography from '@/components/ui/Typography'

import { useMegaMenu } from '../MegaMenuProvider'

import s from './styles.module.css'
import { LinkProps } from './types'

/**
 * Mega menu link
 * @param props 
 * @returns 
 */
const Link = (props: LinkProps) => {
  // On click of any menu item close menu
  const { toggleActive } = useMegaMenu()
  const { children, description, ...linkProps } = props

  return (
    <BaseLink
      variant="default"
      onClick={() => toggleActive(null)}
      activeClassName={s.active}
      className={s.link}
      {...linkProps}>
      <Typography
        variant="default"
        uppercase
        className={s.link_label}
        as="span">
        {children}
      </Typography>
      <Typography
        variant="secondary"
        className={s.link_description}
        as="span">
        {description}
      </Typography>
    </BaseLink>
  )
}

export default Link