import { useEffect, useState } from 'react'
import { VeritreeStatDataType } from './types'

// const API_MAP_DATA = 'https://api.veritree.com/api/sponsors/172/map-data?_result=1&_v=10.0.0'
const API_STATS_DATA =
    'https://api.veritree.com/api/orgs/pstats?org_id=172&org_type=sponsorAccount&_result=1&_v=10.0.0'

async function getStatData(): Promise<VeritreeStatDataType | null> {
    let data = null

    await fetch(API_STATS_DATA)
        .then((response) => response.json())
        .then((result) => {
            data = result as VeritreeStatDataType
        })

    return data
}

// async function getMapData(): Promise<VeritreeMapDataType | null> {
//     let data = null

//     await fetch(API_MAP_DATA)
//         .then((response) => response.json())
//         .then((result) => {
//             data = result as VeritreeMapDataType
//         })

//     return data
// }

function useVeritreeApi() {
    const [stats, setStats] = useState<VeritreeStatDataType | null>()

    const callApi = async () => {
        const value = await getStatData()
        setStats(value)
    }

    useEffect(() => {
        callApi()
    }, [])

    return stats
}

export default useVeritreeApi
