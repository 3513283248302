import { useQuery } from '@tanstack/react-query'
import { BlogHttpClient } from '../network'
import { SiteCategoriesFetchDataQueryParams, SitePostFetchDataQueryParams } from '../types'

export const POSTS_QUERY_KEY = 'posts'
export const CATEGORIES_QUERY_KEY = 'categories'
export const POST_QUERY_KEY = 'post'

export const useCatergoryQuery = (params: SiteCategoriesFetchDataQueryParams) =>
    useQuery({
        queryKey: [CATEGORIES_QUERY_KEY, params],
        queryFn: () => BlogHttpClient.GetSiteCategories(params),
        retry: false,
        refetchOnWindowFocus: false,
    })

export const usePostsQuery = (params: SitePostFetchDataQueryParams) =>
    useQuery({
        queryKey: [POSTS_QUERY_KEY, params],
        queryFn: () => BlogHttpClient.GetSitePosts(params),
        retry: false,
        refetchOnWindowFocus: false,
    })

export const usePostQuery = (slug: string) =>
    useQuery({
        queryKey: [POSTS_QUERY_KEY, slug],
        queryFn: () => BlogHttpClient.GetSitePost(slug),
        retry: false,
        refetchOnWindowFocus: false,
    })
