import { useSyncExternalStore } from 'react'
import { parseISO, differenceInCalendarDays, add, Duration, sub } from 'date-fns'

// An empty subscribe function for useSyncExternalStore
const emptySubscribe = () => () => {}

const getDifference = (isoDate: string, addDuration?: Duration, subDuration?: Duration): number => {
    const parsed = parseISO(isoDate)

    if (!addDuration && !subDuration) return 0

    let newDate = parsed

    if (addDuration) newDate = add(parsed, addDuration)
    else if (subDuration) newDate = sub(parsed, subDuration)

    const difference = differenceInCalendarDays(newDate, new Date())

    return difference
}

const useSafeDistanceToIsoDate = (
    isoDate: string | null | undefined,
    addDuration?: Duration,
    subDuration?: Duration,
): number | null => {
    // This should silence hydration errors
    const difference = useSyncExternalStore(
        emptySubscribe,
        () => (isoDate ? getDifference(isoDate, addDuration, subDuration) : null),
        () => null,
    )

    return difference
}

export default useSafeDistanceToIsoDate
