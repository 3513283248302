import React from 'react'
import { ROUTE_LOGIN } from 'consts/routes'

import Typography from '../Typography'
import Link from '../Link'

const AccessDenied = () => (
  <>
    <Typography as="h2">Access Denied</Typography>
    <p>
      You must be signed in to view this page.
    </p>
    <p className="mt-2">
      <Link className="!text-skin-primary" href={ROUTE_LOGIN}>
        Click here to login in.
      </Link>
    </p>
  </>
)

export default AccessDenied