import React from 'react'
import cn from 'classnames'

import { SlideItemContentProps } from './types'
import s from './styles.module.css'

const SlideItemContent = (props: SlideItemContentProps) => {
  const { contentAlignment = 'middle', contentPosition = "left", className, children, classes = {} } = props

  const contentClasses = cn({
    [`${s.item_content}`]: true,
    [`${s.item_content_top}`]: contentAlignment === 'top',
    [`${s.item_content_middle}`]: contentAlignment === 'middle',
    [`${s.item_content_bottom}`]: contentAlignment === 'bottom',
    [`${s.item_content_left}`]: contentPosition === 'left',
    [`${s.item_position_middle}`]: contentPosition === 'middle',
    [`${s.item_content_right}`]: contentPosition === 'right',
    [`${classes.contentPositionLeft}`]: classes.contentPositionLeft && contentPosition === 'left',
    [`${classes.contentPositionRight}`]: classes.contentPositionRight && contentPosition === 'right',
    [`${className}`]: true,
  })

  return (
    <div className={contentClasses}>
      {children}
    </div>
  )
}

export default SlideItemContent 
