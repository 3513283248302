import { useCallback, useEffect, useRef, useState } from 'react'
import useEventListener from 'hooks/useEventListener'

interface Dimensions {
    width: number
    height: number
    top: number
    left: number
    right: number
    bottom: number
}

const useElementDimensions = <T extends HTMLElement>() => {
    const ref = useRef<T | null>(null)
    const [dimensions, setDimensions] = useState<Dimensions | null>(null)

    const refresh = useCallback(() => {
        const domRect = ref.current?.getBoundingClientRect()

        if (domRect) {
            setDimensions({
                width: domRect.width,
                height: domRect.height,
                top: domRect.top,
                left: domRect.left,
                right: domRect.right,
                bottom: domRect.bottom,
            })
        }
    }, [])

    useEffect(() => {
        refresh()
    }, [])

    useEventListener('resize', refresh)

    return { dimensions, ref, refresh }
}

export default useElementDimensions
