const MUESLISWAP_API = `https://analyticsv2.muesliswap.com/ticker`

const getPriceFromMuesliSwap = async (id: string): Promise<number> => {
    let price = 0

    await fetch(MUESLISWAP_API)
        .then((response) => response.json())
        .then((result) => {
            price = result[id]?.last_price
        })
        .catch(() => {
            price = 0
        })

    return price
}

export default getPriceFromMuesliSwap
