import React from 'react'
import { usePageScroll } from './PageScrollProvider'

import s from './styles.module.css'
import { PageScrollProps } from './types'

/**
 * Page scroll container
 */
const PageScroll = (props: PageScrollProps) => {
  const { pages } = usePageScroll()
  const { children } = props;

  return (
    <div className={s.container}>
      {
        children && typeof children === "function" &&
        pages.map((page, index) => children(page, index))
      }
    </div>
  )
}

export default PageScroll 
