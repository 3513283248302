/**
 * Find word in a string from a string array
 * @param str
 * @param wordsArray
 * @returns
 */
const extractWordFromString = (str: string, wordsArray: string[]): string | null => {
    for (let i = 0; i < wordsArray.length; i += 1) {
        const word = wordsArray[i]
        const regex = new RegExp(`^${word}`, 'i') // 'i' flag for case-insensitive matching

        if (regex.test(str)) {
            const extractedWord = str.match(regex)?.[0]

            return extractedWord ?? null
        }
    }

    return null // Return null if no match is found
}

export default extractWordFromString
