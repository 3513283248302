import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const CalidoValleyRedLogo = (props: SVGIconProps) => {
  const { width = 273, height = 58, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} {...svgProps}>
      <g clipPath="url(#clip0_6286_5415)">
        <path d="M43.848 46.0744H32.3317L29.8519 53.1177L32.7583 57.5893H39.7923L43.848 46.0744Z" fill="#ED355E" />
        <path d="M24.8697 34.5581L28.9254 23.0419H17.4091L14.9133 30.1319L17.7891 34.5581H24.8697Z" fill="#FF8C10" />
        <path d="M9.94841 11.5269L14.0027 0.0106812H2.48644L0 7.18601L2.78107 11.4656L9.94841 11.5269Z" fill="#FF8C10" />
        <path d="M66.8791 46.0744H55.3628L52.9191 53.0151L55.8935 57.5893H62.8248L66.8791 46.0744Z" fill="#407AB8" />
        <path d="M48.4088 46.0744H55.3629L59.4185 34.5581H47.9022L45.4504 41.5215L48.4088 46.0744Z" fill="#407AB8" />
        <path d="M40.9242 34.5581H47.9022L51.9565 23.0419H40.4402L37.9805 30.0292L40.9242 34.5581Z" fill="#ED355E" />
        <path d="M33.4396 23.0419H40.4403L44.4959 11.5269H32.9796L30.5118 18.5356L33.4396 23.0419Z" fill="#ED355E" />
        <path d="M32.9796 11.5269L37.0339 0.0106812H25.519L23.0419 7.04336L25.9563 11.5269H32.9796Z" fill="#FF8C10" />
        <path d="M89.9103 46.0744H78.3954L75.9449 53.0337L78.906 57.5893H85.856L89.9103 46.0744Z" fill="#904594" />
        <path d="M71.4214 46.0744H78.3954L82.4497 34.5581H70.9334L68.475 41.5415L71.4214 46.0744Z" fill="#904594" />
        <path d="M63.9367 34.5581H70.9334L74.989 23.0419H63.4728L61.0063 30.0479L63.9367 34.5581Z" fill="#407AB8" />
        <path d="M56.4534 23.0419H63.4728L67.5271 11.5269H56.0121L53.5364 18.5556L56.4534 23.0419Z" fill="#407AB8" />
        <path d="M56.0122 11.5269L60.0664 0.0106812H48.5502L46.0677 7.06203L48.9688 11.5269H56.0122Z" fill="#ED355E" />
        <path d="M87.0266 34.5581H93.9659L98.0202 23.0419H86.5039L84.0575 29.9892L87.0266 34.5581Z" fill="#904594" />
        <path d="M79.5433 23.0419H86.504L90.5596 11.5269H79.0433L76.5889 18.497L79.5433 23.0419Z" fill="#904594" />
        <path d="M79.0433 11.5269L83.0976 0.0106812H71.5827L69.1202 7.0047L72.0586 11.5269H79.0433Z" fill="#407AB8" />
        <path d="M102.075 11.5269L106.13 0.0106812H94.6139L92.1448 7.02203L95.0725 11.5269H102.075Z" fill="#904594" />
        <path d="M218.646 7.88062L217.961 6.82605L219.98 1.06525L221.403 0.0106812H234.62L235.305 1.06525L233.286 6.82605L231.862 7.88062H218.646ZM219.829 6.22344H231.841L233.437 1.66786H221.425L219.829 6.22344Z" fill="white" />
        <path d="M153.374 7.88062L154.172 5.60216H142.16L141.362 7.88062H139.704L142.092 1.06525L143.516 0.0106812H156.732L157.417 1.06525L155.03 7.88062H153.374ZM142.74 3.94631H154.752L155.551 1.66786H143.539L142.74 3.94631Z" fill="white" />
        <path d="M117.956 7.88062L117.27 6.82605L119.289 1.06525L120.713 0.0106812H134.777L134.195 1.66786H120.734L119.138 6.22344H132.6L132.02 7.88062H117.956Z" fill="white" />
        <path d="M163.705 7.88062L163.02 6.82605L165.407 0.0106812H167.064L164.887 6.22344H177.314L176.733 7.88062H163.705Z" fill="white" />
        <path d="M194.577 7.88062L197.334 0.0106812H211.604L212.29 1.06525L210.271 6.82471L208.846 7.87928H194.575L194.577 7.88062ZM196.814 6.22344H208.826L210.422 1.66786H198.409L196.814 6.22344Z" fill="white" />
        <path d="M185.217 7.88062L187.976 0.0106812H189.632L186.875 7.88062H185.217Z" fill="white" />
        <path d="M149.322 43.0733L158.315 17.669H112.916L109.062 28.5574H142.956L141.671 32.1864H107.731L103.952 43.0733H123.067H149.322Z" fill="#ED355E" />
        <path d="M124.994 57.5893H144.184L128.347 46.7023H109.211L124.994 57.5893Z" fill="#ED355E" />
        <path d="M272.16 17.669H226.762L222.907 28.5574H256.801L251.661 43.0733H263.167L272.16 17.669Z" fill="#ED355E" />
        <path d="M216.484 46.7023L212.631 57.5893H258.029L261.884 46.7023H250.375H216.484Z" fill="#ED355E" />
        <path d="M155.796 57.5893H201.195L205.049 46.7023H159.65L155.796 57.5893Z" fill="#ED355E" />
        <path d="M215.327 17.669H169.928L166.074 28.5574H211.472L215.327 17.669Z" fill="#ED355E" />
        <path d="M210.064 32.1864H164.789L160.989 43.0733H206.334L210.064 32.1864Z" fill="#ED355E" />
      </g>
      <defs>
        <clipPath id="clip0_6286_5415">
          <rect width="272.16" height="57.6" fill="white" />
        </clipPath>
      </defs>
    </SVGIcon>
  )
}

export default CalidoValleyRedLogo
