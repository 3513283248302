import { useState, useEffect, MutableRefObject } from 'react'

const useClickOrDrag = (
    ref: MutableRefObject<HTMLElement | null>,
    threshold: number = 5,
): boolean => {
    const [isClick, setIsClick] = useState<boolean>(false)

    useEffect(() => {
        if (!ref.current) return

        let startX: number, startY: number

        const handleMouseDown = (event: MouseEvent) => {
            startX = event.clientX
            startY = event.clientY
        }

        const handleMouseUp = (event: MouseEvent) => {
            const endX = event.clientX
            const endY = event.clientY
            const diffX = Math.abs(endX - startX)
            const diffY = Math.abs(endY - startY)

            if (diffX < threshold && diffY < threshold) {
                setIsClick(true)
            } else {
                setIsClick(false)
            }
        }

        const element = ref.current
        element.addEventListener('mousedown', handleMouseDown)
        element.addEventListener('mouseup', handleMouseUp)

        return () => {
            element.removeEventListener('mousedown', handleMouseDown)
            element.removeEventListener('mouseup', handleMouseUp)
        }
    }, [ref, threshold])

    return isClick
}

export default useClickOrDrag
