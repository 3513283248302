import {
    API_GET_USER_NODES,
    API_NODES_DEREGISTER_NODE,
    API_NODES_GET_NODES_LIST,
    API_NODES_GET_NODES_OVERVIEW,
    API_NODES_REGISTER_NODE,
} from 'consts/apiRoutes'
import {
    NodesListResponse,
    Node,
    NodeListRequest,
    OnChainNodesRequest,
    OnChainNodesResponse,
    NodesOverviewResponse,
} from '@/types/Nodes'
import queryString from 'query-string'

import HttpClientBase from './HttpClientBase'

/**
 * Used for node related API calls
 */
class NodeHttpClient extends HttpClientBase {
    static setAccessToken(accessToken?: string) {
        super.setAccessToken(accessToken)

        return this
    }

    static async GetNodesOverview() {
        return this.request2<NodesOverviewResponse>({
            url: API_NODES_GET_NODES_OVERVIEW,
            method: 'GET',
        })
    }

    static async GetNodeList(params: NodeListRequest) {
        this.requireAuth()

        const qs = queryString.stringify(params)

        return this.request2<NodesListResponse>({
            url: `${API_NODES_GET_NODES_LIST}?${qs}`,
            method: 'GET',
        })
    }

    static async RegisterNode(id: string) {
        this.requireAuth()

        return this.request2<Node>({
            url: API_NODES_REGISTER_NODE(id),
            method: 'POST',
        })
    }

    static async DeregisterNode(id: string) {
        this.requireAuth()

        return this.request2<Node>({
            url: API_NODES_DEREGISTER_NODE(id),
            method: 'POST',
        })
    }

    static async GetOnChainNodes(params: OnChainNodesRequest) {
        this.requireAuth()

        return this.request2<OnChainNodesResponse>({
            url: API_GET_USER_NODES,
            method: 'POST',
            data: params,
        })
    }
}

export default NodeHttpClient
