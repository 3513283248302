import React, { ComponentPropsWithRef, ElementType, useMemo } from 'react'
import cx from 'classnames'

import { useVariant } from '../../../hooks'

import { TypographyProps } from './types'
import s from './styles.module.css'

const styleKeys = [
  "ml",
  "mr",
  "mt",
  "mb",
  "mx",
  "my",
  "m",
  "pl",
  "pr",
  "pt",
  "pb",
  "px",
  "py",
  "p"
]

/**
 * Displays content with applied styles & variants 
 * @param props 
 * @returns 
 */
const Typography = <T extends ElementType = "span">(props: TypographyProps<T> & ComponentPropsWithRef<T>) => {
  const {
    as = "span",
    children,
    variant = "default",
    className,
    center = false,
    uppercase = false,
    ref,
    ...rest
  } = props

  const variantClass = useVariant({
    variant,
    color: true
  })

  const twClasses = styleKeys.filter((key) => !!rest[key])
    .map((key) => `${key}-${rest[key]}`)
  const classes = cx(
    s.typography,
    [`${variantClass}`],
    { [`${s.center}`]: center },
    { [`${s.upper}`]: uppercase },
    twClasses,
    className
  )

  const Component = as

  // Create a new list of props excluding the typography props
  const componentProps = useMemo(() =>
    Object.keys(rest)
      .filter((prop) => !styleKeys.includes(prop))
      .reduce((acc, key) => {
        acc[key] = rest[key]

        return acc
      }, {} as ComponentPropsWithRef<T>)
    , [rest])

  return (
    <Component
      ref={ref}
      className={classes}
      {...componentProps}>
      {children}
    </Component>
  )
}

export default Typography