import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const Document = (props: SVGIconProps) => {
  const { width = 100, height = 100, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} {...svgProps}>
      <path d="M79.2 32.4L57.4 10.6c-.4-.4-.9-.6-1.4-.6H24c-2.2 0-4 1.8-4 4v72c0 2.2 1.8 4 4 4h52c2.2 0 4-1.8 4-4V33.7c-.1-.6-.4-.9-.8-1.3zM58 16.8L73.2 32H58V16.8zM76 86H24V14h30v20c0 1.1.9 2 2 2h20v50z" />
      <path d="M384-790V894h-1784V-790H384m8-8h-1800V902H392V-798z" />
    </SVGIcon>
  )
}

export default Document