import React from 'react'
import cn from 'classnames'

import useVariant from 'hooks/useVariant'
import Image from '../../Image'
import Typography from '../../Typography'
import { SlideNavigationThumbnailProps } from './types'
import s from './styles.module.css'
import HighlightedText from '../../HighlightedText'

const SlideNavigationThumbnail = (props: SlideNavigationThumbnailProps) => {
  const { onClick, index, active, src, label, variant, labelHighlight } = props

  const overlayClasses = useVariant({
    variant,
    background: true
  })

  return (
    <button
      key={index}
      onClick={() => onClick(index)}
      className={cn(s.slide_navigation_item, s.slide_navigation_thumbnail, active && s.active)}
    >
      <Image
        width={600}
        height={400}
        rounded={false}
        draggable={false}
        alt=""
        src={src} />

      {
        label &&
        <Typography
          uppercase
          className={cn(s.slide_navigation_item_text_overlay, variant ? overlayClasses : "bg-black/70")}>
          <HighlightedText
            text={label}
            highlight={labelHighlight}
            className="font-bold" />
        </Typography>
      }
    </button>
  )
}

export default SlideNavigationThumbnail
