import { useSyncExternalStore } from 'react'
import { parseISO, format } from 'date-fns'

// An empty subscribe function for useSyncExternalStore
const emptySubscribe = () => () => {}

const getFormattedDate = (isoDate: string, formatStr: string): string => {
    const parsed = parseISO(isoDate)
    const formatted = format(parsed, formatStr)
    return formatted
}

const useSafeFormatIsoDate = (
    isoDate: string | null | undefined,
    formatStr = 'do MMMM yyyy, HH:mm',
): string | null => {
    // This should silence hydration errors
    const formattedDate = useSyncExternalStore(
        emptySubscribe,
        () => (isoDate ? getFormattedDate(isoDate, formatStr) : null),
        () => null,
    )

    return formattedDate
}

export default useSafeFormatIsoDate
