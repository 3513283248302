/* eslint-disable react/no-danger */
import React, { Fragment } from 'react'

import { ROUTE_LEGAL_TERMS_OF_SERVICE } from '@/consts'
import { tRend, t } from 'helpers/translation'

import Link from '../../ui/Link'
import Typography from '../../ui/Typography'

import s from './styles.module.css'

/**
 * Copyright container
 * @returns 
 */
const Copyright = () => {
  const currentYear = new Date().getFullYear()

  return (
    <div className={s.copyright}>
      <Typography
        variant="default"
        as="p"
        uppercase>
        {tRend("footer.copyright", <Fragment key="copyright_icon">&copy;</Fragment>, currentYear)}
      </Typography>

      <Link href={ROUTE_LEGAL_TERMS_OF_SERVICE} className={s.legal}>
        <Typography
          variant="secondary"
          uppercase
          as="span">
          {t("footer.legalPrivacyStatement")}
        </Typography>
      </Link>
    </div>
  )
}

export default Copyright