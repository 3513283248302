import React from 'react'
import { Menu } from '@headlessui/react'
import { motion } from 'framer-motion'
import cx from 'classnames'

import s from './styles.module.css'
import { DropdownMenuItemProps } from './types'

/**
 * Menu component built from a list of options
 * @param props 
 * @returns 
 */
const DropdownMenuItem = (props: DropdownMenuItemProps) => {
  const { children, className, disabled = false } = props

  const menuItemVariants = {
    closed: { opacity: 0, x: -16 },
    open: { opacity: 1, x: 0, transition: { duration: 0.2 } }
  }

  const menuItemClasses = cx(s.menu_item, className)

  return (
    <Menu.Item
      as={motion.div}
      disabled={disabled}
      className={menuItemClasses}
      variants={menuItemVariants}>
      {children}
    </Menu.Item>
  )
}

export default DropdownMenuItem