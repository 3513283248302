/* eslint-disable react/no-danger */
import React from 'react'

import Date from '../../Date'
import CoverImage from '../CoverImage'

import s from './styles.module.css'
import { PostPreviewProps } from './types'
import { ROUTE_BLOG_BASE } from '../../../../consts'
import { buildPath } from '../../../../helpers'
import Link from '../../Link'
import Typography from '../../Typography'

/**
 * Post preview showing featured image and excerpt
 * @param props 
 * @returns 
 */
export default function PostPreview(props: PostPreviewProps) {
  const {
    title,
    coverImage,
    publishedDate,
    slug,
  } = props

  return (
    <article key={slug} className={s.article}>
      <CoverImage
        slug={slug}
        title={title}
        width={325}
        height={182}
        coverImage={coverImage}
        imageClassName={s.image}
        className={s.image_wrapper} />
      <div className={s.content}>
        <header className={s.header}>
          <Typography uppercase variant="secondary" as="span">
            <Date dateString={publishedDate} />
          </Typography>
        </header>
        <Link href={buildPath(ROUTE_BLOG_BASE, slug)}>
          <Typography
            as="h3"
            className={s.title}>
            {title}
          </Typography>
        </Link>
      </div>
    </article>
  )
}