import { Gate } from '.'
import PlayerHttpClient from '../../httpClients/PlayerHttpClient'

/**
 * GateServer will retrieve the user and return methods to determine
 * if a given user is authorised against a give role(s)
 * @param accessToken
 * @param id
 * @returns
 */
const GateServer = async (accessToken: string, id: number) => {
    try {
        const user = await PlayerHttpClient.setAccessToken(accessToken).GetUserShort()

        return Gate(user)
    } catch {
        return Promise.reject(new Error('gate_error_fetching_user'))
    }
}

export default GateServer
