import getWordsFromString from './getWordsFromString'

/**
 * Function aim to clean YouTube description by removing links and culling content
 * @param str
 * @param wordCount
 * @param ellipsis
 * @returns
 */
const cleanYoutubeDescription = (str: string, wordCount?: number, ellipsis?: boolean): string => {
    const urlRegex =
        /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$]).*/gim

    // Remove line breaks and extra space
    let s = str
        .replace(/(\r\n|\n|\r)/gm, '')
        .replace(/\s+/g, ' ')
        .trim()

    // Remove Urls
    s = str.replaceAll(urlRegex, '')

    // Plus one so we keep the period.
    s = s.substring(0, s.lastIndexOf('.') + 1)

    return wordCount ? getWordsFromString(s, wordCount, ellipsis) : s
}

export default cleanYoutubeDescription
