import {
    API_MASTER_LEDGER_GET_CLAIM_CREATE,
    API_MASTER_LEDGER_GET_CLAIM_PARAMETERS,
    API_MASTER_LEDGER_GET_REWARDS,
    API_MASTER_LEDGER_GET_REWARD_CLAIMS,
} from 'consts/apiRoutes'
import {
    MasterLedgerCreateClaimRequest,
    MasterLedgerGetClaimParametersRequest,
    MasterLedgerGetClaimParametersResponse,
    MasterLedgerGetRewardClaimsRequest,
    MasterLedgerGetRewardClaimsResponse,
    MasterLedgerGetRewardsRequest,
    MasterLedgerGetRewardsResponse,
} from '@/types/MasterLedger'

import HttpClientBase from './HttpClientBase'

class MasterLedgerHttpClient extends HttpClientBase {
    static setAccessToken(accessToken?: string) {
        super.setAccessToken(accessToken)

        return this
    }

    static async GetUserRewards(params: MasterLedgerGetRewardsRequest) {
        this.requireAuth()

        return this.request2<MasterLedgerGetRewardsResponse>({
            url: this.createParamUrl(API_MASTER_LEDGER_GET_REWARDS, params),
            method: 'GET',
        })
    }
    static async GetUserRewardClaims(params: MasterLedgerGetRewardClaimsRequest) {
        this.requireAuth()

        return this.request2<MasterLedgerGetRewardClaimsResponse>({
            url: this.createParamUrl(API_MASTER_LEDGER_GET_REWARD_CLAIMS, params),
            method: 'GET',
        })
    }

    static async GetClaimParameters(params: MasterLedgerGetClaimParametersRequest) {
        this.requireAuth()

        return this.request2<MasterLedgerGetClaimParametersResponse>({
            url: API_MASTER_LEDGER_GET_CLAIM_PARAMETERS,
            method: 'POST',
            data: params,
        })
    }

    static async CreateClaim(params: MasterLedgerCreateClaimRequest) {
        this.requireAuth()

        return this.request2<MasterLedgerGetRewardsResponse>({
            url: API_MASTER_LEDGER_GET_CLAIM_CREATE,
            method: 'POST',
            data: params,
        })
    }
}

export default MasterLedgerHttpClient
