import React, { useState, useCallback, ChangeEvent, useMemo } from 'react'
import { Client } from 'filestack-js';
import { Area } from "react-easy-crop";
import cn from 'classnames';

import { createCroppedImage } from 'helpers/filestack';

import { FileInput, ValidationSummary } from '../Form'
import StyledButton from '../StyledButton';
import ImageCropper from '../ImageCropper'
import Typography from '../Typography';
import Button from '../Button'
import Loader from '../Loader';
import Image from '../Image';

import { AvatarFileValidationType, AvatarUploaderProps } from './types';

import s from './styles.module.css'

/**
 * Component to manage avatar uploads and allow cropping
 * @param props 
 * @returns 
 */
const AvatarUploader = (props: AvatarUploaderProps) => {
  const {
    apiKey,
    avatarSrc = '/_assets/account/default-avatar.png',
    avatarCropData,
    canCrop = true,
    onSave,
    fileRules = {}
  } = props

  const filestackClient = useMemo(() => new Client(apiKey), [apiKey])
  const defaultRules = {
    accept: ["image/png", "image/jpeg", "image/jpg"],
    minSize: 1,
    maxSize: 10240
  }
  const rules = { ...defaultRules, ...fileRules }

  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [avatarUrl, setAvatarUrl] = useState<string>()
  const [crop, setCrop] = useState<Area>()
  const [error, setError] = useState<Record<string, { message: string }>>()

  const validateFile = (file: File, options: AvatarFileValidationType) => {
    const { minSize, maxSize, accept } = options

    if (!file)
      return "Please choose a file"

    const fileSizeKiloBytes = file.size / 1024

    if (minSize && fileSizeKiloBytes < minSize) {
      return "File size is less than minimum limit"
    }

    if (maxSize && fileSizeKiloBytes > maxSize) {
      return "File size is greater than maximum limit"
    }

    if (accept && !accept.includes(file.type)) {
      return "File type is not allowed"
    }

    return true
  };

  const onFileSelect = async (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target

    if (files) {
      const file = files[0]
      const validated = validateFile(file, rules)
      if (validated === true) {

        setIsUploading(true)

        if (apiKey) {
          const url = await filestackClient.upload(file)
            .then(response => response.url)
            .catch(fsError => {
              setError({ root: { message: fsError.message } })
            });

          if (url) {
            setAvatarUrl(url)
            setError(undefined)
          }
        }
        else {
          // eslint-disable-next-line no-console
          console.error("Filestack API has not been set.")
        }

        setIsUploading(false)
      }
      else {
        setIsUploading(false)
        setError({
          root: {
            message: validated
          }
        })
      }

    }
  };

  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      setCrop(croppedAreaPixels)
    },
    []
  );

  const handleOnSave = () => {
    if (onSave)
      onSave(avatarUrl as string, crop as Area)
  }

  if (isUploading)
    return (
      <div className={s.loader}>
        <Typography uppercase className={s.loader_text}>Uploading...</Typography>
        <Loader size="sm" dotClassName={s.loader_circles} />
      </div>
    )

  if (avatarUrl) {
    // Remove cropData if a new image has been uploaded
    return (
      <>
        <ImageCropper
          aspect={1}
          cropData={avatarSrc === avatarUrl ? avatarCropData : undefined}
          url={avatarUrl}
          onCropComplete={onCropComplete} />

        <div className={s.modal_cropper_button_container}>
          <StyledButton
            onClick={handleOnSave}
            variant="filled"
            color="primary">
            Save
          </StyledButton>
          <StyledButton
            onClick={() => setAvatarUrl(undefined)}
            variant="outline"
            color="default"
          >
            Select another image
          </StyledButton>

        </div>
      </>
    )
  }

  return (
    <>
      <div className={s.modal_button_container}>
        <FileInput
          className={cn(s.actions, s.upload_button)}
          name="avatar"
          accept={rules.accept.join(", ")}
          onChange={onFileSelect}
          label={(
            <>
              <Image
                fill
                rounded={false}
                src='/_assets/account/default-avatar.png'
              />
              <Typography>
                Upload new
              </Typography>
            </>
          )}
        />
        <Button
          disabled={!canCrop}
          className={s.actions}
          onClick={() => setAvatarUrl(avatarSrc)}>
          <Image
            fill
            rounded={false}
            src={avatarSrc && avatarCropData ? createCroppedImage(avatarSrc, avatarCropData) : '/_assets/account/default-avatar.png'}
          />
          <Typography>
            Re-crop current
          </Typography>
        </Button>
      </div>

      {
        error &&
        <ValidationSummary errors={error} />
      }
    </>
  )
}

export default AvatarUploader