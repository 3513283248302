import { Blockchain } from '@/types/Blockchain'
import {
    BLOCKCHAIN_TYPE_CARDANO,
    BLOCKCHAIN_TYPE_BASE,
    BLOCKCHAIN_TYPE_BNB,
    BLOCKCHAIN_TYPE_ETHEREUM,
} from 'consts/blockchain'

import isProduction from '../isProduction'

const getAddressExplorerUrl = (address: string, chain: Blockchain): string | undefined => {
    const prod = isProduction()

    switch (chain.toLowerCase()) {
        case BLOCKCHAIN_TYPE_CARDANO:
            return `https://${!prod ? 'preprod.' : ''}cardanoscan.io/address/${address}`
        case BLOCKCHAIN_TYPE_ETHEREUM:
            return `https://${!prod ? 'sepolia.' : ''}etherscan.io/address/${address}`
        case BLOCKCHAIN_TYPE_BNB:
            return `https://${!prod ? 'testnet.' : ''}bscscan.com/address/${address}`
        case BLOCKCHAIN_TYPE_BASE:
            return `https://${!prod ? 'sepolia.' : ''}basescan.org/address/${address}`
    }
}

export default getAddressExplorerUrl
