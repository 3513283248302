import { RegisterUserPayloadType } from 'types'
import { AuthHttpClient } from '../httpClients'

const register = async (data: RegisterUserPayloadType) => {
    const response = await AuthHttpClient.Register(data)

    return response
}

export default register
