import CBOR from 'cbor'

import { HumanReadableUtxo, HumanReadableUtxoAsset } from '../global'
import { toHexString } from './common'

export default class Utxo {
    utxo: HumanReadableUtxo | null

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(utxo: any) {
        const decoded = CBOR.decode(utxo)
        this.utxo = Utxo.toHumanReadable(decoded)
    }

    /**
     * Check if policy ids exist in utxo
     * @param policyId
     * @returns
     */
    hasPolicyId(policyIds: string | string[]): boolean {
        const found = this.findAssetsWithPolicyIds(policyIds)

        return found.length > 0
    }

    /**
     * Find assets with policy ids
     * @param policyIds
     * @returns
     */
    findAssetsWithPolicyIds(policyIds: string | string[]): HumanReadableUtxoAsset[] {
        if (this.utxo === null) return []

        const { tokens } = this.utxo.txDetails

        if (!tokens) return []

        const { assets } = tokens

        return assets.filter((asset) => {
            if (typeof policyIds === 'string') return asset.policyId === policyIds

            if (Array.isArray(policyIds)) return policyIds.includes(asset.policyId)

            return false
        })
    }

    /**
     * Convert utxo to something more readable
     * @param utxo
     * @returns
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static toHumanReadable(utxo: any): HumanReadableUtxo | null {
        const utxoIn = utxo[0]
        const [txHash, index] = utxoIn as [Uint8Array, number]

        const utxoOut = utxo[1]

        if (!Array.isArray(utxoOut)) {
            // Something isn't correct, out utxo
            // eslint-disable-next-line no-console
            console.warn('Non-standard UTXO found:')
            // eslint-disable-next-line no-console
            console.log(CBOR.encode(utxo))
            // eslint-disable-next-line no-console
            console.log(utxoIn, utxoOut)

            return null
        }

        const [destination, token] = utxoOut as
            | [Uint8Array, number]
            | [Uint8Array, [number, Map<Uint8Array, Map<Uint8Array, number>>]]

        let tx = null

        if (typeof token === 'number') {
            tx = { value: token }
        }

        if (Array.isArray(token)) {
            const [fee, assets] = token

            const readableAssets = Array.from(assets.entries()).map(([key, value]) => ({
                policyId: toHexString(key),
                assets: Array.from(value.entries()).map(([assetKey, assetValue]) => ({
                    assetName: Buffer.from(toHexString(assetKey), 'hex').toString(),
                    assetNameHex: toHexString(assetKey),
                    quantity: assetValue,
                })),
            }))

            tx = {
                tokens: {
                    fee,
                    assets: readableAssets,
                },
            }
        }

        return {
            tx: {
                txHash: toHexString(txHash),
                idx: index,
            },
            txDetails: {
                destination: toHexString(destination),
                ...tx,
            },
        } as HumanReadableUtxo
    }
}
