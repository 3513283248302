import React from 'react'

import s from './styles.module.css'

/**
 * Screen reader
 * @param props 
 * @returns 
 */
const VisuallyHidden = (props: { children: React.ReactNode }) => {
  const { children } = props

  return (
    <span className={s.screen_reader} > {children}</span>
  )
}

export default VisuallyHidden