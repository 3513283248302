/* eslint-disable no-restricted-globals */
import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'

type ActiveRouteProps = {
    href: string
    as?: string
}

/**
 * Returns whether a href is active or not
 * @param param0
 * @returns
 */
const useActiveRoute = ({ href, as }: ActiveRouteProps): boolean => {
    const { asPath, isReady } = useRouter()
    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        // Check if the router fields are updated client-side
        if (isReady) {
            // Dynamic route will be matched via props.as
            // Static route will be matched via props.href
            const linkUrl = new URL((as || href) as string, location.href)
            const linkPathname = linkUrl.pathname
            const linkHash = linkUrl.hash
            let active = false

            if (location.host === linkUrl.host) {
                const activeUrl = new URL(asPath, location.href)
                const activePathname = activeUrl.pathname
                const activeHash = activeUrl.hash

                active =
                    linkPathname === activePathname &&
                    (activeHash !== '' || linkHash !== '' ? linkHash === activeHash : true)
            }

            // Compare pathname, if hash exists also compare that
            setIsActive(active)
        }
    }, [asPath, isReady, as, href])

    return isActive
}

export default useActiveRoute
