import React, { createContext, useContext, useMemo, useState, useEffect } from 'react'
import { useCreateAffiliateLinkLog } from 'mutations'
import useLocalStorage from 'hooks/useLocalStorage'
import { LOCAL_STORAGE_MARKETPLACE_AFFILIATE_LINK_GUID_ID } from 'consts/marketplace'
import { usePostsQuery } from 'queries'
import { PostTypeTypes } from '@/components/ui/Blog/types'
import { useRouter } from 'next/router'

import { SiteContextType, SiteProviderProps } from './types'

const SiteContext = createContext<SiteContextType>({
  announcements: [],
  sidebarMobileVisible: false,
  setSidebarMobileVisible: (visible: boolean) => visible
})

const SiteProvider = (props: SiteProviderProps) => {
  const { children } = props
  const router = useRouter();

  const [sidebarMobileVisible, setSidebarMobileVisible] = useState<boolean>(false)
  const { setValue: setAffiliateLinkGuid } = useLocalStorage<string | null>(LOCAL_STORAGE_MARKETPLACE_AFFILIATE_LINK_GUID_ID, null)
  const { mutate: createAffiliateLinkLog } = useCreateAffiliateLinkLog()

  // Check the URL for the affiliate parameter if it exists, call the Azure marketplace endpoint to awknowledge it's been used.
  // Store the returned ID which should be appended to ANY marketplace url
  useEffect(() => {
    // Don't do this server side
    if (typeof window !== 'undefined') {
      const urlParams = new URLSearchParams(window.location.search)
      const alcCode = urlParams.get('alc')

      if (alcCode) {
        const currentUrl = window.location.href
        const originalOpenTime = new Date().toISOString()

        const newUrl = new URL(currentUrl)

        createAffiliateLinkLog({
          code: alcCode,
          url: newUrl.toString(),
          originalOpenTime,
        }, {
          onSuccess(data) {
            if (data.id)
              setAffiliateLinkGuid(data.id)

            // Let's remove the affiliate link parameters
            newUrl.searchParams.delete('alc')
            router.replace(newUrl.toString(), undefined, { shallow: true })
          },
        })
      }
    }
  }, [])

  const { data: announcements } = usePostsQuery({
    type: PostTypeTypes.ANNOUNCEMENT,
    orderBy: "publishedDate desc",
    offset: 0,
    limit: 5
  })

  const value = useMemo(() => ({
    announcements: announcements?.posts ?? [],
    sidebarMobileVisible,
    setSidebarMobileVisible
  }), [
    announcements,
    sidebarMobileVisible,
    setSidebarMobileVisible
  ])

  return <SiteContext.Provider value={value}>{children}</SiteContext.Provider>
}

const useSite = () => {
  const context = useContext(SiteContext)
  if (!context) {
    throw new Error('useSiteContext must be used within an < SiteProvider />')
  }

  return context
}

export { useSite, SiteContext }
export default SiteProvider