import { useEffect } from 'react'
import { FieldValues, UseFormSetFocus, FieldErrors, Path } from 'react-hook-form'

const useFocusOrder = <T extends FieldValues>(
    errors: FieldErrors<T>,
    setFocus: UseFormSetFocus<T>,
    order: Path<T>[],
) => {
    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            for (const fieldName of order) {
                if (errors[fieldName]) {
                    setFocus(fieldName)
                    break
                }
            }
        }
    }, [errors, setFocus, order])
}

export default useFocusOrder
