import { useState } from 'react'

function usePasswordConfirmModal() {
  const [open, setOpen] = useState<boolean>(false)
  const [error, setError] = useState<string | undefined>()

  const handleCancel = () => {
    setOpen(false)
    setError(undefined)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  return {
    open: handleOpen,
    close: handleCancel,
    isOpen: open,
    error,
    setError,
  }

}

export default usePasswordConfirmModal
