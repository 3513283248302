import React, { useEffect, useRef, useState } from 'react'
import cx from 'classnames'

import useOnClickOutside from 'hooks/useOnClickOutside'
import useKeyPress from 'hooks/useKeyPress'
import t from 'helpers/translation/getTranslation'

import { AnnoucementsProps } from './types'
import VisuallyHidden from '../VisuallyHidden'
import Conditional from '../Conditional'
import Alert from '../Alert'
import Announcement from './Annoucement'
import Typography from '../Typography'
import { Close, DoubleArrow } from '../Icon'
import Drawer from '../Drawer'
import Link from '../Link'
import Button from '../Button'

import { ROUTE_ANNOUNCEMENTS } from '../../../consts'
import s from './styles.module.css'

/**
 * Announcements pop out container
 * @param props 
 * @returns 
 */
const Announcements = (props: AnnoucementsProps) => {
  const { announcements = [], expandButtonClassName, panelClassName } = props
  const ref = useRef<HTMLDivElement>(null)
  const [isVisible, setIsVisible] = useState(false)

  const close = () => setIsVisible(false)

  const escape = useKeyPress("Escape")
  const onClick = () => { setIsVisible(!isVisible) }

  useOnClickOutside(ref, () => close())

  const panelClasses = cx(s.panel, panelClassName)
  const buttonExpandClasses = cx(s.expand_button, expandButtonClassName)

  useEffect(() => {
    if (escape) {
      close()
    }
  }, [escape])

  return (
    <>
      {
        !isVisible && <div className={s.expand_button_wrapper}>
          <button
            onClick={onClick}
            className={buttonExpandClasses}
            type="button">
            <Typography
              variant="primary"
              as="span"
              uppercase
              className={s.label}>
              {t("pages.announcements.panelLabel")}
            </Typography>
            <Typography
              as="span"
              className={s.expand_label}>
              {t("pages.announcements.expandButton")}
            </Typography>
            <DoubleArrow className={s.arrow} />
          </button>
        </div>
      }
      <Drawer
        ref={ref}
        direction="right"
        isVisible={isVisible}
        className={panelClasses}>
        <Typography
          variant="primary"
          as="h4"
          className={s.panel_title}>
          {t("pages.announcements.panelLabel")}
          <Button
            onClick={onClick}>
            <Close
              className={s.close_button} />
            <VisuallyHidden>{t("pages.announcements.ariaClosePanel")}</VisuallyHidden>
          </Button>
        </Typography>
        <div className={s.announcements_container}>
          <Conditional
            condition={announcements.length === 0}
            component={(
              <Alert type='warning'>{t("pages.announcements.nothingToShow")}</Alert>
            )}
          >
            {
              announcements.map((annoucement) => <Announcement key={annoucement.id} {...annoucement} />)
            }
          </Conditional>

        </div>
        <div className={s.links_container}>
          <Link
            filled
            activeClassName={s.view_all_active}
            variant="primary"
            onClick={close}
            className="px-2"
            href={ROUTE_ANNOUNCEMENTS}>
            {t("pages.announcements.viewAll")}
          </Link>
        </div>

      </Drawer>
    </>
  )
}

export default Announcements