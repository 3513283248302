import React from 'react'
import cn from 'classnames'

import { NavItemProps } from './types'
import s from './styles.module.css'

/**
 * Nav Item wrapper
 * @param props 
 * @returns 
 */
const NavItem = (props: NavItemProps) => {
  const { children, className } = props;

  return (
    <li className={cn(s.nav_item, className)}>{children}</li>
  )
}

export default NavItem