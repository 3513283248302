import { NextApiResponse } from 'next'
import { destroyCookie, parseCookies, setCookie } from 'nookies'
import { FlashMessage, FlashMessageType } from './types'

const FLASH_MESSAGE_ID = 'flash_message'

/**
 * Set flash message
 * @param res
 * @param message
 * @param type
 */
export const setFlashMessage = (
    message: string,
    type: FlashMessageType,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    res?: NextApiResponse<any>,
) => {
    const flashMessage = {
        message,
        type,
    } as FlashMessage

    setCookie(res ? { res } : undefined, FLASH_MESSAGE_ID, JSON.stringify(flashMessage), {
        maxAge: 60 * 10, // cookie does not need a long life, expires after 10 minutes
        secure: process.env.NODE_ENV === 'production',
        sameSite: 'strict',
        path: '/',
    })
}

/**
 * Get flash message
 * @returns
 */
export const getFlashMessage = () => {
    const cookies = parseCookies()

    const flashMessage = cookies[FLASH_MESSAGE_ID]

    if (!flashMessage) return undefined

    return JSON.parse(flashMessage) as FlashMessage
}

/**
 * Remove current flash message
 */
export const destroyFlashMessage = () => {
    destroyCookie(null, FLASH_MESSAGE_ID, {
        path: '/',
    })
}
