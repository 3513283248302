import React from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { HttpClientApiFieldError, HttpClientServerError } from '@/network/httpClients'
import { useConfirmRedirectIfDirty } from '@/hooks'

import { FormProps } from './types'

const FORM_DEFAULT_SUCCESS_MESSAGE = "Successfully saved"

function getError(error: HttpClientServerError | HttpClientApiFieldError) {
  if (error?.message) return error.message

  if (Array.isArray(error))
    return error[0].message
}

function Form(props: FormProps) {
  const { resetOnSuccess, resetOnFail, preventNavigationIfDirty = true, showToast = false, httpClient, initialData, renderForm, onSuccess, onFail, successMessage = FORM_DEFAULT_SUCCESS_MESSAGE } = props
  const { register, control, reset, watch, handleSubmit, setError, formState: { isSubmitting, isSubmitSuccessful, errors, isDirty } } = useForm({ defaultValues: initialData });

  useConfirmRedirectIfDirty(preventNavigationIfDirty ? false : isDirty)

  const onSubmit = async (payload: object) => {
    const { response, data, error } = await httpClient(payload)

    if (!response.ok) {
      if (resetOnFail)
        reset(resetOnFail)

      // Validation error, expect response to be a JSON response {"field": "error message for that field"}
      setError('root', { message: getError(error) })

      if (onFail)
        onFail({ response, error })


    } else if (response.ok) {
      if (onSuccess) onSuccess({ response, data })

      if (resetOnSuccess)
        reset(resetOnSuccess)
    }

    if (showToast) {
      if (response.ok)
        toast.success(successMessage)
    }

  }

  const hasErrors = errors && Object.keys(errors).length > 0

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {
        renderForm({
          register,
          watch,
          control,
          errors,
          hasErrors,
          isSubmitting,
          isSubmitted: isSubmitSuccessful,
          isDirty,
          onSubmit: () => handleSubmit(onSubmit)()
        })
      }
    </form>
  )
}

export default Form