import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const Wallet = (props: SVGIconProps) => {
  const { width = 32, height = 28, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} aria-label="Wallet Icon" {...svgProps}>
      <path d="M28.825 6H5C4.4475 6 4 5.5525 4 5C4 4.4475 4.4475 4 5 4H29C29.5525 4 30 3.5525 30 3C30 1.34312 28.6569 0 27 0H4C1.79063 0 0 1.79063 0 4V24C0 26.2094 1.79063 28 4 28H28.825C30.5763 28 32 26.6544 32 25V9C32 7.34562 30.5763 6 28.825 6ZM26 19C24.8956 19 24 18.1044 24 17C24 15.8956 24.8956 15 26 15C27.1044 15 28 15.8956 28 17C28 18.1044 27.1044 19 26 19Z" stroke="currentcolor" />
    </SVGIcon>
  )
}


export default Wallet