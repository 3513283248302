
import React, { useState, useRef, useEffect, useCallback } from 'react'
import { useInView } from 'react-intersection-observer'
import { motion } from 'framer-motion'
import cn from 'classnames'

import { formatFileStackImage } from 'helpers/filestack'
import Card, { CardBody } from '@/components/ui/Card'
import Image from '@/components/ui/Image'

import s from './styles.module.css'
import { NftProps } from './types'
import Typography from '../Typography'
import LazyLoad from '../LazyLoad'

const MotionImage = motion(Image)

/**
 * Nft component
 * @param props 
 * @returns 
 */
function Nft(props: NftProps) {
  const {
    href,
    size = "lg",
    imageUrl,
    videoUrl,
    name,
    rarity,
    imageClassName,
    showVideos,
    className
  } = props

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const { ref: inViewRef, inView } = useInView({
    rootMargin: '-10px'
  })
  const nextVideoStartTimeRef = useRef<number | null>(null);
  const [isHovering, setIsHovering] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isPlaying, setIsPlaying] = useState<boolean>(false)

  const nftImageSrc = imageUrl && imageUrl !== "" ? formatFileStackImage(imageUrl, { resize: { width: 650 } }) : '/_assets/placeholder.webp'
  const nftVideoSrc = videoUrl && videoUrl !== "" ? videoUrl : null
  const shouldShowVideo = showVideos && !!nftVideoSrc

  const playVideo = useCallback(() => {
    const videoElement = videoRef.current
    if (!videoElement) return

    videoElement.play().catch((error: DOMException) => {
      // Suppress logging for "AbortError" errors, which are thrown when the video is paused while it was trying to play.
      if (error.name === "AbortError") {
        // eslint-disable-next-line no-useless-return
        return
      }
    })
  }, []);

  const pauseVideo = useCallback(() => {
    const videoElement = videoRef.current
    if (!videoElement) return

    videoElement.pause()
  }, []);

  // Manage video when it's offscreen
  useEffect(() => {
    if (shouldShowVideo) {
      if (!inView) {
        setIsHovering(false)
        pauseVideo()
      }
      else {
        setIsHovering(true)
        playVideo()
      }
    }
    else {
      setIsPlaying(false)
    }
  }, [inView, pauseVideo, playVideo, shouldShowVideo])

  useEffect(() => {
    const videoElement = videoRef.current;
    if (shouldShowVideo || !videoElement) return

    if (isHovering && !isLoading && !isPlaying) {
      playVideo()
    }
  }, [isHovering, isLoading, isPlaying, playVideo, shouldShowVideo])

  useEffect(() => {
    const videoElement = videoRef.current
    if (shouldShowVideo || !videoElement) return

    if (!isHovering && (isPlaying || isLoading)) {
      pauseVideo()

      nextVideoStartTimeRef.current = videoElement.currentTime
    }
  }, [
    isHovering,
    isLoading,
    isPlaying,
    pauseVideo,
    shouldShowVideo
  ]);

  const showOverlay = !isHovering || isHovering && !isPlaying

  // eslint-disable-next-line react/destructuring-assignment
  const variants = {
    initial: { opacity: 0, transition: { duration: 0.25 } },
    animate: { opacity: 1, transition: { duration: 0.25 } },
  }

  // Set multiple refs to an element
  const setRefs = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (node: any) => {
      // Set our element ref
      videoRef.current = node
      // Pass our video ref into the inView ref
      inViewRef(node)
    },
    [inViewRef]
  )

  const handleHoverOn = () => {
    if (shouldShowVideo) setIsHovering(true)
  }

  const handleHoverOff = () => {
    if (shouldShowVideo && !inView) setIsHovering(false)
  }

  const containerClasses = cn({
    [s.container]: true,
    [s.lg]: size === "lg",
    [s.sm]: size === "sm",
    [`${className}`]: !!className
  })

  return (
    <LazyLoad
      className={containerClasses}
      rootMargin="1000px 0px 1000px 0px"
      triggerOnce
    >
      <Card
        onMouseEnter={handleHoverOn}
        onMouseLeave={handleHoverOff}
        onFocus={handleHoverOn}
        onBlur={handleHoverOff}
        tabIndex={0}
        className={s.nft_card}
        href={href}>

        <div className={cn(s.image_wrapper, imageClassName)}>
          <MotionImage
            variants={variants}
            initial="initial"
            animate={showOverlay ? "animate" : "initial"}
            rounded={false}
            draggable={false}
            alt={name}
            src={nftImageSrc}
            width={400}
            height={250}
            className={s.overlay}
          />

          {
            nftVideoSrc && shouldShowVideo &&
            <LazyLoad
              debounce
              initialInView
              delay={100}
              rootMargin="1000px 0px 1000px 0px">
              <video
                loop
                muted
                autoPlay
                src={nftVideoSrc}
                ref={setRefs}
                className={s.video}
                disableRemotePlayback
                disablePictureInPicture
                onPlaying={() => setIsPlaying(true)}
                onPause={() => setIsPlaying(false)}
                onEnded={() => setIsPlaying(false)}
                onError={() => setIsPlaying(false)}
                onLoadStart={() => setIsLoading(true)}
                onLoadedData={() => setIsLoading((videoRef.current?.readyState || 0) < HTMLMediaElement.HAVE_ENOUGH_DATA)}
                onAbort={() => setIsLoading(false)}
                controls={false}>
                <source src={nftVideoSrc} />
                <track kind="captions" />
                Your browser does not support the video tag.
              </video>
            </LazyLoad>
          }
        </div>

        <CardBody
          titleClassName={s.nft_title}
          className={s.nft_content}
          title={name}>
          <Typography
            as="span"
            className={s.nft_rarity}
            variant={rarity}
            uppercase>
            {rarity}
          </Typography>
        </CardBody>
      </Card>
    </LazyLoad>
  )
}

export default Nft