import { useRef, useCallback } from 'react'
import { Synaps } from '@synaps-io/verify-sdk'

const useSynapsKyc = () => {
    const initialized = useRef(false)

    const init = useCallback(async (sessionId: string, onFinish?: () => void) => {
        if (!initialized.current) {
            Synaps.init({
                sessionId,
                onFinish,
                mode: 'modal',
            })
            initialized.current = true
        }
    }, [])

    const handleOpen = () => {
        Synaps.show()
    }

    return { init, handleOpen }
}

export default useSynapsKyc
