import { isMainnet } from 'helpers/cardanoNetworkTypes'
import extractWordFromString from 'helpers/extractWordFromString'
import { UseFiltersFilterConfiguration, formatFilterValue } from 'hooks/useFilters'
import { ArrayParam, withDefault } from 'use-query-params'
import { UserOwnedAsset } from 'types'

export const MAINNET_POLICY_ID_LIST = [
    // Bubblejet Sprinter 2022
    'e282271ec9251ba23fb123b0f53618b35cf5a6cde4170c003a0ebf13',
    // Bubblejett Sprinter OG Custom 2022
    'a4badea66770768b02ff7b9674d445f13016ce901804f45844e00ebd',
    // GTi Javelin
    '086849cd9f672e731e0d9590a2d28a6a690ffa2f73bae0e1970f0491',
    // NFT2Tree 2022
    '754b103922978d3e4969d36420239f0cfd8604ebbf094996e4c20c13',
    // NFT2Tree Series Two 2022
    '916a6fdd92c86dee9be3e68f93279c914566bb1278de80567768268f',
    // NFT2Tree Douglas Fir 2022
    '4fc6c6639b26992d9b9d887ef1cb959fe8b33bc2bbde4b5afbeda28a',
    // Land  Plots (Small - Copias  - Zones 1-3)
    '07b39a8ead0ef1e3054e816a3b6910060beaf2210fded63fb90ce137',
    // Custom Domes
    '9cce4bbd9f6e06ef9d67b95ad8511532b4569d290d1575fcbac48732',
    // Farmer Joe Bobblehead Holiday 2022
    'b024c9cbca03eb9ab49330b69ecd18a7619fcf39f3c34bd783ea360f',
    // Cheeky Racer
    '742b004c42b6dd7f7935c85f5d3884037778c8d80a0f9724d3b57049',
    // Bubblejett Bonanza OG Custom 2023
    'a34d2c4d1e27334ff6e580904bf4b69cc34640e9f9a495b704d4f0ef',
    // Raceworx Valley T3
    'ba6015d1f4ba32815ce34831e9b033b1918a849931ae8b67cdeef74d',
]

export const COPI_TOKEN_CARDANO_POLICY_ID =
    'b6a7467ea1deb012808ef4e87b5ff371e85f7142d7b356a40d9b42a0'

export const PREPROD_POLICY_ID_LIST = [
    '2cd7321cac4c449b930e45856a21abd38be93e488377a85fb3baa254',
    '530bc89cfe779cc3ac993444af331a5f4ff3a2d383485d84f4cd10c4',
    '54ff358d2341beaaa9f4813049b3892589d2850e28caddba5b1e9f42',
    'cc6a927336aaa64350529ca214c9c5f0c7d7953e76bdd37e757d5995',
    'f876d1992766d0c23b76c1b6c2f1998fc52ac9f03369f895dd3a05a4',
]

export const getAllPolicyIdsRequiredToAddWallet = (): string[] =>
    isMainnet() ? [...MAINNET_POLICY_ID_LIST, COPI_TOKEN_CARDANO_POLICY_ID] : PREPROD_POLICY_ID_LIST
export const getGameAssetsPolicyIds = (): string[] =>
    isMainnet() ? MAINNET_POLICY_ID_LIST : PREPROD_POLICY_ID_LIST

export const MY_ASSETS_FILTERS = [
    {
        id: 'nftCollectionName',
        alias: 'collection',
        displayName: 'Collection',
        type: withDefault(ArrayParam, []),
        caseSensitive: false,
        multiple: true,
    },
    {
        id: 'rarity',
        alias: 'rarity',
        displayName: 'Rarity',
        type: withDefault(ArrayParam, []),
        caseSensitive: false,
        multiple: true,
    },
] as UseFiltersFilterConfiguration

// Full list of filters and values
export const MY_ASSETS_FILTER_VALUES = {
    rarity: ['None', 'Common', 'Uncommon', 'Rare', 'Legendary', 'Mythic'],
}

export function hexToString(string: string): string {
    return Buffer.from(string, 'hex').toString()
}

// Define the rarity order
const rarityOrder = {
    common: 1,
    uncommon: 2,
    rare: 3,
    legendary: 4,
    mythic: 5,
} as Record<string, number>

const sizeOrder = {
    small: 1,
    medium: 2,
    large: 3,
    epic: 4,
    copias: 5,
} as Record<string, number>

export function sortAssets(a: UserOwnedAsset, b: UserOwnedAsset) {
    // Push broken assets to the bottom
    if (!a.nftCollectionName) return 1

    if (a.nftCollectionName < b.nftCollectionName) {
        return -1
    }
    if (a.nftCollectionName > b.nftCollectionName) {
        return 1
    }

    // If the nftCollectionId is the same, compare by rarity order
    const nftVariantRarityA = (a.rarity || 'common').toLowerCase()
    const nftVariantRarityB = (b.rarity || 'common').toLowerCase()

    const rarityA = rarityOrder[nftVariantRarityA]
    const rarityB = rarityOrder[nftVariantRarityB]

    if (rarityA < rarityB) {
        return -1
    }
    if (rarityA > rarityB) {
        return 1
    }

    // Extract size from name if it exists
    const sizeA = extractWordFromString(a.name, Object.keys(sizeOrder))
    const sizeB = extractWordFromString(b.name, Object.keys(sizeOrder))

    // If the rarity is the same, compare by name order
    if (sizeA && sizeB) {
        const nameA = sizeOrder[sizeA.toLowerCase()]
        const nameB = sizeOrder[sizeB.toLowerCase()]

        if (nameA < nameB) {
            return -1
        }
        if (nameA > nameB) {
            return 1
        }
    }

    return 0
}

export function filterAssetsByKeys(
    assets: UserOwnedAsset[],
    filters: Record<keyof UserOwnedAsset, string[]>,
) {
    return assets.filter((item) => {
        let isValid = true
        Object.keys(filters).forEach((key) => {
            const value = formatFilterValue(item[key as keyof UserOwnedAsset])
            const values = filters[key as keyof UserOwnedAsset]

            if (values.length > 0 && !values.includes(value)) {
                isValid = false
            }
        })

        return isValid
    })
}
