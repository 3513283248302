import { UserOwnedAsset } from 'types'

export type WalletChain = 'Cardano' | 'Evm'

export type SupportedWalletId =
    | 'eternl'
    | 'gerowallet'
    | 'flint'
    | 'lace'
    | 'nufi'
    | 'typhon'
    | 'nami'
    | 'yoroi'
    | 'vespr'
    | 'io.metamask'
    | 'coinbaseWalletSDK'
    | 'walletConnect'

export type WalletAddressDetailsList = Record<string, WalletAddressDetails>

export type WalletAddressDetails = {
    assets: UserOwnedAsset[]
    isSaved?: boolean
    chain: WalletChain
    chainId?: string
}

export enum UnavailableWalletVisibility {
    SHOW_UNAVAILABLE,
    HIDE_UNAVAILABLE,
    SHOW_UNAVAILABLE_ON_MOBILE,
}

export type SupportedWalletType = {
    icon: string
    name: string
    connector?: string
    displayName: string
    enabled: boolean
    chain: WalletChain
    installUrl: string
    desktopEnabled?: boolean
    mobileEnabled: boolean
}

export interface IWalletConnect {
    (
        wallet: SupportedWalletType,
        onConnectSuccess?: (wallet: SupportedWalletType) => void,
        onConnectFailed?: (error: Error) => void,
    ): void
}

export interface ISignMessageFunc {
    (
        chain: string,
        message: string,
        hexAddress?: string,
        onSignMessage?: (signature: string, key: string | undefined) => void,
        onSignError?: (error: Error) => void,
    ): void
}

export type ISignMessageOnSignMessageFn = (signature: string, key: string | undefined) => void
export type ISignMessageOnSignTxFn = (signature: string) => void
export type ISignMessageOnSignErrorFn = (error: Error) => void
