import isProduction from 'helpers/isProduction'
import { t } from 'helpers/translation'
import { http, createConfig, createStorage, cookieStorage } from 'wagmi'
import { base, baseSepolia, mainnet, sepolia } from 'wagmi/chains'
import { coinbaseWallet, injected, metaMask, walletConnect } from 'wagmi/connectors'

const baseUrl = process.env.NEXT_PUBLIC_SITE_URL

// NOTE:: Do NOTE include this in the index file of this folder because it will break the build
// eslint-disable-next-line import/prefer-default-export
export const wagmiConfig = createConfig({
    chains: isProduction() ? [mainnet, base] : [mainnet, base, sepolia, baseSepolia],
    ssr: true,
    connectors: [
        injected(),
        // TODO:: This throws an error when metamask and coinbase are both enabled
        // Maybe coinbase wallet 4 will fix this
        metaMask({
            dappMetadata: {
                name: t('sitename'),
                iconUrl: `${baseUrl}/_assets/cornucopias-c-circle.png`,
                url: baseUrl,
            },
        }),
        coinbaseWallet({
            appName: t('sitename'),
            appLogoUrl: `${baseUrl}/_assets/cornucopias-c-circle.png`,
            headlessMode: true,
            // version: '4',
            // preference: 'smartWalletOnly',
        }),
        walletConnect({
            projectId: process.env.NEXT_PUBLIC_WALLET_CONNECT_KEY as string,
            metadata: {
                name: t('sitename'),
                description: t('siteDescription'),
                icons: [`${baseUrl}/_assets/cornucopias-c-circle.png`],
                url: baseUrl as string,
            },
        }),
    ],
    storage: createStorage({
        storage: cookieStorage,
    }),
    transports: {
        [mainnet.id]: http(),
        [base.id]: http(),
        [sepolia.id]: http(),
        [baseSepolia.id]: http(),
    },
})
