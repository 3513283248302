import React, { useState, useCallback, useEffect, useRef } from 'react'
import { PanInfo, useMotionValue } from 'framer-motion'
import cn from 'classnames'

import useEventListener from 'hooks/useEventListener'

import SlideTrack from './SlideTrack'
import SlideNavigation from './SlideNavigation'
import Slide from './Slide/Slide'
import { getRandomInt } from '../../../helpers'
import { SlideshowProps } from './types'

import s from './styles.module.css'

const Slideshow = (props: SlideshowProps) => {
  const {
    sliderClassName,
    slides,
    delaySecs = getRandomInt(20, 30),
    autoplay = true,
    showNavigation = true,
    thumbnails,
    thumbnailsClassName,
    autoHeight = true
  } = props
  const [currentIndex, setCurrentIndex] = useState<number>(1)
  const [height, setHeight] = useState<number | string>('auto')
  const dragX = useMotionValue(0)
  // This is used to store the most up to date next function in non state
  const autoPlayRef = useRef<() => void>()
  // Used to store the interval to allow start and stop
  const intervalRef = useRef<NodeJS.Timeout | null>(null)
  // Track slider container
  const sliderContainerRef = useRef<HTMLDivElement | null>(null)
  // Used to get the height of the active slide
  const slidesRef = useRef<HTMLDivElement[]>([])
  // This is used to store the most up to date next function in non state
  const handleResize = useRef<() => void>()
  const sliderClasses = cn(s.slider, sliderClassName)

  // Duplicate first and last slides
  const totalSlides = slides.length + 2 // Include the duplicated slides

  const getWrappedSlides = () => {
    return [
      slides[slides.length - 1], // Last slide duplicated at the start
      ...slides,
      slides[0], // First slide duplicated at the end
    ]
  }

  const wrappedSlides = getWrappedSlides()

  // Update slider height based on active slide
  const updateSliderHeight = useCallback(() => {
    // For some reason, potentially loading related or framer motion related, the size is not correct without the delay
    // It looks like the padding is missing initially, the timeout seems to correct the issue
    setTimeout(() => {
      if (slidesRef.current) {
        const slideHeight = slidesRef.current[currentIndex].getBoundingClientRect().height
        setHeight(slideHeight)
      }
    }, 500)
  }, [slidesRef, setHeight, currentIndex])

  const goToImage = useCallback((index: number) => {
    if (index === currentIndex) return

    if (index >= totalSlides - 1) {
      // Jump back to the first real slide
      setCurrentIndex(1)
    } else if (index <= 0) {
      // Jump to the last real slide
      setCurrentIndex(slides.length)
    } else {
      setCurrentIndex(index)
    }
  },
    [setCurrentIndex, currentIndex],
  )

  const startAutoplay = useCallback(() => {
    if (autoplay && !intervalRef.current) {
      intervalRef.current = setInterval(() => {
        if (autoPlayRef.current) autoPlayRef.current()
      }, delaySecs * 1000)
    }
  }, [autoplay, delaySecs])

  const stopAutoplay = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
      intervalRef.current = null
    }
  }, [])

  const onNavigationClick = useCallback((index: number) => {
    stopAutoplay()
    startAutoplay()
    goToImage(index)
  }, [stopAutoplay, startAutoplay, goToImage])

  const onHoverStart = useCallback(() => {
    stopAutoplay()
  }, [stopAutoplay])

  const onHoverEnd = useCallback(() => {
    startAutoplay()
  }, [startAutoplay])

  const onDragStart = useCallback((evt: Event) => {
    // Stop interval to prevent weird animations
    stopAutoplay()
  }, [stopAutoplay])

  const onDragEnd = useCallback((evt: Event, dragProps: PanInfo) => {
    // Restart interval
    startAutoplay()

    const clientWidth = sliderContainerRef.current?.clientWidth || 0

    const { offset } = dragProps

    if (offset.x > clientWidth / 4) {
      goToImage(currentIndex - 1)
    } else if (offset.x < -clientWidth / 4) {
      goToImage(currentIndex + 1)
    }
  }, [startAutoplay, goToImage, currentIndex, dragX])

  const next = useCallback(() => {
    goToImage(currentIndex + 1)
  }, [currentIndex, goToImage])

  useEffect(() => {
    autoPlayRef.current = next
    handleResize.current = updateSliderHeight
  })

  useEffect(() => {
    if (autoplay) {
      startAutoplay()
    }

    return () => {
      stopAutoplay()
    }
  }, [autoplay, startAutoplay, stopAutoplay])

  useEffect(() => {
    updateSliderHeight()

    if (handleResize.current)
      window.addEventListener('resize', handleResize.current)

    return () => {
      if (handleResize.current)
        window.removeEventListener('resize', handleResize.current)
    }
  }, [currentIndex, updateSliderHeight, handleResize])

  useEventListener('keydown', (event: Event) => {
    const keyboardEvent = event as KeyboardEvent

    if (keyboardEvent.key === 'ArrowRight') {
      goToImage(currentIndex + 1)
    } else if (keyboardEvent.key === 'ArrowLeft') {
      goToImage(currentIndex - 1)
    }
  })

  return (
    <div
      ref={sliderContainerRef}
      className={s.slider_container}>
      <div
        className={sliderClasses}
        style={autoHeight ? { height: `${height}px` } : undefined}>
        <div>
          <SlideTrack
            dragX={dragX}
            currentIndex={currentIndex}
            onHoverStart={onHoverStart}
            onHoverEnd={onHoverEnd}
            onDragEnd={onDragEnd}
            onDragStart={onDragStart}>
            {wrappedSlides.map((slide, index) => (
              <Slide
                key={index}
                active={currentIndex === index}>
                {
                  // We want the size of the actual slide so use this div
                }
                <div ref={(node: any) => slidesRef.current.push(node)}>
                  {slide}
                </div>
              </Slide>
            ))
            }
          </SlideTrack>
        </div>
      </div>

      {
        showNavigation &&
        <SlideNavigation
          thumbnails={thumbnails}
          // Account for offset because of duplicated slides at the start and end.
          startIndex={1}
          className={thumbnailsClassName}
          onClick={onNavigationClick}
          currentIndex={currentIndex}
          slideCount={slides.length} />
      }
    </div>
  )
}

export default Slideshow
