import React, { forwardRef } from 'react'
import cx from 'classnames'

import s from './styles.module.css'
import { TextAreaProps } from './types'

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>((props, ref) => {
  const {
    className,
    value,
    hasError,
    ...inputProps
  } = props

  const textareaClasses = cx(
    s.textarea,
    hasError && s.error,
    className
  )

  return (
    <textarea
      ref={ref}
      className={textareaClasses}
      {...inputProps}>
      {value}
    </textarea>
  )
})

export default TextArea