import React from 'react'

import Button from '@/components/ui/Button'
import Conditional from '@/components/ui/Conditional'
import Typography from '@/components/ui/Typography'
import { Checkbox, Label, } from '@/components/ui/Form'
import useFilters from 'hooks/useFilters';

import Accordion, { AccordionHeader, AccordionItem, AccordionPanel } from '../Accordion';
import { FiltersProps } from './types';
import s from './styles.module.css'

const Filters = (props: FiltersProps) => {
  const { data, config, values, hideEmptyFilters = false, showFilterCounts = true, filterItem, resetFilterOnChange } = props
  const { onFilterChange, getExistingFilterValues, isFilterActive, getFilterConfig, clearFilters } = useFilters(config)

  // Filter out values that do not exist in assets
  const filteredValues = getExistingFilterValues(data, values, hideEmptyFilters)

  return Object.keys(filteredValues).map(filter => {
    const filterType = getFilterConfig(filter)

    return (
      <div key={filterType?.id}>
        <Accordion>
          <AccordionItem>
            <AccordionHeader
              label={(
                <Typography as="h4" variant="primary" className={s.filter_title}>
                  {filterType?.displayName}
                </Typography>
              )}>
              <Button
                onClick={() => clearFilters(filter)}
                title="Reset filters"
                className={s.filter_clear}>
                Reset
              </Button>
            </AccordionHeader>
            <AccordionPanel>
              <ul className={s.filter_list}>
                <Conditional
                  condition={filteredValues[filter].length === 0}
                  component={(<p>No filters to display</p>)}>
                  {
                    filteredValues[filter].map(({ displayName, value, count }) => {
                      const id = `${filter}_${value}`

                      return (
                        <li key={value} className={s.filter_list_item}>
                          <Label
                            htmlFor={id}>
                            <Checkbox
                              id={id}
                              checked={isFilterActive(filter, value)}
                              onChange={() => {
                                onFilterChange(filter, value, resetFilterOnChange)
                              }} />
                            <Typography>
                              {filterItem ? filterItem(filter, displayName, value) : displayName}
                              {
                                showFilterCounts && count !== undefined &&
                                <Typography className={s.filter_list_item_count}>({count})</Typography>
                              }
                            </Typography>
                          </Label>
                        </li>
                      )
                    })
                  }
                </Conditional>
              </ul>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
    )
  })

}

export default Filters