import queryString from 'query-string'

import { API_GET_SITE_POSTS, API_GET_SITE_POST, API_GET_SITE_CATEGORIES } from 'consts/apiRoutes'
import {
    SiteCategoriesFetchDataQueryParams,
    SiteCategoriesResponse,
    SitePostFetchDataQueryParams,
    SitePostResponse,
    SitePostsResponse,
} from 'types'
import HttpClientBase from './HttpClientBase'

class BlogHttpClient extends HttpClientBase {
    static setAccessToken(accessToken: string) {
        super.setAccessToken(accessToken)

        return this
    }

    static async GetSiteCategories(params: SiteCategoriesFetchDataQueryParams) {
        const query = queryString.stringify(params)

        return this.request2<SiteCategoriesResponse>({
            url: `${API_GET_SITE_CATEGORIES}?${query}`,
            method: 'GET',
        })
    }

    static async GetSitePosts(params: SitePostFetchDataQueryParams) {
        const query = queryString.stringify(params)

        return this.request2<SitePostsResponse>({
            url: `${API_GET_SITE_POSTS}?${query}`,
            method: 'GET',
        })
    }

    static async GetSitePost(slug: string) {
        return this.request2<SitePostResponse>({
            url: API_GET_SITE_POST(slug),
            method: 'GET',
        })
    }
}

export default BlogHttpClient
