/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useMemo } from 'react'
import { NextRouter, useRouter } from 'next/router'

import s from './styles.module.css'
import { BreadcrumbsProps, CrumbProps, GetDefaultTextGeneratorType, GetTextGeneratorType } from './type';
import Crumb from './Crumb';

const _defaultGetDefaultTextGenerator = ((path) => path) as GetDefaultTextGeneratorType

// Pulled out the path part breakdown because its
// going to be used by both `asPath` and `pathname`
const generatePathParts = (pathStr: string) => {
  const pathWithoutQuery = pathStr.split("?")[0];
  const pathWithoutHash = pathWithoutQuery.split("#")[0];

  return pathWithoutHash.split("/")
    .filter(v => v.length > 0);
}

function generateBreadcrumbs(
  router: NextRouter,
  getDefaultTextGenerator: GetDefaultTextGeneratorType,
  getTextGenerator?: GetTextGeneratorType | null
): Array<CrumbProps> {
  const asPathNestedRoutes = generatePathParts(router.asPath);
  const pathnameNestedRoutes = generatePathParts(router.pathname);

  const crumblist = asPathNestedRoutes.map((subpath, idx) => {
    // Pull out and convert "[post_id]" into "post_id"
    const param = pathnameNestedRoutes[idx]?.replace("[", "").replace("]", "");

    const href = `/${asPathNestedRoutes.slice(0, idx + 1).join("/")}`;

    const crumb = {
      text: getDefaultTextGenerator(subpath, href),
      href
    } as {
      text: string
      href: string
      textGenerator?: GetTextGeneratorType
    };

    if (getTextGenerator)
      crumb.textGenerator = () => getTextGenerator(param, router.query)

    return crumb
  })

  return [{ href: "/", text: "Home" }, ...crumblist];
}

/**
 * Create breadcrumbs based on nextjs routing
 * @param props 
 * @returns 
 */
const Breadcrumbs = (props: BreadcrumbsProps) => {
  const {
    getTextGenerator = null,
    getDefaultTextGenerator = _defaultGetDefaultTextGenerator
  } = props

  const router = useRouter()

  const breadcrumbs = useMemo(() =>
    generateBreadcrumbs(
      router,
      getDefaultTextGenerator,
      getTextGenerator
    ),
    [router, getDefaultTextGenerator, getTextGenerator])

  return (
    <div className={s.breadcrumbs}>
      {
        breadcrumbs.map((crumb, index) => (
          <Crumb
            {...crumb}
            // eslint-disable-next-line react/no-array-index-key
            key={crumb.href}
            last={index === breadcrumbs.length - 1} />
        ))
      }
    </div>
  )
}

export default Breadcrumbs
