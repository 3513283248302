export enum FlashMessageType {
    SUCCESS = 'success',
    ERROR = 'error',
    WARNING = 'warning',
}

export type FlashMessage = {
    message: string
    type: FlashMessageType
}
