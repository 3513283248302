import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const UpArrow = (props: SVGIconProps) => {
  const { width = 32, height = 36, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} {...svgProps}>
      <path d="M24.1361 35.4989H8.36007C7.33146 35.4989 6.4963 34.6637 6.4963 33.6351V18.5422C6.4963 18.0125 6.06538 17.5816 5.53573 17.5816H2.49794C1.88024 17.5816 1.34793 17.2267 1.11179 16.6557C0.874312 16.0847 0.998385 15.4577 1.43598 15.0201L14.9293 1.52679C15.6324 0.82237 16.8598 0.82237 17.5642 1.52679L31.0575 15.0201C31.4951 15.4577 31.6205 16.0834 31.383 16.6557C31.1469 17.2281 30.6145 17.5829 29.9968 17.5829H26.9591C26.4294 17.5829 25.9985 18.0139 25.9985 18.5435V33.6364C25.9985 34.6637 25.1633 35.5002 24.1361 35.5002V35.4989ZM16.2474 1.88433C15.9912 1.88433 15.7498 1.98439 15.5683 2.16583L2.07502 15.6591C1.81353 15.9206 1.90959 16.2235 1.94695 16.3102C1.98163 16.3969 2.12839 16.6797 2.49927 16.6797H5.53707C6.56434 16.6797 7.3995 17.5149 7.3995 18.5435V33.6364C7.3995 34.1661 7.83042 34.597 8.36007 34.597H24.1361C24.6657 34.597 25.0966 34.1661 25.0966 33.6364V18.5435C25.0966 17.5162 25.9318 16.6797 26.9604 16.6797H29.9982C30.3691 16.6797 30.5145 16.3969 30.5505 16.3102C30.5865 16.2235 30.6826 15.9206 30.4211 15.6591L16.9278 2.16583C16.7464 1.98439 16.5049 1.88433 16.2474 1.88433Z" fill="black" stroke="black" strokeWidth="0.266824" strokeMiterlimit="10" />
    </SVGIcon>
  )
}

export default UpArrow