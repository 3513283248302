import React from 'react'
import cn from 'classnames'

import { SlotHeadIcon, SlotPackIcon, SlotShipIcon } from '../Icon'
import { SlotIconProps } from './types'

import s from './styles.module.css'
import Tooltip from '../Tooltip'

const SlotIcon = (props: SlotIconProps) => {
  const { slot, className, ...iconProps } = props
  let icon
  let text

  switch (slot.toLowerCase()) {
    case "head":
      icon = <SlotHeadIcon {...iconProps} />
      text = "Head"
      break
    case "ship":
      icon = <SlotShipIcon {...iconProps} />
      text = "Ship"
      break
    case "pack":
      icon = <SlotPackIcon {...iconProps} />
      text = "Pack"
      break
    default:
      text = "Not defined"
      break
  }

  return (
    <Tooltip
      className={s.icon_tooltip}
      offsetY={10}
      button={(
        <span
          className={cn(s.icon, className)}>
          {icon}
        </span>
      )}>
      {text}
    </Tooltip>
  )
}

export default SlotIcon