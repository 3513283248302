import React from 'react'
import cn from 'classnames'

import s from './styles.module.css'
import { MaskProps } from './types'

/**
 * A component to manage all the different masks used i.e. notched elements
 * If using an outline, the pseudo before will be used because of how the clipping works
 * @param props 
 * @returns 
 */
const Mask = (props: MaskProps) => {
  const { children, type = 'notched', notchSize = "md", className } = props

  return (
    <div className={cn(s.wrapper, s[type], s[`notch_${notchSize}`], className)}>
      {children}
    </div>
  )
}

export default Mask
