import React from 'react'
import cx from 'classnames'

import s from './styles.module.css'
import { ValidationSummaryProps } from './types'

const ValidationSummary = (props: ValidationSummaryProps) => {
  const {
    className,
    compact,
    makeInvisible = false,
    errors = {},
    success
  } = props

  const hasErrors = Object.keys(errors).length > 0;

  // Get first error message in form
  const formError = hasErrors ?
    errors[(Object.keys(errors))[0]]?.message?.toString() : null

  const shouldHide = (!hasErrors || formError === '') && !success

  if (!makeInvisible && shouldHide) return <></>

  const classes = cx(
    s.validation,
    hasErrors && s.error,
    !hasErrors && success && s.success,
    compact && s.compact,
    shouldHide && makeInvisible && s.validation_invisible,
    className
  )

  return (
    <div className={classes}>
      {hasErrors ? formError : success}
    </div>
  )
}

export default ValidationSummary