import React from 'react'
import cx from 'classnames'

import Typography from '@/components/ui/Typography'
// TODO:: JSON needs to come from somewhere else
import sitemap from '@/network/data/footer_sitemap.json'
import { getFooterSocials } from '@/utils/Social'
import { isProduction, t } from '@/helpers'

import Sitemap from '../Sitemap'
import SocialBar from '../SocialBar'
import Newsletter from '../Newsletter'
import Copyright from '../Copyright'

import { FooterProps } from './types'
import s from './styles.module.css'
import { TransakPay } from '@/components/ui'


/**
 * Site footer
 * @returns 
 */
const Footer = (props: FooterProps) => {
  const { className = '' } = props
  const classes = cx(s.footer, className)

  const links = getFooterSocials()

  return (
    <footer className={classes}>
      <div className={s.sitemap_container}>
        <Typography
          variant="default"
          uppercase
          className={s.title}>
          {t("footer.title")}
        </Typography>
        <Sitemap links={sitemap} columnSize={6} />
      </div>

      <div className={s.right_column}>
        <Typography
          variant="default"
          uppercase
          className={s.newsletter_title}>
          {t("footer.newsletterTitle")}
        </Typography>
        <div className={s.newsletter}>
          <Newsletter />
        </div>
        <div>
          <SocialBar className={s.social} iconClassName={s.social_icon} links={links} />
          <div className={s.copyright_container}>
            <TransakPay
              apiKey={String(process.env.NEXT_PUBLIC_TRANSAK_PAY_API_KEY)}
              environment={isProduction() ? "PRODUCTION" : "STAGING"}
            />
            <Copyright />
          </div>
        </div>
      </div>

    </footer>
  )
}

export default Footer