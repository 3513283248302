import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const CalidoValleyYellowLogo = (props: SVGIconProps) => {
  const { width = 455, height = 58, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} {...svgProps}>
      <g clipPath="url(#clip0_6286_5375)">
        <path d="M43.8485 46.0746H32.3321L29.8523 53.1181L32.7587 57.5897H39.7928L43.8485 46.0746Z" fill="#ED355E" />
        <path d="M24.87 34.5582L28.9257 23.0418H17.4093L14.9135 30.1319L17.7892 34.5582H24.87Z" fill="#FF8C10" />
        <path d="M9.94855 11.5267L14.0029 0.0102844H2.48647L0 7.18572L2.78111 11.4654L9.94855 11.5267Z" fill="#FF8C10" />
        <path d="M66.88 46.0746H55.3636L52.9198 53.0154L55.8942 57.5897H62.8257L66.88 46.0746Z" fill="#407AB8" />
        <path d="M48.4095 46.0746H55.3636L59.4193 34.5582H47.9029L45.451 41.5217L48.4095 46.0746Z" fill="#407AB8" />
        <path d="M40.9247 34.5582H47.9028L51.9572 23.0418H40.4408L37.981 30.0292L40.9247 34.5582Z" fill="#ED355E" />
        <path d="M33.44 23.0418H40.4408L44.4964 11.5267H32.98L30.5122 18.5355L33.44 23.0418Z" fill="#ED355E" />
        <path d="M32.9801 11.5267L37.0345 0.0102844H25.5194L23.0422 7.04306L25.9567 11.5267H32.9801Z" fill="#FF8C10" />
        <path d="M89.9116 46.0746H78.3965L75.946 53.0341L78.9071 57.5897H85.8573L89.9116 46.0746Z" fill="#904594" />
        <path d="M71.4224 46.0746H78.3965L82.4508 34.5582H70.9344L68.476 41.5417L71.4224 46.0746Z" fill="#904594" />
        <path d="M63.9376 34.5582H70.9344L74.9901 23.0418H63.4737L61.0072 30.0479L63.9376 34.5582Z" fill="#407AB8" />
        <path d="M56.4542 23.0418H63.4737L67.528 11.5267H56.0129L53.5371 18.5555L56.4542 23.0418Z" fill="#407AB8" />
        <path d="M56.0129 11.5267L60.0673 0.0102844H48.5508L46.0684 7.06173L48.9695 11.5267H56.0129Z" fill="#ED355E" />
        <path d="M87.0278 34.5582H93.9673L98.0216 23.0418H86.5052L84.0587 29.9892L87.0278 34.5582Z" fill="#904594" />
        <path d="M79.5444 23.0418H86.5052L90.5609 11.5267H79.0444L76.59 18.4968L79.5444 23.0418Z" fill="#904594" />
        <path d="M79.0444 11.5267L83.0988 0.0102844H71.5837L69.1212 7.0044L72.0596 11.5267H79.0444Z" fill="#407AB8" />
        <path d="M102.076 11.5267L106.132 0.0102844H94.6151L92.146 7.02173L95.0738 11.5267H102.076Z" fill="#904594" />
        <path d="M218.649 7.88033L217.964 6.82574L219.983 1.06487L221.407 0.0102844H234.623L235.308 1.06487L233.29 6.82574L231.866 7.88033H218.649ZM219.832 6.22313H231.844L233.44 1.66749H221.428L219.832 6.22313Z" fill="white" />
        <path d="M153.376 7.88033L154.174 5.60184H142.162L141.363 7.88033H139.706L142.094 1.06487L143.518 0.0102844H156.734L157.42 1.06487L155.032 7.88033H153.376ZM142.742 3.94597H154.754L155.553 1.66749H143.541L142.742 3.94597Z" fill="white" />
        <path d="M117.957 7.88033L117.272 6.82574L119.291 1.06487L120.714 0.0102844H134.779L134.197 1.66749H120.736L119.14 6.22313H132.602L132.022 7.88033H117.957Z" fill="white" />
        <path d="M163.707 7.88033L163.022 6.82574L165.41 0.0102844H167.067L164.89 6.22313H177.317L176.735 7.88033H163.707Z" fill="white" />
        <path d="M194.579 7.88033L197.336 0.0102844H211.607L212.292 1.06487L210.274 6.82441L208.849 7.87899H194.578L194.579 7.88033ZM196.816 6.22313H208.829L210.425 1.66749H198.412L196.816 6.22313Z" fill="white" />
        <path d="M185.22 7.88033L187.978 0.0102844H189.634L186.877 7.88033H185.22Z" fill="white" />
        <path d="M216.484 46.7026L212.631 57.5897H258.03L261.885 46.7026H228.082H216.484Z" fill="#FF8C10" />
        <path d="M238.364 17.6689H226.762L217.769 43.0735H229.367L238.364 17.6689Z" fill="#FF8C10" />
        <path d="M289.86 46.7026H285.143H273.544L269.691 57.5897H315.09L318.944 46.7026H292.069H289.86Z" fill="#FF8C10" />
        <path d="M295.423 17.6689H283.821L274.829 43.0735H286.428L295.423 17.6689Z" fill="#FF8C10" />
        <path d="M106.682 46.7026H102.641L98.7869 57.5897H144.186L148.04 46.7026H136.532H106.682Z" fill="#FF8C10" />
        <path d="M149.324 43.0735L158.317 17.6689H146.814L141.673 32.1864H119.3L124.439 17.6689H112.918L103.952 43.0735H106.682H137.817H149.324Z" fill="#FF8C10" />
        <path d="M155.798 57.5897H201.199L205.052 46.7026H159.653L155.798 57.5897Z" fill="#FF8C10" />
        <path d="M215.33 17.6689H169.931L166.076 28.5574H211.475L215.33 17.6689Z" fill="#FF8C10" />
        <path d="M210.067 32.1864H164.791L160.991 43.0735H206.337L210.067 32.1864Z" fill="#FF8C10" />
        <path d="M342.1 46.7026H330.5L326.647 57.5897H372.041H372.047L375.901 46.7026H364.299H342.1Z" fill="#FF8C10" />
        <path d="M348.526 28.5574H370.721L365.583 43.0735H377.185L386.177 17.6689H340.778L331.786 43.0735H343.385L348.526 28.5574Z" fill="#FF8C10" />
        <path d="M454.848 17.6689H443.245L434.252 43.0735H445.851L454.848 17.6689Z" fill="#FF8C10" />
        <path d="M431.428 17.6689H419.825L410.833 43.0735H422.432L431.428 17.6689Z" fill="#FF8C10" />
        <path d="M421.147 46.7026H409.549H399.11H387.511L383.658 57.5897H440.71L444.566 46.7026H432.968H421.147Z" fill="#FF8C10" />
        <path d="M409.39 17.6689H397.789L388.796 43.0735H400.395L409.39 17.6689Z" fill="#FF8C10" />
      </g>
      <defs>
        <clipPath id="clip0_6286_5375">
          <rect width="454.848" height="57.6" fill="white" />
        </clipPath>
      </defs>
    </SVGIcon>
  )
}

export default CalidoValleyYellowLogo
