const CEX_API = `https://js.cexplorer.io/api-static/asset/list.json`

const getPriceFromCEX = async (id: string): Promise<number> => {
    let price = 0

    await fetch(CEX_API)
        .then((response) => response.json())
        .then((result) => {
            const { data } = result

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const token = data.find((item: any) => item.fingerprint === id)
            price = token?.price_ada || 0
        })
        .catch(() => {
            price = 0
        })

    return typeof price === 'string' ? parseFloat(price) : price
}

export default getPriceFromCEX
