import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const Medium = (props: SVGIconProps) => {
  const { width = 23, height = 14, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} aria-label="Medium" {...svgProps}>
      <path d="M12.86 7.07998C12.86 10.65 9.97999 13.55 6.42999 13.55C2.87999 13.55 0 10.65 0 7.07998C0 3.50998 2.87999 0.609985 6.42999 0.609985C9.97999 0.609985 12.86 3.49998 12.86 7.07998ZM19.91 7.07998C19.91 10.44 18.47 13.17 16.7 13.17C14.93 13.17 13.49 10.44 13.49 7.07998C13.49 3.71998 14.93 0.989985 16.7 0.989985C18.47 0.989985 19.91 3.70998 19.91 7.07998ZM22.79 7.07998C22.79 10.09 22.28 12.54 21.66 12.54C21.04 12.54 20.53 10.1 20.53 7.07998C20.53 4.05998 21.04 1.61999 21.66 1.61999C22.28 1.61999 22.79 4.05998 22.79 7.07998Z" />
    </SVGIcon>
  )
}

export default Medium