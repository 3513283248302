
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react'

import { NavigationContextValueType, NavigationProviderProps } from './types'

const NavigationContext = createContext<NavigationContextValueType | null>(null)

const NavigationProvider = (props: NavigationProviderProps) => {
  const { children } = props
  const [visible, setVisible] = useState<boolean>(false)

  const toggleVisible = useCallback(() => {
    setVisible(!visible)
  }, [visible, setVisible])

  const value = useMemo(
    () => ({
      toggleVisible,
      visible
    }),
    [visible, toggleVisible]
  )

  return (
    <NavigationContext.Provider value={value}>
      {children}
    </NavigationContext.Provider>
  )
}

const useNavigation = () => {
  const context = useContext(NavigationContext)
  if (!context) {
    throw new Error('useNavigation must be used within an < NavigationProvider />')
  }

  return context
}

export { useNavigation, NavigationContext }
export default NavigationProvider