import React, { useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion';

import ConnectedNavSubMenu from './ConnectedNavSubMenu';
import ConnectedNavItem from './ConnectedNavItem';

import { ConnectedNavProps } from './types';
import s from './styles.module.css'

/**
 * A vertical navigation menu where items are linked by lines.
 * @param props 
 * @returns 
 */
const ConnectedNav = (props: ConnectedNavProps) => {
  const { options = [], initialIndex = undefined, onNavItemClick } = props
  const [currentSubMenu, setCurrentSubMenu] = useState<number | undefined>(initialIndex)

  return (
    <>
      <ul className={s.nav_list}>
        {
          options.map((option, index) => {
            const { label, href, subMenu } = option
            const active = currentSubMenu === index

            return (
              // eslint-disable-next-line react/no-array-index-key
              <li key={index} className={s.nav_list_item}>
                <ConnectedNavItem
                  active={active}
                  onClick={() => {
                    if (!href) {
                      setCurrentSubMenu(index)

                      return
                    }

                    if (onNavItemClick) onNavItemClick()
                  }}
                  href={href}>
                  {label}
                </ConnectedNavItem>
                <AnimatePresence initial={false}>
                  {
                    subMenu && currentSubMenu === index &&
                    <motion.div
                      initial={{
                        opacity: 0,
                        height: 0
                      }}
                      animate={{
                        opacity: 1,
                        height: 'auto'
                      }}
                      exit={{
                        opacity: 0,
                        height: 0
                      }}>
                      <ConnectedNavSubMenu
                        onClick={onNavItemClick}
                        links={subMenu} />
                    </motion.div>
                  }
                </AnimatePresence>
              </li>
            )
          })
        }
      </ul>
    </>
  )
}

export default ConnectedNav
