export const COINGECKO_API = 'https://api.coingecko.com/api/v3'

export type CoinGeckoCurrencies =
    | 'aed'
    | 'ars'
    | 'aud'
    | 'bch'
    | 'bdt'
    | 'bhd'
    | 'bmd'
    | 'bnb'
    | 'brl'
    | 'btc'
    | 'cad'
    | 'chf'
    | 'clp'
    | 'cny'
    | 'czk'
    | 'dkk'
    | 'dot'
    | 'eos'
    | 'eth'
    | 'eur'
    | 'gbp'
    | 'hkd'
    | 'huf'
    | 'idr'
    | 'ils'
    | 'inr'
    | 'jpy'
    | 'krw'
    | 'kwd'
    | 'lkr'
    | 'ltc'
    | 'mmk'
    | 'mxn'
    | 'myr'
    | 'ngn'
    | 'nok'
    | 'nzd'
    | 'php'
    | 'pkr'
    | 'pln'
    | 'rub'
    | 'sar'
    | 'sek'
    | 'sgd'
    | 'thb'
    | 'try'
    | 'twd'
    | 'uah'
    | 'usd'
    | 'vef'
    | 'vnd'
    | 'xag'
    | 'xau'
    | 'xdr'
    | 'xlm'
    | 'xrp'
    | 'yfi'
    | 'zar'
    | 'bits'
    | 'link'
    | 'sats'

export type CoinGeckoPlatforms = {
    [key: string]: string
}

export type CoinGeckoCoin = {
    id: string
    symbol: string
    name: string
    platforms: CoinGeckoPlatforms
    market_data: {
        current_price: {
            [key in CoinGeckoCurrencies]: number
        }
        market_cap: {
            [key in CoinGeckoCurrencies]: number
        }
        fully_diluted_valuation: {
            [key in CoinGeckoCurrencies]: number
        }
        total_volume: {
            [key in CoinGeckoCurrencies]: number
        }
        high_24h: {
            [key in CoinGeckoCurrencies]: number
        }
        total_supply: number
        max_suppler: number
        circulating_supply: number
        last_updated: string
    }
}

export type CoinGeckoTokenPrices = {
    [key in string]: CoinGeckoTokenPrice
}

export type CoinGeckoTokenPrice = {
    [key in CoinGeckoCurrencies]: number
}
