import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const Search = (props: SVGIconProps) => {
  const { width = 23, height = 23, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} aria-label="Search" {...svgProps}>
      <path
        fill="none"
        fillRule="evenodd"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        d="M8.83502 16.666C13.1622 16.666 16.6701 13.159 16.6701 8.833C16.6701 4.50695 13.1622 1 8.83502 1C4.50785 1 1 4.50695 1 8.833C1 13.159 4.50785 16.666 8.83502 16.666Z" />
      <path d="M14.2969 14.4606C14.4649 14.2926 22.0479 22.2096 22.0479 22.2096" stroke="white" strokeMiterlimit="10" strokeLinecap="round" />
    </SVGIcon>
  )
}

export default Search