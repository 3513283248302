import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const Close = (props: SVGIconProps) => {
  const { width = 35, height = 35, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} aria-label="Close" {...svgProps}>
      <path d="M20.628 15.0194C20.4613 15.0194 20.3011 14.9553 20.1729 14.8335C19.9229 14.5835 19.9229 14.1796 20.1729 13.9296L33.91 0.186035C34.16 -0.0639648 34.5639 -0.0639648 34.8139 0.186035C35.0639 0.436035 35.0639 0.839881 34.8139 1.08988L21.0767 14.8271C20.9485 14.9553 20.7882 15.013 20.6216 15.013L20.628 15.0194Z" fill="currentcolor" />
      <path d="M0.640675 34.9998C0.474008 34.9998 0.313752 34.9357 0.185547 34.8139C-0.0644531 34.5639 -0.0644531 34.16 0.185547 33.91L13.9227 20.1729C14.1727 19.9229 14.5766 19.9229 14.8266 20.1729C15.0766 20.4229 15.0766 20.8267 14.8266 21.0767L1.0958 34.8139C0.967598 34.9421 0.807342 34.9998 0.640675 34.9998Z" fill="currentcolor" />
      <path d="M34.3593 34.9998C34.1926 34.9998 34.0324 34.9357 33.9042 34.8139L20.167 21.0767C19.917 20.8267 19.917 20.4229 20.167 20.1729C20.417 19.9229 20.8208 19.9229 21.0708 20.1729L34.808 33.91C35.058 34.16 35.058 34.5639 34.808 34.8139C34.6798 34.9421 34.5196 34.9998 34.3529 34.9998H34.3593Z" fill="currentcolor" />
      <path d="M14.3779 15.0194C14.2112 15.0194 14.0509 14.9553 13.9227 14.8335L0.185547 1.09629C-0.0644531 0.846292 -0.0644531 0.436035 0.185547 0.186035C0.435547 -0.0639648 0.845803 -0.0639648 1.0958 0.186035L14.833 13.9232C15.083 14.1732 15.083 14.5771 14.833 14.8271C14.7048 14.9553 14.5445 15.013 14.3779 15.013V15.0194Z" fill="currentcolor" />
    </SVGIcon>
  )
}

export default Close
