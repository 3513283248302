import { useRef, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { ClientOnlyPortalProps } from './type'

const ClientOnlyPortal = (props: ClientOnlyPortalProps) => {
  const { children, selector } = props
  const ref = useRef<Element | null>(null)
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    ref.current = document.querySelector(selector)
    setMounted(true)
  }, [selector])

  return mounted && ref.current ? createPortal(children, ref.current) : null
}

export default ClientOnlyPortal
