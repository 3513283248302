import React, { useCallback, useEffect, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import cx from 'classnames';

import useKeyPress from 'hooks/useKeyPress'

import Iframe from '../Iframe'

import s from './styles.module.css'
import Button from '../Button'
import VisuallyHidden from '../VisuallyHidden'
import { Close } from '../Icon'
import { ModalVideoProps } from './types'
import ClientOnlyPortal from '../ClientOnlyPortal';

/**
 * Modal popup with embedded video player
 * @param props 
 * @returns 
 */
const ModalVideo = (props: ModalVideoProps) => {
  const { src, trigger } = props
  const [open, setOpen] = useState<boolean>(false)
  const escape = useKeyPress("Escape")

  const close = () => setOpen(false)

  const classes = cx(s.modal, open && s.open, "overlay")


  const clickHandler = (evt: React.MouseEvent) => {
    const { target } = evt
    if (target instanceof Element && target.classList.contains("overlay")) {
      close();
    }
  };


  const onClick = useCallback(() => {
    setOpen(true)
  }, [])

  useEffect(() => {
    if (!open) return

    if (escape) {
      close()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [escape])

  return (
    <>
      {
        trigger && trigger(onClick)
      }
      <ClientOnlyPortal selector='#modals'>
        <AnimatePresence>
          {
            open &&
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={clickHandler}
              className={classes}>
              <div className={s.content}>
                <div className={s.header}>
                  <Button
                    className={s.close_button}
                    onClick={() => close()}
                  >
                    Close
                    <VisuallyHidden>Close</VisuallyHidden>
                    <Close className={s.close_icon} />
                  </Button>
                </div>
                <Iframe
                  src={src}
                  title="Video player"
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            </motion.div>
          }
        </AnimatePresence>

      </ClientOnlyPortal>
    </>
  )
}

export default ModalVideo
