import { EmailConfirmRequestType } from 'types'
import { PlayerHttpClient } from '../httpClients'

const confirm = async (data: EmailConfirmRequestType) => {
    const response = await PlayerHttpClient.AccountConfirm(data)

    return response
}

export default confirm
