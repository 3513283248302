import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const Exit = (props: SVGIconProps) => {
  const { width = 10, height = 10, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} {...svgProps}>
      <path d="M9.87789 5.29687L7.69091 7.48437C7.61094 7.56083 7.50472 7.6037 7.3941 7.60417C7.3115 7.60403 7.2308 7.57933 7.16227 7.53321C7.09373 7.48709 7.04045 7.42163 7.0092 7.34515C6.97794 7.26868 6.97013 7.18463 6.98674 7.1037C7.00335 7.02276 7.04365 6.9486 7.1025 6.89062L8.57611 5.41667H3.74912C3.63864 5.41667 3.53268 5.37277 3.45456 5.29463C3.37644 5.21649 3.33255 5.11051 3.33255 5C3.33255 4.88949 3.37644 4.78351 3.45456 4.70537C3.53268 4.62723 3.63864 4.58333 3.74912 4.58333H8.57611L7.1025 3.10937C7.02378 3.03133 6.97929 2.9252 6.9788 2.81434C6.97831 2.70348 7.02187 2.59697 7.0999 2.51823C7.17793 2.43949 7.28403 2.39498 7.39486 2.3945C7.5057 2.39401 7.61219 2.43758 7.69091 2.51562L9.87789 4.70312C9.95612 4.78212 10 4.88881 10 5C10 5.11119 9.95612 5.21788 9.87789 5.29687ZM3.74912 9.16667H0.833138V0.833333H3.74912C3.8596 0.833333 3.96556 0.789435 4.04368 0.711294C4.1218 0.633154 4.16569 0.527174 4.16569 0.416667C4.16569 0.30616 4.1218 0.200179 4.04368 0.122039C3.96556 0.0438988 3.8596 0 3.74912 0H0.833138C0.612176 0 0.400264 0.0877973 0.24402 0.244078C0.0877768 0.400358 0 0.61232 0 0.833333V9.16667C0 9.38768 0.0877768 9.59964 0.24402 9.75592C0.400264 9.9122 0.612176 10 0.833138 10H3.74912C3.8596 10 3.96556 9.9561 4.04368 9.87796C4.1218 9.79982 4.16569 9.69384 4.16569 9.58333C4.16569 9.47283 4.1218 9.36685 4.04368 9.2887C3.96556 9.21056 3.8596 9.16667 3.74912 9.16667Z" fill="currentcolor" />
    </SVGIcon>
  )
}

export default Exit
