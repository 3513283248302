import { createHash } from 'crypto'

function encodeId(strings: string[]): string {
    const str = strings.join('')
    const hash = createHash('sha256').update(str).digest()

    return Buffer.from(hash).toString('base64')
}

export default encodeId
