import React from 'react'
import SVGIcon from '../Icon'
import { SVGIconProps } from '../types'

const AdaIcon = (props: SVGIconProps) => {
  const { width = 19, height = 23, ...svgProps } = props

  return (
    <SVGIcon width={width} height={height} {...svgProps}>
      <path d="M18.9988 13.8708C18.9716 13.3453 18.5096 12.9443 17.9796 12.9443H15.0987L14.3105 10.9114H16.0907C16.6071 10.9114 17.0827 10.5241 17.1099 9.9848C17.1371 9.43162 16.7022 8.97525 16.1722 8.97525H13.5631L10.3017 0.608492C10.1659 0.248928 9.81253 0 9.41845 0C9.03796 0 8.68464 0.248928 8.53516 0.608492L5.40967 8.96142H2.85492C2.33853 8.96142 1.86291 9.34865 1.83574 9.88799C1.80856 10.4412 2.24341 10.8975 2.77338 10.8975H4.63509L3.8741 12.9166H1.02039C0.504005 12.9166 0.0283867 13.3038 0.00120854 13.8432C-0.0259696 14.3964 0.408881 14.8527 0.938856 14.8527H3.1267L0.571951 21.6015C0.381703 22.0993 0.626307 22.6663 1.11551 22.8599C1.22423 22.9152 1.33294 22.9152 1.44165 22.9152C1.82215 22.9152 2.17546 22.694 2.32494 22.3068L5.15148 14.8666H13.7534L16.6886 22.3897C16.8245 22.777 17.1778 22.9982 17.5719 22.9982C17.6806 22.9982 17.8165 22.9706 17.9252 22.9429C18.4144 22.7493 18.6319 22.1961 18.4416 21.6844L15.7781 14.8804H17.9388C18.5775 14.8666 19.0259 14.424 18.9988 13.8708ZM11.5112 8.96142H7.46162L9.28202 4.1347C9.3433 3.97221 9.56888 3.9711 9.63085 4.13346C10.1244 5.42858 11.5112 8.96142 11.5112 8.96142ZM5.92605 12.9443L6.68704 10.9114H12.2178L13.006 12.9443H5.92605Z" />
    </SVGIcon>
  )
}

export default AdaIcon