import { createContext, useContext } from 'react'

/**
 * Used to pass site nonce to components for Content Security Plan
 */
const NonceContext = createContext<string>('')

export const useNonce = (): string => useContext(NonceContext)

export default NonceContext
