import { NextSeoProps } from 'next-seo'

export type CoverImageType = {
    src: string
    alt?: string
}

export type AuthorType = {
    displayName?: string
    firstName?: string
    lastName?: string
}

export type TagType = {
    displayName: string
    slug: string
}

export type CategoryType = {
    id: number
    displayName: string
    slug: string
}

export enum PostStatusTypes {
    DRAFT = 'draft',
    PUBLISHED = 'published',
}
export type PostStatusType = string

export enum PostTypeTypes {
    POST = 'post',
    PAGE = 'page',
    ANNOUNCEMENT = 'announcement',
    JOBLISTING = 'joblisting',
}
export type PostTypeType = string

export interface PostType {
    id: number
    title: string
    status: PostStatusType
    content: string
    publishedDate: string
    type: PostTypeType
    slug: string
    excerpt?: string
    author?: AuthorType
    coverImage?: CoverImageType | null
    tags?: Array<TagType> | null
    categories?: Array<CategoryType> | null
    isFeatured?: boolean
    seo?: NextSeoProps
}

export type PostsType = {
    posts: PostType[]
    categories: CategoryType[]
}
