/* eslint-disable max-classes-per-file */
import capitalize from 'helpers/capitalize'

import { NetworkType, UnavailableWalletVisibility } from '../global/types'

export const toHexString = (byteArray: Uint8Array) =>
    // eslint-disable-next-line no-bitwise
    Array.from(byteArray, (byte) => `0${(byte & 0xff).toString(16)}`.slice(-2)).join('')

export class FailedToReadWalletError extends Error {
    constructor(walletname: string) {
        const message = `Failed to read the ${capitalize(
            walletname,
        )} wallet. Please open the browser extension to ensure it is working correctly and try again.`
        super(message)
        this.name = 'FailedToReadWalletError'
    }
}

export class WrongNetworkTypeError extends Error {
    constructor(targetNetwork: NetworkType, currentNetwork: NetworkType) {
        const message = `You have tried to call functions on ${capitalize(
            currentNetwork,
        )}, while the network type is limited to ${capitalize(
            targetNetwork,
        )}. Please change the network and try again.`
        super(message)
        this.name = 'WrongNetworkTypeError'
    }
}

export class WalletNotCip30CompatibleError extends Error {
    constructor(walletname: string) {
        const message = `It seems that the API of ${capitalize(
            walletname,
        )} is not cip30 compatible.`
        super(message)
        this.name = 'WalletNotCip30CompatibleError'
    }
}

export class WalletExtensionNotFoundError extends Error {
    constructor(walletname: string) {
        const message = `${capitalize(
            walletname,
        )} was not found. Please check if it is installed correctly.`
        super(message)
        this.name = 'WalletExtensionNotFoundError'
    }
}

export class EnablementFailedError extends Error {
    constructor(walletname: string, reason?: string) {
        const wallet = walletname === 'typhoncip30' ? 'typhon' : walletname
        let message = `Cannot enable ${capitalize(wallet)}. ${
            reason ? `Reason: '${reason}'. ` : ''
        }`

        if (walletname === 'eternl' && reason === 'no account set')
            message = `Cannot enable ${capitalize(
                wallet,
            )}. No DApp account selected. Please open Eternl, click DApp Browser and connect an account.`

        super(message)
        this.name = 'EnablementFailedError'
    }
}

export const checkIsMobile = () =>
    typeof navigator === 'undefined'
        ? false
        : /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
              navigator.userAgent,
          )

export const estimateAvailableWallets = (
    supportedWallets: Array<string>,
    showUnavailableWallets: UnavailableWalletVisibility,
    alwaysVisibleWallets: Array<string>,
    installedExtensions: Array<string>,
) => {
    let availableWallets: Array<string> = []
    const { HIDE_UNAVAILABLE, SHOW_UNAVAILABLE } = UnavailableWalletVisibility

    if (showUnavailableWallets === HIDE_UNAVAILABLE) {
        availableWallets = supportedWallets.filter((supportExtension) =>
            installedExtensions.includes(supportExtension.toLowerCase()),
        )
    } else if (showUnavailableWallets === SHOW_UNAVAILABLE) {
        availableWallets = supportedWallets
    } else if (checkIsMobile()) {
        availableWallets = supportedWallets
    } else {
        availableWallets = supportedWallets.filter((supportExtension) =>
            installedExtensions.includes(supportExtension.toLowerCase()),
        )
    }

    availableWallets = Array.from(
        new Set([
            ...alwaysVisibleWallets.map((wallet) => wallet.toLowerCase()),
            ...availableWallets,
        ]),
    )

    return availableWallets
}

export class InjectWalletListener {
    interval: number

    onChangeCallback: (wallets: string[]) => void

    timeoutId: NodeJS.Timeout | null

    private wallets: Array<string>

    constructor(onChangeCallback: (wallets: string[]) => void) {
        this.interval = 20
        this.timeoutId = null
        this.onChangeCallback = onChangeCallback
        this.wallets = []
    }

    private checkWallets() {
        const injectedWallets = window.cardano && Object.keys(window.cardano).sort()
        if (JSON.stringify(this.wallets) !== JSON.stringify(injectedWallets)) {
            this.wallets = injectedWallets
            this.onChangeCallback(this.wallets)
        }
    }

    start() {
        this.timeoutId = setTimeout(() => {
            this.checkWallets()

            if (this.interval < 10000) {
                this.interval *= 1.5
            }

            this.start()
        }, this.interval)
    }

    stop() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId)
        }
    }
}
