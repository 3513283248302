import { useCallback } from 'react'
import { useConnect, useSignMessage, Config, useDisconnect } from 'wagmi'

import { ConnectData } from 'wagmi/query'
import { UserOwnedAsset } from '@/types/Api'

import { checkIsMobile } from '../utils'
import { SupportedWalletId } from '../types'
import { EVM_WALLET_ID_COINBASE, EVM_WALLET_ID_METAMASK } from './consts'

const useEvm = () => {
    const { connect: connectWallet, connectors } = useConnect()
    const { disconnectAsync: disconnectWallet } = useDisconnect()
    const { signMessageAsync: evmSignMessage } = useSignMessage()

    const getConnector = useCallback(
        (walletName: string) => {
            try {
                const connector = connectors.find(
                    (wallet) => wallet.id.toLowerCase() === walletName.toLowerCase(),
                )

                return connector
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error('failed to connect', e)
            }
        },
        [connectors],
    )

    const connect = useCallback(
        async (
            walletName: string,
            onConnect?: (
                walletName: string,
                address?: readonly string[] | null,
            ) => void | undefined,
            onError?: (code: Error) => void,
        ) => {
            try {
                const connector = getConnector(walletName)

                if (connector) {
                    // Disconnect existing connections
                    await disconnectWallet({ connector })
                    connectWallet(
                        {
                            connector,
                        },
                        {
                            onSuccess: (data: ConnectData<Config>) => {
                                if (onConnect) {
                                    // console.log('connected', walletName)
                                    onConnect(walletName, data.accounts)
                                }
                            },
                            onError: (error) => {
                                // Don't do anything
                                //console.log('Cancelled', error)
                                if (onError) {
                                    onError(error)
                                }
                            },
                        },
                    )
                }
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error('failed to connect', e)
            }
        },
        [connectWallet, disconnectWallet],
    )

    const disconnect = useCallback(
        /**
         * Disconnect connected wallet
         */
        (walletName: string) => {
            try {
                const connector = getConnector(walletName)

                if (connector) {
                    disconnectWallet({
                        connector,
                    })
                }
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error('failed to disconnect', e)
            }
        },
        [disconnectWallet],
    )

    const signMessage = useCallback(
        /**
         *
         * Sign Data.
         * @param message
         * @param address
         * @param onSignMessage
         * @param onSignError
         * @returns
         */
        async (
            message: string,
            address?: string,
            onSignMessage?: (signature: string, key: string | undefined) => void,
            onSignError?: (error: Error) => void,
        ) => {
            try {
                const result = await evmSignMessage({
                    message,
                    account: address as `0x${string}`,
                })

                if (typeof onSignMessage === 'function') {
                    onSignMessage(result, undefined)
                }
            } catch (error) {
                if (typeof onSignError === 'function') {
                    onSignError(error as Error)
                } else {
                    // eslint-disable-next-line no-console
                    console.warn(error)
                }
            }
        },
        [evmSignMessage],
    )

    const signTx = useCallback(
        /**
         * TODO:: This needs finished
         * Sign Data.
         * @param message
         * @param address
         * @param onSignMessage
         * @param onSignError
         * @returns
         */
        async (
            message: string,
            address?: string,
            onSignTx?: (signature: string) => void,
            onSignError?: (error: Error) => void,
        ) => {
            try {
                const result = await evmSignMessage({
                    message,
                    account: address as `0x${string}`,
                })

                if (typeof onSignTx === 'function') {
                    onSignTx(result)
                }
            } catch (error) {
                if (typeof onSignError === 'function') {
                    onSignError(error as Error)
                } else {
                    // eslint-disable-next-line no-console
                    console.warn(error)
                }
            }
        },
        [evmSignMessage],
    )

    /**
     * Find addresses that contain accepted policy ids
     */
    const getAddressesWithAssets = useCallback(
        async (walletName: string): Promise<string[]> => {
            // console.log('Not fully implemented - getAddressesWithAssets')

            try {
                const connector = getConnector(walletName)

                if (connector) {
                    const addresses = await connector.getAccounts()

                    return [...addresses]
                }
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error('failed to get addresses', e)
            }

            return []
        },
        [getConnector],
    )

    /**
     * Find assets in addresses
     */
    const getAddressAssets = useCallback(async (): Promise<UserOwnedAsset[]> => {
        // console.log('Not implemented - getAddressAssets')

        try {
            return []
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error('failed to get addresses', e)
        }

        return []
    }, [])

    const isWalletInstalled = (walletName: string) => {
        const { ethereum } = window

        if (typeof ethereum === 'undefined') {
            return false
        }

        const connector = getConnector(walletName)

        if ((walletName as SupportedWalletId) === EVM_WALLET_ID_METAMASK)
            return checkIsMobile() || (window.ethereum.isMetaMask && !!connector)

        // isCoinbaseWallet didn't seem to work so let's just go with checking if there is a connector
        if ((walletName as SupportedWalletId) === EVM_WALLET_ID_COINBASE)
            return checkIsMobile() || (window.ethereum && !!connector)

        return true
    }

    /**
     * Get enabled wallet address
     * @returns string
     */
    const getAddress = async (walletName: string): Promise<string[] | undefined> => {
        try {
            const connector = getConnector(walletName)

            if (connector) {
                const addresses = await connector.getAccounts()

                return addresses.map((addr) => addr.toString())
            }

            return undefined
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error('failed to disconnect', e)
        }
    }

    return {
        connect,
        disconnect,
        signMessage,
        signTx,
        getAddressAssets,
        getAddressesWithAssets,
        isWalletInstalled,
        getAddress,
    }
}

export default useEvm
