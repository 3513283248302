import useAuthApi from 'hooks/useAuthApi'
import { NodeListRequest, OnChainNodesRequest } from '@/types/Nodes'
import { NodeHttpClient } from '../network'

export const NODES_QUERY_KEY = 'nodes'
export const NODES_OVERVIEW_QUERY_KEY = `${NODES_QUERY_KEY}_overview`
export const NODES_ONCHAIN_QUERY_KEY = `${NODES_QUERY_KEY}_onchain`
export const NODES_LIST_QUERY_KEY = `${NODES_QUERY_KEY}_list`

export const useNodesOverviewQuery = () =>
    useAuthApi({
        queryKey: [NODES_OVERVIEW_QUERY_KEY],
        queryFn: (token) => NodeHttpClient.setAccessToken(token).GetNodesOverview(),
        retry: false,
        refetchOnWindowFocus: false,
    })

export const useNodesQuery = (params: NodeListRequest, refetchInterval: number | false = false) =>
    useAuthApi({
        queryKey: [NODES_LIST_QUERY_KEY, params],
        queryFn: (token) => NodeHttpClient.setAccessToken(token).GetNodeList(params),
        retry: false,
        refetchOnWindowFocus: false,
        refetchInterval,
    })

export const useOnChainNodesQuery = (
    params: OnChainNodesRequest,
    refetchInterval: number | false = false,
) =>
    useAuthApi({
        queryKey: [NODES_ONCHAIN_QUERY_KEY, params],
        queryFn: (token) => NodeHttpClient.setAccessToken(token).GetOnChainNodes(params),
        retry: false,
        refetchOnWindowFocus: false,
        refetchInterval,
    })
