/* eslint-disable react/no-array-index-key */
import React from 'react'

import Link from '@/components/ui/Link'
import { ROUTE_BLOG_TAG } from '@/consts'
import { buildPath } from '@/helpers'

import s from './styles.module.css'
import { TagsProps } from './types'

const Tags = (props: TagsProps) => {
  const { tags, separator = '/' } = props

  return (
    <>
      {
        tags &&
        <div className={s.tag_wrapper}>
          {tags.map((tag, index) => (
            <span key={index} className={s.tag}>
              {index ? <span className={s.separator}>{separator}</span> : ''}
              <Link variant="secondary" href={buildPath(ROUTE_BLOG_TAG, tag.slug)}>{tag.displayName}</Link>
            </span>
          ))}
        </div>
      }
    </>
  )
}

export default Tags 